import React, { useState } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  Typography,
  Box,
  Dialog,
  DialogTitle,
  DialogContent,
  useTheme,
} from '@mui/material';
import { Visibility as VisibilityIcon } from '@mui/icons-material';
import CloseIcon from '@mui/icons-material/Close';
import { useTranslation } from 'react-i18next';

const getStatusStyles = (status) => {
  if (status === 'PENDING') {
    return {
      background: '#FFE6AD',
      color: '#FFB100',
      padding: '4px 16px',
      fontWeight: '700',
      lineHeight: '16px',
      textAlign: 'center',
      borderRadius: '8px',
    };
  }
  if (status === 'REJECTED') {
    return {
      backgroundColor: '#FFCDD2', // Red background
      color: '#D32F2F', // Dark red text
      textAlign: 'center',
      padding: '4px 16px',
      borderRadius: '8px',
    };
  }
  // Add a default case or handle other statuses as needed
  return {
    // Default styles
    backgroundColor: '#FFF', // White background
    color: '#333', // Dark gray text
    padding: '5px 10px',
    borderRadius: '5px',
  };
};

const CustomTable = ({ columns, rows = [], actions, userId, isManager, tabValue, handleViewDetails }) => {
  const { t, i18n } = useTranslation();
  const isRtl = i18n.language === 'ar';
  const theme = useTheme();
  const isDarkMode = theme.palette.mode === 'dark';
  const [openDialog, setOpenDialog] = useState(false);
  const [dialogContent, setDialogContent] = useState('');

  const handleDialogOpen = (comment) => {
    setDialogContent(comment || '');
    setOpenDialog(true);
  };

  const handleDialogClose = () => {
    setOpenDialog(false);
    setDialogContent('');
  };

  // const shouldShowActions = (row) => Number(isManager) === 1 || userId === row.managerId;
  const shouldShowActions = (isManager, userId, row) => {
    if (!row || row.managerId === undefined) return false;
    return Number(isManager) === 1 || userId === row.managerId;
  };

  const getCursorStyle = (tabValue, isManager, userId, row) => {
    if (Number(isManager) === 1 || (tabValue === 0 && row.managerId === userId && row.status === 'PENDING'))
      return 'pointer';
    if (tabValue === 0 && row.managerId !== userId && row.status === 'REJECTED') return 'pointer';
    return 'default';
  };
  const getRowClickHandler = (isManager, userId, row, handleViewDetails) => {
    if (!shouldShowActions(isManager, userId, row) && row.status === 'PENDING') return undefined;
    return () => handleViewDetails(row);
  };

  return (
    <TableContainer component={Paper} sx={{ mt: 3 }}>
      <Table aria-label="custom table" sx={{ direction: isRtl ? 'rtl' : 'ltr' }}>
        <TableHead sx={{ whiteSpace: 'nowrap' }}>
          <TableRow>
            {columns.map((column) => (
              <TableCell sx={{ backgroundColor: isDarkMode ? '#000000' : '#32de84' }} key={column.field}>
                {' '}
                <Typography
                  sx={{
                    color: 'white',
                    fontWeight: '700',
                    fontSize: '12px',
                    lineHeight: '20px',
                    whiteSpace: 'nowrap',
                  }}
                >
                  {column.headerName}{' '}
                </Typography>
              </TableCell>
            ))}
            {tabValue === 0 && rows.some((row) => shouldShowActions(isManager, userId, row)) && (
              <TableCell sx={{ backgroundColor: isDarkMode ? '#000000' : '#32de84' }}>
                {' '}
                <Typography
                  sx={{
                    color:  "white",
                    fontWeight: '700',
                    fontSize: '12px',
                    lineHeight: '20px',
                    whiteSpace: 'nowrap',
                  }}
                >
                  {t('General.Action')}
                </Typography>
              </TableCell>
            )}
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row, rowIndex) => {
            return (
              <TableRow
                sx={{
                  cursor: getCursorStyle(tabValue, isManager, userId, row),
                  whiteSpace: 'nowrap',
                }}
                // onClick={() => handleViewDetails(row)}
                onClick={getRowClickHandler(isManager, userId, row, handleViewDetails)}
                key={rowIndex}
              >
                {columns.map((column) => (
                  <TableCell sx={{ whiteSpace: 'nowrap' }} key={column.field}>
                    {column.field === 'status' ? (
                      <Box sx={getStatusStyles(row[column.field])}>
                        <Typography
                          sx={{ color: 'text.secondary', fontWeight: '400', fontSize: '12px', lineHeight: '20px' }}
                          variant="subtitle2"
                          noWrap
                        >
                          {row[column.field]}
                        </Typography>
                      </Box>
                    ) : column.field === 'comment' && row.status === 'REJECTED' ? (
                      <IconButton
                        onClick={(e) => {
                          e.stopPropagation();
                          handleDialogOpen(row[column.field]);
                        }}
                      >
                        <VisibilityIcon />
                      </IconButton>
                    ) : column.field === 'productType' ? (
                      t(`General.${row[column.field]}`)
                    ) : (
                      row[column.field]
                    )}
                  </TableCell>
                ))}
                {tabValue === 0 && shouldShowActions(isManager, userId, row) && (
                  <TableCell onClick={(e) => e.stopPropagation()}>
                    {actions.map((action, actionIndex) => (
                      <IconButton
                        key={actionIndex}
                        color={action.color}
                        onClick={(e) => {
                          e.stopPropagation();
                          action.handler(row);
                        }}
                      >
                        {action.icon}
                      </IconButton>
                    ))}
                  </TableCell>
                )}
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
      <Dialog sx={{ p: 4 }} open={openDialog} onClose={handleDialogClose} maxWidth="sm" fullWidth>
        <DialogTitle>
          Reasons of Rejection
          <IconButton
            aria-label="close"
            onClick={handleDialogClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent
          sx={{
            margin: '15px',
            border: '1px solid #f0e9e9',
            borderRadius: '8px',
            backgroundColor: '#f7f7f7',
            color: '#676363',
          }}
          dividers
        >
          {dialogContent || ''}
        </DialogContent>
      </Dialog>
    </TableContainer>
  );
};

export default CustomTable;
