import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Box, Grid, Typography } from '@mui/material';
import { TabPanel, TabContext } from '@mui/lab';
import { useTranslation } from 'react-i18next';
import { useProjectFormContext } from '../context/projectFormContext';
import ManageBuildingsWithProjectId from './ManageBuildingsWithProjectId';
import UnitsListWithProjectId from './UnitsWithProjectId';
import ProjectDataForm from '../sections/@dashboard/projectForm/projectDataForm';
import OwnerDataForm from '../sections/@dashboard/projectForm/ownerDataForm';
import ConsltantDataForm from '../sections/@dashboard/projectForm/consltantDataForm';
import StyledTabs from '../components/StyledTabs/StyledTabs';
import Layout from '../components/Layout/Layout';

const ManageProject = () => {
  const {
    isViewMode,
    isEditMode,
    uploadedFilesUploaded,
    handleDelete,
    handleSubmit,
    id,
    loading_submit,
    detailsData,
    addItem,
    deleteItem,
    refetch,
    setRefetch,
    setSelectedFacilities,
    setSelectedServices,
    setSelectedPostSaleServices,
    selectedFacilities,
    selectedServices,
    selectedAfterSaleServices,
  } = useProjectFormContext();
  const location = useLocation();
  const fromPage = location.state;

  const projectId = detailsData?.id;
  const { t } = useTranslation();
  const [currentTab, setCurrentTab] = useState('1');

  useEffect(() => {
    if (fromPage?.from === 'add-unit-with-project' && projectId) {
      setCurrentTab('5');
    } else if (fromPage?.from === 'add-building-with-project' && projectId) {
      setCurrentTab('4');
    }
  }, [projectId]);

  const handleChange = (newValue) => {
    if (isEditMode) setCurrentTab(newValue);
  };

  const handleNextTab = () => {
    setCurrentTab((prevValue) => {
      const nextValue = parseInt(prevValue, 10) + 1;
      return nextValue > 3 ? '1' : nextValue.toString();
    });
  };

  const handlePrevTab = () => {
    setCurrentTab((prevValue) => {
      const prevValueInt = parseInt(prevValue, 10) - 1;
      return prevValueInt < 1 ? '3' : prevValueInt.toString();
    });
  };
  const tabs = [
    { label: t('ManageProject.projectData'), value: '1' },
    { label: t('ManageProject.ownerData'), value: '2' },
    { label: t('ManageProject.consultantData'), value: '3' },
  ];
  if (isEditMode) {
    tabs.push({ label: t('ManageProject.buildingData'), value: '4' });
    tabs.push({ label: t('ManageProject.unitData'), value: '5' });
  }
  return (
    <>
      <Layout>
        <Typography variant="h4" gutterBottom>
          {t('ManageProject.title')}{' '}
        </Typography>

        <TabContext value={currentTab}>
          <Box
            sx={{
              borderBottom: 1,
              borderColor: 'divider',
              display: 'flex',
            }}
          >
            <StyledTabs value={currentTab} onChange={handleChange} tabs={tabs} />
          </Box>
          <TabPanel value="1">
            <ProjectDataForm
              id={id}
              setSelectedFacilities={setSelectedFacilities}
              setSelectedServices={setSelectedServices}
              setSelectedPostSaleServices={setSelectedPostSaleServices}
              selectedFacilities={selectedFacilities}
              selectedServices={selectedServices}
              selectedAfterSaleServices={selectedAfterSaleServices}
              uploadedFilesUploaded={uploadedFilesUploaded}
              isViewMode={isViewMode}
              isEditMode={isEditMode}
              editData={detailsData}
              handleDelete={handleDelete}
              handleNext={handleNextTab}
              addItem={addItem}
              deleteItem={deleteItem}
              refetch={refetch}
              setRefetch={setRefetch}
            />
          </TabPanel>
          <TabPanel value="2">
            <OwnerDataForm
              id={id}
              editData={detailsData && detailsData?.owner}
              isViewMode={isViewMode}
              handlePrev={handlePrevTab}
              handleNext={handleNextTab}
            />
          </TabPanel>
          <TabPanel handleNext={handleNextTab} handlePrev={handlePrevTab} value="3">
            <ConsltantDataForm
              id={id}
              editData={detailsData && detailsData?.consultant}
              handlePrev={handlePrevTab}
              submitForm={handleSubmit}
              isViewMode={isViewMode}
              loading_submit={loading_submit}
            />
          </TabPanel>
          <TabPanel handleNext={handleNextTab} handlePrev={handlePrevTab} value="4">
            <Grid md={12}>
              <ManageBuildingsWithProjectId id={projectId} />
            </Grid>
          </TabPanel>
          <TabPanel handleNext={handleNextTab} handlePrev={handlePrevTab} value="5">
            <Grid md={12}>
              <UnitsListWithProjectId id={projectId} />
            </Grid>
          </TabPanel>
        </TabContext>
      </Layout>
    </>
  );
};

export default ManageProject;
