// LoadingOverlay.js
import React from 'react';
import { Box, CircularProgress } from '@mui/material';
import logo from '../../theme/images/logo.jpeg';


const LoadingOverlay = () => (
  <Box
    sx={{
      position: 'fixed',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      backgroundColor: 'rgba(255, 255, 255, 0.8)',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      zIndex: 1300,
      '@keyframes spin': {
        '0%': {
          transform: 'rotate(0deg)',
        },
        '100%': {
          transform: 'rotate(360deg)',
        },
      },
      '& img': {
        width: '50px',
        height: '50px',
        animation: 'spin 2s linear infinite',
      },
    }}
  >
    <img src={logo} alt="Loading..." />
  </Box>
);

export default LoadingOverlay;