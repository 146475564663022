/* eslint-disable react/prop-types */
import { useState } from 'react';
// @mui

import {
  Table,
  TableRow,
  TableBody,
  TableCell,
  Typography,
  TableContainer,
  Pagination,
  Button,
  Box,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { ReactComponent as ShowIcon } from '../../../theme/images/showTableIcon.svg';

// components
import Scrollbar from '../../../components/scrollbar';
// sections
import ClientHeadTable from '../../../components/clientHeadTable/ClientHeadTable';

// ----------------------------------------------------------------------

// ----------------------------------------------------------------------

export default function UsersListTable({ usersList, handleChangePage, page, setShow }) {
  const [rowsPerPage] = useState(10); // Set a default value for rowsPerPage

  const { t, i18n } = useTranslation();
  const isRtl = i18n.language === 'ar';
  const TABLE_HEAD = [
    { id: 'name', label: t('users.userName'), alignRight: isRtl && true },
    { id: 'email', label: t('users.userEmail'), alignRight: isRtl && true },
    { id: 'phoneNumber', label: t('users.phoneNumber'), alignRight: isRtl && true },
    { id: 'dateOfBirth', label: t('users.dateOfBirth'), alignRight: isRtl && true },
    { id: 'verfied', label: t('users.verfied'), alignRight: isRtl && true },

    { id: 'actions', label: t('General.Action'), alignRight: isRtl && true },
  ];
  return (
    <>
      <Scrollbar>
        <TableContainer sx={{ minWidth: 800 }}>
          <Table>
            <ClientHeadTable headLabel={TABLE_HEAD} />
            <TableBody>
              {usersList?.content?.map((row) => {
                const { userId, firstNameEn, firstNameAr, email, phone, birthDate, isVerified } = row;
                return (
                  <TableRow hover key={userId} tabIndex={-1}>
                    <TableCell sx={{ textAlign: isRtl ? 'right' : 'left' }} component="th" scope="row">
                      <Typography variant="subtitle2" noWrap>
                        {isRtl ? firstNameAr : firstNameEn}
                      </Typography>
                    </TableCell>
                    <TableCell sx={{ textAlign: isRtl ? 'right' : 'left' }} align="left">
                      {email}
                    </TableCell>
                    <TableCell sx={{ textAlign: isRtl ? 'right' : 'left' }} align="left">
                      {phone}
                    </TableCell>
                    <TableCell sx={{ textAlign: isRtl ? 'right' : 'left' }} align="left">
                      {birthDate}
                    </TableCell>
                    <TableCell component="th" scope="row" sx={{ textAlign: isRtl ? 'right' : 'left' }}>
                      {isVerified === '1' ? (
                        <Box
                          sx={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            padding: '4px 16px',
                            backgroundColor: '#E7F7EF',
                            borderRadius: '8px',
                            width: '88px',
                          }}
                        >
                          <Typography
                            sx={{ color: '#0CAF60', fontWeight: '700', fontSize: '10px', lineHeight: '16px' }}
                            variant="subtitle2"
                            textAlign={'center'}
                            noWrap
                          >
                            {t('users.verfied')}
                          </Typography>
                        </Box>
                      ) : (
                        <Box
                          sx={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            padding: '4px 16px',
                            backgroundColor: '#FFEDEC',
                            borderRadius: '8px',
                            width: '88px',
                          }}
                        >
                          <Typography
                            sx={{ color: '#E03137', fontWeight: '700', fontSize: '10px', lineHeight: '16px' }}
                            variant="subtitle2"
                            textAlign={'center'}
                            noWrap
                          >
                            {t('users.unverfied')}
                          </Typography>
                        </Box>
                      )}
                    </TableCell>
                    <TableCell sx={{ textAlign: isRtl ? 'right' : 'left' }} align="left">
                      <Box
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          gap: '2px',
                        }}
                      >
                        <Button
                          onClick={() =>
                            setShow((prev) => {
                              return { ...prev, open: true, userId };
                            })
                          }
                        >
                          <ShowIcon />
                        </Button>{' '}
                      </Box>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Scrollbar>
      {usersList?.length === 0 && (
        <Box textAlign="center">
          <h5>No data found</h5>
        </Box>
      )}
      {usersList?.totalPages > 0 && (
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            padding: '10px',
            marginTop: '5rem',
          }}
        >
          <Pagination count={usersList.totalPages} page={page + 1} onChange={handleChangePage} color="primary" />
          <Box sx={{ display: 'block' }}>
            {t('General.showing')} {page * rowsPerPage + 1} {t('General.to')}{' '}
            {Math.min((page + 1) * rowsPerPage, usersList.totalElements)} {t('General.of')} {usersList.totalElements}{' '}
            {t('General.entries')}
          </Box>
        </Box>
      )}
    </>
  );
}
