import React, { useState } from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Button,
  InputAdornment,
  IconButton,
} from '@mui/material';
import { Formik, Field, Form } from 'formik';
import * as yup from 'yup';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import Iconify from '../../../components/iconify';
import { updatePassword } from '../../../network/apis';

export default function ResetPasswordModal({ open, handleClose }) {
  const { t, i18n } = useTranslation();
  const isRtl = i18n.language === 'ar';
  const [showPassword, setShowPassword] = useState(false);

  const validationSchema = yup.object({
    newPassword: yup.string().required(t('resetPasswordModal.passwordRequired')),
    confirmPassword: yup
      .string()
      .oneOf([yup.ref('newPassword'), null], t('resetPasswordModal.passwordsMustMatch'))
      .required(t('resetPasswordModal.confirmPasswordRequired')),
  });
  const handleSubmit = (values) => {
    const userToken = JSON.parse(localStorage.getItem('userToken') || sessionStorage.getItem('userToken'));
    const requestBody = {
      token: userToken,
      password: values.newPassword,
    };

    updatePassword(requestBody)
      .then((response) => {
        toast.success(response?.data?.MESSAGE);
        handleClose();
      })
      .catch((error) => {
        toast.error('Faild to update password');
        console.error('Error resetting password:', error);
      });
  };

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>{t('resetPasswordModal.title')}</DialogTitle>
      <Formik
        initialValues={{ newPassword: '', confirmPassword: '' }}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({ errors, touched, handleChange, handleBlur }) => (
          <Form>
            <DialogContent>
              <Field
                as={TextField}
                name="newPassword"
                variant="outlined"
                type={showPassword ? 'text' : 'password'}
                onChange={handleChange}
                onBlur={handleBlur}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                        <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                InputLabelProps={{
                  style: {
                    direction: isRtl ? 'rtl' : 'ltr',
                    textAlign: isRtl ? 'right' : 'left',
                    width: '100%',
                  },
                }}
                label={t('resetPasswordModal.newPassword')}
                fullWidth
                error={touched.newPassword && !!errors.newPassword}
                helperText={touched.newPassword && errors.newPassword}
                margin="dense"
              />
              <Field
                as={TextField}
                name="confirmPassword"
                type={showPassword ? 'text' : 'password'}
                InputLabelProps={{
                  style: {
                    direction: isRtl ? 'rtl' : 'ltr',
                    textAlign: isRtl ? 'right' : 'left',
                    width: '100%',
                  },
                }}
                label={t('resetPasswordModal.confirmPassword')}
                onChange={handleChange}
                onBlur={handleBlur}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                        <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                fullWidth
                error={touched.confirmPassword && !!errors.confirmPassword}
                helperText={touched.confirmPassword && errors.confirmPassword}
                margin="dense"
              />
            </DialogContent>
            <DialogActions sx={{ justifyContent: 'center', flexDirection: isRtl ? 'row-reverse' : 'row' }}>
              <Button
                onClick={handleClose}
                sx={{ padding: '8px 25px', marginLeft: isRtl ? '10px' : '0px' }}
                size="medium"
                variant="outlined"
              >
                {t('resetPasswordModal.cancel')}
              </Button>
              <Button
                type="submit"
                sx={{ border: '1px solid #111827', padding: '8px 25px' }}
                size="medium"
                variant="contained"
              >
                {t('resetPasswordModal.save')}
              </Button>
            </DialogActions>
          </Form>
        )}
      </Formik>
    </Dialog>
  );
}
