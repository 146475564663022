import { Helmet } from 'react-helmet-async';
import { useLocation, useNavigate } from 'react-router-dom'; // Import useNavigate

import { useSelector, useDispatch } from 'react-redux';

import { useState, useEffect } from 'react';

// @mui
import { Stack, Button, Typography, Skeleton, Grid, Select, MenuItem, FormControl } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { hasPermission } from '../utils/permissions';
import { CityReqTable } from '../sections/@dashboard/unitFormTabs/UnitsList';
import Layout from '../components/Layout/Layout';
import SearchComponet from '../components/search/Search';
import { ReactComponent as DropdownIcon } from '../theme/images/deopdownIcon.svg';
import { resetFormData } from '../Store/features/Units/unitsSlice';

import { getUnitsList } from '../network/apis';

export default function Units() {
  const dispatch = useDispatch();

  const navigate = useNavigate();
  const location = useLocation();

  const [page, setPage] = useState(0);
  const [filters, setFilters] = useState({});
  const [loading, setLoading] = useState(false);
  const [clientReqList, setClientReqList] = useState([]);
  const [search, setSearch] = useState('');
  const [status, setChangeStatus] = useState('');

  const handleChange = (e) => {
    setChangeStatus(e.target.value);
  };

  const clientRequests = (page, search, status) => {
    setLoading(true);
    getUnitsList(page, search, status)
      .then((res) => {
        setClientReqList(res.data);
        setLoading(false);
      })
      .catch(() => setLoading(false));
  };
  useEffect(() => {
    clientRequests(page, search, status);
  }, [page, search, status]);
  useEffect(() => {
    dispatch(resetFormData());
  }, []);
  const handleChangePage = (event, newPage) => {
    setPage(newPage - 1);
  };

  const handleOpenModal = () => {
    navigate('/dashboard/units/add');
  };
  const { t, i18n } = useTranslation();
  const isRtl = i18n.language === 'ar';
  const sideMenu = useSelector((state) => state.login?.sideMenu || []);
  const hasSearchPermission = hasPermission(sideMenu, '/dashboard/units', 'Search For Units');
  const hasAddPermission = hasPermission(sideMenu, '/dashboard/units', 'Add Unit');
  const hasEditPermission = hasPermission(sideMenu, '/dashboard/units', 'Edit Unit');
  return (
    <>
      <Helmet>
        <title> {t('Units.units')} </title>
      </Helmet>

      <Layout>
        <Grid marginBottom={1} display="flex" alignItems={'center'} justifyContent="space-between" md={6} xs={12}>
          <Typography sx={{ fontSize: '24px', fontWeight: '700', lineHeight: '32px', color: '#111827' }}>
            {t('Units.units')}
          </Typography>
          {hasAddPermission && (
            <Button
              onClick={handleOpenModal}
              style={{
                backgroundColor: 'black',
                color: 'white',
                borderRadius: '10px',
                padding: '21px 24px',
                width: '164px',
                height: '56px',
                display: 'flex',
                flexDirection: isRtl ? 'row-reverse' : 'row',
              }}
            >
              {' '}
              <Typography sx={{ display: 'flex', gap: 1, flexDirection: isRtl ? 'row-reverse' : 'row' }}>
                <span> + </span> <span>{t('Units.addUnit')}</span>
              </Typography>
            </Button>
          )}
        </Grid>
        <Grid mb={2} rowSpacing={2} container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }}>
          {hasSearchPermission && (
            <Grid item xs={2} sm={4} md={4}>
              <SearchComponet onSearch={setSearch} style={{ width: '100%', borderRadius: '10px' }} />
            </Grid>
          )}

          <Grid item xs={2} sm={4} md={4}>
            <FormControl fullWidth>
              <Select
                displayEmpty
                value={status}
                sx={{
                  paddingRight: '10px',
                  paddingLeft: '10px',
                  borderRadius: '10px',
                  direction: isRtl ? 'rtl' : 'ltr',
                }}
                renderValue={(selected) => {
                  if (selected === '') {
                    return <span> {t('Units.all')} </span>;
                  }
                  if (selected === 'FREE') return <span>{t('Units.available')}</span>;
                  if (selected === 'RESERVED') return <span>{t('Units.reserved')}</span>;
                  if (selected === 'SOLD') return <span>{t('Units.sold')}</span>;
                  return <span style={{ color: '#9e9e9e' }}> {t('General.select')} </span>;
                }}
                IconComponent={() => <DropdownIcon style={{ width: '15px', height: '15px' }} />}
                labelId="Status"
                id="status"
                onChange={handleChange}
              >
                <MenuItem value="">{t('Units.all')}</MenuItem>
                <MenuItem value="FREE">{t('Units.available')}</MenuItem>
                <MenuItem value="RESERVED">{t('Units.reserved')}</MenuItem>
                <MenuItem value="SOLD">{t('Units.sold')}</MenuItem>
              </Select>
            </FormControl>
          </Grid>
        </Grid>
        {loading ? (
          <Stack>
            <Skeleton height={100} />
            <Skeleton height={100} />
            <Skeleton height={100} />
            <Skeleton height={100} />
            <Skeleton height={100} />
            <Skeleton height={100} />
          </Stack>
        ) : (
          <>
            <>
              <CityReqTable
                hasEditPermission={hasEditPermission}
                hasAddPermission={hasAddPermission}
                handleChangePage={handleChangePage}
                page={page}
                requests={clientReqList}
              />
            </>
          </>
        )}
      </Layout>
    </>
  );
}
