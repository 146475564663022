import { Card, Container, Stack, Typography } from '@mui/material';
import React, { useEffect , useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useLocation, useParams } from 'react-router-dom';
import { getProjectRequestDetails } from '../network/apis';
import { RequestDetailsCard } from '../sections/@dashboard/requestDetails';

export default function RequestDetails() {
  const params = useParams();
  const {id} = params
  const location = useLocation();
 
  const currentPath = location.pathname;

  
  const [viewMode , setIsViewMode] = useState(false)
  useEffect(() => {
    if (currentPath.includes('view')) {
      setIsViewMode(true)
      
    }
  }, [viewMode , currentPath]);
 
  const [details, setDetails] = useState(null);
  useEffect(() => {
    getProjectRequestDetails(params.id).then((res) => setDetails(res.data.data));
  }, []);

  return (
    <>
      <Helmet>
        <title>Project Details</title>
      </Helmet>

      <Container maxWidth="xl">
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
          Project Details
          </Typography>
        </Stack>

        <Card>{details && <RequestDetailsCard setIsViewMode={setIsViewMode} viewMode={viewMode} id={id} details={details} />}</Card>
      </Container>
    </>
  );
}
