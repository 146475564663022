import { Helmet } from 'react-helmet-async';
// @mui
import { styled } from '@mui/material/styles';
import { Container, Typography } from '@mui/material';
// hooks
import useResponsive from '../hooks/useResponsive';
// components
import Logo from '../components/logo';
// sections
import { ResetPasswordForm } from '../sections/auth/resetPassword';

// ----------------------------------------------------------------------

const StyledRoot = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex',
  },
}));

const StyledSection = styled('div')(({ theme }) => ({
  width: '100%',
  maxWidth: 500,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  boxShadow: theme.customShadows.card,
  backgroundColor: theme.palette.background.default,
}));

const StyledContent = styled('div')(({ theme }) => ({
  maxWidth: 500,
  margin: 'auto',
  minHeight: '100vh',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  padding: theme.spacing(12, 0),
}));

// ----------------------------------------------------------------------

export default function ResetPassword() {
  const mdUp = useResponsive('up', 'md');

  return (
    <>
      <Helmet>
        <title> Reset Password </title>
      </Helmet>

      <StyledRoot>
        {mdUp && (
          <StyledSection>
            <img src="/assets/images/login/Photo.png" alt="login" />
          </StyledSection>
        )}

        <Container maxWidth="sm">
          <StyledContent>
            <img
              src="/assets/images/logo/logo.png"
              alt="logo"
              style={{
                width: '100%',

                imageRendering: 'auto',
                objectFit: 'cover',
                margin: '1rem 0',
              }}
            />
            <div>
              <img
                src="/assets/images/login/Ornament.png"
                alt="Ornament"
                style={{
                  width: '96px',
                  height: '41px',
                  imageRendering: 'auto',
                  objectFit: 'contain',
                  position: 'relative',
                  bottom: '66px',
                  right: '22%',
                }}
              />
            </div>
            <Typography sx={{ mb: 5, color: '#111827', textAlign: 'center' }} variant="h4" gutterBottom>
              Reset your password
            </Typography>

            <ResetPasswordForm />
          </StyledContent>
          {/* <StyledContent>
            <Typography sx={{ mb: 5 }} variant="h3" gutterBottom>
              Sign In
            </Typography>

            <LoginForm />
          </StyledContent> */}
        </Container>
      </StyledRoot>
    </>
  );
}
