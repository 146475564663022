import * as React from 'react';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';

import { styled, css } from '@mui/system';
import { Modal as BaseModal } from '@mui/base/Modal';
import Fade from '@mui/material/Fade';
import TextField from '@mui/material/TextField';
import { Grid, Typography, Button, Stack, CircularProgress, Box, Select, MenuItem, FormControl } from '@mui/material';
import { Formik } from 'formik';
import { useTranslation } from 'react-i18next';
import { updateContact } from '../../network/apis';
import { ReactComponent as DropdownIcon } from '../../theme/images/deopdownIcon.svg';

export default function AddOurOffersModal({ open, handleClose, list, edit }) {
  const { t, i18n } = useTranslation();
  const isRtl = i18n.language === 'ar';
  const [loading, setLoading] = React.useState(false);

  const handleAddOurService = async (values) => {
    setLoading(true);
    try {
      await updateContact({
        [values.code]: values.value,
      });
      toast.success('Offer added successfully');
      handleClose();
    } catch (error) {
      console.log(error);
      toast.error('Failed to add Offer. Please try again');
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={open}
      onClose={handleClose}
      closeAfterTransition
      slots={{ backdrop: StyledBackdrop }}
    >
      <Fade in={open}>
        <ModalContent sx={style}>
          <Typography id="transition-modal-title" variant="h6" component="h2">
            {t('contacts.add_contact')}
          </Typography>
          <Formik
            initialValues={{
              code: edit.code,
              value: '',
            }}
            onSubmit={(values, { setSubmitting }) => {
              handleAddOurService(values);
              handleClose();
            }}
          >
            {({ values, errors, handleChange, handleSubmit, handleBlur, touched, setFieldValue }) => {
              return (
                <>
                  <form
                    onSubmit={(e) => {
                      e.preventDefault();
                      handleSubmit(e);
                    }}
                  >
                    <Grid
                      padding={'20px'}
                      container
                      spacing={{ xs: 2, md: 2 }}
                      columns={{ xs: 4, sm: 8, md: 12 }}
                      sx={{
                        height: '100%',
                        padding: '20px',
                        borderBottom: '1px solid #E5E5E5',
                      }}
                    >
                      <Grid item xs={2} sm={4} md={12}>
                        <Typography variant="body1" gutterBottom>
                          {t('contacts.contacts_type')}
                        </Typography>
                        <FormControl fullWidth>
                          <Select
                            displayEmpty
                            disabled={edit.edit}
                            value={values.code}
                            sx={{
                              paddingX: '10px',
                              borderRadius: '10px',
                              flexDirection: 'row',
                              justifyContent: 'start',
                            }}
                            renderValue={(selected) => {
                              const item = list?.find((item) => item.code === selected);
                              return (
                                <Box display={'flex'} alignItems={'center'} gap={2}>
                                  <img
                                    style={{ width: '45px', height: '45px', objectFit: 'cover', borderRadius: '50%' }}
                                    src={item?.icon || ''}
                                    alt={''}
                                  />
                                  <Typography
                                    margin={'0'}
                                    display={'flex'}
                                    alignItems={'center'}
                                    justifyContent={'center'}
                                  >
                                    {isRtl
                                      ? item?.labelAr || t('General.select')
                                      : item?.labelEn || t('General.select')}
                                  </Typography>
                                </Box>
                              );
                            }}
                            IconComponent={() => <DropdownIcon style={{ width: '15px', height: '15px' }} />}
                            labelId="Status"
                            id="status"
                            onChange={(e) => setFieldValue('code', e.target.value)}
                          >
                            {list?.map((item, index) => (
                              <MenuItem key={index} value={item?.code}>
                                {' '}
                                <Box display={'flex'} alignItems={'start'} gap={2}>
                                  {' '}
                                  <img
                                    style={{ width: '45px', height: '45px', objectFit: 'cover', borderRadius: '50%' }}
                                    src={item?.icon}
                                    alt={''}
                                  />
                                  <Typography
                                    margin={'0'}
                                    display={'flex'}
                                    alignItems={'center'}
                                    justifyContent={'center'}
                                  >
                                    {isRtl ? item?.labelAr : item?.labelEn}
                                  </Typography>
                                </Box>
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </Grid>
                      <Grid item xs={2} sm={4} md={12}>
                        <Typography variant="body1" gutterBottom>
                          {t('contacts.contacts_value')}
                        </Typography>
                        <TextField
                          name="value"
                          required
                          multiline
                          rows={3}
                          fullWidth
                          value={values.value}
                          variant="outlined"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          error={touched.value && Boolean(errors.value)}
                          helperText={touched.value && errors.value}
                        />
                      </Grid>
                    </Grid>
                    <Stack
                      padding={2}
                      sx={{ width: '100%' }}
                      direction="row"
                      justifyContent="end"
                      alignItems="end"
                      gap={2}
                    >
                      <Button
                        sx={{ mt: 4, border: '1px solid #111827' }}
                        size="large"
                        type="button"
                        onClick={handleClose}
                      >
                        {' '}
                        {t('General.cancel')}
                      </Button>
                      <Button
                        sx={{ mt: 4, border: '1px solid #111827' }}
                        size="large"
                        type="submit"
                        variant="contained"
                      >
                        {' '}
                        {loading ? <CircularProgress /> : t('General.submit')}
                        {}
                      </Button>
                    </Stack>
                  </form>
                </>
              );
            }}
          </Formik>
        </ModalContent>
      </Fade>
    </Modal>
  );
}

const Backdrop = React.forwardRef((props, ref) => {
  const { open, ...other } = props;
  return (
    <Fade in={open}>
      <div ref={ref} {...other} />
    </Fade>
  );
});

Backdrop.propTypes = {
  open: PropTypes.bool,
};

const grey = {
  50: '#F3F6F9',
  100: '#E5EAF2',
  200: '#DAE2ED',
  300: '#C7D0DD',
  400: '#B0B8C4',
  500: '#9DA8B7',
  600: '#6B7A90',
  700: '#434D5B',
  800: '#303740',
  900: '#1C2025',
};

const Modal = styled(BaseModal)`
  position: fixed;
  z-index: 1300;
  inset: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const StyledBackdrop = styled(Backdrop)`
  z-index: -1;
  position: fixed;
  inset: 0;
  background-color: rgb(0 0 0 / 0.5);
  -webkit-tap-highlight-color: transparent;
`;

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 800,
  maxHeight: '90%',
  overflow: 'auto',
};

const ModalContent = styled('div')(
  ({ theme }) => css`
    font-family: 'IBM Plex Sans', sans-serif;
    font-weight: 500;
    text-align: start;
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 8px;
    overflow: hidden;
    background-color: ${'#fff'};
    border-radius: 8px;
    border: 1px solid ${grey[200]};
    box-shadow: 0 4px 12px ${'rgb(0 0 0 / 0.2)'};
    padding: 24px;
    color: ${grey[900]};

    & .modal-title {
      margin: 0;
      line-height: 1.5rem;
      margin-bottom: 8px;
    }

    & .modal-description {
      margin: 0;
      line-height: 1.5rem;
      font-weight: 400;
      color: ${grey[800]};
      margin-bottom: 4px;
    }
  `
);
