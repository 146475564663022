import React from 'react';
import { Tabs, Tab } from '@mui/material';
import { styled } from '@mui/system';
import { useTranslation } from 'react-i18next';

const StyledTabs = styled(Tabs)({
  '& .MuiTabs-indicator': {
    display: 'none',
  },
});

const StyledTab = styled(Tab)(({ theme }) => ({
  textTransform: 'none',
  minWidth: 120,
  fontWeight: theme.typography.fontWeightRegular,
  marginRight: theme.spacing(1),
  color: 'rgba(0, 0, 0, 0.85)',
  position: 'relative',
  '&.Mui-selected': {
    backgroundColor: `${theme.palette.mode === 'dark' ? 'black' : '#E0FFE1'}`,
    borderRadius: '8px 8px 0 0',
    color: '#30A14E',
    fontWeight: 'bold',
  },
  '&.Mui-selected::after': {
    content: '""',
    position: 'absolute',
    bottom: 0,
    left: '25%',
    width: '50%',
    borderBottom: `3px solid ${theme.palette.mode === 'dark' ? 'black' : '#30A14E'}`, // Add centered bottom border for the active tab
  },
  '&:hover': {
    backgroundColor: '#E0FFE1',
    borderRadius: '8px 8px 0 0',
    color: `${theme.palette.mode === 'dark' ? 'black' : '#30A14E'}`,
  },
}));

const CustomTabs = ({ value, onChange, tabs }) => {
  const { i18n } = useTranslation();
  const isRtl = i18n.language === 'ar';
  return (
    <StyledTabs sx={{ direction: isRtl ? 'rtl' : 'ltr' }} value={value} onChange={onChange} aria-label="custom tabs">
      {tabs.map((tab, index) => (
        <StyledTab key={index} label={tab} />
      ))}
    </StyledTabs>
  );
};

export default CustomTabs;
