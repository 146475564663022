import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { addUnit } from '../../../network/apis'; // Import the API function

const initialState = {
  formData: {
    owner: {},
    consultant: {},
  },
  files: [],

  currentStep: 0,
  loading: false, // Track loading state
  error: null, // Track error state
};

const formSlice = createSlice({
  name: 'planForm',
  initialState,
  reducers: {
    updateFormData: (state, action) => {
      state.formData = { ...state.formData, ...action.payload };
    },
    resetFormData: (state) => {
      state.formData = initialState.formData;
    },
    nextStep: (state) => {
      state.currentStep += 1;
    },
    prevStep: (state) => {
      state.currentStep -= 1;
    },
  },
});

export const { updateFormData, resetFormData, nextStep, prevStep } = formSlice.actions;

export default formSlice.reducer;
