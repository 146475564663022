import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { styled, keyframes } from '@mui/material/styles';
import { Box, Stack, AppBar, Toolbar, IconButton, ListItem, Menu, useTheme, MenuItem } from '@mui/material';
import { useTranslation } from 'react-i18next';
import SmallBadge from '../../../components/Badge/Badge'; // Import the SmallBadge component
import Iconify from '../../../components/iconify';
import CustomNotificationsIcon from '../../../theme/images/notifications.svg';
import AccountPopover from './AccountPopover';
import NotificationList from '../../../pages/Notifications';
import DarkModeToggle from '../../../components/ThemeButton/ThemeButton';

import { useNotification } from '../../../context/NotificationsContext';

const simpleMove = keyframes`
  0%, 100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-5px);
  }
`;

const AnimatedIcon = styled('img')`
  width: 24px;
  height: 24px;
  animation: ${(props) => (props.animate ? `${simpleMove} 0.5s` : 'none')};
`;

const NAV_WIDTH_OPEN = 280;
const NAV_WIDTH_CLOSED = 55;
const HEADER_MOBILE = 64;
const HEADER_DESKTOP = 92;

const StyledRoot = styled(AppBar)(({ theme, openNav }) => ({
  boxShadow: 'none',

  // [theme.breakpoints.up('lg')]: {
  //   width: `calc(100% - ${openNav ? NAV_WIDTH_OPEN + 1 : NAV_WIDTH_CLOSED + 1}px)`,
  // },
}));

const StyledToolbar = styled(Toolbar)(({ theme }) => ({
  minHeight: HEADER_MOBILE,
  [theme.breakpoints.up('lg')]: {
    minHeight: HEADER_DESKTOP,
    padding: theme.spacing(0, 5),
  },
}));

Header.propTypes = {
  onOpenNav: PropTypes.func,
};

export default function Header({ onOpenNav, openNav }) {
  const { t, i18n } = useTranslation();
  const theme = useTheme();
  const isDarkMode = theme.palette.mode === 'dark';

  const isRtl = i18n.language === 'ar';
  const { unseenCount, fetchNotifications } = useNotification();
  const [anchorEl, setAnchorEl] = useState(null);
  const [animate, setAnimate] = useState(false);
  const [languageMenuAnchorEl, setLanguageMenuAnchorEl] = useState(null);
  const handleNotificationClick = async (event) => {
    setAnchorEl(event.currentTarget);
    await fetchNotifications();
    triggerAnimation();
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const triggerAnimation = () => {
    setAnimate(true);
    setTimeout(() => setAnimate(false), 500);
  };

  const handleLanguageClick = (event) => {
    setLanguageMenuAnchorEl(event.currentTarget);
  };

  const handleLanguageClose = () => {
    setLanguageMenuAnchorEl(null);
  };

  const handleLanguageChange = (language) => {
    i18n.changeLanguage(language);
    localStorage.setItem('i18nextLng', language);
    setLanguageMenuAnchorEl(null);
  };
  useEffect(() => {
    triggerAnimation();
    fetchNotifications();
  }, []);

  return (
    <StyledRoot openNav={openNav} style={{ backgroundColor: 'white', borderBottom: '1px solid #E9EAEC' }}>
      <StyledToolbar style={{ minHeight: '80px' }}>
        <IconButton
          onClick={onOpenNav}
          sx={{
            color: 'text.primary',
            display: { lg: 'none' },
          }}
        >
          <Iconify icon="eva:menu-2-fill" />
        </IconButton>

        <Box sx={{ flexGrow: 1 }} />
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
          <DarkModeToggle />
          <IconButton
            color="black"
            sx={{
              width: '40px',
              height: '40px',
              borderRadius: '10px',
              backgroundColor: isDarkMode ? '#000000' : '#0caf60',
              color: 'white',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
            onClick={() => {
              if (isRtl) {
                handleLanguageChange('en');
              } else {
                handleLanguageChange('ar');
              }
            }}
          >
            {isRtl ? 'en' : 'ع'}
          </IconButton>
        </Box>

        <Stack
          direction="row"
          alignItems="center"
          spacing={{
            xs: 0.5,
            sm: 1,
          }}
          sx={{ flexDirection: 'row' }}
        >
          <ListItem>
            <IconButton color="black" onClick={handleNotificationClick}>
              <SmallBadge sx={{ position: 'relative' }} badgeContent={unseenCount}>
                <Box
                  sx={{
                    insetBlockStart: '0px',
                    insetInlineEnd: '0px',
                    width: '10px',
                    height: '10px',
                    position: 'absolute',
                    borderRadius: '50%',
                    backgroundColor: 'rgba(238, 51, 94, 0.8)',
                    cursor: 'pointer',
                    boxShadow: '0 0 0 rgba(238, 51, 94, 0.9)',
                    animation: 'pulse 2s infinite ease-in-out',
                    '@keyframes pulse': {
                      '0%': { boxShadow: '0 0 0 0 rgba(238, 51, 94, 0.9)' },
                      '70%': { boxShadow: '0 0 0 10px rgba(238, 51, 94, 0)' },
                      '100%': { boxShadow: '0 0 0 0 rgba(238, 51, 94, 0)' },
                    },
                  }}
                />
                <AnimatedIcon src={CustomNotificationsIcon} alt="Notifications" animate={animate} />
              </SmallBadge>
            </IconButton>
            <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose}>
              <NotificationList anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose} />
            </Menu>
          </ListItem>
          <AccountPopover />
        </Stack>
      </StyledToolbar>
    </StyledRoot>
  );
}
