import * as React from 'react';
import { useState } from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { Box } from '@mui/system';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import ThumbDownIcon from '@mui/icons-material/ThumbDown';
import { changeRequestStatus } from '../../network/apis';

export default function ConfirmationDialog({ open, setOpen, request_technology_status: { id, status }, requestList }) {
  const [comment, setComment] = useState('');

  const handleClose = () => {
    setOpen(false);
  };

  const handleConfirm = () => {
    changeRequestStatus(id, {
      is_accepted: status,
      comment,
    }).then((res) => {
      if (res) {
        setOpen(false);
        requestList();
      }
    });
  };

  return (
    <Dialog maxWidth={'sm'} fullWidth open={open} onClose={handleClose}>
      <DialogTitle>Request Approval</DialogTitle>
      <DialogContent>
        <Box display={'flex'} alignItems="center" gap={1}>
          <p>You will {status === 0 ? <span>Disapprove</span> : <span>Approve</span>} this request</p>
          {status === 0 ? <ThumbDownIcon sx={{ color: '#dc3545' }} /> : <ThumbUpIcon sx={{ color: '#198754' }} />}
        </Box>
        <TextField
          margin="dense"
          multiline
          rows={3}
          id="comment"
          label="Comment"
          type="text"
          fullWidth
          variant="outlined"
          onChange={(e) => setComment(e.target.value)}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
        <Button onClick={handleConfirm}>Confirm</Button>
      </DialogActions>
    </Dialog>
  );
}
