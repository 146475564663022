import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {
  Button,
  CircularProgress,
  Box,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { Formik } from 'formik';
import * as yup from 'yup';

import { getOwnersData } from '../../../network/apis';
import { updateFormData } from '../../../Store/features/projectForm/projectFormSlice';

export default function OwnerDataForm({ handlePrev, isViewMode, handleNext, editData }) {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  const [ownersList, setOwnersList] = useState([]);
  const [ownerDropDownOpened, setOwnerDropDownOpened] = useState(false);

  const allFormData = useSelector((state) => state.form.formData.owner);
  useEffect(() => {
    if (!ownerDropDownOpened) {
      getOwnersList();

      setOwnerDropDownOpened(true);
    }
  }, [editData, ownerDropDownOpened]);
  const { t, i18n } = useTranslation();
  const isRtl = i18n.language === 'ar';
  const validationSchema = yup.object({
    nameEn: yup
      .string()
      .required('Required field')
      .test('is-english', 'Please enter English text', (value) => {
        const englishRegex = /^[A-Za-z0-9_\s]*$/;
        return englishRegex.test(value);
      }),

    nameAr: yup
      .string()
      .required('Required field')
      .test('is-arabic', 'Please enter Arabic text\nيرجي ادخال نص عربي', (value) => {
        const arabicRegex = /[\u0600-\u06FF\u0660-\u0669]/;
        const englishRegex = /[A-Za]/;
        return arabicRegex.test(value) && !englishRegex.test(value);
      }),
  });

  const getOwnersList = () => {
    setLoading(true);
    getOwnersData()
      .then((res) => {
        setOwnersList(res.data);
        setLoading(false);
      })
      .catch(() => setLoading(false));
  };

  return (
    <Formik
      initialValues={{
        ...(allFormData || editData),
        owner: {
          id: '',
          cr: '',
          nameEn: '',
          nameAr: '',
          website: '',
          email: '',
          phone: '',
          address: '',
          logo: '',
        },
      }}
      onSubmit={(values, { setSubmitting }) => {
        const ownerData = {
          owner: {
            id: values.id,
            cr: values.cr,
            nameEn: values.nameEn,
            nameAr: values.nameAr,
            website: values.website,
            email: values.email,
            phone: values.phone,
            address: values.address,
            logo: values.logo,
          },
        };

        dispatch(updateFormData(ownerData));
        dispatch(handleNext());

        setSubmitting(false);
      }}
      validationSchema={validationSchema}
    >
      {({ values, errors, touched, handleChange, handleBlur, handleSubmit, setValues }) => (
        <form onSubmit={handleSubmit}>
          <Grid
            rowSpacing={2}
            container
            spacing={{ xs: 2, md: 3 }}
            columns={{ xs: 4, sm: 8, md: 12 }}
            sx={{ direction: isRtl ? 'rtl' : 'ltr' }}
          >
            <Grid item xs={2} sm={4} md={6}>
              <Stack>
                <InputLabel id="ownerName">{t('ownerForm.ownerName')}</InputLabel>
                <Select
                  sx={{ mt: 1 }}
                  labelId="id"
                  id="id"
                  value={values.id}
                  disabled={isViewMode}
                  onOpen={() => {
                    if (!ownerDropDownOpened) {
                      getOwnersList();
                      setOwnerDropDownOpened(true);
                    }
                  }}
                  onChange={(event) => {
                    const selectedOwnerId = event.target.value;
                    dispatch(updateFormData({ owner: { ownerId: selectedOwnerId } }));

                    const selectedOwner = ownersList.find((owner) => owner.id === selectedOwnerId);
                    if (selectedOwner) {
                      setValues({
                        ...values,
                        id: selectedOwner.id,
                        cr: selectedOwner.cr,
                        nameEn: selectedOwner.nameEn,
                        nameAr: selectedOwner.nameAr,
                        website: selectedOwner.website,
                        email: selectedOwner.email,
                        phone: selectedOwner.phone,
                        address: selectedOwner.address,
                        logo: selectedOwner.logo,
                      });
                    }
                  }}
                >
                  {loading ? (
                    <MenuItem>
                      <CircularProgress size={24} />
                    </MenuItem>
                  ) : (
                    ownersList?.map((option, index) => (
                      <MenuItem sx={{ direction: isRtl ? 'rtl' : 'ltr' }} key={index} value={option.id}>
                        {isRtl ? option.nameAr : option.nameEn}
                      </MenuItem>
                    ))
                  )}
                </Select>
              </Stack>
            </Grid>

            <Grid item xs={2} sm={4} md={6}>
              <Typography variant="body1" gutterBottom>
                {t('ownerForm.ownerId')}
              </Typography>
              <TextField
                name="id"
                fullWidth
                value={values.id}
                variant="outlined"
                onChange={handleChange}
                onBlur={handleBlur}
                disabled={isViewMode}
              />
            </Grid>

            <Grid item xs={2} sm={4} md={6}>
              <Typography variant="body1" gutterBottom>
                {t('ownerForm.nameEn')}
              </Typography>
              <TextField
                name="nameEn"
                required
                fullWidth
                value={values.nameEn}
                variant="outlined"
                disabled={isViewMode}
                onChange={handleChange}
                onBlur={handleBlur}
                error={touched.nameEn && Boolean(errors.nameEn)}
                helperText={
                  touched.nameEn &&
                  errors.nameEn && <span dangerouslySetInnerHTML={{ __html: errors.nameEn.replace('\n', '<br />') }} />
                }
              />
            </Grid>

            <Grid item xs={2} sm={4} md={6}>
              <Typography variant="body1" gutterBottom>
                {t('ownerForm.nameAr')}
              </Typography>
              <TextField
                name="nameAr"
                required
                disabled={isViewMode}
                fullWidth
                value={values.nameAr}
                variant="outlined"
                onChange={handleChange}
                onBlur={handleBlur}
                error={touched.nameAr && Boolean(errors.nameAr)}
                helperText={
                  touched.nameAr &&
                  errors.nameAr && <span dangerouslySetInnerHTML={{ __html: errors.nameAr.replace('\n', '<br />') }} />
                }
              />
            </Grid>

            <Grid item xs={2} sm={4} md={6}>
              <Typography variant="body1" gutterBottom>
                {t('ownerForm.ownerCR')}
              </Typography>
              <TextField
                name="cr"
                required
                fullWidth
                value={values.cr}
                disabled={isViewMode}
                variant="outlined"
                onChange={handleChange}
                onBlur={handleBlur}
                error={touched.cr && Boolean(errors.cr)}
                helperText={touched.cr && errors.cr}
              />
            </Grid>

            <Grid item xs={2} sm={4} md={6}>
              <Typography variant="body1" gutterBottom>
                {t('ownerForm.ownerWebsite')}
              </Typography>
              <TextField
                name="website"
                required
                fullWidth
                disabled={isViewMode}
                value={values.website}
                variant="outlined"
                onChange={handleChange}
                onBlur={handleBlur}
                error={touched.website && Boolean(errors.website)}
                helperText={touched.website && errors.website}
              />
            </Grid>

            <Grid item xs={2} sm={4} md={6}>
              <Typography variant="body1" gutterBottom>
                {t('ownerForm.email')}
              </Typography>
              <TextField
                name="email"
                disabled={isViewMode}
                required
                fullWidth
                value={values.email}
                variant="outlined"
                onChange={handleChange}
                onBlur={handleBlur}
                error={touched.email && Boolean(errors.email)}
                helperText={touched.email && errors.email}
              />
            </Grid>

            <Grid item xs={2} sm={4} md={6}>
              <Typography variant="body1" gutterBottom>
                {t('ownerForm.address')}
              </Typography>
              <TextField
                name="address"
                required
                disabled={isViewMode}
                fullWidth
                value={values.address}
                variant="outlined"
                onChange={handleChange}
                onBlur={handleBlur}
                error={touched.address && Boolean(errors.address)}
                helperText={touched.address && errors.address}
              />
            </Grid>

            <Grid item xs={2} sm={4} md={6}>
              <Typography variant="body1" gutterBottom>
                {t('ownerForm.logo')}
              </Typography>
              {values.logo && (
                <img
                  src={values.logo}
                  alt="Owner Logo"
                  style={{ maxWidth: '34%', maxHeight: '29%', objectFit: 'contain' }}
                />
              )}
            </Grid>
          </Grid>

          <Box width={'100%'} display={'flex'} justifyContent={'space-between'} alignItems={'center'} mt={2}>
            <Button sx={{ mt: 4, border: '1px solid #111827' }} size="large" type="button" onClick={handlePrev}>
              {t('General.back')}
            </Button>
            <Button sx={{ mt: 4, border: '1px solid #111827' }} size="large" type="submit" variant="contained">
              {t('General.next')}
            </Button>
          </Box>
        </form>
      )}
    </Formik>
  );
}
