import React from 'react';
import {
    PlanFromProvider
} from '../context/landFormContext';
import ManageLands from './ManageLands';



const ManageLandsWithProvider = () => (
    <PlanFromProvider>
        <ManageLands />
    </PlanFromProvider>
);

export default ManageLandsWithProvider;