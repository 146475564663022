// src/utils/getQueryParams.js

export const getQueryParams = (queryString) => queryString
      .slice(1)
      .split('&')
      .reduce((acc, param) => {
        const [key, value] = param.split('=');
        acc[key] = decodeURIComponent(value);
        return acc;
      }, {});
  