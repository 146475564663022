// localization

import { useTranslation } from 'react-i18next';

import { Helmet } from 'react-helmet-async';
import { useNavigate, useLocation, useParams } from 'react-router-dom'; // Import useNavigate
import { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
// @mui
import { Stack, Button, Skeleton, Grid, Typography } from '@mui/material';
import { useSelector } from 'react-redux';
import { CityReqTable } from '../sections/@dashboard/BuildingsWithPorjectId';
import Layout from '../components/Layout/Layout';
import SearchComponet from '../components/search/Search';
import { hasPermission } from '../utils/permissions';

import { getBuildingListWithProjectId, deleteBuilding } from '../network/apis';

export default function Admins() {
  const { t, i18n } = useTranslation();
  const isRtl = i18n.language === 'ar';
  const navigate = useNavigate();
  const location = useLocation();
  const unitsData = location.state?.unitsData;
  const [page, setPage] = useState(0);
  const [filters, setFilters] = useState({});
  const [loading, setLoading] = useState(false);
  const [clientReqList, setClientReqList] = useState([]);
  const [search, setSearch] = useState('');
  const { id } = useParams();

  const sideMenu = useSelector((state) => state.login?.sideMenu || []);
  const hasSearchPermission = hasPermission(sideMenu, '/dashboard/buildings', 'Search Building');
  const hasAddPermission = hasPermission(sideMenu, '/dashboard/buildings', 'Add Building');
  const hasEditPermission = hasPermission(sideMenu, '/dashboard/buildings', 'Update Building');
  const hasDeletePermission = hasPermission(sideMenu, '/dashboard/buildings', 'Delete Building');

  const clientRequests = () => {
    setLoading(true);
    getBuildingListWithProjectId(id)
      .then((res) => {
        setClientReqList(res.data);
        setLoading(false);
      })
      .catch((error) => {
        console.error('Error fetching admin list:', error);
        // Display a toast message with a generic error message
        toast.error(error.message);
        setLoading(false);
      });
  };

  useEffect(() => {
    clientRequests(id);
  }, [id]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleDelete = (id) => {
    deleteBuilding(id)
      .then((res) => {
        if (res.data.STATUS === '0') {
          toast.error(res.data.MESSAGE);
        } else toast.success(res.data.MESSAGE);

        clientRequests(page, search);
      })
      .catch((error) => {
        console.log( error);
        toast.error('Failed to delete building. Please try again.');
      });
  };

  const handleNavigate = () => {
    navigate(`/dashboard/building/add/${id}`, {
      state: { updateProject: { projectId: id, from: 'manage-buildings-with-project-id-add' } },
    });
  };

  return (
    <>
      <Helmet>
        <title> Manage Buildings </title>
      </Helmet>

      <Layout>
        <Grid marginBottom={1} display="flex" alignItems={'center'} justifyContent="space-between" md={6} xs={12}>
          <Typography sx={{ fontSize: '24px', fontWeight: '700', lineHeight: '32px', color: '#111827' }}>
            {t('Building.manageBuilding')}
          </Typography>
          {hasAddPermission && (
            <Button
              onClick={handleNavigate}
              style={{
                backgroundColor: 'black',
                color: 'white',
                borderRadius: '10px',
                padding: '21px 24px',
                width: '164px',
                height: '56px',
              }}
            >
              {' '}
              + {t('Building.addBuilding')}
            </Button>
          )}
        </Grid>
        {hasSearchPermission && (
          <Grid mb={2} rowSpacing={2} container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }}>
            <Grid item xs={2} sm={4} md={4}>
              <SearchComponet label={t('Building.searchBuilding')} onSearch={setSearch} style={{ width: '100%' }} />
            </Grid>
          </Grid>
        )}

        {loading ? (
          <Stack>
            <Skeleton height={100} />
            <Skeleton height={100} />
            <Skeleton height={100} />
            <Skeleton height={100} />
            <Skeleton height={100} />
            <Skeleton height={100} />
          </Stack>
        ) : (
          <>
            <>
              <CityReqTable
                handleChangePage={handleChangePage}
                page={page}
                requests={clientReqList}
                onDelete={handleDelete}
                hasEditPermission={hasEditPermission}
                hasDeletePermission={hasDeletePermission}
              />
            </>
          </>
        )}
      </Layout>
    </>
  );
}
