import React from 'react';
import { FormProvider } from '../context/projectFormContext';
import ManageProject from './ManageProjects';

const ManageProjectWithProvider = () => (
  <FormProvider>
    <ManageProject />
  </FormProvider>
);

export default ManageProjectWithProvider;
