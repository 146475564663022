import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';

import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { Box, Typography, Skeleton, Stack, Pagination, Grid } from '@mui/material';

import moment from 'moment';

import { ReactComponent as ShowIcon } from '../theme/images/showTableIcon.svg';
import SearchComponent from '../components/search/Search';
import CustomTabs from '../components/CustomTabs/ReviewTabs';
import CustomTable from '../sections/@dashboard/OwnerShipRequests/OwnerShipRequestsTable';
import { getListNewOwnershipRequests, getListCompletedOwnershipRequests } from '../network/apis';

import { hasPermission } from '../utils/permissions';

const OwnerShipRequests = () => {
  const { t, i18n } = useTranslation();
  const isRtl = i18n.language === 'ar';
  const tabs = [t('ownerShipRequests.newRequets'), t('ownerShipRequests.compeletedRequets')];

  const columns = [
    { field: 'id', headerName: t('ownerShipRequests.requestId'), alignRight: isRtl && true },
    { field: 'requestType', headerName: t('ownerShipRequests.requestType'), alignRight: isRtl && true },
    {
      field: isRtl ? 'flagNameAr' : 'flagNameEn',
      headerName: t('ownerShipRequests.unit_piece_name'),
      alignRight: isRtl && true,
    },
    {
      field: isRtl ? 'categoryNameAr' : 'categoryNameEn',
      headerName: t('ownerShipRequests.project_plan_name'),
      alignRight: isRtl && true,
    },
    {
      field: isRtl ? 'fullNameAr' : 'fullNameEn',
      headerName: t('ownerShipRequests.Buyer_name'),
      alignRight: isRtl && true,
    },
    { field: 'phone', headerName: t('ownerShipRequests.phone'), alignRight: isRtl && true },
    { field: 'totalAmount', headerName: t('ownerShipRequests.total_price'), alignRight: isRtl && true },
    { field: 'downPayment', headerName: t('ownerShipRequests.downpay'), alignRight: isRtl && true },
    { field: 'creationDate', headerName: t('ownerShipRequests.requestDate'), alignRight: isRtl && true },
    { field: 'requestStatus', headerName: t('ownerShipRequests.requestStatus'), alignRight: isRtl && true },
    { field: 'paymentStatus', headerName: t('ownerShipRequests.paymentStatus'), alignRight: isRtl && true },
  ];

  const reviewedTabactions = [
    {
      icon: <ShowIcon />,
      handler: (row) => handleViewDetails(row),
    },
  ];

  const [tabValue, setTabValue] = useState(0);
  const [searchQuery, setSearchQuery] = useState('');
  const [underReviewingRows, setUnderReviewingRows] = useState([]);
  const [publishedRows, setPublishedRows] = useState([]);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [totalPages, setTotalPages] = useState(0);
  const [totalElements, setTotalElements] = useState(0);
  const [numberOfElements, setNumberOfElements] = useState(0);

  const userId = JSON.parse(localStorage.getItem('userId') || sessionStorage.getItem('userId'));
  const isManager = JSON.parse(localStorage.getItem('isManager') || sessionStorage.getItem('isManager'));
  const navigate = useNavigate();

  const sideMenu = useSelector((state) => state.login?.sideMenu || []);
  const hasSearchPermission = hasPermission(sideMenu, '/dashboard/ownership-requests', 'Search');
  const hasNewListRequestedPermission = hasPermission(sideMenu, '/dashboard/ownership-requests', 'List new requests');
  const hasNewListCompletePermission = hasPermission(
    sideMenu,
    '/dashboard/ownership-requests',
    'List completed Requests'
  );
  const hasAssignPermission = hasPermission(sideMenu, '/dashboard/ownership-requests', 'Assign Request');
  const hasFinishPermission = hasPermission(sideMenu, '/dashboard/ownership-requests', 'Finish Request');

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
    setPage(1);
  };

  const handleSearch = (query) => {
    setSearchQuery(query);
    setPage(1);
  };

  if (tabValue === 1) {
    columns.push({ field: 'finishDate', headerName: t('General.ActionDate'), alignRight: isRtl && true });
  }

  useEffect(() => {
    if (tabValue === 0) {
      fetchUnderReviewingData(page - 1, rowsPerPage, searchQuery);
    } else {
      fetchPublishedData(page - 1, rowsPerPage, searchQuery);
    }
  }, [tabValue, searchQuery, page, rowsPerPage]);

  const fetchUnderReviewingData = async (page, size, search) => {
    setLoading(true);
    try {
      const response = await getListNewOwnershipRequests(page, search, size);
      const data = response?.data?.content || [];
      const totalPages = response?.data?.totalPages || 0;
      const totalElements = response?.data?.totalElements || 0;
      const numberOfElements = response?.data?.numberOfElements || 0;
      const formatDownPayment = (downPayment) => {
        if (!downPayment) return 'No Down Payment Information';
        if (downPayment === '0') return 'Under Processing';
        if (downPayment === '1') return 'Finished';
        if (downPayment === '-1') return 'Cancelled';
        return '';
      };

      const formatPaymentStatus = (paymentStatus) => {
        if (!paymentStatus) return '';
        return paymentStatus === '0' ? 'Not Completed' : 'Completed';
      };

      const formattedData = data.map((item) => ({
        id: item.id,
        requestType: item.requestType,
        flagNameEn: item.flagNameEn,
        flagNameAr: item.flagNameAr,

        managerName: item.managerName,
        categoryNameEn: item.categoryNameEn,
        categoryNameAr: item.categoryNameAr,

        fullNameEn: item.fullNameEn,
        fullNameAr: item.fullNameAr,

        phone: item.phone,
        flagId: item.flagId,
        flagType: item.flagType,
        downPayment: item.downPayment,
        nextAction: item.nextAction,
        reservationInvoice: item?.reservationInvoice,
        paymentStatus: formatPaymentStatus(item.paymentStatus),
        requestStatus: formatDownPayment(item.requestStatus),

        totalAmount: item?.totalAmount || '',

        creationDate: item?.creationDate !== null ? moment(item?.creationDate).format('DD/MM/YYYY') : '',
      }));

      setUnderReviewingRows(formattedData);
      setTotalPages(totalPages);
      setTotalElements(totalElements);
      setNumberOfElements(numberOfElements);
    } catch (error) {
      console.error('Failed to fetch data:', error);
      setUnderReviewingRows([]);
    } finally {
      setLoading(false);
    }
  };

  const fetchPublishedData = async (page, size, search) => {
    setLoading(true);
    try {
      const response = await getListCompletedOwnershipRequests(page, search, size);
      const data = response.data.content || [];
      const totalPages = response?.data?.totalPages || 0;
      const totalElements = response?.data?.totalElements || 0;
      const numberOfElements = response?.data?.numberOfElements || 0;

      const formatDownPayment = (downPayment) => {
        if (!downPayment) return 'No Down Payment Information';
        if (downPayment === '0') return 'Under Processing';
        if (downPayment === '1') return 'Finished';
        if (downPayment === '-1') return 'Cancelled';
        return '';
      };

      const formatPaymentStatus = (paymentStatus) => {
        if (!paymentStatus) return '';
        return paymentStatus === '0' ? 'Not Completed' : 'Completed';
      };
      const formattedData = data.map((item) => ({
        id: item.id,
        requestType: item.requestType,
        flagNameEn: item.flagNameEn,
        flagNameAr: item.flagNameAr,
        managerName: item.managerName,
        categoryNameEn: item.categoryNameEn,
        categoryNameAr: item.categoryNameAr,
        fullNameEn: item.fullNameEn,
        fullNameAr: item.fullNameAr,
        phone: item.phone,
        flagId: item.flagId,
        downPayment: item.downPayment,
        paymentStatus: formatPaymentStatus(item.paymentStatus),
        requestStatus: formatDownPayment(item.requestStatus),
        nextAction: item.nextAction,
        totalAmount: item?.totalAmount || '',
        finishDate: item?.finishDate !== null ? moment(item?.finishDate || '').format('DD/MM/YYYY') : '',
        creationDate: item?.creationDate !== null ? moment(item?.creationDate).format('DD/MM/YYYY') : '',
      }));

      setPublishedRows(formattedData);
      setTotalPages(totalPages);
      setTotalElements(totalElements);
      setNumberOfElements(numberOfElements);
    } catch (error) {
      console.error('Failed to fetch data:', error);
      setPublishedRows([]);
    } finally {
      setLoading(false);
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleViewDetails = (row) => {
    if (row.flagType === 'UNIT') {
      navigate(`/dashboard/ownership-requests/details/${row.flagId}`, {
        state: {
          from: 'ownership-requests',
          nextAction: row.nextAction,
          paymentStatus: row.paymentStatus,
          requestStatus: row.requestStatus,
          requestId: row.id,
          userId: row.userId,
          reservationInvoice: row?.reservationInvoice,
          hasAssignPermission,
          hasFinishPermission,
        },
      });
    }

    if (row.flagType === 'LAND') {
      navigate(`/dashboard/ownership-requests/details/land/${row.flagId}`, {
        state: {
          from: 'ownership-requests',
          nextAction: row.nextAction,
          paymentStatus: row.paymentStatus,
          requestStatus: row.requestStatus,
          requestId: row.id,
          userId: row.userId,
          hasAssignPermission,
          hasFinishPermission,
          reservationInvoice: row?.reservationInvoice,
        },
      });
    }
  };

  const getPaginationLabel = () => {
    const start = (page - 1) * rowsPerPage + 1;
    const end = start + numberOfElements - 1;
    return `Showing ${start} to ${end} of ${totalElements} entries`;
  };

  return (
    <>
      {' '}
      <Helmet>
        <title>{t('ownerShipRequests.ownerShipRequests')}</title>
      </Helmet>{' '}
      <Box sx={{ flexGrow: 1, p: 3, backgroundColor: 'white', borderRadius: '16px' }}>
        <Typography variant="h4" gutterBottom>
          {t('ownerShipRequests.ownerShipRequests')}{' '}
        </Typography>
        {hasSearchPermission && (
          <Grid sx={{ sm: 5 }}>
            <SearchComponent
              label={t('General.Search')}
              onSearch={handleSearch}
              style={{ marginBottom: '20px', width: '30%' }}
            />
          </Grid>
        )}

        <CustomTabs value={tabValue} onChange={handleTabChange} tabs={tabs} />
        {loading ? (
          <Stack spacing={1}>
            {[...Array(rowsPerPage)].map((_, index) => (
              <Skeleton key={index} height={40} />
            ))}
          </Stack>
        ) : (
          <>
            {tabValue === 0 && hasNewListRequestedPermission ? (
              underReviewingRows.length > 0 ? (
                <CustomTable
                  userId={userId}
                  isManager={isManager}
                  rows={underReviewingRows}
                  columns={columns}
                  actions={reviewedTabactions}
                  tabValue={tabValue}
                  handleViewDetails={handleViewDetails}
                />
              ) : (
                <Typography style={{ marginTop: '6px' }} variant="h6" align="center">
                  No Data Found
                </Typography>
              )
            ) : publishedRows.length > 0 && hasNewListCompletePermission ? (
              <CustomTable columns={columns} rows={publishedRows} tabValue={tabValue} handleViewDetails={() => {}} />
            ) : (
              <Typography style={{ marginTop: '6px' }} variant="h6" align="center">
                No Data Found
              </Typography>
            )}

            {totalPages > 1 && (
              <div style={{ display: 'flex', justifyContent: 'space-between', padding: '10px', marginTop: '5rem' }}>
                <Pagination count={totalPages} page={page} onChange={handleChangePage} color="primary" />
                <div>{getPaginationLabel()}</div>
              </div>
            )}
          </>
        )}
      </Box>
    </>
  );
};

export default OwnerShipRequests;
