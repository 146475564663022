import {
  Box,
  Checkbox,
  Container,
  FormControl,
  Grid,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  Tab,
  Tabs,
  Typography,
} from '@mui/material';
import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

const RoleTabsUI = ({ tabsData, setSelectedPermissions }) => {
  const [selectedHorizontalTab, setSelectedHorizontalTab] = useState(0);
  const [selectedVerticalTab, setSelectedVerticalTab] = useState(0);
  const [selectedOptions, setSelectedOptions] = useState({});

  const { t, i18n } = useTranslation();
  const isRtl = i18n.language === 'ar';

  useEffect(() => {
    const initializeSelectedOptions = () => {
      if (tabsData && tabsData.PERMISSIONS) {
        const options = {};
        tabsData.PERMISSIONS.forEach((permission, hIndex) => {
          if (!options[hIndex]) {
            options[hIndex] = {};
          }
          permission.pages.forEach((page, vIndex) => {
            const checkedActions = page.actions
              .filter((action) => action.isChecked === '1')
              .map((action) => (isRtl ? action.actionNameAr : action.actionName));
            if (checkedActions.length > 0) {
              options[hIndex][vIndex] = checkedActions;
            }
          });
        });
        setSelectedOptions(options);
      }
    };
    initializeSelectedOptions();
  }, [tabsData]);

  const horizontalTabs = tabsData.PERMISSIONS
    ? tabsData.PERMISSIONS.map((permission) => (isRtl ? permission.nameAr : permission.nameEn))
    : [];
  const verticalTabs =
    tabsData.PERMISSIONS && tabsData.PERMISSIONS[selectedHorizontalTab]
      ? tabsData.PERMISSIONS[selectedHorizontalTab].pages
      : [];

  const actions = verticalTabs[selectedVerticalTab] ? verticalTabs[selectedVerticalTab].actions : [];

  useEffect(() => {
    const permissions = {};

    Object.keys(selectedOptions).forEach((horizontalIndex) => {
      const verticalSelections = selectedOptions[horizontalIndex];

      Object.keys(verticalSelections).forEach((verticalIndex) => {
        const functionId = tabsData.PERMISSIONS[horizontalIndex]?.pages[verticalIndex]?.id;

        if (functionId) {
          const actionIds = verticalSelections[verticalIndex]
            .map((name) => {
              const action = tabsData.PERMISSIONS[horizontalIndex].pages[verticalIndex].actions.find((action) =>
                isRtl ? action.actionNameAr === name : action.actionName === name
              );
              return action ? action.id : null;
            })
            .filter((id) => id !== null);

          if (!permissions[functionId]) {
            permissions[functionId] = [];
          }
          permissions[functionId].push(...actionIds);
        }
      });
    });

    setSelectedPermissions(permissions);
  }, [selectedOptions, tabsData]);

  const handleHorizontalTabChange = (event, newValue) => {
    setSelectedHorizontalTab(newValue);
    setSelectedVerticalTab(0);
  };

  const handleVerticalTabChange = (event, newValue) => {
    setSelectedVerticalTab(newValue);
  };

  const handleSelectChange = (event) => {
    const {
      target: { value },
    } = event;

    let updatedOptions;
    if (value.includes('select-all')) {
      const allSelected = currentSelectedOptions.length === actions.length;
      updatedOptions = allSelected ? [] : actions.map((action) => (isRtl ? action.actionNameAr : action.actionName));
    } else {
      updatedOptions = typeof value === 'string' ? value.split(',') : value;
    }

    setSelectedOptions((prev) => {
      const updated = { ...prev };
      if (!updated[selectedHorizontalTab]) {
        updated[selectedHorizontalTab] = {};
      }
      updated[selectedHorizontalTab][selectedVerticalTab] = updatedOptions.filter((option) => option !== 'select-all');
      return updated;
    });
  };

  const currentSelectedOptions = selectedOptions[selectedHorizontalTab]?.[selectedVerticalTab] || [];

  return (
    <Container sx={{ mt: 5 }}>
      <Typography variant="h5" gutterBottom>
        {t('General.groupPermission')}
      </Typography>
      <Tabs
        value={selectedHorizontalTab}
        onChange={handleHorizontalTabChange}
        indicatorColor="primary"
        textColor="primary"
        sx={{ mt: 3 }}
      >
        {horizontalTabs.map((label, index) => (
          <Tab key={index} label={label} />
        ))}
      </Tabs>
      <Grid container spacing={3}>
        <Grid item xs={3}>
          <Tabs
            orientation="vertical"
            value={selectedVerticalTab}
            onChange={handleVerticalTabChange}
            TabIndicatorProps={{ style: { display: 'none' } }}
            sx={{
              borderRight: 0,
              mt: 3,
              '.MuiTab-root': {
                padding: '16px',
                gap: '0px',
                borderRadius: '10px',
                backgroundColor: 'transparent',
                opacity: 1,
              },
              '.Mui-selected': {
                backgroundColor: '#F8F8F8',
                border: 'none',
                color: '#535455',
              },
            }}
          >
            {verticalTabs.map((page, index) => (
              <Tab sx={{ alignItems: 'flex-start' }} key={index} label={isRtl ? page.nameAr : page.nameEn} />
            ))}
          </Tabs>
        </Grid>
        <Grid sx={{ mt: 4 }} item xs={9}>
          <Box>
            <FormControl fullWidth sx={{ mb: 2 }}>
              <Select
                multiple
                displayEmpty
                value={currentSelectedOptions}
                onChange={handleSelectChange}
                input={<OutlinedInput label={t('General.selectAction')} />}
                renderValue={(selected) => {
                  if (selected.length === 0) {
                    return <em>{t('General.selectAction')}</em>;
                  }
                  return selected.join(', ');
                }}
              >
                <MenuItem key="select-all" value="select-all">
                  <Checkbox checked={currentSelectedOptions.length === actions.length && actions.length > 0} />
                  <ListItemText primary={t('General.selectAll')} />
                </MenuItem>

                {actions.map((action) => (
                  <MenuItem
                    sx={{
                      '&.Mui-selected': {
                        backgroundColor: 'transparent',
                      },
                    }}
                    key={action.id}
                    value={isRtl ? action.actionNameAr : action.actionName}
                  >
                    <Checkbox
                      checked={currentSelectedOptions.indexOf(isRtl ? action.actionNameAr : action.actionName) > -1}
                    />
                    <ListItemText primary={isRtl ? action.actionNameAr : action.actionName} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
        </Grid>
      </Grid>
    </Container>
  );
};

export default RoleTabsUI;
