import Box from "@mui/material/Box";
import { ButtonBase, Stack, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

import Modal from "@mui/material/Modal";

import { ReactComponent as NotIcon } from "../../theme/images/notIcon.svg";

const DeleteFileModal = ({ open, handelClose, onDelete }) => {
  const { t } = useTranslation();
  return (
    <div>
      <Modal sx={{ border: "none" }} open={open} onClose={handelClose}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "563px",
            bgcolor: "background.paper",
            padding: "32px 24px",
            borderRadius: "10px",
          }}
        >
          <Stack
            sx={{
              alignItems: "center",
              textAlign: "center",
              gap: "24px",
              width: "100%",
            }}
          >
            <NotIcon />
            <Stack>
              <Typography
                sx={{
                  fontWeight: 700,
                  fontSize: "20px",
                  lineHeight: "24px",
                  color: "#0D2416",
                  marginBottom: "24px",
                }}
              >
                {t("General.areYouSure")}
              </Typography>
              <Typography
                sx={{
                  fontWeight: 400,
                  fontSize: " 14px",
                  lineHeight: "21px",
                  color: "#0D2416",
                }}
              >
                {t("General.confirm")}
              </Typography>
            </Stack>
            <Box
              sx={{
                width: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-evenly",
              }}
            >
              {" "}
              <ButtonBase
                onClick={handelClose}
                sx={{
                  padding: "24px",
                  backgroundColor: "white",
                  lineHeight: "16px",
                  fontWeight: "600",
                  fontSize: "14px",
                  color: "#0D2416",
                  border: "1px solid #0D2416",
                  borderRadius: "10px",
                  minWidth: "200px",
                }}
              >
                {t("General.cancel")}
              </ButtonBase>
              <ButtonBase
                onClick={onDelete}
                sx={{
                  padding: "24px",
                  backgroundColor: "#0D2416",
                  lineHeight: "16px",
                  fontWeight: "600",
                  fontSize: "14px",
                  color: "white",
                  borderRadius: "10px",
                  minWidth: "200px",
                }}
              >
                {t("General.confirmDelete")}
              </ButtonBase>{" "}
            </Box>
          </Stack>
        </Box>
      </Modal>
    </div>
  );
};

export default DeleteFileModal;
