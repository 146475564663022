import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useNavigate } from 'react-router-dom';
import { Button, Box, Grid, TextField, Typography, MenuItem, Select, FormControl } from '@mui/material';
import { Formik } from 'formik';
import * as yup from 'yup';
import { toast } from 'react-toastify';
import { LoadingButton } from '@mui/lab';
import { ReactComponent as DropdownIcon } from '../../../theme/images/deopdownIcon.svg';

import { addCityRequest, getRegionList } from '../../../network/apis';

export default function RegionForm({ handleClose }) {
  const { t, i18n } = useTranslation();
  const isRtl = i18n.language === 'ar';
  const [loading, setLoading] = useState(false);
  const [regions, setRegions] = useState([]);

  const getRegions = async () => {
    try {
      const { data } = await getRegionList(0, 100, '');
      setRegions(
        data?.content?.map((region) => {
          return {
            id: region.id,
            nameEn: region.nameEn,
            nameAr: region.nameAr,
          };
        })
      );
    } catch (error) {
      console.error('Failed to fetch regions:', error);
      toast.error('Failed to fetch regions');
    }
  };
  useEffect(() => {
    getRegions();
  }, []);

  const validationSchema = yup.object({
    nameEn: yup
      .string()
      .required(t('Validation.requiredField'))
      .test('is-english', t('Validation.englishReq'), (value) => {
        // Regular expression to match English characters
        const englishRegex = /^[A-Za-z0-9_\s]*$/;
        return englishRegex.test(value);
      }),

    nameAr: yup
      .string()
      .required(t('Validation.requiredField'))
      .test('is-arabic', t('Validation.arabicReq'), (value) => {
        // Regular expression to match Arabic characters
        const arabicRegex = /[\u0600-\u06FF]/;
        // Regular expression to match English characters
        const englishRegex = /[A-Za-z]/;
        // Check if the value contains both Arabic and English characters
        return arabicRegex.test(value) && !englishRegex.test(value);
      }),
  });
  return (
    <Formik
      initialValues={{
        nameEn: '',
        nameAr: '',
        regionId: '',
      }}
      validationSchema={validationSchema}
      onSubmit={(values) => {
        setLoading(true);

        addCityRequest(values)
          .then((res) => {
            setLoading(false);
            if (res.data.STATUS === 'FAILED') {
              toast.error(res.data.MESSAGE);
            } else {
              toast.success(res.data.MESSAGE);
              handleClose(); // Only close the modal if the status is not '0'
            }
          })
          .catch((err) => {
            setLoading(false);
            toast.error(err.data.MESSAGE, {
              position: 'top-right',
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: 'light',
            });
          });
      }}
    >
      {({ values, errors, touched, handleChange, handleBlur, handleSubmit, setFieldValue }) => {
        return (
          <form onSubmit={handleSubmit}>
            <Grid rowSpacing={2} container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }}>
              <Grid item xs={2} sm={4} md={12}>
                <Typography variant="body1" gutterBottom>
                  {t('General.region')}
                </Typography>
                <FormControl fullWidth>
                  <Select
                    displayEmpty
                    value={values.regionId}
                    sx={{
                      paddingX: '10px',
                      borderRadius: '10px',
                      flexDirection: 'row',
                      justifyContent: 'start',
                    }}
                    renderValue={(selected) => {
                      if (selected === '') {
                        return <em>{t('General.region')}</em>;
                      }
                      return isRtl
                        ? regions?.find((region) => region?.id === selected)?.nameAr
                        : regions?.find((region) => region?.id === selected)?.nameEn;
                    }}
                    IconComponent={() => <DropdownIcon style={{ width: '15px', height: '15px' }} />}
                    labelId="region"
                    id="region"
                    onChange={(e) => setFieldValue('regionId', e.target.value)}
                  >
                    {regions.map((region) => (
                      <MenuItem value={region?.id}>{isRtl ? region.nameAr : region.nameEn}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={2} sm={4} md={6}>
                <Typography variant="body1" gutterBottom>
                  {t('General.nameEn')}
                </Typography>
                <TextField
                  name="nameEn"
                  required
                  fullWidth
                  value={values.nameEn}
                  variant="outlined"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.nameEn && Boolean(errors.nameEn)}
                  helperText={touched.nameEn && errors.nameEn}
                />
              </Grid>
              <Grid item xs={2} sm={4} md={6}>
                <Typography variant="body1" gutterBottom>
                  {t('General.nameAr')}
                </Typography>
                <TextField
                  name="nameAr"
                  required
                  fullWidth
                  value={values.nameAr}
                  variant="outlined"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.nameAr && Boolean(errors.nameAr)}
                  helperText={
                    touched.nameAr &&
                    errors.nameAr && (
                      <span dangerouslySetInnerHTML={{ __html: errors.nameAr.replace('\n', '<br />') }} />
                    )
                  }
                />
              </Grid>
              <Box
                sx={{
                  width: '100%',
                  display: 'flex',
                  gap: 2,
                  alignItems: 'center',
                  justifyContent: 'flex-end',
                  paddingX: '20px',
                }}
              >
                <Button
                  sx={{ mt: 4, border: '1px solid #111827' }}
                  size="large"
                  onClick={handleClose}
                  type="button"
                  variant=""
                >
                  {t('General.cancel')}
                </Button>
                <LoadingButton sx={{ mt: 4 }} loading={loading} size="large" type="submit" variant="contained">
                  {t('General.save')}
                </LoadingButton>
              </Box>
            </Grid>
          </form>
        );
      }}
    </Formik>
  );
}
