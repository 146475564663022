import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  Box,
  useTheme,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

const CustomTable = ({ columns, rows = [], actions, userId, isManager, tabValue, handleViewDetails }) => {
  const { t, i18n } = useTranslation();
  const isRtl = i18n.language === 'ar';

  const theme = useTheme();
  const isDarkMode = theme.palette.mode === 'dark';
  const [openDialog, setOpenDialog] = useState(false);
  const [dialogContent, setDialogContent] = useState('');

  const navigate = useNavigate();

  const handleDialogClose = () => {
    setOpenDialog(false);
    setDialogContent('');
  };

  const getStatusStyles = (status) => {
    if (status === 'Completed' || status === 'Finished') {
      return {
        background: '#E7F7EF',

        color: '#0CAF60',
        padding: '4px 16px',
        fontWeight: '700',
        lineHeight: '16px',
        textAlign: 'center',
        borderRadius: '8px',
      };
    }
    if (status === 'Not Completed' || status === 'Cancelled') {
      return {
        backgroundColor: '#FFCDD2', // Red background
        color: '#D32F2F', // Dark red text
        textAlign: 'center',
        padding: '4px 16px',
        borderRadius: '8px',
      };
    }
    if (status === 'Under Processing') {
      return {
        backgroundColor: '#FFF6D3',
        color: '#E6BB20',
        textAlign: 'center',
        padding: '4px 16px',
        borderRadius: '8px',
      };
    }
    // Add a default case or handle other statuses as needed
    return {
      // Default styles
      backgroundColor: '#FFF', // White background
      color: '#333', // Dark gray text
      padding: '5px 10px',
      borderRadius: '5px',
    };
  };
  return (
    <TableContainer component={Paper} sx={{ mt: 3 }}>
      <Table aria-label="custom table">
        <TableHead sx={{ whiteSpace: 'nowrap', borderRadius: '10px' }}>
          <TableRow>
            {columns.map((column) => (
              <TableCell
                sx={{
                  textAlign: isRtl ? 'right' : 'left',
                  whiteSpace: 'nowrap',
                  backgroundColor: isDarkMode ? '#000000' : '#32de84',
                  borderBottom: 0,
                  color: 'white',
                }}
                component="th"
                scope="row"
                key={column.field}
              >
                <Typography
                  sx={{
                    fontWeight: 'bold',
                    fontSize: '12px',
                    lineHeight: '20px',
                    color: 'white',
                  }}
                  variant="subtitle2"
                  noWrap
                >
                  {column.headerName}
                </Typography>
              </TableCell>
            ))}

            {actions !== undefined && (
              <TableCell
                sx={{
                  whiteSpace: 'nowrap',
                  backgroundColor: isDarkMode ? '#000000' : '#32de84',
                  color: 'white',
                  borderBottom: 0,
                }}
              >
                <Typography
                  sx={{
                    fontWeight: 'bold',
                    fontSize: '12px',
                    lineHeight: '20px',
                    color: 'white',
                  }}
                  variant="subtitle2"
                  noWrap
                >
                  {t('General.Action')}
                </Typography>
              </TableCell>
            )}
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row, rowIndex) => {
            return (
              <TableRow
                sx={{
                  '&:hover': {
                    backgroundColor: '#f7f7f7',
                  },
                  whiteSpace: 'nowrap',
                }}
                key={rowIndex}
              >
                {columns.map((column) => (
                  <TableCell sx={{ textAlign: isRtl ? 'right' : 'left', whiteSpace: 'nowrap' }} key={column.field}>
                    {column.field === 'paymentStatus' ? (
                      <Box sx={getStatusStyles(row[column.field])}>
                        <Typography variant="body2" sx={{ fontSize: '12px', fontWeight: '700' }}>
                          {row[column.field].toString() === 'Not Completed'
                            ? t('General.inCompleted')
                            : t('General.Completed')}
                        </Typography>
                      </Box>
                    ) : column.field === 'requestStatus' ? (
                      <Box sx={getStatusStyles(row[column.field])}>
                        <Typography variant="body2" sx={{ fontSize: '12px', fontWeight: '700' }}>
                          {row[column.field].toString() === 'Under Processing'
                            ? t('General.Pending')
                            : t('General.finished')}
                        </Typography>
                      </Box>
                    ) : column.field === 'requestType' ? (
                      t(`General.${row[column.field]}`)
                    ) : (
                      row[column.field] || t('General.noData')
                    )}
                  </TableCell>
                ))}

                <TableCell onClick={(e) => e.stopPropagation()}>
                  {actions !== undefined &&
                    actions?.map((action, actionIndex) => (
                      <IconButton key={actionIndex} color="primary" onClick={() => action.handler(row)}>
                        {action.icon}
                      </IconButton>
                    ))}
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
      <Dialog open={openDialog} onClose={handleDialogClose}>
        <DialogTitle>
          <IconButton
            edge="end"
            color="inherit"
            onClick={handleDialogClose}
            aria-label="close"
            sx={{ position: 'absolute', right: 8, top: 8 }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Typography>{dialogContent}</Typography>
        </DialogContent>
      </Dialog>
    </TableContainer>
  );
};

export default CustomTable;
