import React, { useEffect, useState } from 'react';

import { Box, Tabs, Tab, Button, Stack } from '@mui/material';
import { TabContext, TabPanel } from '@mui/lab';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { usePlanFormContext } from '../context/landFormContext';
import StyledTabs from '../components/StyledTabs/StyledTabs';

import Layout from '../components/Layout/Layout';
import { createApproveWFRequest, createRejectWFRequest } from '../network/apis';

import LandPieceDataFormTab from '../sections/@dashboard/landFormTabs/LandPieceData';
import FinancialDataForm from '../sections/@dashboard/landFormTabs/financialDetailsData';

const ManageLands = () => {
  const {
    currentTab,
    handleChange,
    handleNext,
    handlePrev,
    editData,
    handleDelete,
    addItem,
    deleteItem,
    refetch,
    setRefetch,
    uploadedFilesUploaded,
    handleSubmit,
    fromPage,
    loading,
    userId,
    managerId,
    isManager,
    requestId,
    isViewMode,
    isEditMode,
  } = usePlanFormContext();

  const navigate = useNavigate();

  const [openRejectDialog, setOpenRejectDialog] = useState(false);
  const [rejectReason, setRejectReason] = useState('');
  const { i18n, t } = useTranslation();
  const isRtl = i18n.language === 'ar';
  const handleApprove = async (row) => {
    const requestData = {
      REQUEST_ID: requestId,
      COMMENT: 'APPROVED',
    };

    try {
      const response = await createApproveWFRequest(requestData);
      toast.success('Product approved successfully!');
      navigate('/dashboard/review-products');
    } catch (error) {
      toast.error('Failed to approve product');
    }
  };

  const handleOpenRejectDialog = (row) => {
    // setSelectedRow(row);
    setOpenRejectDialog(true);
  };

  const handleCloseRejectDialog = () => {
    setOpenRejectDialog(false);
    navigate('/dashboard/review-products');
    setRejectReason('');
  };

  const handleReject = async () => {
    const requestData = {
      REQUEST_ID: requestId,
      COMMENT: rejectReason,
    };

    try {
      const response = await createRejectWFRequest(requestData);
      toast.success('Product rejected!');
      handleCloseRejectDialog();
      // setSelectedRow(null);
    } catch (error) {
      toast.error('Failed to reject product');
      handleCloseRejectDialog();
    }
  };
  return (
    <Layout sx={{ width: '100%' }}>
      <h3>{isEditMode ? t('LANDS.editLand') : t('LANDS.newLand')}</h3>
      <TabContext value={currentTab}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider', direction: isRtl ? 'rtl' : 'ltr', display: 'flex' }}>
          <StyledTabs
            value={currentTab}
            onChange={handleChange}
            tabs={[
              {
                label: t('LANDS.landData'),
                value: '1',
              },
              { label: t('LANDS.financialDetials'), value: '2' },
            ]}
          />
        </Box>

        <TabPanel value="1">
          <LandPieceDataFormTab
            handleNext={handleNext}
            editData={editData && editData?.data}
            isEditMode={isEditMode}
            handleDelete={handleDelete}
            viewMode={isViewMode}
            addItem={addItem}
            uploadedFilesUploaded={uploadedFilesUploaded}
            deleteItem={deleteItem}
            setRefetch={setRefetch}
            refetch={refetch}
          />
        </TabPanel>
        <TabPanel value="2">
          <FinancialDataForm
            t={t}
            isRtl={isRtl}
            viewMode={isViewMode}
            isEditMode={isEditMode}
            editData={editData && editData?.data}
            submitFormValues={handleSubmit}
            handlePrevTab={handlePrev}
            userId={userId}
            isManager={isManager}
            managerId={managerId}
            fromPage={fromPage}
            handleApprove={handleApprove}
            loading_submit={loading}
            open={openRejectDialog}
            onClose={handleCloseRejectDialog}
            onReject={handleReject}
            rejectReason={rejectReason}
            setRejectReason={setRejectReason}
            handleOpenRejectDialog={handleOpenRejectDialog}
          />
        </TabPanel>
      </TabContext>
    </Layout>
  );
};

export default ManageLands;
