import React from 'react';
import CircularProgress from '@mui/material/CircularProgress';


const SuccessPage = () => (
  <div style={styles.container}>
   <CircularProgress  disableShrink />
   </div>
);


const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100vh',
    backgroundColor: '#f0f8ff',
  }
};

export default SuccessPage;