import * as React from "react";
import PropTypes from "prop-types";
import { toast } from "react-toastify";

import { styled, css } from "@mui/system";
import { Modal as BaseModal } from "@mui/base/Modal";
import Fade from "@mui/material/Fade";
import TextField from "@mui/material/TextField";
import {
  Grid,
  Typography,
  Button,
  Stack,
  CircularProgress,
} from "@mui/material";
import { Formik } from "formik";
import { useTranslation } from "react-i18next";
import UploadFileOnChange from "../UploadFileOnChange.jsx/UploadFileOnChange";
import { addOurOffer } from "../../network/apis";

export default function AddOurOffersModal({
  open,
  handleClose,
  ourOffersListApi,
}) {
  const { t } = useTranslation();
  const [loading, setLoading] = React.useState(false);
  const [uploadedFiles, setUploadedFiles] = React.useState({
    ourOfferImage: [],
  });
  // Add Item Function
  const addItem = (fieldName, items) => {
    if (!uploadedFiles[fieldName]) {
      uploadedFiles[fieldName] = [];
    }
    items.forEach((item) => {
      if (!uploadedFiles[fieldName].includes(item)) {
        uploadedFiles[fieldName].push(item);
      }
    });
  };

  // Delete Item Function
  const deleteItem = (fieldName, item) => {
    if (uploadedFiles[fieldName]) {
      uploadedFiles[fieldName] = uploadedFiles[fieldName].filter(
        (i) => i !== item
      );
    }
  };
  const addItemCallback = React.useCallback((fieldName, items) => {
    addItem(fieldName, items);
  }, []);

  const deleteItemCallback = React.useCallback((fieldName, item) => {
    deleteItem(fieldName, item);
  }, []);

  // Handle Add Our Service

  const handleAddOurService = async (values) => {
    setLoading(true);
    const res = await addOurOffer({
      nameEn: values.nameEn,
      nameAr: values.nameAr,
      descriptionEn: values.descriptionEn,
      descriptionAr: values.descriptionAr,
      image: uploadedFiles?.ourOfferImage?.map((item) => item.id),
    });
    if (res?.data?.STATUS === "SUCCESS") {
      toast.success(res?.data?.MESSAGE);
      handleClose();
      ourOffersListApi(0, "");

      setLoading(false);
    } else {
      toast.error(res?.data?.MESSAGE);
      setLoading(false);
    }
  };

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={open}
      onClose={handleClose}
      closeAfterTransition
      slots={{ backdrop: StyledBackdrop }}
    >
      <Fade in={open}>
        <ModalContent sx={style}>
          <Typography id="transition-modal-title" variant="h6" component="h2">
            {t("addOurOffers.addOurOffers")}
          </Typography>
          <Formik
            initialValues={{
              nameEn: "",
              nameAr: "",
              descriptionEn: "",
              descriptionAr: "",
            }}
            onSubmit={(values, { setSubmitting }) => {
              handleAddOurService(values);
              handleClose();
            }}
          >
            {({
              values,
              errors,
              handleChange,
              handleSubmit,
              handleBlur,
              touched,
            }) => {
              return (
                <>
                  <form
                    onSubmit={(e) => {
                      e.preventDefault();
                      handleSubmit(e);
                    }}
                  >
                    <Grid
                      padding={"20px"}
                      container
                      spacing={{ xs: 2, md: 2 }}
                      columns={{ xs: 4, sm: 8, md: 12 }}
                      sx={{
                        height: "100%",
                        padding: "20px",
                        borderBottom: "1px solid #E5E5E5",
                      }}
                    >
                      <Grid item xs={2} sm={4} md={6}>
                        <Typography variant="body1" gutterBottom>
                          {t("addOurOffers.nameEn")}
                        </Typography>
                        <TextField
                          name="nameEn"
                          required
                          fullWidth
                          value={values.nameEn}
                          variant="outlined"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          error={touched.nameEn && Boolean(errors.nameEn)}
                          helperText={touched.nameEn && errors.nameEn}
                        />
                      </Grid>{" "}
                      <Grid item xs={2} sm={4} md={6}>
                        <Typography variant="body1" gutterBottom>
                          {t("addOurOffers.nameAr")}
                        </Typography>
                        <TextField
                          name="nameAr"
                          required
                          fullWidth
                          value={values.nameAr}
                          variant="outlined"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          error={touched.nameAr && Boolean(errors.nameAr)}
                          helperText={touched.nameAr && errors.nameAr}
                        />
                      </Grid>
                      <Grid item xs={2} sm={4} md={12}>
                        <Typography variant="body1" gutterBottom>
                          {t("addOurOffers.image")}
                        </Typography>
                        <UploadFileOnChange
                          type="PHOTO"
                          category="PROJECT"
                          multiple={false}
                          label={t("addOurServices.image")}
                          fieldName="ourOfferImage"
                          maxSize={5000000}
                          acceptedTypes="image/*"
                          uploadedFilesUploaded={uploadedFiles}
                          addItem={addItemCallback}
                          deleteItem={deleteItemCallback}
                        />
                      </Grid>
                      <Grid item xs={2} sm={4} md={12}>
                        <Typography variant="body1" gutterBottom>
                          {t("addOurOffers.descriptionEn")}
                        </Typography>
                        <TextField
                          name="descriptionEn"
                          required
                          multiline
                          rows={3}
                          fullWidth
                          value={values.descriptionEn}
                          variant="outlined"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          error={
                            touched.descriptionEn &&
                            Boolean(errors.descriptionEn)
                          }
                          helperText={
                            touched.descriptionEn && errors.descriptionEn
                          }
                        />
                      </Grid>{" "}
                      <Grid item xs={2} sm={4} md={12}>
                        <Typography variant="body1" gutterBottom>
                          {t("addOurOffers.descriptionAr")}
                        </Typography>
                        <TextField
                          name="descriptionAr"
                          required
                          multiline
                          rows={3}
                          fullWidth
                          value={values.descriptionAr}
                          variant="outlined"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          error={
                            touched.descriptionAr &&
                            Boolean(errors.descriptionAr)
                          }
                          helperText={
                            touched.descriptionAr && errors.descriptionAr
                          }
                        />
                      </Grid>
                    </Grid>
                    <Stack
                      padding={2}
                      sx={{ width: "100%" }}
                      direction="row"
                      justifyContent="end"
                      alignItems="end"
                      gap={2}
                    >
                      <Button
                        sx={{ mt: 4, border: "1px solid #111827" }}
                        size="large"
                        type="button"
                        onClick={handleClose}
                      >
                        {" "}
                        {t("General.cancel")}
                      </Button>
                      <Button
                        sx={{ mt: 4, border: "1px solid #111827" }}
                        size="large"
                        type="submit"
                        variant="contained"
                      >
                        {" "}
                        {loading ? <CircularProgress /> : t("General.submit")}
                        {}
                      </Button>
                    </Stack>
                  </form>
                </>
              );
            }}
          </Formik>
        </ModalContent>
      </Fade>
    </Modal>
  );
}

const Backdrop = React.forwardRef((props, ref) => {
  const { open, ...other } = props;
  return (
    <Fade in={open}>
      <div ref={ref} {...other} />
    </Fade>
  );
});

Backdrop.propTypes = {
  open: PropTypes.bool,
};

const grey = {
  50: "#F3F6F9",
  100: "#E5EAF2",
  200: "#DAE2ED",
  300: "#C7D0DD",
  400: "#B0B8C4",
  500: "#9DA8B7",
  600: "#6B7A90",
  700: "#434D5B",
  800: "#303740",
  900: "#1C2025",
};

const Modal = styled(BaseModal)`
  position: fixed;
  z-index: 1300;
  inset: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const StyledBackdrop = styled(Backdrop)`
  z-index: -1;
  position: fixed;
  inset: 0;
  background-color: rgb(0 0 0 / 0.5);
  -webkit-tap-highlight-color: transparent;
`;

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 800,
  height: "90%",
  maxHeight: "90%",
  overflow: "auto",
};

const ModalContent = styled("div")(
  ({ theme }) => css`
    font-family: "IBM Plex Sans", sans-serif;
    font-weight: 500;
    text-align: start;
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 8px;
    overflow: hidden;
    background-color: ${"#fff"};
    border-radius: 8px;
    border: 1px solid ${grey[200]};
    box-shadow: 0 4px 12px ${"rgb(0 0 0 / 0.2)"};
    padding: 24px;
    color: ${grey[900]};

    & .modal-title {
      margin: 0;
      line-height: 1.5rem;
      margin-bottom: 8px;
    }

    & .modal-description {
      margin: 0;
      line-height: 1.5rem;
      font-weight: 400;
      color: ${grey[800]};
      margin-bottom: 4px;
    }
  `
);
