import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet-async';

import { useNavigate } from 'react-router-dom';
import { Box, Typography, Skeleton, Stack, Pagination, Grid } from '@mui/material';
import { toast } from 'react-toastify';
import moment from 'moment';
import { ReactComponent as TrickIcon } from '../theme/images/tick-square.svg';
import { ReactComponent as Closecon } from '../theme/images/close-square.svg';

import SearchComponent from '../components/search/Search';
import CustomTabs from '../components/CustomTabs/ReviewTabs';
import CustomTable from '../sections/@dashboard/reviewProducts/ReviewProductsTable';
import ModalData from '../sections/@dashboard/reviewProducts/Modal';
import {
  getFilterUnderReviewProducts,
  getfilterPublishedProducts,
  createApproveWFRequest,
  createRejectWFRequest,
} from '../network/apis';

const ReviewApproveProducts = () => {
  const { t, i18n } = useTranslation();
  const isRtl = i18n.language === 'ar';
  const tabs = [t('review_products.under_review.title'), t('review_products.approved.title')];
  const columns = [
    {
      field: isRtl ? 'managerNameAr' : 'managerNameEn',
      headerName: t('review_products.manager_name'),
      alignRight: isRtl && true,
    },
    {
      field: isRtl ? 'requesterNameAr' : 'requesterNameEn',
      headerName: t('review_products.creator_name'),
      alignRight: isRtl && true,
    },
    {
      field: isRtl ? 'categoryNameAr' : 'categoryNameEn',
      headerName: t('review_products.product_name'),
      alignRight: isRtl && true,
    },
    {
      field: 'productType',
      headerName: t('review_products.product_type'),
      alignRight: isRtl && true,
    },
    {
      field: isRtl ? 'parentNameAr' : 'parentNameEn',
      headerName: t('review_products.name_project_paln'),
      alignRight: isRtl && true,
    },
  ];

  const reviewedTabactions = [
    {
      icon: <TrickIcon />,
      handler: async (row) => handleApprove(row),
    },
    {
      icon: <Closecon />,
      handler: (row) => handleOpenRejectDialog(row),
    },
  ];

  const [tabValue, setTabValue] = useState(0);
  const [searchQuery, setSearchQuery] = useState('');
  const [underReviewingRows, setUnderReviewingRows] = useState([]);
  const [publishedRows, setPublishedRows] = useState([]);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [totalPages, setTotalPages] = useState(0);
  const [totalElements, setTotalElements] = useState(0);
  const [numberOfElements, setNumberOfElements] = useState(0);
  const [selectedRow, setSelectedRow] = useState(null);

  const [openRejectDialog, setOpenRejectDialog] = useState(false);
  const [rejectReason, setRejectReason] = useState('');
  const userId = JSON.parse(localStorage.getItem('userId') || sessionStorage.getItem('userId'));
  const isManager = JSON.parse(localStorage.getItem('isManager') || sessionStorage.getItem('isManager'));
  const navigate = useNavigate();

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
    setPage(1); // Reset to first page on tab change
  };

  const handleSearch = (query) => {
    setSearchQuery(query);
    setPage(1); // Reset to first page on search
  };

  if (tabValue === 1) {
    columns.push({ field: 'creationDate', headerName: t('General.creationDate'), alignRight: isRtl && true });
    columns.push({ field: 'actionDate', headerName: t('General.publishDate'), alignRight: isRtl && true });
  }
  if (tabValue === 0) {
    if (isManager !== '1') {
      columns.push({ field: 'status', headerName: t('General.state'), alignRight: isRtl && true });
      columns.push({ field: 'actionDate', headerName: t('General.rejectedDate'), alignRight: isRtl && true });
      columns.push({ field: 'comment', headerName: t('General.RejectedReason'), alignRight: isRtl && true });
    } else {
      columns.push({ field: 'creationDate', headerName: t('General.creationDate'), alignRight: isRtl && true });
    }
  }

  useEffect(() => {
    if (tabValue === 0) {
      fetchUnderReviewingData(page - 1, rowsPerPage, searchQuery);
    } else {
      fetchPublishedData(page - 1, rowsPerPage, searchQuery);
    }
  }, [tabValue, searchQuery, page, rowsPerPage]);

  const fetchUnderReviewingData = async (page, size, search) => {
    setLoading(true);
    try {
      const response = await getFilterUnderReviewProducts(page, search, size);
      const data = response?.data?.content || [];
      const totalPages = response?.data?.totalPages || 0;
      const totalElements = response?.data?.totalElements || 0;
      const numberOfElements = response?.data?.numberOfElements || 0;

      const formattedData = data.map((item) => ({
        id: item.id,
        managerId: item.managerId,
        categoryId: item.categoryId,
        status: item.status,
        managerNameEn: item.managerNameEn,
        managerNameAr: item.managerNameAr,

        requesterNameEn: item.requesterNameEn,
        requesterNameAr: item.requesterNameAr,

        categoryNameEn: item.categoryNameEn,
        categoryNameAr: item.categoryNameAr,

        productType: item.category,

        parentNameEn: item?.parentNameEn || '',
        parentNameAr: item?.parentNameAr || '',

        actionDate: item?.actionDate !== null ? moment(item?.actionDate || '').format('DD/MM/YYYY') : '',
        creationDate: item?.creationDate !== null ? moment(item?.creationDate).format('DD/MM/YYYY') : '',
        comment: item.comment || '',
      }));

      setUnderReviewingRows(formattedData);
      setTotalPages(totalPages);
      setTotalElements(totalElements);
      setNumberOfElements(numberOfElements);
    } catch (error) {
      console.error('Failed to fetch data:', error);
      setUnderReviewingRows([]);
    } finally {
      setLoading(false);
    }
  };

  const fetchPublishedData = async (page, size, search) => {
    setLoading(true);
    try {
      const response = await getfilterPublishedProducts(page, search, size);
      const data = response.data.content || [];
      const totalPages = response?.data?.totalPages || 0;
      const totalElements = response?.data?.totalElements || 0;
      const numberOfElements = response?.data?.numberOfElements || 0;

      const formattedData = data.map((item) => ({
        id: item.id,
        managerNameEn: item.managerNameEn,
        managerNameAr: item.managerNameAr,

        requesterNameEn: item.requesterNameEn,
        requesterNameAr: item.requesterNameAr,

        categoryNameEn: item.categoryNameEn,
        categoryNameAr: item.categoryNameAr,

        categoryId: item.categoryId,
        productType: item.category,

        requestDate: item.requestDate,
        parentNameEn: item.parentNameEn || '',
        parentNameAr: item.parentNameAr || '',

        creationDate: moment(item?.creationDate || '').format('DD/MM/YYYY'),
        actionDate: moment(item?.actionDate || '').format('DD/MM/YYYY'),
      }));

      setPublishedRows(formattedData);
      setTotalPages(totalPages);
      setTotalElements(totalElements);
      setNumberOfElements(numberOfElements);
    } catch (error) {
      console.error('Failed to fetch data:', error);
      setPublishedRows([]);
    } finally {
      setLoading(false);
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleApprove = async (row) => {
    const requestData = {
      REQUEST_ID: row.id,
      COMMENT: 'APPROVED',
    };

    try {
      const response = await createApproveWFRequest(requestData);
      toast.success('Product approved successfully!');
      if (tabValue === 0) {
        fetchUnderReviewingData(page - 1, rowsPerPage, searchQuery);
      } else {
        fetchPublishedData(page - 1, rowsPerPage, searchQuery);
      }
    } catch (error) {
      toast.error('Failed to approve product');
    }
  };

  const handleOpenRejectDialog = (row) => {
    setSelectedRow(row);
    setOpenRejectDialog(true);
  };

  const handleCloseRejectDialog = () => {
    setOpenRejectDialog(false);
    setRejectReason('');
  };

  const handleReject = async () => {
    const requestData = {
      REQUEST_ID: selectedRow.id,
      COMMENT: rejectReason,
    };

    try {
      const response = await createRejectWFRequest(requestData);
      toast.success('Product rejected!');
      handleCloseRejectDialog();
      setSelectedRow(null);
      if (tabValue === 0) {
        fetchUnderReviewingData(page - 1, rowsPerPage, searchQuery);
      } else {
        fetchPublishedData(page - 1, rowsPerPage, searchQuery);
      }
    } catch (error) {
      toast.error('Failed to reject product');
      handleCloseRejectDialog();
    }
  };

  const handleViewDetails = (row) => {
    if (row.productType === 'UNIT') {
      navigate(`/dashboard/units/edit/${row.categoryId}`, {
        state: { from: 'review-products', requestId: row.id, userId, managerId: row.managerId, isManager },
      });
    }

    if (row.productType === 'LAND') {
      navigate(`/dashboard/land/edit/${row.categoryId}`, {
        state: { from: 'review-products', requestId: row.id, userId, managerId: row.managerId, isManager },
      });
    }
  };

  const getPaginationLabel = () => {
    const start = (page - 1) * rowsPerPage + 1;
    const end = start + numberOfElements - 1;
    return `Showing ${start} to ${end} of ${totalElements} entries`;
  };

  return (
    <>
      <Helmet>
        <title> {t('review_products.review_and_products')}</title>
      </Helmet>
      <Box sx={{ flexGrow: 1, p: 3, backgroundColor: 'white', borderRadius: '16px' }}>
        <Grid marginBottom={1} display="flex" alignItems={'center'} justifyContent="space-between" md={6} xs={12}>
          <Typography sx={{ fontSize: '24px', fontWeight: '700', lineHeight: '32px', color: '#111827' }}>
            {t('review_products.review_and_products')}
          </Typography>
        </Grid>
        <Grid mb={2} rowSpacing={2} container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }}>
          <Grid item xs={2} sm={4} md={4}>
            <SearchComponent onSearch={handleSearch} style={{ width: '100%' }} />
          </Grid>
        </Grid>

        <CustomTabs value={tabValue} onChange={handleTabChange} tabs={tabs} />
        {loading ? (
          <Stack spacing={1}>
            {[...Array(rowsPerPage)].map((_, index) => (
              <Skeleton key={index} height={40} />
            ))}
          </Stack>
        ) : (
          <>
            {tabValue === 0 ? (
              underReviewingRows.length > 0 ? (
                <CustomTable
                  userId={userId}
                  isManager={isManager}
                  rows={underReviewingRows}
                  columns={columns}
                  actions={reviewedTabactions}
                  tabValue={tabValue}
                  handleViewDetails={handleViewDetails}
                />
              ) : (
                <Typography style={{ marginTop: '6px' }} variant="h6" align="center">
                  No Data Found
                </Typography>
              )
            ) : publishedRows.length > 0 ? (
              <CustomTable columns={columns} rows={publishedRows} tabValue={tabValue} handleViewDetails={() => {}} />
            ) : (
              <Typography style={{ marginTop: '6px' }} variant="h6" align="center">
                No Data Found
              </Typography>
            )}

            {totalPages > 1 && (
              <div style={{ display: 'flex', justifyContent: 'space-between', padding: '10px', marginTop: '5rem' }}>
                <Pagination count={totalPages} page={page} onChange={handleChangePage} color="primary" />
                <div>{getPaginationLabel()}</div>
              </div>
            )}
          </>
        )}
        <ModalData
          open={openRejectDialog}
          onClose={handleCloseRejectDialog}
          onReject={handleReject}
          rejectReason={rejectReason}
          setRejectReason={setRejectReason}
        />
      </Box>
    </>
  );
};

export default ReviewApproveProducts;
