/* eslint-disable react/prop-types */
import React, { useState } from 'react';
// @mui
import { useNavigate } from 'react-router-dom';
import {
  Paper,
  Table,
  TableRow,
  TableBody,
  Button,
  TableCell,
  Typography,
  TableContainer,
  TablePagination,
  Pagination,
} from '@mui/material';
import { Box } from '@mui/system';
import { useTranslation } from 'react-i18next';
// icons
import { ReactComponent as EditIcon } from '../../../theme/images/editTableIcon.svg';
import { ReactComponent as ShowIcon } from '../../../theme/images/showTableIcon.svg';
import { ReactComponent as DeleteIcon } from '../../../theme/images/deleteTableIcon.svg';

// components
import Scrollbar from '../../../components/scrollbar';

// sections
import ClientReqHead from '../../../components/clientHeadTable/ClientHeadTable';

// ----------------------------------------------------------------------

// ----------------------------------------------------------------------

export default function ClientReqTable({
  requests,
  handleChangePage,
  page,
  onDelete,
  hasEditPermission,
  hasDeletePermission,
}) {
  const [rowsPerPage] = useState(1);
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const isRtl = i18n.language === 'ar';

  const TABLE_HEAD = [
    { id: 'nameEn', label: t('Building.buildingName'), alignRight: isRtl && true },
    { id: 'buildingCode', label: t('Building.buildingCode'), alignRight: isRtl && true },
    { id: 'unitCount', label: t('Building.unitCount'), alignRight: isRtl && true },
    { id: 'levelCount', label: t('Building.levelCount'), alignRight: isRtl && true },
    { id: 'advertiseId', label: t('ManageProject.advertiseId'), alignRight: isRtl && true },
    { id: 'action', label: t('General.Action'), alignRight: isRtl && true },
    { id: 'action', label: '', alignRight: isRtl && true },
    { id: '', label: '', alignRight: isRtl && true },
  ];

  return (
    <>
      <Scrollbar>
        <TableContainer component={Paper} sx={{ minWidth: 800 }}>
          <Table sx={{ direction: isRtl ? 'rtl' : 'ltr' }}>
            <ClientReqHead headLabel={TABLE_HEAD} />
            <TableBody>
              {requests?.content?.map((row) => {
                const { advertiseId, buildingCode, nameEn, nameAr, unitCount, id, levelCount } = row;

                return (
                  <TableRow hover key={id} tabIndex={-1}>
                    <TableCell sx={{ textAlign: isRtl ? 'right' : 'left' }} component="th" scope="row">
                      <Typography
                        sx={{ color: 'text.secondary', fontWeight: '400', fontSize: '12px', lineHeight: '20px' }}
                        variant="subtitle2"
                        noWrap
                      >
                        {' '}
                        {isRtl ? nameAr : nameEn}
                      </Typography>
                    </TableCell>

                    <TableCell sx={{ textAlign: isRtl ? 'right' : 'left' }} component="th" scope="row">
                      <Typography
                        sx={{ color: 'text.secondary', fontWeight: '400', fontSize: '12px', lineHeight: '20px' }}
                        variant="subtitle2"
                        noWrap
                      >
                        {' '}
                        {buildingCode || ''}
                      </Typography>
                    </TableCell>
                    <TableCell sx={{ textAlign: isRtl ? 'right' : 'left' }} component="th" scope="row">
                      <Typography
                        sx={{ color: 'text.secondary', fontWeight: '400', fontSize: '12px', lineHeight: '20px' }}
                        variant="subtitle2"
                        noWrap
                      >
                        {' '}
                        {unitCount || ''}
                      </Typography>
                    </TableCell>
                    <TableCell sx={{ textAlign: isRtl ? 'right' : 'left' }} component="th" scope="row">
                      <Typography
                        sx={{ color: 'text.secondary', fontWeight: '400', fontSize: '12px', lineHeight: '20px' }}
                        variant="subtitle2"
                        noWrap
                      >
                        {' '}
                        {levelCount || ''}
                      </Typography>
                    </TableCell>
                    <TableCell sx={{ textAlign: isRtl ? 'right' : 'left' }} component="th" scope="row">
                      <Typography
                        sx={{ color: 'text.secondary', fontWeight: '400', fontSize: '12px', lineHeight: '20px' }}
                        variant="subtitle2"
                        noWrap
                      >
                        {' '}
                        {advertiseId || ''}
                      </Typography>
                    </TableCell>

                    {hasEditPermission && (
                      <TableCell align="center">
                        <Box sx={{ display: 'flex', alignItems: 'center', gap: '2px' }}>
                          <Button
                            onClick={() =>
                              navigate(`/dashboard/building/view/${id}`, {
                                state: { editData: row },
                              })
                            }
                          >
                            <ShowIcon />
                          </Button>
                          <Button
                            onClick={() =>
                              navigate(id ? `/dashboard/building/edit/${id}` : '/dashboard/building/add/', {
                                state: { editData: row },
                              })
                            }
                          >
                            <EditIcon />
                          </Button>
                          {hasDeletePermission && (
                            <Button onClick={() => onDelete(id)}>
                              <DeleteIcon />
                            </Button>
                          )}
                        </Box>
                      </TableCell>
                    )}
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Scrollbar>
      {requests?.totalElements === 0 && (
        <Box textAlign="center">
          <h5>No data found</h5>
        </Box>
      )}
      {requests?.totalPages > 0 && (
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            padding: '10px',
            marginTop: '5rem',
          }}
        >
          <Pagination count={requests.totalPages} page={page + 1} onChange={handleChangePage} color="primary" />
          <Box sx={{ display: 'block' }}>
            {t('General.showing')} {page * rowsPerPage + 1} {t('General.of')}{' '}
            {Math.min((page + 1) * rowsPerPage, requests.totalElements)} {t('General.to')} {requests.totalElements}{' '}
            {t('General.entries')}
          </Box>
        </Box>
      )}
      {/* {requests?.totalPages > 0 && (
        <TablePagination
          rowsPerPageOptions={[8]}
          component="div"
          count={requests.totalPages}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
        />
      )} */}
    </>
  );
}
