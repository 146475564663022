import React, { createContext, useContext, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { addPlan, editPlan, getPlanDetails, deleteAttachments, deletePictures } from '../network/apis';
import LoadingOverlay from '../components/loading-overlay/LoadingOverlay';

const PlanFormContext = createContext();

export const usePlanFormContext = () => useContext(PlanFormContext);

export const PlanFromProvider = ({ children }) => {
  const { id } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const currentPath = location.pathname;
  const [detailsData, setDetails] = useState([]);

  // multi select states
  const [selectedFacilities, setSelectedFacilities] = useState(
    detailsData?.availableFacilities ? detailsData?.availableFacilities?.map((value) => value.id) : []
  );
  const [selectedServices, setSelectedServices] = useState(
    detailsData?.availableServices ? detailsData.availableServices?.map((value) => value.id) : []
  );
  const [selectedAfterSaleServices, setSelectedPostSaleServices] = useState(
    detailsData?.afterSaleServices ? detailsData.afterSaleServices?.map((value) => value.id) : []
  );

  const [currentTab, setCurrentTab] = useState('1');
  const [refetch, setRefetch] = useState(false);
  const [uploadedFilesUploaded, setUploadedFilesUploaded] = useState({
    planBanner: [],
    planPictures: [],
    planAttachments: [],
    virtualTour: [],
    primaryPhoto: [],
  });
  const [loading_submit, setSubmitLoading] = useState(true);
  const formDetails = useSelector((state) => state.plan.formData);

  const isEditMode = currentPath.includes('edit') && Object.keys(formDetails).length > 0;
  const isViewMode = currentPath.includes('view') && Object.keys(formDetails).length > 0;

  const fetchPlanDetails = async () => {
    try {
      const { data } = await getPlanDetails(id);
      setDetails(data.data);
      setSelectedFacilities(data.data?.availableFacilities?.map((value) => value.id));
      setSelectedServices(data.data?.availableServices?.map((value) => value.id));
      setSelectedPostSaleServices(data.data?.afterSaleServices?.map((value) => value.id));
    } catch (error) {
      console.error('Failed to fetch plan details:', error);
      toast.error('Failed to fetch plan details');
    } finally {
      setSubmitLoading(false);
    }
  };

  useEffect(() => {
    if (id) {
      setSubmitLoading(true);
      fetchPlanDetails();
    } else {
      setSubmitLoading(false);
    }
  }, [id, refetch]);
  const handleDelete = async (fileId, fieldName) => {
    let response;
    if (fieldName === 'attachment') {
      response = await deleteAttachments(fileId);
    } else {
      response = await deletePictures(fileId);
    }
    if (response?.status === '1') {
      toast.success('File deleted successfully!');
    } else {
      toast.error('Failed to delete the file!');
    }
  };

  // Add Item FunctionCopy code
  function addItem(fieldName, items, acceptMultiple = true) {
    // Initialize the field if it doesn't exist
    if (!uploadedFilesUploaded[fieldName]) {
      uploadedFilesUploaded[fieldName] = [];
    }

    if (acceptMultiple) {
      // If multiple items are allowed, add items if they don't exist
      items.forEach((item) => {
        if (!uploadedFilesUploaded[fieldName].includes(item)) {
          uploadedFilesUploaded[fieldName].push(item);
        }
      });
    } else {
      // If multiple items are not allowed, replace the existing item
      uploadedFilesUploaded[fieldName] = [items[0]];
    }

    return uploadedFilesUploaded;
  }

  // Delete Item Function
  function deleteItem(fieldName, item) {
    if (uploadedFilesUploaded[fieldName]) {
      setUploadedFilesUploaded((prevUploadedFilesUploaded) => {
        const newUploadedFilesUploaded = { ...prevUploadedFilesUploaded };
        newUploadedFilesUploaded[fieldName] = newUploadedFilesUploaded[fieldName].filter((i) => i?.id !== item);
        return newUploadedFilesUploaded;
      });
    }
    return uploadedFilesUploaded;
  }

  const handleChange = (newValue) => {
    if (isEditMode || isViewMode) setCurrentTab(newValue);
  };
  const handleNext = () => {
    setCurrentTab((prevValue) => {
      const nextValue = parseInt(prevValue, 10) + 1;
      return nextValue > 3 ? '1' : nextValue.toString();
    });
  };

  const handlePrev = () => {
    setCurrentTab((prevValue) => {
      const prevValueInt = parseInt(prevValue, 10) - 1;
      return prevValueInt < 1 ? '3' : prevValueInt.toString();
    });
  };
  const handleSubmit = async (event) => {
    setSubmitLoading(true);
    const mutatedPlan = {
      nameEn: formDetails?.nameEn,
      nameAr: formDetails?.nameAr,
      ownerLogo: [],
      owner: formDetails?.owner,
      regionId: formDetails?.regionId,
      cityId: formDetails?.cityId,
      licenceId: formDetails?.licenceId,
      landOffered: formDetails?.landOffered,
      facilities: formDetails?.facilities,
      services: formDetails?.services,
      afterSaleServicesIds: formDetails?.afterSaleServices,
      endDate: formDetails?.endDate,
      addressEn: formDetails?.addressEn,
      addressAr: formDetails?.addressAr,
      location: formDetails?.location,
      totalArea: formDetails?.totalArea,
      detailsEn: formDetails?.detailsEn,
      detailsAr: formDetails?.detailsAr,
      reservationStatus: formDetails?.reservationStatus,
      planStatus: formDetails?.planStatus,
      landType: formDetails?.landType,
      recentPlan: formDetails?.recentPlan,
      consultant: formDetails?.consultant,
      planDataStatus: formDetails?.planDataStatus,
      displayConfig: formDetails?.displayConfig,
      virtualLink: formDetails?.virtualLink ? formDetails?.virtualLink : 0,
      virtualType: formDetails?.virtualType ? 'IMAGE' : 'LINK',
      totalLands: formDetails?.totalLands,
      landmarks: formDetails?.nearestLandmarks?.map((landmark) => {
        return {
          ...landmark,
          distance: landmark.distance.toString(),
        };
      }),
      pictures: uploadedFilesUploaded?.planPictures?.map((item) => item.id),
      planBanner: uploadedFilesUploaded?.planBanner?.map((item) => item.id),
      primaryPhoto: uploadedFilesUploaded?.primaryPhoto?.map((item) => item.id),
      virtualTour: uploadedFilesUploaded?.virtualTour?.map((item) => item.id),
      attachments: uploadedFilesUploaded?.planAttachments?.map((item) => item.id),
      states: 'ACTIVE',
    };
    setSubmitLoading(true);

    if (isEditMode) {
      editPlan({ ...mutatedPlan, id })
        .then((response) => {
          setSubmitLoading(false);
          if (response.data.STATUS === 'SUCCESS') {
            toast.success('The Plan is successfully updated');
            navigate('/dashboard/plans');
          } else if (response.data.STATUS === 'FAILED') {
            toast.error(response.data.MESSAGE);
          }
        })
        .catch((error) => {
          setSubmitLoading(false);
          toast.error(error.response.data.message);
        });
    } else {
      addPlan(mutatedPlan)
        .then((response) => {
          setSubmitLoading(false);
          if (response.data.STATUS === 'SUCCESS') {
            toast.success('The Plan is successfully Created');
            navigate('/dashboard/plans');
          } else if (response.data.STATUS === 'FAILED') {
            toast.error(response.data.MESSAGE);
          }
        })
        .catch((error) => {
          setSubmitLoading(false);
          if (error?.response) toast.error(error?.response?.data?.message);
          else {
            toast.error('Please check your network');
          }
        });
    }
  };

  return (
    <PlanFormContext.Provider
      value={{
        currentTab,
        setCurrentTab,
        isViewMode,
        isEditMode,
        detailsData,
        setDetails,
        refetch,
        setRefetch,
        uploadedFilesUploaded,
        setUploadedFilesUploaded,
        addItem,
        deleteItem,
        handleChange,
        handleNext,
        handlePrev,
        handleSubmit,
        handleDelete,
        selectedFacilities,
        setSelectedFacilities,
        selectedServices,
        setSelectedServices,
        selectedAfterSaleServices,
        setSelectedPostSaleServices,
        setSubmitLoading,
      }}
    >
      {loading_submit && <LoadingOverlay />}
      {children}
    </PlanFormContext.Provider>
  );
};
