// src/hooks/useNavConfig.js
import { useTranslation } from 'react-i18next';

import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { buildNavConfig, initialNavConfig } from '../layouts/dashboard/nav/config';

export const useNavConfig = () => {
  const sideMenu = useSelector((state) => state.login.sideMenu) || [];
  const dashboardSideMenu = JSON.parse(
    localStorage?.getItem('dashboardSideMenu') || sessionStorage?.getItem('dashboardSideMenu')
  );

  const { t, i18n } = useTranslation();
  const isRtl = i18n.language === 'ar';

  const navConfig = useMemo(() => {
    return [...initialNavConfig, ...buildNavConfig(sideMenu, isRtl)];
  }, [dashboardSideMenu]);

  return navConfig;
};

export default useNavConfig;
