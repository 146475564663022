import React from 'react';
import { Dialog, DialogActions, DialogContent, DialogContentText, Button, TextField, Icon } from '@mui/material';
import { useTranslation } from 'react-i18next';

const RejectModal = ({ open, onClose, onReject, rejectReason, setRejectReason }) => {
  const { t } = useTranslation();
  return (
    <Dialog
      open={open}
      onClose={onClose}
      PaperProps={{
        style: { width: '400px' },
      }}
    >
      <DialogContent>
        <DialogContentText>{t('review_products.reject.title')}</DialogContentText>
        <TextField
          autoFocus
          margin="dense"
          label={t('review_products.reject.comment')}
          type="text"
          fullWidth
          multiline
          rows={4}
          maxRows={10}
          value={rejectReason}
          onChange={(e) => setRejectReason(e.target.value)}
          required
        />
      </DialogContent>
      <DialogActions style={{ marginBottom: '1rem' }}>
        <Button onClick={onClose} style={{ border: '1px solid #655e5e', color: '#655e5e' }}>
        {t('review_products.reject.cancel')}
        </Button>
        <Button
          variant="contained"
          onClick={onReject}
          style={{
            border: '1px solid',
            backgroundColor: rejectReason ? '#0CAF60' : 'gray',
            color: 'white',
            opacity: rejectReason ? 1 : 0.5, // Adjust opacity when disabled
            display: 'flex',
            alignItems: 'center',
          }}
          disabled={!rejectReason}
        >
          {t('review_products.reject.confirm')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default RejectModal;
