// src/components/StepOneForm.js
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { Formik, Field, FieldArray, useFormikContext } from 'formik';
import * as yup from 'yup';
import { omit } from 'lodash';

import {
  Autocomplete,
  Box,
  Button,
  CircularProgress,
  FormControlLabel,
  FormGroup,
  Grid,
  IconButton,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  TextField,
  Typography,
  Paper,
  OutlinedInput,
  SvgIcon,
} from '@mui/material';

import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import { AddCircleOutline, AttachFileOutlined, ImageOutlined, PictureAsPdf } from '@mui/icons-material';
import Switch from '@mui/material/Switch';

import Select from '@mui/material/Select';

import { updateFormData } from '../../../Store/features/Units/unitsSlice';

import { getListProjectSummaryDetails, getLookupsList, getRoomCategoryList } from '../../../network/apis';
import UploadFileOnChange from '../../../components/UploadFileOnChange.jsx/UploadFileOnChange';

import ClientHeadTable from '../../../components/clientHeadTable/ClientHeadTable';

import { ReactComponent as EditIcon } from '../../../theme/images/editTableIcon.svg';
import { ReactComponent as DeleteIcon } from '../../../theme/images/deleteTableIcon.svg';

import PreviewFile from '../../../components/previewFile/PreviewFile';

import DeleteFileModal from '../../../components/deleteFileModal/DeleteFileModal';

const CustomSwitch = ({ field, form, viewMode }) => {
  const { name, value } = field;
  const { setFieldValue } = form;

  const handleChange = (event) => {
    setFieldValue(name, event.target.checked ? 'ACTIVE' : 'IN_ACTIVE');
  };

  return (
    <FormControlLabel
      disabled={viewMode}
      control={<Switch checked={value === 'ACTIVE'} onChange={handleChange} />}
      label="Status"
    />
  );
};

const UnitDataFormTab = ({
  editData = {},
  isEditMode,
  handleNext,
  viewMode,
  handleDelete,
  loading_submit,
  addItem,
  editItem,
  deleteItem,
  refetch,
  setRefetch,
  uploadedFilesUploaded,
}) => {
  const navigate = useNavigate();
  const { i18n, t } = useTranslation();
  const focusOnSelect = useRef();
  const formikRef = useRef();
  const formData = useSelector((state) => state.unitform.formData);

  const isRtl = i18n.language === 'ar';

  const TABLE_HEAD = [
    { id: 'id', label: t('General.id'), alignRight: false },
    { id: 'room_type', label: t('Units.roomType'), alignRight: false },
    { id: 'room_count', label: t('Units.roomCount'), alignRight: false },
  ];
  if (!viewMode) {
    TABLE_HEAD.push({ id: 'action', label: t('General.Action'), alignRight: false });
  }

  const dispatch = useDispatch();
  const { id } = useParams();
  const projectId = editData?.project?.id
    ? editData?.project?.id
    : formData?.projectDetails && formData?.projectDetails?.id;

  const [deleteFileData, setDeleteFIleData] = useState({
    open: false,
    type: '',
    id: '',
    name: '',
  });
  const [changeVirtualTour, setChangeVirtualTour] = useState(editData?.virtualType !== 'LINK');
  const [loading, setLoading] = useState(false);
  const [projectNameOptions, setProjectNameOptions] = useState([]);

  const [buildingId, setBuildingId] = useState([]);
  const [selectedProject, setSelectedProject] = useState(null);

  const [roomTypeList, setRoomTypeList] = useState([]);
  const [roomTypeListdropdownOpened, setRoomTypeListDropdownOpened] = useState(false);

  const getProjectsListOptions = () => {
    setLoading(true);
    getListProjectSummaryDetails()
      .then((res) => {
        setProjectNameOptions(res.data);
        const foundProject = res.data.find((project) => project.id === projectId);

        setSelectedProject(foundProject);
        if (formikRef.current) {
          formikRef.current.setValues((prevValues) => ({
            ...prevValues,
            projectDetails: foundProject,
          }));
        }
        setLoading(false);
      })
      .catch(() => setLoading(false));
  };

  const getRoomList = () => {
    setLoading(true);
    getRoomCategoryList()
      .then((res) => {
        setRoomTypeList(res.data);

        setLoading(false);
      })
      .catch(() => setLoading(false));
  };
  useEffect(() => {
    getProjectsListOptions();
  }, []);

  useEffect(() => {
    if (isEditMode && editData) {
      dispatch(updateFormData(editData));
    }
  }, [isEditMode, editData, dispatch]);
  useEffect(() => {
    if (formData?.projectDetails) {
      setBuildingId(formData?.projectDetails?.buildingList || editData?.building);
    } else {
      setBuildingId(editData?.building);
    }
  }, [formData?.projectDetails, editData?.building]);

  useEffect(() => {
    // Check if formData and formData.projectDetails are defined

    if (formData?.projectDetails) {
      if (isEditMode && formData.projectDetails.building) {
        setBuildingId(formData.projectDetails.building);
      } else if (!isEditMode && formData.projectDetails.buildingList) {
        console.log(formData.projectDetails.buildingList);
        setBuildingId(formData.projectDetails.buildingList);
      }
    } else if (editData) {
      // If formData.projectDetails is not defined but editData is available, use editData.building directly
      setBuildingId(editData.building);
    }
  }, [formData?.projectDetails, editData, isEditMode]);

  useEffect(() => {
    if (projectId && projectNameOptions.length > 0) {
      const foundProject = projectNameOptions.find((project) => project.id === projectId);

      if (foundProject) {
        setSelectedProject(foundProject);
        if (formikRef.current) {
          formikRef.current.setValues((prevValues) => ({
            ...prevValues,
            projectDetails: foundProject,
          }));
        }
        dispatch(updateFormData({ projectDetails: foundProject }));
      }
    }
  }, [id, projectNameOptions, dispatch]);

  useEffect(() => {
    if (editData && editData.building) {
      setBuildingId([editData.building]);
    }
  }, [editData]);

  useEffect(() => {
    if (!roomTypeListdropdownOpened) {
      getRoomList();
      setRoomTypeListDropdownOpened(true);
    }
  }, [editData, roomTypeListdropdownOpened]);

  if (loading_submit && loading) {
    return <div>Loading...</div>;
  }
  const unitDataValidationSchema = yup.object().shape({
    nameEn: yup
      .string()
      .required(t('General.required_field'))
      .test('is-english', 'Please enter English text', (value) => {
        // Regular expression to match English characters
        const englishRegex = /^[A-Za-z0-9_-\s]*$/;
        return englishRegex.test(value);
      }),
    nameAr: yup
      .string()
      .required(t('General.required_field'))
      .test('is-arabic-or-number-or-dash', 'Please enter Arabic text\nيرجي ادخال نص عربي', (value) => {
        // Regular expression to match Arabic characters, Arabic-Indic digits, regular digits, spaces, and dashes
        const arabicRegex = /^[\u0600-\u06FF\u0660-\u0669\s0-9-]+$/;

        // Regular expression to match English characters
        const englishRegex = /[A-Za-z]/;

        // Check if the value contains only Arabic characters, digits, spaces, and dashes, and does not contain English characters
        return arabicRegex.test(value) && !englishRegex.test(value);
      }),

    // Other fields validation

    type: yup.string().required(t('General.required_field')),

    area: yup
      .string()
      .required('This field is required Please enter a number')
      .test('is-valid-number', 'Please enter a valid number', (value) => {
        const formattedValue = parseFloat(value?.replace(',', '.'));
        return !Number.isNaN(formattedValue) && formattedValue >= 1;
      }),
    levelNo: yup
      .number()
      .typeError(t('General.required_field'))
      .required(t('General.required_field'))
      .min(0, 'Must be more than 0')
      .positive('Must be a positive number')
      .integer(' Please enter valid data format'),

    // facade: yup.string().required(t('General.required_field')),
    // buildingId: yup.string().required('This field is required Please choose value'),
    // northernBoardEn: yup.string().required(t('General.required_field')),

    // northernBoardAr: yup.string().required(t('General.required_field')),

    // southernBoardEn: yup.string().required(t('General.required_field')),

    // southernBoardAr: yup.string().required(t('General.required_field')),

    // westernBoardEn: yup.string().required(t('General.required_field')),

    // westernBoardAr: yup.string().required(t('General.required_field')),

    // images: yup.string().required('Please Uplode Pic Or Video'),

    // virtualTours: yup.string().required('Please Uplode Pic Or Video'),

    // attachments: yup.string().required('Please Uplode Pic Or Video'),

    // primaryPhoto: yup.string().required('Please Uplode Pic Or Video'),

    // purpose: yup.string().required('This field is required Please choose value'),
  });
  const CustomArrowIcon = () => (
    <SvgIcon style={{ transform: 'rotate(-360deg)', color: '#637381' }}>
      <path d="M7 10l5 5 5-5z" />
    </SvgIcon>
  );
  console.log(editData);
  return (
    <Formik
      innerRef={formikRef}
      initialValues={
        isEditMode
          ? {
              ...editData,
              projectDetails: formData.project || editData?.project || selectedProject,
              roomDetails: formData.roomDetails || editData?.roomDetails,
              buildingId: editData?.building?.id || '',
            }
          : {
              ...formData,
              projectDetails: formData.projectDetails || '',
              states: 'ACTIVE',
            }
      }
      validationSchema={unitDataValidationSchema}
      onSubmit={(values, { setSubmitting }) => {
        dispatch(
          updateFormData({
            ...values,
            virtualType: changeVirtualTour,
            roomDetails: values.roomDetails.map((room) => ({
              id: room.id,
              count: parseInt(room.count, 10),
            })),
          })
        );
        dispatch(handleNext());

        setSubmitting(false);
      }}
    >
      {({ values, errors, touched, handleChange, handleBlur, handleSubmit, setFieldValue }) => {
        return (
          <form style={{ height: '100%' }} onSubmit={handleSubmit}>
            <Grid
              style={{ marginTop: '10px' }}
              rowSpacing={2}
              container
              spacing={{ xs: 2, md: 3 }}
              columns={{ xs: 4, sm: 8, md: 12 }}
            >
              <input type="hidden" name="rerender" value={values.id} />
              <Grid item xs={2} sm={4} md={6}>
                <Typography variant="body1" gutterBottom>
                  {t('Units.projectName')} <span style={{ color: 'red' }}>*</span>
                </Typography>

                <Autocomplete
                  disablePortal
                  id="combo-box-demo"
                  disabled={viewMode}
                  value={values.projectDetails}
                  onChange={(__, newValue) => {
                    if (!newValue) {
                      setFieldValue('projectDetails', null);
                      return;
                    }
                    const updatedValue = omit(newValue, ['nameAr', 'label', 'unitType']);
                    dispatch(
                      updateFormData({
                        projectDetails: {
                          ...updatedValue,
                          projectId: newValue.id || '',
                        },
                      })
                    );
                    setFieldValue('email', newValue.email);
                    setFieldValue('mobile', newValue.mobile);
                    setFieldValue('whatsappNumber', newValue.whatsappNumber);
                    setFieldValue('buildingId', '');
                    setFieldValue('projectDetails', newValue);
                  }}
                  options={
                    projectNameOptions?.map((option) => ({
                      ...option,
                      id: option.id || '',
                    })) || []
                  }
                  getOptionLabel={(option) => (isRtl ? option.nameAr : option?.nameEn || option?.nameEn)}
                  sx={{ width: '100%' }}
                  renderInput={(params) => <TextField {...params} />}
                />
              </Grid>
              <Grid item xs={12} sm={4} md={6}>
                <Typography variant="body1" gutterBottom>
                  {t('Units.selectBuilding')}
                </Typography>

                <Select
                  sx={{ width: '100%' }}
                  labelId="buildingId"
                  placeholder={t('Units.building')}
                  id="buildingId"
                  disabled={viewMode}
                  name="buildingId"
                  value={values.buildingId}
                  onChange={(event) => {
                    setFieldValue('buildingId', event.target.value);
                  }}
                  IconComponent={() => <CustomArrowIcon isRtl={isRtl} />}
                  input={<OutlinedInput label={t('Units.building')} />}
                >
                  {editData && (
                    <MenuItem key={buildingId?.id} value={buildingId?.id}>
                      {isRtl ? buildingId?.nameAr : buildingId?.nameEn}
                    </MenuItem>
                  )}
                  {buildingId && buildingId.length > 0 ? (
                    buildingId.map((building) => (
                      <MenuItem key={building.id} value={building.id}>
                        {isRtl ? building?.nameAr : building?.nameEn}
                      </MenuItem>
                    ))
                  ) : (
                    <MenuItem disabled>{t('Units.noBuildings')}</MenuItem>
                  )}
                </Select>
                {touched && errors && errors.buildingId && <p style={{ color: 'red' }}>{t('Units.requiredField')}</p>}
              </Grid>

              <Grid item xs={2} sm={4} md={6}>
                <Typography variant="body1" gutterBottom>
                  {t('Units.levelNo')}
                </Typography>
                <TextField
                  name="levelNo"
                  disabled={viewMode}
                  required
                  fullWidth
                  value={values.levelNo}
                  variant="outlined"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.levelNo && Boolean(errors.levelNo)}
                  helperText={touched.levelNo && errors.levelNo}
                />
              </Grid>
              <Grid item xs={2} sm={4} md={6}>
                <Typography variant="body1" gutterBottom>
                  {t('Units.unitNameEn')}
                </Typography>
                <TextField
                  name="nameEn"
                  required
                  fullWidth
                  disabled={viewMode}
                  value={values.nameEn}
                  variant="outlined"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.nameEn && Boolean(errors.nameEn)}
                  helperText={
                    touched.nameEn &&
                    errors.nameEn && (
                      <span dangerouslySetInnerHTML={{ __html: errors.nameEn.replace('\n', '<br />') }} />
                    )
                  }
                />
              </Grid>
              <Grid item xs={2} sm={4} md={6}>
                <Typography variant="body1" gutterBottom>
                  {t('Units.unitNameAr')}
                </Typography>
                <TextField
                  name="nameAr"
                  required
                  fullWidth
                  disabled={viewMode}
                  value={values.nameAr}
                  variant="outlined"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.nameAr && Boolean(errors.nameAr)}
                  helperText={
                    touched.nameAr &&
                    errors.nameAr && (
                      <span dangerouslySetInnerHTML={{ __html: errors.nameAr.replace('\n', '<br />') }} />
                    )
                  }
                />
              </Grid>
              <Grid item xs={2} sm={4} md={6}>
                <Stack>
                  <InputLabel id="type">{t('Units.unitType')}</InputLabel>
                  <Field
                    as={Select}
                    sx={{ mt: 1, direction: isRtl ? 'rtl' : 'ltr' }}
                    IconComponent={() => <CustomArrowIcon isRtl={isRtl} />}
                    labelId="type"
                    id="type"
                    disabled={viewMode}
                    value={values.type}
                    onChange={(event) => {
                      handleChange({
                        target: {
                          name: 'type',
                          value: event.target.value,
                        },
                      });
                    }}
                    error={touched.type && Boolean(errors.type)}
                    helperText={touched.type && errors.type}
                  >
                    <MenuItem sx={{ direction: isRtl ? 'rtl' : 'ltr' }} value="VILLA">
                      {t('Units.villa')}
                    </MenuItem>
                    <MenuItem sx={{ direction: isRtl ? 'rtl' : 'ltr' }} value="APPARTMENT">
                      {t('Units.apartment')}
                    </MenuItem>
                  </Field>
                </Stack>
              </Grid>
              <Grid item xs={2} sm={4} md={6}>
                <Typography variant="body1" gutterBottom>
                  {t('Units.totalArea')}
                </Typography>
                <TextField
                  name="area"
                  required
                  disabled={viewMode}
                  fullWidth
                  value={values.area}
                  variant="outlined"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.area && Boolean(errors.area)}
                  helperText={touched.area && errors.area}
                />
              </Grid>

              <Grid
                sx={{ marginLeft: '10px', marginTop: '3px', marginBottom: '3px' }}
                container
                direction="row"
                spacing={2}
              >
                <Grid item xs={2} sm={4} md={6}>
                  <Stack>
                    <InputLabel id="type">{t('Units.purpose')}</InputLabel>
                    <Field
                      as={Select}
                      sx={{ mt: 1, direction: isRtl ? 'rtl' : 'ltr' }}
                      IconComponent={() => <CustomArrowIcon isRtl={isRtl} />}
                      labelId="purpose"
                      disabled={viewMode}
                      id="purpose"
                      value={values.purpose}
                      onChange={(event) => {
                        handleChange({
                          target: {
                            name: 'purpose',
                            value: event.target.value,
                          },
                        });
                      }}
                      error={touched.purpose && Boolean(errors.purpose)}
                      helperText={touched.purpose && errors.purpose}
                    >
                      <MenuItem sx={{ direction: isRtl ? 'rtl' : 'ltr' }} value="RESIDENTIAL">
                        {t('Units.residential')}
                      </MenuItem>
                      <MenuItem sx={{ direction: isRtl ? 'rtl' : 'ltr' }} value="COMMERCIAL">
                        {t('Units.commercial')}
                      </MenuItem>
                    </Field>
                    {touched && errors && errors.purpose && <p style={{ color: 'red' }}>{t('Units.requiredField')}</p>}
                  </Stack>
                </Grid>
                <Grid item xs={2} sm={6} md={6}>
                  <Stack>
                    <InputLabel id="facade">{t('Units.facade')}</InputLabel>

                    <Select
                      sx={{ mt: 1, direction: isRtl ? 'rtl' : 'ltr' }}
                      IconComponent={() => <CustomArrowIcon isRtl={isRtl} />}
                      labelId="facade"
                      id="facade"
                      disabled={viewMode}
                      value={values.facade}
                      // onOpen={() => {
                      //   if (!facadedropdownOpened) {
                      //     getFacadeRequest();
                      //     setFacadeDropdownOpened(true);
                      //   }
                      // }}
                      onChange={(event) => {
                        handleChange({
                          target: {
                            name: 'facade',
                            value: event.target.value,
                          },
                        });
                      }}
                    >
                      <MenuItem sx={{ direction: isRtl ? 'rtl' : 'ltr' }} value="EASTERN">
                        {t('General.EASTERN')}
                      </MenuItem>
                      <MenuItem sx={{ direction: isRtl ? 'rtl' : 'ltr' }} value="NORTHERN">
                        {t('General.NORTHERN')}
                      </MenuItem>
                      <MenuItem sx={{ direction: isRtl ? 'rtl' : 'ltr' }} value="SOUTHERN">
                        {t('General.SOUTHERN')}
                      </MenuItem>
                      <MenuItem sx={{ direction: isRtl ? 'rtl' : 'ltr' }} value="WESTERN">
                        {t('General.WESTERN')}
                      </MenuItem>
                    </Select>
                    {touched && errors && errors.facade && <p style={{ color: 'red' }}>{t('Units.requiredField')}</p>}
                  </Stack>
                </Grid>
              </Grid>
              <Grid item xs={2} sm={4} md={6}>
                <Typography variant="body1" gutterBottom>
                  {t('Units.northernBorderEn')}
                </Typography>
                <TextField
                  name="northernBoardEn"
                  disabled={viewMode}
                  fullWidth
                  value={values.northernBoardEn}
                  variant="outlined"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.northernBoardEn && Boolean(errors.northernBoardEn)}
                  helperText={touched.northernBoardEn && errors.northernBoardEn}
                />
              </Grid>
              <Grid item xs={2} sm={4} md={6}>
                <Typography variant="body1" gutterBottom>
                  {t('Units.northernBorderAr')}
                </Typography>
                <TextField
                  name="northernBoardAr"
                  fullWidth
                  disabled={viewMode}
                  value={values.northernBoardAr}
                  variant="outlined"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.northernBoardAr && Boolean(errors.northernBoardAr)}
                  helperText={touched.northernBoardAr && errors.northernBoardAr}
                />
              </Grid>

              <Grid item xs={2} sm={4} md={6}>
                <Typography variant="body1" gutterBottom>
                  {t('Units.southernBorderEn')}
                </Typography>
                <TextField
                  name="southernBoardEn"
                  disabled={viewMode}
                  fullWidth
                  value={values.southernBoardEn}
                  variant="outlined"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.southernBoardEn && Boolean(errors.southernBoardEn)}
                  helperText={touched.southernBoardEn && errors.southernBoardEn}
                />
              </Grid>
              <Grid item xs={2} sm={4} md={6}>
                <Typography variant="body1" gutterBottom>
                  {t('Units.southernBorderAr')}
                </Typography>
                <TextField
                  name="southernBoardAr"
                  disabled={viewMode}
                  fullWidth
                  value={values.southernBoardAr}
                  variant="outlined"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.southernBoardAr && Boolean(errors.southernBoardAr)}
                  helperText={touched.southernBoardAr && errors.southernBoardAr}
                />
              </Grid>

              <Grid item xs={2} sm={4} md={6}>
                <Typography variant="body1" gutterBottom>
                  {t('Units.westernBorderEn')}
                </Typography>
                <TextField
                  name="westernBoardEn"
                  disabled={viewMode}
                  fullWidth
                  value={values.westernBoardEn}
                  variant="outlined"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.westernBoardEn && Boolean(errors.westernBoardEn)}
                  helperText={touched.westernBoardEn && errors.westernBoardEn}
                />
              </Grid>
              <Grid item xs={2} sm={4} md={6}>
                <Typography variant="body1" gutterBottom>
                  {t('Units.westernBorderAr')}
                </Typography>
                <TextField
                  name="westernBoardAr"
                  disabled={viewMode}
                  fullWidth
                  value={values.westernBoardAr}
                  variant="outlined"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.westernBoardAr && Boolean(errors.westernBoardAr)}
                  helperText={touched.westernBoardAr && errors.westernBoardAr}
                />
              </Grid>

              <Grid item xs={2} sm={4} md={6}>
                <Typography variant="body1" gutterBottom>
                  {t('Units.easternBorderEn')}
                </Typography>
                <TextField
                  name="easternBoardEn"
                  fullWidth
                  disabled={viewMode}
                  value={values.easternBoardEn}
                  variant="outlined"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.easternBoardEn && Boolean(errors.easternBoardEn)}
                  helperText={touched.easternBoardEn && errors.easternBoardEn}
                />
              </Grid>
              <Grid item xs={2} sm={4} md={6}>
                <Typography variant="body1" gutterBottom>
                  {t('Units.easternBorderAr')}
                </Typography>
                <TextField
                  name="easternBoardAr"
                  disabled={viewMode}
                  fullWidth
                  value={values.easternBoardAr}
                  variant="outlined"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.easternBoardAr && Boolean(errors.easternBoardAr)}
                  helperText={touched.easternBoardAr && errors.easternBoardAr}
                />
              </Grid>
              <Grid item xs={2} sm={4} md={6}>
                <Stack>
                  <InputLabel id="pieceStatus">{t('Units.unitStatus')}</InputLabel>
                  <Field
                    as={Select}
                    sx={{ mt: 1, direction: isRtl ? 'rtl' : 'ltr' }}
                    IconComponent={() => <CustomArrowIcon isRtl={isRtl} />}
                    disabled={viewMode}
                    labelId="pieceStatus"
                    id="pieceStatus"
                    value={values.pieceStatus}
                    onChange={(event) => {
                      handleChange({
                        target: {
                          name: 'pieceStatus',
                          value: event.target.value,
                        },
                      });
                      dispatch(updateFormData({ pieceStatus: event.target.value }));
                    }}
                    error={touched.pieceStatus && Boolean(errors.pieceStatus)}
                    helperText={touched.pieceStatus && errors.pieceStatus}
                  >
                    <MenuItem sx={{ direction: isRtl ? 'rtl' : 'ltr' }} disabled={viewMode} value="TRUE">
                      {t('General.Active')}
                    </MenuItem>
                    <MenuItem sx={{ direction: isRtl ? 'rtl' : 'ltr' }} disabled={viewMode} value="FALSE">
                      {t('General.InActive')}
                    </MenuItem>
                  </Field>
                </Stack>
              </Grid>
              <Grid item xs={2} sm={4} md={6}>
                <Stack>
                  <InputLabel id="reservationStatus">{t('General.reservationStatus')}</InputLabel>
                  <Field
                    as={Select}
                    sx={{ mt: 1, direction: isRtl ? 'rtl' : 'ltr' }}
                    IconComponent={() => <CustomArrowIcon isRtl={isRtl} />}
                    disabled={viewMode}
                    labelId="reservationStatus"
                    id="reservationStatus"
                    value={values.reservationStatus}
                    onChange={(event) => {
                      handleChange({
                        target: {
                          name: 'reservationStatus',
                          value: event.target.value,
                        },
                      });
                      dispatch(
                        updateFormData({
                          email: values.email,
                          whatsappNumber: values.whatsappNumber,
                          mobile: values.mobile,
                          blockNumber: values.blockNumber,
                          website: values.website,
                          pieceStatus: values.pieceStatus,
                          pieceDataStatus: values.pieceDataStatus,

                          reservationStatus: event.target.value,
                        })
                      );
                    }}
                    error={touched.reservationStatus && Boolean(errors.reservationStatus)}
                    helperText={touched.reservationStatus && errors.reservationStatus}
                  >
                    <MenuItem sx={{ direction: isRtl ? 'rtl' : 'ltr' }} value="FREE">
                      {t('Units.free')}
                    </MenuItem>
                    <MenuItem sx={{ direction: isRtl ? 'rtl' : 'ltr' }} value="RESERVED">
                      {t('Units.reserved')}
                    </MenuItem>
                  </Field>
                </Stack>
              </Grid>
              <Grid item xs={2} sm={4} md={6}>
                <Stack>
                  <InputLabel id="pieceDataStatus">{t('Units.unitDataStatus')}</InputLabel>
                  <Field
                    disabled={viewMode}
                    as={Select}
                    sx={{ mt: 1, direction: isRtl ? 'rtl' : 'ltr' }}
                    IconComponent={() => <CustomArrowIcon isRtl={isRtl} />}
                    labelId="pieceDataStatus"
                    id="pieceDataStatus"
                    value={values.pieceDataStatus}
                    onChange={(event) => {
                      setFieldValue('pieceDataStatus', event.target.value);
                      dispatch(updateFormData({ pieceDataStatus: event.target.value }));
                    }}
                    error={touched.pieceDataStatus && Boolean(errors.pieceDataStatus)}
                    helperText={touched.pieceDataStatus && errors.pieceDataStatus}
                  >
                    <MenuItem value="INCOMPLETE">{t('Units.incomplete')}</MenuItem>
                    <MenuItem value="COMPLETE">{t('Units.complete')}</MenuItem>
                  </Field>
                </Stack>
              </Grid>
              <Stack
                sx={{ width: '100%', margin: '1.5rem' }}
                direction="row"
                justifyContent="start"
                alignItems="start"
                spacing={1}
              >
                <FieldArray name="roomDetails">
                  {({ push, remove }) => (
                    <div
                      style={{
                        width: '100%',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                      }}
                    >
                      {!viewMode && (
                        <Grid
                          container
                          sx={{ width: '100%', display: 'flex', alignItems: 'center', marginBottom: '1rem' }}
                          columns={{ xs: 12, sm: 12, md: 12 }}
                          spacing={1}
                        >
                          <Grid item xs={2} sm={6} md={6}>
                            <Stack>
                              <InputLabel id="roomType">
                                {t('Units.roomType')} <span style={{ color: 'red' }}>*</span>
                              </InputLabel>
                              <Select
                                disabled={viewMode}
                                sx={{ direction: isRtl ? 'rtl' : 'ltr' }}
                                IconComponent={() => <CustomArrowIcon isRtl={isRtl} />}
                                labelId="roomType"
                                ref={focusOnSelect}
                                id="roomType"
                                name="roomType"
                                onOpen={() => {
                                  if (!roomTypeListdropdownOpened) {
                                    getRoomList();
                                    setRoomTypeListDropdownOpened(true);
                                  }
                                }}
                                value={values.roomType}
                                onChange={(event) => setFieldValue('roomType', event.target.value)}
                              >
                                {loading ? (
                                  <MenuItem>
                                    <CircularProgress size={24} />
                                  </MenuItem>
                                ) : roomTypeList.length === 0 ? (
                                  <MenuItem disabled>{t('Units.noOptions')}</MenuItem>
                                ) : (
                                  roomTypeList.map((option) => (
                                    <MenuItem
                                      sx={{ direction: isRtl ? 'rtl' : 'ltr' }}
                                      key={option.id}
                                      value={option.id}
                                    >
                                      {isRtl ? option.nameAr : option.nameEn}
                                    </MenuItem>
                                  ))
                                )}
                              </Select>
                            </Stack>
                          </Grid>
                          <Grid item xs={2} sm={4} md={5}>
                            <Stack>
                              <InputLabel id="roomCount">
                                {t('Units.roomCount')} <span style={{ color: 'red' }}>*</span>
                              </InputLabel>
                              <TextField
                                id="roomCount"
                                name="roomCount"
                                value={values.roomCount}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                disabled={viewMode}
                                fullWidth
                                variant="outlined"
                              />
                            </Stack>
                          </Grid>
                          <Grid item xs={2} sm={2} md={1} alignItems={'center'}>
                            {' '}
                            <IconButton
                              disabled={viewMode}
                              onClick={() => {
                                if (values.roomType && values.roomCount) {
                                  push({
                                    id: values.roomType,
                                    count: values.roomCount,
                                  });
                                  setFieldValue('roomType', '');
                                  setFieldValue('roomCount', '');
                                }
                              }}
                              sx={{
                                marginTop: '15px !important',
                                width: '40px',
                                height: '40px',
                                borderRadius: '50%',
                                backgroundColor: '#000',
                                color: 'white',
                                '&:hover': {
                                  backgroundColor: '#303f9f',
                                },
                              }}
                            >
                              <AddCircleOutline />
                            </IconButton>
                          </Grid>
                        </Grid>
                      )}

                      <div
                        style={{
                          width: '100%',
                          maxWidth: '100%',
                          marginRight: 'auto',
                          marginLeft: 'auto',
                          maxHeight: '300px ',
                          overflowY: 'auto',
                        }}
                      >
                        <TableContainer component={Paper} sx={{ minWidth: 800 }}>
                          <Table>
                            <ClientHeadTable headLabel={TABLE_HEAD} />
                            <TableBody>
                              {values.roomDetails?.map((row, index) => (
                                <TableRow key={index} tabIndex={-1}>
                                  <TableCell component="th" scope="row">
                                    <Typography variant="subtitle2" noWrap>
                                      {index + 1}
                                    </Typography>
                                  </TableCell>
                                  <TableCell component="th" scope="row">
                                    <Typography variant="subtitle2" noWrap>
                                      {isRtl
                                        ? roomTypeList.find((item) => item.id === row?.id)?.nameAr
                                        : roomTypeList.find((item) => item.id === row?.id)?.nameEn}
                                    </Typography>
                                  </TableCell>
                                  <TableCell component="th" scope="row">
                                    <Typography variant="subtitle2" noWrap>
                                      {row?.count}
                                    </Typography>
                                  </TableCell>
                                  {!viewMode && (
                                    <TableCell component="th" align="left" scope="row">
                                      <Typography variant="subtitle2" noWrap>
                                        <Button sx={{ color: '#27A376' }}>
                                          <EditIcon
                                            onClick={() => {
                                              remove(index);
                                              const roomDetailToEdit = values.roomDetails[index];
                                              setFieldValue('roomType', roomDetailToEdit.id);
                                              setFieldValue('roomCount', roomDetailToEdit.count);
                                              if (focusOnSelect.current) {
                                                focusOnSelect.current.scrollIntoView({
                                                  behavior: 'smooth',
                                                  block: 'center',
                                                });
                                              }
                                            }}
                                          />
                                        </Button>
                                        <Button sx={{ color: '#27A376' }}>
                                          <DeleteIcon onClick={() => remove(index)} />
                                        </Button>
                                      </Typography>
                                    </TableCell>
                                  )}
                                </TableRow>
                              ))}
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </div>
                    </div>
                  )}
                </FieldArray>
              </Stack>

              <Grid item xs={2} sm={4} md={6}>
                <Typography variant="body1" gutterBottom>
                  {t('Units.whatsappNumber')}
                </Typography>
                <TextField
                  name="whatsappNumber"
                  disabled={viewMode}
                  required
                  fullWidth
                  value={values.whatsappNumber}
                  variant="outlined"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.whatsappNumber && Boolean(errors.whatsappNumber)}
                  helperText={touched.whatsappNumber && errors.whatsappNumber}
                />
              </Grid>
              <Grid item xs={2} sm={4} md={6}>
                <Typography variant="body1" gutterBottom>
                  {t('Units.email')}
                </Typography>
                <TextField
                  name="email"
                  required
                  fullWidth
                  disabled={viewMode}
                  value={values.email}
                  variant="outlined"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.email && Boolean(errors.email)}
                  helperText={touched.email && errors.email}
                />
              </Grid>
              <Grid item xs={2} sm={4} md={6}>
                <Typography variant="body1" gutterBottom>
                  {t('Units.mobileNumber')}
                </Typography>
                <TextField
                  name="mobile"
                  required
                  fullWidth
                  disabled={viewMode}
                  value={values.mobile}
                  variant="outlined"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.mobile && Boolean(errors.mobile)}
                  helperText={touched.mobile && errors.mobile}
                />
              </Grid>
              <Grid item xs={2} sm={4} md={6}>
                <Typography variant="body1" gutterBottom>
                  {t('Units.blockNumber')}
                </Typography>
                <TextField
                  name="blockNumber"
                  disabled={viewMode}
                  required
                  fullWidth
                  value={values.blockNumber}
                  variant="outlined"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.blockNumber && Boolean(errors.blockNumber)}
                  helperText={touched.blockNumber && errors.blockNumber}
                />
              </Grid>

              <Grid item xs={2} sm={4} md={6}>
                <Typography variant="body1" gutterBottom>
                  {t('Units.supportingDocs')}
                </Typography>
                <UploadFileOnChange
                  viewMode={viewMode}
                  uploadedFilesUploaded={uploadedFilesUploaded}
                  multiple
                  type="ATTACHMENT"
                  category="UNIT"
                  label={t('Units.supportingDocs')}
                  isViewMode={viewMode}
                  fieldName="attachments"
                  maxSize={5000000} // 5MB
                  acceptedTypes="application/pdf, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                  addItem={addItem}
                  editItem={editItem}
                  deleteItem={deleteItem}
                />

                <Box>
                  {editData?.attachments &&
                    editData?.attachments?.map((file, index) => (
                      <PreviewFile
                        key={index}
                        ViewMode={viewMode}
                        file={file}
                        onDelete={() =>
                          setDeleteFIleData({
                            open: true,
                            id: file.id,
                            name: file.name,
                            type: 'attachment',
                          })
                        }
                      />
                    ))}
                </Box>
              </Grid>

              <Grid item xs={2} sm={4} md={12}>
                <Typography variant="body1" gutterBottom>
                  {t('Units.unitImages')}
                </Typography>
                <UploadFileOnChange
                  viewMode={viewMode}
                  uploadedFilesUploaded={uploadedFilesUploaded}
                  multiple
                  type="PICTURE"
                  category="UNIT"
                  label={t('Units.unitImages')}
                  acceptedTypes="image/*"
                  isViewMode={viewMode}
                  fieldName="unitImages"
                  maxSize={5000000} // 5MB
                  addItem={addItem}
                  editItem={editItem}
                  deleteItem={deleteItem}
                />
                <Box>
                  {editData?.pictures &&
                    editData?.pictures?.map((file, index) => (
                      <PreviewFile
                        key={index}
                        ViewMode={viewMode}
                        file={file}
                        onDelete={() =>
                          setDeleteFIleData({
                            open: true,
                            id: file.id,
                            name: file.name,
                            type: 'pictures',
                          })
                        }
                      />
                    ))}
                </Box>
              </Grid>

              <Grid item xs={2} sm={4} md={12}>
                <Typography variant="body1" gutterBottom>
                  {t('Units.unitSketch')}
                </Typography>
                <UploadFileOnChange
                  uploadedFilesUploaded={uploadedFilesUploaded}
                  viewMode={viewMode}
                  multiple
                  type="SKETCH"
                  category="UNIT"
                  label={t('Units.unitSketch')}
                  isViewMode={viewMode}
                  fieldName="unitSketch"
                  maxSize={5000000} // 5MB
                  acceptedTypes="application/pdf"
                  addItem={addItem}
                  editItem={editItem}
                  deleteItem={deleteItem}
                />
                <Box>
                  {editData?.unitSketch &&
                    editData?.unitSketch?.map((file, index) => (
                      <PreviewFile
                        key={index}
                        ViewMode={viewMode}
                        file={file}
                        onDelete={() =>
                          setDeleteFIleData({
                            open: true,
                            id: file.id,
                            name: file.name,
                            type: 'sketch',
                          })
                        }
                      />
                    ))}
                </Box>
              </Grid>

              <Grid item xs={2} sm={4} md={12}>
                <Stack direction={'row'} alignItems={'center'}>
                  <Switch
                    checked={changeVirtualTour}
                    name="virtualType"
                    onChange={() => {
                      handleChange('virtualType');
                      setChangeVirtualTour(!changeVirtualTour);
                    }}
                  />
                  <Typography variant="body1">
                    {changeVirtualTour ? t('Units.virtualTour') : t('General.virtualTourURL')}
                  </Typography>
                </Stack>
                {changeVirtualTour ? (
                  <>
                    <UploadFileOnChange
                      viewMode={viewMode}
                      uploadedFilesUploaded={uploadedFilesUploaded}
                      multiple
                      type="VIRTUAL_TOUR"
                      category="UNIT"
                      label={t('Units.virtualTour')}
                      isViewMode={viewMode}
                      fieldName="virtualTour"
                      maxSize={5000000} // 5MB
                      acceptedTypes="image/*,video/*"
                      addItem={addItem}
                      editItem={editItem}
                      deleteItem={deleteItem}
                    />
                    {editData?.virtualTour && uploadedFilesUploaded?.virtualTour?.length === 0 && (
                      <PreviewFile
                        file={{
                          path: editData?.virtualTour,
                          displayName: editData?.virtualTour,
                          id: uploadedFilesUploaded?.virtualTour?.length,
                        }}
                      />
                    )}
                  </>
                ) : (
                  <TextField
                    sx={{ marginBottom: 2 }}
                    name="virtualLink"
                    placeholder={t('General.virtualTourURL')}
                    fullWidth
                    value={values.virtualLink}
                    onChange={handleChange}
                    variant="outlined"
                  />
                )}
                <Typography variant="body1" gutterBottom>
                  {t('General.primaryPhoto')}
                </Typography>

                <UploadFileOnChange
                  setFieldValue={setFieldValue}
                  uploadedFilesUploaded={uploadedFilesUploaded}
                  viewMode={viewMode}
                  type="PHOTO"
                  category="UNIT"
                  multiple={false}
                  label={t('General.primaryPhoto')}
                  isViewMode={viewMode}
                  fieldName="primaryPhoto"
                  maxSize={5000000} // 5MB
                  acceptedTypes="image/*"
                  addItem={addItem}
                  editItem={editItem}
                  deleteItem={deleteItem}
                />
                <Box>
                  {editData?.primaryPhoto && uploadedFilesUploaded?.primaryPhoto?.length === 0 && (
                    <PreviewFile
                      file={{
                        path: editData.primaryPhoto,
                        displayName: editData.project.primaryPhotoName,
                        id: uploadedFilesUploaded?.primaryPhoto?.length,
                      }}
                    />
                  )}
                </Box>
              </Grid>
              <Grid style={{ marginTop: '5px' }} item xs={2} sm={12} md={12}>
                <FormGroup>
                  <Field viewMode={viewMode} name="states" component={CustomSwitch} />
                </FormGroup>
              </Grid>
            </Grid>

            <Box width={'100%'} display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
              <Button
                sx={{ mt: 4, border: '1px solid #111827' }}
                size="large"
                onClick={() => {
                  navigate(-1);
                }}
              >
                {t('General.cancel')}
              </Button>
              <Button sx={{ mt: 4, border: '1px solid #111827' }} size="large" type="submit" variant="contained">
                {t('General.next')}
              </Button>
            </Box>
            <input type="hidden" name="updateUi" onChange={() => setFieldValue('updateUi', true)} />
            <DeleteFileModal
              open={deleteFileData.open}
              setOpen={deleteFileData}
              handelClose={() => setDeleteFIleData({ ...deleteFileData, open: false })}
              onDelete={() => {
                handleDelete(deleteFileData?.id, deleteFileData?.type, deleteFileData?.name);
                setDeleteFIleData({ ...deleteFileData, open: false });
                setRefetch(!refetch);
              }}
            />
          </form>
        );
      }}
    </Formik>
  );
};

export default UnitDataFormTab;
