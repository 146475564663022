import React, { useState } from 'react';
import imageCompression from 'browser-image-compression';
import { uploadFile } from '../network/apis';

const UploadFile = () => {
  const [selectedFile, setSelectedFile] = useState(null);
  const [compressedFile, setCompressedFile] = useState(null);
  const [previewURL, setPreviewURL] = useState('');
  const [status, setStatus] = useState('');

  const handleFileChange = async (event) => {
    const file = event.target.files[0];
    if (!file) return;

    setSelectedFile(file);

    const originalSizeMB = file.size / 1024 / 1024;
    // Define the maximum size in MB after compression
    const maxSize = originalSizeMB * 0.9; // Example: reduce to 90% of original if less than 1MB

    // Compress the image
    const options = {
      maxSizeMB: maxSize,
      maxWidthOrHeight: 800,
      useWebWorker: true,
      initialQuality: 0.9,
    };

    try {
      const compressedFile = await imageCompression(file, options);
      setCompressedFile(compressedFile);
      setPreviewURL(URL.createObjectURL(compressedFile));
    } catch (error) {
      console.error('Error during compression:', error);
    }
  };

  const upload = async () => {

    if (!compressedFile) return;

    const unpdatefile = new File([compressedFile], selectedFile.name, {
      type: selectedFile.type,
      lastModified: Date.now(),
    });

    const formData = new FormData();
    formData.append('file', unpdatefile);

    try {

      const response = await uploadFile(formData);
      setStatus('Upload successful');
    } catch (error) {
      console.error('Upload failed:', error);
      setStatus('Upload failed');
    }
  };

  return (
    <div>
      <input type="file" accept="image/*" onChange={handleFileChange} />
      {previewURL && <img src={previewURL} alt="Preview" style={{ width: '300px', height: 'auto' }} />}
      <button onClick={upload}>Upload Image</button>
      <p>{status}</p>
    </div>
  );
};

export default UploadFile;
