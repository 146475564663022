import * as React from 'react';
import PropTypes from 'prop-types';
import { styled, css } from '@mui/system';
import { Modal as BaseModal } from '@mui/base/Modal';
import Fade from '@mui/material/Fade';
import TextField from '@mui/material/TextField';
import { Grid, Typography, Button } from '@mui/material';
import SaveIcon from '@mui/icons-material/Save';

export default function FormModal({ open, handleClose, values, setValues, saveData }) {
  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        slots={{ backdrop: StyledBackdrop }}
      >
        <Fade in={open}>
          <ModalContent sx={style}>
            <Grid rowSpacing={2} container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }}>
              <Grid item xs={2} sm={4} md={6}>
                <Typography variant="body1" gutterBottom>
                  Title (EN)
                </Typography>
                <TextField
                  sx={{
                    width: '100%',
                  }}
                  onChange={(e) => setValues({ ...values, [e.target.name]: e.target.value })}
                  required
                  name="titleEn"
                  id="outlined-required"
                  label="Required"
                  value={values.titleEn}
                />
              </Grid>
              <Grid item xs={2} sm={4} md={6}>
                <Typography variant="body1" gutterBottom>
                  Title (AR)
                </Typography>
                <TextField
                  sx={{
                    width: '100%',
                  }}
                  onChange={(e) => setValues({ ...values, [e.target.name]: e.target.value })}
                  name="titleAr"
                  required
                  id="outlined-required"
                  label="Required"
                  value={values.titleAr}
                />
              </Grid>
              <Grid item xs={2} sm={4} md={6}>
                <Typography variant="body1" gutterBottom>
                  Body (EN)
                </Typography>
                <TextField
                  sx={{
                    width: '100%',
                  }}
                  onChange={(e) => setValues({ ...values, [e.target.name]: e.target.value })}
                  name="bodyEn"
                  required
                  label="Required"
                  rows={4}
                  multiline
                  value={values.bodyEn}
                />
              </Grid>

              <Grid item xs={2} sm={4} md={6}>
                <Typography variant="body1" gutterBottom>
                  Body (AR)
                </Typography>
                <TextField
                  sx={{
                    width: '100%',
                  }}
                  onChange={(e) => setValues({ ...values, [e.target.name]: e.target.value })}
                  required
                  label="Required"
                  name="bodyAr"
                  rows={4}
                  multiline
                  value={values.bodyAr}
                />
              </Grid>

              <Grid item xs={2} sm={4} md={6}>
                <Typography variant="body1" gutterBottom>
                  Sequence
                </Typography>
                <TextField
                  sx={{
                    width: '100%',
                  }}
                  onChange={(e) => setValues({ ...values, [e.target.name]: e.target.value })}
                  required
                  label="Required"
                  name="sequence"
                  value={values.sequence}
                />
              </Grid>
            </Grid>

            <Grid mt={2} rowSpacing={2} justifyContent={'end'} container spacing={{ xs: 2, md: 3 }}>
              <Button variant="contained" onClick={saveData} endIcon={<SaveIcon />}>
                Save
              </Button>
            </Grid>
          </ModalContent>
        </Fade>
      </Modal>
    </div>
  );
}

const Backdrop = React.forwardRef((props, ref) => {
  const { open, ...other } = props;
  return (
    <Fade in={open}>
      <div ref={ref} {...other} />
    </Fade>
  );
});

Backdrop.propTypes = {
  open: PropTypes.bool,
};

const blue = {
  200: '#99CCFF',
  300: '#66B2FF',
  400: '#3399FF',
  500: '#007FFF',
  600: '#0072E5',
  700: '#0066CC',
};

const grey = {
  50: '#F3F6F9',
  100: '#E5EAF2',
  200: '#DAE2ED',
  300: '#C7D0DD',
  400: '#B0B8C4',
  500: '#9DA8B7',
  600: '#6B7A90',
  700: '#434D5B',
  800: '#303740',
  900: '#1C2025',
};

const Modal = styled(BaseModal)`
  position: fixed;
  z-index: 1300;
  inset: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const StyledBackdrop = styled(Backdrop)`
  z-index: -1;
  position: fixed;
  inset: 0;
  background-color: rgb(0 0 0 / 0.5);
  -webkit-tap-highlight-color: transparent;
`;

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 600,
};

const ModalContent = styled('div')(
  ({ theme }) => css`
    font-family: 'IBM Plex Sans', sans-serif;
    font-weight: 500;
    text-align: start;
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 8px;
    overflow: hidden;
    background-color: ${theme.palette.mode === 'dark' ? grey[900] : '#fff'};
    border-radius: 8px;
    border: 1px solid ${theme.palette.mode === 'dark' ? grey[700] : grey[200]};
    box-shadow: 0 4px 12px ${theme.palette.mode === 'dark' ? 'rgb(0 0 0 / 0.5)' : 'rgb(0 0 0 / 0.2)'};
    padding: 24px;
    color: ${theme.palette.mode === 'dark' ? grey[50] : grey[900]};

    & .modal-title {
      margin: 0;
      line-height: 1.5rem;
      margin-bottom: 8px;
    }

    & .modal-description {
      margin: 0;
      line-height: 1.5rem;
      font-weight: 400;
      color: ${theme.palette.mode === 'dark' ? grey[400] : grey[800]};
      margin-bottom: 4px;
    }
  `
);

