import { Helmet } from 'react-helmet-async';
import { useEffect, useState } from 'react';
// @mui
import { Card, Stack, Container, Typography } from '@mui/material';
import { useParams } from 'react-router-dom';

import { RequestApprovalTable } from '../sections/@dashboard/requestApproval';
// mock
import { getClientRequestSummary } from '../network/apis';

export default function RequestApproval() {
  const params = useParams();

  const [clientTechnologies, setClientTechnologies] = useState([]);

  const requestList = () => {
    getClientRequestSummary(params.request_id).then((res) => {
      setClientTechnologies(res.data.data);
    });
  }

  useEffect(() => {
    requestList()
  }, []);

  return (
    <>
      <Helmet>
        <title>Request Approval</title>
      </Helmet>

      <Container maxWidth="xl">
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Request Approval
          </Typography>
        </Stack>

        <Card>{clientTechnologies.length > 0 && <RequestApprovalTable requestList={requestList} list={clientTechnologies} />}</Card>
      </Container>
    </>
  );
}
