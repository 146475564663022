import { Helmet } from 'react-helmet-async';
import { useSelector } from 'react-redux';
import { useState, useEffect } from 'react';
import { toast, ToastContainer } from 'react-toastify';
// @mui
import { Stack, Button, Typography, Skeleton, Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import Layout from '../components/Layout/Layout';
import SearchComponet from '../components/search/Search';
import { hasPermission } from '../utils/permissions';
import { getContactsList } from '../network/apis';
import ContactsTable from '../components/contacts/ContactsTable';

import AddContactsModal from '../components/contacts/AddContactModal';

export default function OurServices() {
  const { t } = useTranslation();
  const [openAddModal, setAddOpenModal] = useState(false);
  const [edit, setEdit] = useState({
    code: '',
    open: false,
    edit: false,
  });
  const [search, setSearch] = useState('');
  const [page, setPage] = useState(0);
  const [loading, setLoading] = useState(false);
  const [contactList, setContactList] = useState([]);

  const contactsListApi = (page, search) => {
    setLoading(true);
    getContactsList(page, search)
      .then((res) => {
        setContactList(res.data.content);
        setLoading(false);
      })
      .catch(() => setLoading(false));
  };
  useEffect(() => {
    contactsListApi(page, search);
  }, [page, search]);

  const handleChangePage = (__, newPage) => {
    setPage(newPage - 1);
  };

  const handleOpenModal = () => {
    setAddOpenModal(true);
  };

  const sideMenu = useSelector((state) => state.login?.sideMenu || []);
  //   const hasSearchPermission = hasPermission(
  //     sideMenu,
  //     "/dashboard/plans",
  //     "Search For our services"
  //   );
  //   const hasAddPermission = hasPermission(
  //     sideMenu,
  //     "/dashboard/plans",
  //     "Add our services"
  //   );
  //   const hasEditPermission = hasPermission(
  //     sideMenu,
  //     "/dashboard/plans",
  //     "edit our services"
  //   );
  const hasSearchPermission = true;
  const hasAddPermission = true;
  const hasEditPermission = true;

  return (
    <>
      <Helmet>
        <title> Plans </title>
      </Helmet>

      <Layout>
        <Grid display="flex" alignItems={'center'} marginBottom={1} justifyContent="space-between" md={6} xs={12}>
          <Typography
            sx={{
              fontSize: '24px',
              fontWeight: '700',
              lineHeight: '32px',
              color: '#111827',
            }}
          >
            {t('contacts.contacts')}
          </Typography>
          {hasAddPermission && (
            <Button
              onClick={handleOpenModal}
              style={{
                backgroundColor: 'black',
                color: 'white',
                borderRadius: '10px',
                padding: '21px 24px',
                width: '164px',
                height: '56px',
              }}
            >
              <Typography sx={{ display: 'flex', gap: 1 }}>
                <span> + </span> <span>{t('contacts.add_contact')}</span>
              </Typography>
            </Button>
          )}
        </Grid>
        <Grid mb={2} rowSpacing={1} container spacing={{ xs: 2, md: 1 }} columns={{ xs: 4, sm: 8, md: 12 }}>
          {hasSearchPermission && (
            <Grid item xs={2} sm={4} md={4}>
              <SearchComponet onSearch={setSearch} style={{ width: '100%' }} />
            </Grid>
          )}
        </Grid>
        {loading ? (
          <Stack>
            <Skeleton height={100} />
            <Skeleton height={100} />
            <Skeleton height={100} />
            <Skeleton height={100} />
            <Skeleton height={100} />
            <Skeleton height={100} />
          </Stack>
        ) : (
          <>
            <>
              <ContactsTable
                handleChangePage={handleChangePage}
                page={page}
                requests={contactList}
                setEdit={setEdit}
                hasEditPermission={hasEditPermission}
              />
            </>
          </>
        )}
        <AddContactsModal
          open={openAddModal || edit.open}
          edit={edit}
          handleClose={() => {
            setAddOpenModal(false);
            setEdit({ code: '', open: false, edit: false });
          }}
          list={contactList}
        />{' '}
        <ToastContainer />
      </Layout>
    </>
  );
}
