import React from "react";
import PropTypes from "prop-types";
import {
  Stepper,
  Step,
  StepLabel,
  StepConnector,
  StepButton,
} from "@mui/material";
import { styled } from "@mui/system";
import SettingsIcon from "@mui/icons-material/Settings";
import GroupAddIcon from "@mui/icons-material/GroupAdd";
import VideoLabelIcon from "@mui/icons-material/VideoLabel";
import { stepConnectorClasses } from "@mui/material/StepConnector";
import { useTranslation } from "react-i18next";
import BusinessIcon from "@mui/icons-material/Business";
import HomeIcon from "@mui/icons-material/Home";

// Styled connector with RTL support
const ColorlibConnector = styled(StepConnector)(({ theme, rtl }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 22,
    left: rtl ? "calc(50% + 20px)" : "calc(-50% + 20px)",
    right: rtl ? "calc(-50% + 20px)" : "calc(50% + 20px)",
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundImage:
        "linear-gradient( 95deg,rgb(242,113,33) 0%,rgb(233,64,87) 50%,rgb(138,35,135) 100%)",
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundImage:
        "linear-gradient( 95deg,rgb(242,113,33) 0%,rgb(233,64,87) 50%,rgb(138,35,135) 100%)",
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    height: 3,
    border: 0,
    backgroundColor:
      theme.palette.mode === "dark" ? theme.palette.grey[800] : "#eaeaf0",
    borderRadius: 1,
  },
}));

// Styled step icon root
const ColorlibStepIconRoot = styled("div")(({ theme, ownerState }) => ({
  backgroundColor:
    theme.palette.mode === "dark" ? theme.palette.grey[700] : "#ccc",
  zIndex: 1,
  color: "#fff",
  width: 50,
  height: 50,
  display: "flex",
  borderRadius: "50%",
  justifyContent: "center",
  alignItems: "center",
  ...(ownerState.active && {
    backgroundImage:
      "linear-gradient( 136deg, rgb(12, 175, 90) 0%, rgb(233,64,87) 50%, rgb(12, 175, 96) 100%)",
    boxShadow: "0 4px 10px 0 rgba(0,0,0,.25)",
  }),
  ...(ownerState.completed && {
    backgroundImage:
      "linear-gradient( 136deg, rgb(12, 175, 90) 0%, rgb(233,64,87) 50%, rgb(12, 175, 96) 100%)",
  }),
}));

// Step icon component with support for custom icons
function ColorlibStepIcon(props) {
  const { active, completed, className, icon } = props;

  const icons = {
    1: <SettingsIcon />,
    2: <GroupAddIcon />,
    3: <VideoLabelIcon />,
    4: <BusinessIcon />,
    5: <HomeIcon />,
  };

  return (
    <ColorlibStepIconRoot
      ownerState={{ completed, active }}
      className={className}
    >
      {icons[String(icon)]}
    </ColorlibStepIconRoot>
  );
}

ColorlibStepIcon.propTypes = {
  active: PropTypes.bool,
  className: PropTypes.string,
  completed: PropTypes.bool,
  icon: PropTypes.node,
};

// Main component to render the stepper
const CustomTabs = ({ value, tabs, onChange }) => {
  const { i18n } = useTranslation();
  const isRtl = i18n.language === "ar";

  return (
    <Stepper
      sx={{ width: "100%", direction: isRtl ? "rtl" : "ltr" }}
      alternativeLabel
      activeStep={value - 1}
      connector={<ColorlibConnector rtl={isRtl} />}
    >
      {tabs?.map((step) => (
        <Step key={step.value}>
          <StepLabel
            StepIconComponent={ColorlibStepIcon}
            sx={{
              "& .MuiStepLabel-label": {
                direction: isRtl ? "rtl" : "ltr",
              },
              cursor: "pointer",
            }}
            onClick={() => {
              onChange(step.value);
            }}
          >
            {step.label}
          </StepLabel>
        </Step>
      ))}
    </Stepper>
  );
};

CustomTabs.propTypes = {
  value: PropTypes.number.isRequired,
  onChange: PropTypes.func,
  tabs: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.number.isRequired,
      label: PropTypes.string.isRequired,
    })
  ).isRequired,
};

export default CustomTabs;
