// src/components/nav-section/NavSection.js

import React from 'react';
import { useTranslation } from 'react-i18next';

import { useLocation, NavLink as RouterLink } from 'react-router-dom';

import { useTheme } from '@mui/material/styles';
import { Box, Button, Collapse, List, ListItem, ListItemText } from '@mui/material';
import { StyledNavItem, StyledNavItemIcon, StyledNavItemText, SubStyledNavItem } from './styles';
import { useNavConfig } from '../../hooks/useNavConfig'; // Adjust the path based on your directory structure
import { ReactComponent as DashboardIcon } from '../../theme/images/bashboardIcon.svg'; // Import SVG as React component

const SubNavItem = ({ item, openNav, isRtl }) => {
  const location = useLocation();
  const isActive = location.pathname === item.path;

  return (
    <SubStyledNavItem
      sx={{ textAlign: isRtl ? 'right' : 'left' }}
      component={RouterLink}
      to={item.path}
      active={isActive ? 1 : 0}
      isRtl={isRtl}
    >
      <StyledNavItemText open={openNav} active={isActive ? 1 : 0}>
        {' '}
        {item.title}
      </StyledNavItemText>
    </SubStyledNavItem>
  );
};

const NavItem = ({ item, depth = 0, openNav }) => {
  const { t, i18n } = useTranslation();
  const isRtl = i18n.language === 'ar';
  const [open, setOpen] = React.useState(false);
  const location = useLocation();
  const isActive = location.pathname === item.path;
  const theme = useTheme();

  const isDarkMode = theme.palette.mode === 'dark';

  const handleClick = () => {
    if (item.sub) {
      setOpen(!open);
    }
  };
  if (item.component) {
    const Component = item.component;
    return (
      <Box display="flex" justifyContent="center" alignItems="center" m={2}>
        <Button
          variant="contained"
          component={RouterLink}
          to="/dashboard"
          sx={{
            backgroundColor: isDarkMode ? '#000000' : '#0CAF60',

            color: 'white',
            width: '100%',
            height: '50px',
            textTransform: 'none',
            display: 'flex',
            borderRadius: '10px',
            justifyContent: 'space-between',
            flexDirection: 'row-reverse',
            padding: '0 16px',
            fontSize: '16px !important',
            fontWeight: '600 !important',
            '&:hover': {
              backgroundColor: isDarkMode ? theme.palette.grey[400] : '#00a743',
            },
          }}
          startIcon={<DashboardIcon />}
        >
          {openNav && t('General.dashboard')}
        </Button>
      </Box>
    );
  }

  return (
    <>
      <StyledNavItem
        sx={{ textAlign: isRtl ? 'right' : 'left', display: 'flex', alignItems: 'center', gap: 2 }}
        component={RouterLink}
        to={item.path}
        onClick={handleClick}
        active={isActive && 1}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            width: '40px',
            height: '40px',
            backgroundColor: isDarkMode ? '#000000' : 'rgb(12, 175, 96)',
            color: 'white',
            borderRadius: '10px',
            transition: 'all 0.3s',
            '&:hover': {
              backgroundColor: isDarkMode ? theme.palette.grey[400] : 'rgba(0, 0, 0, 0.12)',
            },
          }}
        >
          <StyledNavItemIcon>{item.icon}</StyledNavItemIcon>
        </Box>
        <StyledNavItemText sx={{ fontWeight: '700 !important' }} open={openNav} active={isActive && 1}>
          {item.title}
        </StyledNavItemText>
      </StyledNavItem>
      {item.sub && (
        <Collapse in={open} timeout="auto" unmountOnExit>
          <List
            component="div"
            sx={{
              ...(isRtl ? { pr: 3 } : { pl: 3 }),
              display: 'flex',
              flexDirection: 'column',
              gap: 1,
              position: 'relative',
              '&:after': {
                content: '""',
                position: 'absolute',
                top: 0,
                right: isRtl ? 20 : 'auto',
                left: isRtl ? 'auto' : 20,
                width: '1px',
                height: '100%',
                backgroundColor: isDarkMode ? '#000000' : 'rgba(0, 0, 0, 0.12)',
              },
            }}
          >
            {item.sub.map((subItem, index) => (
              <SubNavItem
                sx={{ textAlign: isRtl ? 'right' : 'left' }}
                key={index}
                openNav={openNav}
                isRtl={isRtl}
                isActive={isActive}
                item={subItem}
                depth={depth + 1}
              />
            ))}
          </List>
        </Collapse>
      )}
    </>
  );
};

const NavSection = ({ open }) => {
  const navConfig = useNavConfig();

  return (
    <List>
      {navConfig.map((item, index) => (
        <NavItem openNav={open} key={index} item={item} depth={0} />
      ))}
    </List>
  );
};

export default NavSection;
