import React from 'react';
import {
    PlanFromProvider
} from '../context/planFormContext';
import ManagePlans from './ManagePlans';



const ManageUnitsWithProvider = () => (
    <PlanFromProvider>
        <ManagePlans />
    </PlanFromProvider>
);

export default ManageUnitsWithProvider;