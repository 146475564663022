import { styled } from '@mui/material/styles';
import Badge from '@mui/material/Badge';

const SmallBadge = styled(Badge)(({ theme }) => ({
  '& .MuiBadge-badge': {
    minWidth: '22px',
    height: '22px',
    padding: '3px',
    fontSize: '10px',
    borderRadius: '50%',
    backgroundColor: '#e8676b',
    marginTop:'2px',
    color: '#fbfbfb'
  },
}));

export default SmallBadge;
