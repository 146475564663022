/* eslint-disable react/prop-types */
import React, { useState } from 'react';

// localiztion

import { useTranslation } from 'react-i18next';

// @mui
import { useNavigate } from 'react-router-dom';
import {
  Table,
  TableRow,
  TableBody,
  TableCell,
  Typography,
  TableContainer,
  TablePagination,
  Box,
  Button,
} from '@mui/material';
import { ReactComponent as EditIcon } from '../../theme/images/editTableIcon.svg';

// components
import Scrollbar from '../scrollbar/Scrollbar';

// sections
import ClientHeadTable from '../clientHeadTable/ClientHeadTable';

// ----------------------------------------------------------------------

// ----------------------------------------------------------------------

export default function ContactsTable({ requests, handleChangePage, page, hasEditPermission, setEdit }) {
  const { t, i18n } = useTranslation();
  const isRtl = i18n.language === 'ar';
  const TABLE_HEAD = [
    {
      id: 'contactsType',
      label: t('contacts.contacts_type'),
      alignRight: isRtl && true,
    },
    {
      id: 'contactsValue',
      label: t('contacts.contacts_value'),
      alignRight: isRtl && true,
    },
    {
      id: 'contactsActions',
      label: t('General.Action'),
      alignRight: isRtl && true,
    },
  ];
  const [rowsPerPage] = useState(1);
  return (
    <>
      <Scrollbar>
        <TableContainer sx={{ minWidth: 800 }}>
          <Table>
            <ClientHeadTable headLabel={TABLE_HEAD} />
            <TableBody>
              {requests.length === 0 && (
                <TableCell component="th" scope="row">
                  <Typography variant="subtitle2" noWrap>
                    No Data
                  </Typography>
                </TableCell>
              )}
              {requests?.map((row) => {
                const { id, labelEn, labelAr, value, icon, code } = row;

                return (
                  <TableRow hover key={id} tabIndex={-1}>
                    <TableCell sx={{ textAlign: isRtl ? 'right' : 'left' }} component="th" scope="row">
                      <Box display={'flex'} alignItems={'center'} gap={2}>
                        {' '}
                        <img
                          style={{ width: '45px', height: '45px', objectFit: 'cover', borderRadius: '50%' }}
                          src={icon}
                          alt={''}
                        />
                        <Typography margin={'0'} display={'flex'} alignItems={'center'} justifyContent={'center'}>
                          {isRtl ? labelAr : labelEn}
                        </Typography>
                      </Box>
                    </TableCell>{' '}
                    <TableCell sx={{ textAlign: isRtl ? 'right' : 'left' }} component="th" scope="row">
                      <Typography
                        sx={{
                          color: 'text.secondary',
                          fontWeight: '400',
                          fontSize: '12px',
                          lineHeight: '20px',
                        }}
                        variant="subtitle2"
                        noWrap
                      >
                        {value}
                      </Typography>
                    </TableCell>
                    <TableCell sx={{ textAlign: isRtl ? 'right' : 'left' }}>
                      {hasEditPermission && (
                        <Button
                          onClick={() => {
                            setEdit((prev) => ({
                              ...prev,
                              open: true,
                              edit: true,
                              code,
                            }));
                          }}
                        >
                          <EditIcon />
                        </Button>
                      )}
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Scrollbar>
      {requests?.totalElements === 0 && (
        <Box textAlign="center">
          <h5>No data found</h5>
        </Box>
      )}

      {requests?.totalPages > 0 && (
        <TablePagination
          rowsPerPageOptions={[8]}
          component="div"
          count={requests.totalPages}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
        />
      )}
    </>
  );
}
