import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Typography,
  Box,
  Skeleton,
  Stack,
  TableContainer,
  TableBody,
  TableRow,
  TableCell,
  Paper,
  Table,
  Pagination,
} from '@mui/material';
import { Helmet } from 'react-helmet-async';
import { getActivityLog } from '../network/apis';
import CityReqTable from '../components/clientHeadTable/ClientHeadTable';
import FilterComponent from '../components/filter/Filter';
import Layout from '../components/Layout/Layout';

function ActivityLog() {
  const [rowsPerPage] = useState(10);

  const { t, i18n } = useTranslation();
  const isRtl = i18n.language === 'ar';
  const [loading, setLoading] = useState(false);
  const [filters, setFilters] = useState({});
  const [page, setPage] = useState(1);
  const [newPage, setNewPage] = useState(0);
  const [userList, setUSerList] = useState([]);
  const [logList, setLogList] = useState([]);
  const [allData, setAllData] = useState({});

  const getAllActivityLog = async (newPage, userList) => {
    setLoading(true);
    try {
      const response = await getActivityLog(
        {
          employeeIds: [],
          startDate: filters?.start || '',
          endDate: filters?.end || '',
        },
        newPage
      );
      setLogList(response?.data.content);
      setAllData(response?.data);
    } catch (error) {
      console.log('error', error);
    } finally {
      setLoading(false);
    }
  };

  const handleChange = (event, PPage) => {
    setPage(PPage);
    setNewPage(PPage - 1);
  };

  useEffect(() => {
    getAllActivityLog(newPage, userList);
  }, [newPage, filters]);
  const handleApply = (newFilters) => {
    setFilters(newFilters);
    setPage(1);
    setNewPage(0);
  };

  const TABLE_HEAD = [
    { id: 'employeeNameAr', label: t('logs.employeeName'), alignRight: isRtl && true },
    { id: 'actionNameEn', label: t('logs.actionName'), alignRight: isRtl && true },
    { id: 'page', label: t('logs.page'), alignRight: isRtl && true },
    { id: 'moduleNameEn', label: t('logs.moduleName'), alignRight: isRtl && true },
    { id: 'creationDate', label: t('General.creationDate'), alignRight: isRtl && true },
  ];
  const formatDate = (isoString) => {
    const date = new Date(isoString);
    return date.toLocaleString(isRtl ? 'ar-EG' : 'en-US', {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      second: 'numeric',
      hour12: true,
    });
  };
  return (
    <>
      {' '}
      <Helmet>
        <title> {t('logs.title')}</title>
      </Helmet>
      <Layout>
        <Box sx={{ width: '100%', display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 3 }}>
          <Typography sx={{ fontSize: '24px', fontWeight: '700', lineHeight: '32px', color: '#111827' }}>
            {t('logs.title')}
          </Typography>
          <FilterComponent isLogers onApply={handleApply} />
        </Box>

        {loading ? (
          <Stack>
            <Skeleton height={100} />
            <Skeleton height={100} />
            <Skeleton height={100} />
            <Skeleton height={100} />
            <Skeleton height={100} />
            <Skeleton height={100} />
          </Stack>
        ) : (
          <>
            <TableContainer component={Paper} sx={{ minWidth: 800 }}>
              <Table>
                <CityReqTable headLabel={TABLE_HEAD} />
                <TableBody>
                  {logList?.map((row, index) => {
                    const {
                      actionNameEn,
                      actionNameAr,
                      moduleNameEn,
                      moduleNameAr,
                      page,
                      creationDate,
                      employeeNameEn,
                      employeeNameAr,
                    } = row;
                    const displayDate = formatDate(creationDate);

                    return (
                      <TableRow hover key={index} tabIndex={-1}>
                        <TableCell sx={{ textAlign: isRtl ? 'right' : 'left' }} component="th" scope="row">
                          <Typography
                            sx={{ color: 'text.secondary', fontWeight: '400', fontSize: '12px', lineHeight: '20px' }}
                            variant="subtitle2"
                            noWrap
                          >
                            {' '}
                            {isRtl ? employeeNameAr || t('General.noData') : employeeNameEn || t('General.noData')}
                          </Typography>
                        </TableCell>

                        <TableCell sx={{ textAlign: isRtl ? 'right' : 'left' }} component="th" scope="row">
                          <Typography
                            sx={{ color: 'text.secondary', fontWeight: '400', fontSize: '12px', lineHeight: '20px' }}
                            variant="subtitle2"
                            noWrap
                          >
                            {' '}
                            {isRtl ? actionNameAr || t('General.noData') : actionNameEn || t('General.noData')}
                          </Typography>
                        </TableCell>
                        <TableCell sx={{ textAlign: isRtl ? 'right' : 'left' }} component="th" scope="row">
                          <Typography
                            sx={{ color: 'text.secondary', fontWeight: '400', fontSize: '12px', lineHeight: '20px' }}
                            variant="subtitle2"
                            noWrap
                          >
                            {t(`logs.${page}`) || ''}
                          </Typography>
                        </TableCell>

                        <TableCell sx={{ textAlign: isRtl ? 'right' : 'left' }} component="th" scope="row">
                          <Typography
                            sx={{ color: 'text.secondary', fontWeight: '400', fontSize: '12px', lineHeight: '20px' }}
                            variant="subtitle2"
                            noWrap
                          >
                            {isRtl ? moduleNameAr || t('General.noData') : moduleNameEn || t('General.noData')}{' '}
                          </Typography>
                        </TableCell>

                        <TableCell sx={{ textAlign: isRtl ? 'right' : 'left' }} component="th" scope="row">
                          <Typography
                            sx={{ color: 'text.secondary', fontWeight: '400', fontSize: '12px', lineHeight: '20px' }}
                            variant="subtitle2"
                            noWrap
                          >
                            {displayDate || ''}
                          </Typography>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>{' '}
            {allData?.totalElements === 0 && (
              <Box textAlign="center">
                <h5>{t('General.noDataFound')}</h5>
              </Box>
            )}
            {allData?.totalPages > 0 && (
              <Box
                sx={{
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  padding: '10px',
                  marginTop: '5rem',
                }}
              >
                <Pagination count={allData.totalPages} page={page} onChange={handleChange} color="primary" />
                <Box sx={{ display: 'block' }}>
                  {t('General.showing')} {(page - 1) * rowsPerPage + 1} {t('General.to')}{' '}
                  {Math.min(page * 10, allData.totalElements)} {t('General.of')} {allData.totalElements}{' '}
                  {t('General.entries')}
                </Box>
              </Box>
            )}
          </>
        )}
      </Layout>
    </>
  );
}
export default ActivityLog;
