import React, { createContext, useContext, useEffect, useState } from 'react';
import { usePusher } from '../PusherContext';
import { getemployeeNotificationsList } from '../network/apis';

const NotificationContext = createContext();

export const useNotification = () => useContext(NotificationContext);

export const NotificationProvider = ({ children }) => {
  const pusherClient = usePusher();
  const [numbersOfNotifications, setNumbersOfNotifications] = useState(0);
  const [pageSize, setPageSize] = useState(5);
  const [notifications, setNotifications] = useState([]);
  const [unseenCount, setUnseenCount] = useState(0);
  const [managerId, setManagerId] = useState();
  const [loading, setLoading] = useState(false);

  const handleSeeMore = () => {
    setPageSize((prev) => prev + 10);
  };

  const fetchNotifications = async () => {
    try {
      setLoading(true);
      const response = await getemployeeNotificationsList(0, pageSize);
      const notifications = response?.data?.content || [];
      setNotifications(notifications);
      setNumbersOfNotifications(response?.data?.totalElements || 0);
      setUnseenCount(notifications.length > 0 ? notifications[0].unreadCount : 0);
      setManagerId(notifications[0].managerId || 0);
    } catch (error) {
      console.error('Failed to fetch notifications', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchNotifications();

    if (pusherClient) {
      const channel = pusherClient.subscribe('EMPLOYEE_NOTIFICATION');
      channel.bind('SEND_NOTIFICATION', (data) => {
        setNotifications((prevNotifications) => [...prevNotifications, data.content]);

        // setUnseenCount((prevCount) => prevCount + 1);
      });

      return () => {
        if (channel) {
          channel.unbind_all();
          channel.unsubscribe();
        }
      };
    }

    return undefined;
  }, [pusherClient, pageSize]);

  //   const markAsSeen = () => {
  //     setNotifications(notifications.map(notif => ({ ...notif, seen: true })));
  //     // setUnseenCount(0);
  //   };

  return (
    <NotificationContext.Provider
      value={{
        notifications,
        unseenCount,
        fetchNotifications,
        managerId,
        loading,
        handleSeeMore,
        numbersOfNotifications,
      }}
    >
      {children}
    </NotificationContext.Provider>
  );
};
