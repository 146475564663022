/* eslint-disable react/prop-types */
import { useState } from 'react';
// @mui
import { useNavigate } from 'react-router-dom';
import {
  Paper,
  Table,
  TableRow,
  TableBody,
  Button,
  TableCell,
  Typography,
  TableContainer,
  Pagination,
  PaginationItem,
} from '@mui/material';
import { Box } from '@mui/system';
// icons
import { useTranslation } from 'react-i18next';
import { ReactComponent as EditIcon } from '../../../theme/images/editTableIcon.svg';
import { ReactComponent as ShowIcon } from '../../../theme/images/showTableIcon.svg';
// components
import Scrollbar from '../../../components/scrollbar';

// sections
import ClientReqHead from '../../../components/clientHeadTable/ClientHeadTable';

export default function ProjectTable({ requests, handleChangePage, page, onDelete, hasEditProjectPermission }) {
  const [rowsPerPage] = useState(10); // Set a default value for rowsPerPage
  const navigate = useNavigate();
  const { i18n, t } = useTranslation();
  const isRtl = i18n.language === 'ar';

  const baseTableHead = [
    { id: 'NAME_EN', label: t('ManageProject.projectName'), alignRight: isRtl && true },
    { id: 'ownerId', label: t('ManageProject.ownerName'), alignRight: isRtl && true },
    { id: 'creation_date', label: t('General.creationDate'), alignRight: isRtl && true },
    { id: 'last_update_date', label: t('General.lastUpdateDate'), alignRight: isRtl && true },
    { id: 'status', label: t('General.Status'), alignRight: isRtl && true },
    { id: 'action', label: t('General.Action'), alignRight: isRtl && true },
  ];

  const TABLE_HEAD = baseTableHead;

  return (
    <>
      <Scrollbar>
        <TableContainer
          component={Paper}
          sx={{
            minWidth: 800,
          }}
        >
          <Table sx={{ direction: isRtl ? 'rtl' : 'ltr' }}>
            <ClientReqHead headLabel={TABLE_HEAD} />
            <TableBody>
              {requests?.content?.map((row) => {
                const { id, nameEn, creationDate, owner, projectStatus, nameAr, lastUpdateDate } = row;
                const displayDate = creationDate
                  ? new Date(creationDate).toLocaleDateString()
                  : new Date().toLocaleDateString();

                return (
                  <TableRow
                    sx={
                      {
                        // display: 'flex',
                        // direction: isRtl ? 'rtl' : 'ltr',
                        // textAlign: 'right',
                      }
                    }
                    hover
                    key={id}
                    tabIndex={-1}
                  >
                    <TableCell sx={{ textAlign: isRtl ? 'right' : 'left' }} component="th" scope="row">
                      <Typography
                        sx={{ color: 'text.secondary', fontWeight: '400', fontSize: '12px', lineHeight: '20px' }}
                        variant="subtitle2"
                        noWrap
                      >
                        {isRtl ? nameAr : nameEn}
                      </Typography>
                    </TableCell>
                    <TableCell component="th" scope="row" sx={{ textAlign: isRtl ? 'right' : 'left' }}>
                      <Typography
                        sx={{ color: 'text.secondary', fontWeight: '400', fontSize: '12px', lineHeight: '20px' }}
                        variant="subtitle2"
                        noWrap
                      >
                        {isRtl ? owner.nameAr : owner.nameEn || ''}
                      </Typography>
                    </TableCell>
                    <TableCell component="th" scope="row" sx={{ textAlign: isRtl ? 'right' : 'left' }}>
                      <Typography
                        sx={{ color: 'text.secondary', fontWeight: '400', fontSize: '12px', lineHeight: '20px' }}
                        variant="subtitle2"
                        noWrap
                      >
                        {displayDate}
                      </Typography>
                    </TableCell>
                    <TableCell component="th" scope="row" sx={{ textAlign: isRtl ? 'right' : 'left' }}>
                      <Typography
                        sx={{ color: 'text.secondary', fontWeight: '400', fontSize: '12px', lineHeight: '20px' }}
                        variant="subtitle2"
                        noWrap
                      >
                        {lastUpdateDate}
                      </Typography>
                    </TableCell>

                    <TableCell component="th" scope="row" sx={{ textAlign: isRtl ? 'right' : 'left' }}>
                      {projectStatus === 'TRUE' ? (
                        <Box
                          sx={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            padding: '4px 16px',
                            backgroundColor: '#E7F7EF',
                            borderRadius: '8px',
                            width: '88px',
                          }}
                        >
                          <Typography
                            sx={{ color: '#0CAF60', fontWeight: '700', fontSize: '10px', lineHeight: '16px' }}
                            variant="subtitle2"
                            textAlign={'center'}
                            noWrap
                          >
                            {t('General.Active')}
                          </Typography>
                        </Box>
                      ) : (
                        <Box
                          sx={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            padding: '4px 16px',
                            backgroundColor: '#FFEDEC',
                            borderRadius: '8px',
                            width: '88px',
                          }}
                        >
                          <Typography
                            sx={{ color: '#E03137', fontWeight: '700', fontSize: '10px', lineHeight: '16px' }}
                            variant="subtitle2"
                            textAlign={'center'}
                            noWrap
                          >
                            {t('General.InActive')}
                          </Typography>
                        </Box>
                      )}
                    </TableCell>
                    {hasEditProjectPermission && (
                      <TableCell align="left">
                        <Box
                          sx={{
                            display: 'flex',
                            alignItems: 'center',
                            gap: '2px',
                          }}
                        >
                          <Button
                            onClick={() =>
                              navigate(id ? `/dashboard/project/view/${id}` : '/dashboard/project/add', {
                                state: { editData: row },
                              })
                            }
                          >
                            <ShowIcon />
                          </Button>
                          <Button
                            onClick={() =>
                              navigate(id ? `/dashboard/project/edit/${id}` : '/dashboard/project/add', {
                                state: { editData: row },
                              })
                            }
                          >
                            <EditIcon />
                          </Button>
                        </Box>
                      </TableCell>
                    )}
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Scrollbar>
      {requests?.totalElements === 0 && (
        <Box textAlign="center">
          <h5>{t('General.noDataFound')}</h5>
        </Box>
      )}
      {requests?.totalPages > 0 && (
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            padding: '10px',
            marginTop: '5rem',
          }}
        >
          <Pagination count={requests.totalPages} page={page + 1} onChange={handleChangePage} color="primary" />
          <Box sx={{ display: 'block' }}>
            {t('General.showing')} {page * rowsPerPage + 1} {t('General.to')}{' '}
            {Math.min((page + 1) * rowsPerPage, requests.totalElements)} {t('General.of')} {requests.totalElements}{' '}
            {t('General.entries')}
          </Box>
        </Box>
      )}
    </>
  );
}
