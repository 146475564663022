import React, { createContext, useContext, useEffect, useState } from 'react';
import Pusher from 'pusher-js';

const PusherContext = createContext();

export const usePusher = () => useContext(PusherContext);

export const PusherProvider = ({ children }) => {
  const [pusherClient, setPusherClient] = useState(null);

  useEffect(() => {
     const pusher = new Pusher('484829b34536dc168dcb', {
      cluster: 'eu',
      encrypted: true
    });


    setPusherClient(pusher);
  

    return () => {
      pusher.disconnect();
    };
  }, []);

  return (
    <PusherContext.Provider value={pusherClient}>
      {children}
    </PusherContext.Provider>
  );
};
