import React, { useEffect, useRef } from "react";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic"; 



function Editor({ onChange, editorLoaded, name, value }) {
  const editorRef = useRef();

  const editorConfig = 
    {
      ckfinder: {
      }
    }

  useEffect(() => {
    editorRef.current = ClassicEditor;
  }, []);



  return (
    <div style={{ height: "400px", minHeight: "400px", maxHeight:"400px", overflowY: "auto", position: "relative" }}>
    {editorLoaded ? (
      <div style={{ position: "absolute", top: "0", bottom: "0", left: "0", right: "0", overflowY: "auto" }}>

        <CKEditor
          editor={ClassicEditor}
          config={editorConfig}
          data={value}
          onChange={(event, editor) => {
            const data = editor.getData();
            onChange(data);
          }}
        />
        </div>
      ) : (
        <div>Editor loading</div>
      )}
    </div>
  );
}

export default Editor;
