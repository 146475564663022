// src/layouts/dashboard/nav/config.js

import { useSelector } from 'react-redux';
import SvgColor from '../../../components/svg-color';
import DashboardButton from '../../../components/dashboardButton/DashboardButton';

const icon = (name) => <SvgColor src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />;

const iconMapping = {
  'Manage Admins ': icon('manage_admins'),
  'Manage packages': icon('ic_package'),
  'Residential Projects': icon('projects'),
  'Manage Plans': icon('ic_plans'),
  'Land pieces': icon('ic_land'),
  'Manage Roles': icon('ic_land'),
  Setting: icon('setting'),
  'Our Service and Offers': icon('offers'),
// default: icon('info'),
  // default: icon('info'),
};

// Function to build the nav config from the sideMenu
export const buildNavConfig = (sideMenu, isRtl) => {
  return sideMenu.map((menu) => ({
    id: menu.id,
    title: isRtl ? menu.nameAr : menu.nameEn,
    icon: iconMapping[menu.nameEn] || iconMapping.default, // Use mapping or default icon
    sub: menu.pages.map((page) => ({
      id: page.id,
      title: isRtl ? page.nameAr : page.nameEn,
      path: page.route,
      icon: iconMapping.default, // Use mapping or default icon
      actions: page.actions
        .filter((action) => action.isChecked === '1')
        .map((action) => ({
          id: action.id,
          title: action.actionName,
          path: action.api,
          icon: page.icon, // Use mapping or default icon
        })),
    })),
  }));
};

// Initial navConfig for default items
export const initialNavConfig = [{ component: DashboardButton }];
