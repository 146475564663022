import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  Button,
  CircularProgress,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { LoadingButton } from '@mui/lab';
import { useTranslation } from 'react-i18next';
import { getConsultantData } from '../../../network/apis';
import { updateFormData } from '../../../Store/features/projectForm/projectFormSlice';

export default function ConsltantDataForm({ handlePrev, submitForm, editData, isViewMode, loading_submit }) {
  const formData = useSelector((state) => state.form.formData);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  const [consultantList, setConsultantList] = useState([]);
  const [consultantDropDownOpened, setConsultantDropDownOpened] = useState(false);

  useEffect(() => {
    if (!consultantDropDownOpened) {
      getConsultantList();

      setConsultantDropDownOpened(true);
    }
  }, [editData, consultantDropDownOpened]);

  const submitAllData = (values) => {
    const consultantData = {
      consultant: {
        name: values.name,
        id: values.id,
        website: values.website,
        cr: values.cr,
        email: values.email,
        address: values.address,
      },
    };
    dispatch(updateFormData(consultantData));
    try {
      submitForm();
    } catch (error) {
      console.error('Error calling submitForm:', error);
    }
  };

  const validationSchema = Yup.object().shape({
    name: Yup.string().required(t('General.required_field')),

    id: Yup.string().required(t('General.required_field')),

    website: Yup.string().required(t('General.required_field')),

    cr: Yup.string().required(t('General.required_field')),

    email: Yup.string().required(t('General.required_field')),

    address: Yup.string().required(t('General.required_field')),
  });

  const getConsultantList = () => {
    setLoading(true);
    getConsultantData()
      .then((res) => {
        setConsultantList(res.data);
        setLoading(false);
      })
      .catch(() => setLoading(false));
  };

  return (
    <Formik
      initialValues={{
        ...editData,
        ...formData,
        consultant: {
          name: '',
          id: '',
          website: '',
          cr: '',
          email: '',
          address: '',
        },
      }}
      onSubmit={submitAllData}
      validationSchema={validationSchema}
    >
      {({ values, errors, touched, handleChange, handleSubmit, handleBlur, setValues }) => (
        <form onSubmit={handleSubmit}>
          <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }}>
            <Grid item xs={2} sm={4} md={6}>
              <Stack>
                <InputLabel id="consultantName">{t('consultantForm.consultantName')}</InputLabel>
                <Select
                  sx={{ mt: 1 }}
                  labelId="id"
                  id="id"
                  value={values.id}
                  disabled={isViewMode}
                  onOpen={() => {
                    if (!consultantDropDownOpened) {
                      getConsultantList();
                      setConsultantDropDownOpened(true);
                    }
                  }}
                  onChange={(event) => {
                    const selectedConsultantId = event.target.value;
                    dispatch(updateFormData({ consultant: { id: selectedConsultantId } }));

                    const selectedConsultant = consultantList.find(
                      (consultant) => consultant.id === selectedConsultantId
                    );
                    if (selectedConsultant) {
                      setValues({
                        ...values,
                        id: selectedConsultant.id,
                        cr: selectedConsultant.cr,
                        name: selectedConsultant.name,
                        website: selectedConsultant.website,
                        email: selectedConsultant.email,
                        phone: selectedConsultant.phone,
                        address: selectedConsultant.address,
                      });
                    }
                  }}
                >
                  {loading ? (
                    <MenuItem>
                      <CircularProgress size={24} />
                    </MenuItem>
                  ) : (
                    consultantList?.map((option, index) => (
                      <MenuItem key={index} value={option.id}>
                        {option.name}
                      </MenuItem>
                    ))
                  )}
                </Select>
              </Stack>
            </Grid>

            <Grid item xs={2} sm={4} md={6}>
              <Typography variant="body1" gutterBottom>
                {t('consultantForm.consultantId')}
              </Typography>
              <TextField
                name="id"
                disabled={isViewMode}
                fullWidth
                value={values.id}
                variant="outlined"
                onChange={handleChange}
                onBlur={handleBlur}
                error={touched.id && Boolean(errors.id)}
                helperText={touched.id && errors.id}
              />
            </Grid>

            <Grid item xs={2} sm={4} md={6}>
              <Typography variant="body1" gutterBottom>
                {t('consultantForm.consultantCR')}
              </Typography>
              <TextField
                name="cr"
                disabled={isViewMode}
                fullWidth
                value={values.cr}
                variant="outlined"
                onChange={handleChange}
                onBlur={handleBlur}
                error={touched.cr && Boolean(errors.cr)}
                helperText={touched.cr && errors.cr}
              />
            </Grid>

            <Grid item xs={2} sm={4} md={6}>
              <Typography variant="body1" gutterBottom>
                {t('consultantForm.name')}
              </Typography>
              <TextField
                name="name"
                disabled={isViewMode}
                fullWidth
                value={values.name}
                variant="outlined"
                onChange={handleChange}
                onBlur={handleBlur}
                error={touched.name && Boolean(errors.name)}
                helperText={
                  touched.name &&
                  errors.name && <span dangerouslySetInnerHTML={{ __html: errors.name.replace('\n', '<br />') }} />
                }
              />
            </Grid>

            <Grid item xs={2} sm={4} md={6}>
              <Typography variant="body1" gutterBottom>
                {t('consultantForm.website')}
              </Typography>
              <TextField
                name="website"
                disabled={isViewMode}
                fullWidth
                value={values.website}
                variant="outlined"
                onChange={handleChange}
                onBlur={handleBlur}
                error={touched.website && Boolean(errors.website)}
                helperText={touched.website && errors.website}
              />
            </Grid>

            <Grid item xs={2} sm={4} md={6}>
              <Typography variant="body1" gutterBottom>
                {t('consultantForm.email')}
              </Typography>
              <TextField
                name="email"
                disabled={isViewMode}
                fullWidth
                value={values.email}
                variant="outlined"
                onChange={handleChange}
                onBlur={handleBlur}
                error={touched.email && Boolean(errors.email)}
                helperText={touched.email && errors.email}
              />
            </Grid>

            <Grid item xs={2} sm={4} md={6}>
              <Typography variant="body1" gutterBottom>
                {t('consultantForm.address')}
              </Typography>
              <TextField
                name="address"
                disabled={isViewMode}
                fullWidth
                value={values.address}
                variant="outlined"
                onChange={handleChange}
                onBlur={handleBlur}
                error={touched.address && Boolean(errors.address)}
                helperText={touched.address && errors.address}
              />
            </Grid>
          </Grid>

          <Stack sx={{ width: '100%' }} direction="row" justifyContent="end" alignItems="end" gap={2}>
            <Button
              sx={{ mt: 4, border: '1px solid #111827' }}
              size="large"
              type="button"
              onClick={() => {
                const consultantData = {
                  consultant: {
                    name: values.name,
                    id: values.id,
                    website: values.website,
                    cr: values.cr,
                    email: values.email,
                    address: values.address,
                  },
                };
                dispatch(updateFormData(consultantData));
                handlePrev();
              }}
            >
              {t('consultantForm.back')}
            </Button>
            {isViewMode && (
              <LoadingButton
                style={{ padding: '0.8rem' }}
                variant="contained"
                onClick={() => navigate('/dashboard/projects')}
              >
                {t('consultantForm.ok')}
              </LoadingButton>
            )}

            {!isViewMode && (
              <LoadingButton
                loading={loading_submit}
                style={{ padding: '0.8rem', marginTop: '10px' }}
                variant="contained"
                type="submit"
              >
                {t('consultantForm.submit')}
              </LoadingButton>
            )}
          </Stack>
        </form>
      )}
    </Formik>
  );
}
