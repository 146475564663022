import { configureStore } from '@reduxjs/toolkit';

import loginReducer from './features/Login/loginSlice';
import projectFormReducer from './features/projectForm/projectFormSlice';
import planSlice from './features/Plans/plansSlice';
import landSlice from './features/Lands/landsSlice';
import unitReducer from './features/Units/unitsSlice';

export const store = configureStore({
  reducer: {
    login: loginReducer,
    form: projectFormReducer,
    plan: planSlice,
    land: landSlice,
    unitform: unitReducer,
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(),
});
