// @mui
import {
  Grid,
} from '@mui/material';
// components
import { useEffect } from 'react';
import DetailsFeaturesList from './DetailsFeaturesList';
import ManageBuildings from '../../../pages/ManageBuildings' 
import AddBuilding from '../Building/buildingForm'
import ManageUnitsWithProvider from '../../../pages/ManageUnitsProvider';
// import ProjectDataForm from '../projectForm/projectDataForm';
import AddProject from '../../../pages/ManageProjects';


export default function RequestDetailsCard({ details  , id , setIsViewMode , viewMode}) {

 
  return (
    <Grid p={3} container spacing={2}>
     
      <Grid style={{borderBottom: "2px solid #f4f1f1"}} item md={12}>
      <h3 >Project Data</h3>

      </Grid>
      <Grid md={12}>
        <>
        <AddProject units={details.units} isViewMode={viewMode} setIsViewMode={setIsViewMode} selectedProjectId={id} editData={details}/>
        </>
      </Grid>
      {/* <Grid item md={3}>
        <h4>Project Name En</h4> {details.nameEn}
      </Grid>
      <Grid item md={3}>
        <h4>Project Name Ar</h4> {details.nameAr}
      </Grid>
      <Grid item md={3}>
        <h4>Address En</h4> {details.addressEn}
      </Grid>
      <Grid item md={3}>
        <h4>Address Ar</h4> {details.addressAr}
      </Grid>
      <Grid item md={3}>
        <h4>Project Id</h4> {details.id}
      </Grid>
      <Grid item md={3}>
        <h4>Total Units</h4> {details.totalUnits}
      </Grid> */}
      {/* <Grid item md={3}>
        <h4>Regions</h4> {details.totalUnits}
      </Grid> */}
      {/* <Grid item md={3}>
        <h4>Total Area</h4> {details.totalArea}
      </Grid>
      <Grid item md={3}>
        <h4>Project Data Status</h4> {details.projectDataStatus}
      </Grid>
      <Grid item md={3}>
        <h4>Project Status</h4> {details.states}
      </Grid>   
      <Grid item md={12}  style={{borderTop:'2px solid #f4f1f1' , margin: "23px 0px 3px 0px" , padding:"0"}} > 
       <p style={{display:"none"}}>border</p>
      </Grid> */}
      {/* <Grid item md={3}>
        <h4>City</h4> {details.totalUnits}
      </Grid>
      <Grid item md={3}>
        <h4>Facilities Available</h4> {details.totalUnits}
      </Grid> */}
      {/* <Grid   item xs={12} >
        <h4>Add Building</h4> 
         <AddBuilding />
      </Grid> */}
      {/* <Grid  style={{borderTop:'2px solid #f4f1f1'}}  item xs={12} >
         <h4>Add Building</h4>
         <ManageUnitsWithProvider />
      </Grid> */}
      {/* <Grid item md={3}>
        <h4>Business Type</h4> {details.client_request.business.type}
      </Grid>
      <Grid item md={3}>
        <h4>Category</h4> {details.category.name}
      </Grid>
      <Grid item md={3}>
        <h4>Technology</h4> {details?.technology?.name}
      </Grid>
      <Grid item md={3}>
        <h4>Availabilty</h4> {details.availabilty}
      </Grid>
      <Grid item md={3}>
        <h4>Level</h4> {details.level}
      </Grid>
      <Grid item md={3}>
      <h4>Estimate</h4>
      {details.time}
      </Grid>
      <Grid item md={3}>
        <h4>Status</h4> {details.status === 1 ? 'Approved' : details.status === 2 ? 'Pending' : 'Rejected'}
      </Grid>    
      <Grid item md={9}>
        <h4>Comment</h4> {details.comment}
      </Grid>  
      <Grid item xs={12} >
        <h4>Features</h4>
        <DetailsFeaturesList level={details.level} list={details.client_request.features} />
      </Grid> */}
    </Grid>
  );
}
