import React, { useEffect, useState } from 'react';
import {
  LineChart,
  Line,
  Brush,
  YAxis,
  CartesianGrid,
  Tooltip as RechartsTooltip,
  Legend as RechartsLegend,
} from 'recharts';
import {
  Container,
  Grid,
  Paper,
  Typography,
  Box,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Select,
  MenuItem,
  FormControl,
} from '@mui/material';
import Stack from '@mui/material/Stack';
import { PieChart } from '@mui/x-charts/PieChart';
import { useTranslation } from 'react-i18next';
import { styled } from '@mui/material/styles';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import TrendingDownIcon from '@mui/icons-material/TrendingDown';
import TrendingFlatIcon from '@mui/icons-material/TrendingFlat';
import { ReactComponent as ProjectIcon } from '../theme/images/total-projects.svg';
import { ReactComponent as PlanIcon } from '../theme/images/total-planssvg.svg';
import { ReactComponent as UnitsIcon } from '../theme/images/total-unitssvg.svg';
import { ReactComponent as LandIcon } from '../theme/images/totla-lands.svg';
import FilterComponent from '../components/filter/Filter';
import ClientHeadTable from '../components/clientHeadTable/ClientHeadTable';

import DashboardTable from '../sections/@dashboard/DashboardPageTable/DasboardTableData';
import { ReactComponent as DropdownIcon } from '../theme/images/deopdownIcon.svg';
import { getDashboard, generateReport } from '../network/apis';

const CustomTooltip = ({ active, payload }) => {
  const { t } = useTranslation();
  if (active && payload && payload.length) {
    return (
      <Box
        sx={{
          display: 'flex',
          gap: 2,
          alignItems: 'center',
          backgroundColor: 'rgb(0,0,0,.8)',
          color: 'white',
          padding: '5px 10px',
          borderRadius: '5px',
        }}
      >
        <Typography
          sx={{
            fontSize: '10px',
            fontWeight: 'normal',
          }}
        >
          {payload[0].payload.label}
        </Typography>
        <Typography
          sx={{
            display: 'flex',
            alignItems: 'center',
            fontSize: '14px',
            fontWeight: 'bold',
          }}
        >
          {t('DashboardPage.salesRatio')}: {payload[0].value}
        </Typography>
      </Box>
    );
  }

  return null;
};

const Dashboard = () => {
  const { t, i18n } = useTranslation();
  const isRtl = i18n.language === 'ar';
  const [filters, setFilters] = useState({ type: 'month', value: '1', start: '', end: '', filter: 'ALL' });
  const [dashboardData, setDashboardData] = useState([]);

  const handleApply = (newFilters) => {
    setFilters(newFilters);
  };

  useEffect(() => {
    const fetchDashboardData = async () => {
      try {
        const res = await getDashboard(filters.type, filters.value, filters.start, filters.end, filters?.filter);
        setDashboardData(res.data);
      } catch (err) {
        console.error('API call failed:', err);
      }
    };

    fetchDashboardData();
  }, [filters]);

  const handleGenerateReport = async () => {
    try {
      const response = await generateReport(filters.type, filters.value, filters.start, filters.end, filters?.filter);
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'report.xlsx');
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);
    } catch (error) {
      console.error('Error generating report:', error);
    }
  };

  const data = dashboardData.PRODUCTS || [];
  const dataCharts = dashboardData?.SALES_CHART?.sort((a, b) => new Date(a.label) - new Date(b.label)) || [];

  const StyledText = styled('text')(({ theme }) => ({
    fill: 'black',
    textAnchor: 'middle',
    dominantBaseline: 'central',
    fontSize: 20,
  }));

  function PieCenterLabel({ children }) {
    return (
      <StyledText x="50%" y="50%">
        {children}
      </StyledText>
    );
  }

  const dataTopPlans = dashboardData?.TOP_PLANS?.content || [];
  const dataTopResidentialProjects = dashboardData?.TOP_PROJECTS?.content || [];

  const tableHeadPlans = [
    { id: 1, label: t('PLANS.planName'), alignRight: isRtl && true },
    { id: 2, label: t('ownerForm.ownerName'), alignRight: isRtl && true },
    { id: 3, label: t('PLANS.landOfferedNumber'), alignRight: isRtl && true },
    { id: 4, label: t('PLANS.landNumber'), alignRight: isRtl && true },
    { id: 5, label: t('Units.available'), alignRight: isRtl && true },
    { id: 6, label: t('Units.sold'), alignRight: isRtl && true },
    { id: 7, label: t('Units.reserved'), alignRight: isRtl && true },
  ];

  const tableHeadProjects = [
    { id: 1, label: t('ManageProject.projectName'), alignRight: isRtl && true },
    { id: 2, label: t('ownerForm.ownerName'), alignRight: isRtl && true },
    { id: 3, label: t('ManageProject.buildingData'), alignRight: isRtl && true },
    { id: 4, label: t('General.totalUnits'), alignRight: isRtl && true },
    { id: 5, label: t('Units.available'), alignRight: isRtl && true },
    { id: 6, label: t('Units.sold'), alignRight: isRtl && true },
    { id: 7, label: t('Units.reserved'), alignRight: isRtl && true },
  ];

  return (
    <Container>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 4 }}>
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
          <FilterComponent onApply={handleApply} />
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Select
              displayEmpty
              value={filters.filter}
              sx={{
                width: '100%',
                minWidth: '100px',
                paddingRight: isRtl ? '0' : '10px',
                paddingLeft: isRtl ? '10px' : '0',
                borderRadius: '10px',
              }}
              renderValue={(selected) => {
                if (selected === '') {
                  return <span>{t('Units.all')}</span>;
                }
                if (selected === 'SOLD') return <span> {t('Units.sold')} </span>;
                if (selected === 'RESERVED') return <span> {t('Units.reserved')} </span>;
                return <span style={{ color: '#9e9e9e' }}> {t('General.select')} </span>;
              }}
              IconComponent={() => <DropdownIcon style={{ width: '15px', height: '15px' }} />}
              labelId="Status"
              id="status"
              onChange={(e) => setFilters({ ...filters, filter: e.target.value })}
            >
              <MenuItem value="">{t('Units.all')}</MenuItem>
              <MenuItem value="SOLD">{t('Units.sold')}</MenuItem>
              <MenuItem value="RESERVED">{t('Units.reserved')}</MenuItem>
            </Select>
          </Box>
        </Box>

        <Button variant="contained" onClick={handleGenerateReport} color="primary">
          {t('DashboardPage.generateReport')}
        </Button>
      </Box>
      <Grid container spacing={3}>
        <Grid item xs={12} md={3}>
          <Paper elevation={3} sx={{ p: 3, display: 'flex', flexDirection: 'column', borderRadius: '12px' }}>
            <Box sx={{ display: 'flex', mb: 2, alignItems: 'center' }}>
              <PlanIcon style={{ marginInlineEnd: '10px' }} />
              <Typography
                variant="span"
                style={{
                  fontSize: '16px',
                  fontWeight: '600',
                  lineHeight: '24px',
                }}
              >
                {t('General.totalPlans')}
              </Typography>
            </Box>
            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
              <Typography variant="p" sx={{ fontSize: '27px', fontWeight: '700' }}>
                {dashboardData?.TOTAL_COUNT?.PLAN_COUNT || ''}
              </Typography>
              {dashboardData?.TOTAL_COUNT?.LAND_RATE_MEASURE === 'UP' ? (
                <Box
                  sx={{
                    display: 'flex',
                    mt: 1,
                    backgroundColor: '#00ff001d',
                    borderRadius: '17px',
                    p: '4px 4px',
                    width: '40%',
                  }}
                >
                  <TrendingUpIcon fontSize="small" sx={{ color: 'green', marginRight: '4px' }} />
                  <Typography variant="body2" sx={{ color: 'green' }}>
                    {dashboardData?.TOTAL_COUNT?.PLAN_RATE || ''}
                  </Typography>
                </Box>
              ) : dashboardData?.TOTAL_COUNT?.LAND_RATE_MEASURE === 'DOWN' ? (
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: 1,
                    mt: 1,
                    backgroundColor: '#ff00001d',
                    borderRadius: '17px',
                    p: '4px 4px',
                    width: '40%',
                  }}
                >
                  <TrendingDownIcon fontSize="small" sx={{ color: 'red', marginRight: '4px' }} />
                  <Typography variant="body2" sx={{ color: 'red' }}>
                    {dashboardData?.TOTAL_COUNT?.PLAN_RATE || ''}
                  </Typography>
                </Box>
              ) : (
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: 1,
                    mt: 1,
                    backgroundColor: '#00ff001d',
                    borderRadius: '17px',
                    p: '4px 4px',
                    width: '40%',
                  }}
                >
                  <TrendingFlatIcon fontSize="small" sx={{ color: 'green', marginRight: '4px' }} />
                  <Typography variant="body2" sx={{ color: 'green' }}>
                    {dashboardData?.TOTAL_COUNT?.PLAN_RATE || ''}
                  </Typography>
                </Box>
              )}
            </Box>
          </Paper>
        </Grid>

        <Grid item xs={12} md={3}>
          <Paper elevation={3} sx={{ p: 3, display: 'flex', flexDirection: 'column', borderRadius: '12px' }}>
            <Box sx={{ display: 'flex', mb: 2, alignItems: 'center' }}>
              <LandIcon style={{ marginInlineEnd: '10px' }} />
              <Typography
                variant="span"
                style={{
                  fontSize: '16px',
                  fontWeight: '600',
                  lineHeight: '24px',
                }}
              >
                {t('General.totalLandPieces')}
              </Typography>
            </Box>
            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
              <Typography variant="p" sx={{ fontSize: '27px', fontWeight: '700' }}>
                {dashboardData?.TOTAL_COUNT?.LAND_COUNT || ''}
              </Typography>

              {dashboardData?.TOTAL_COUNT?.LAND_RATE_MEASURE === 'UP' ? (
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: 1,
                    mt: 1,
                    backgroundColor: '#00ff001d',
                    borderRadius: '17px',
                    p: '4px 4px',
                    width: '40%',
                  }}
                >
                  <TrendingUpIcon fontSize="small" sx={{ color: 'green', marginRight: '4px' }} />
                  <Typography variant="body2" sx={{ color: 'green' }}>
                    {dashboardData?.TOTAL_COUNT?.LAND_RATE || ''}
                  </Typography>
                </Box>
              ) : dashboardData?.TOTAL_COUNT?.LAND_RATE_MEASURE === 'DOWN' ? (
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: 1,
                    mt: 1,
                    backgroundColor: '#ff00001d',
                    borderRadius: '17px',
                    p: '4px 4px',
                    width: '40%',
                  }}
                >
                  <TrendingDownIcon fontSize="small" sx={{ color: 'red', marginRight: '4px' }} />
                  <Typography variant="body2" sx={{ color: 'red' }}>
                    {dashboardData?.TOTAL_COUNT?.LAND_RATE || ''}
                  </Typography>
                </Box>
              ) : (
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: 1,
                    mt: 1,
                    backgroundColor: '#00ff001d',
                    borderRadius: '17px',
                    p: '4px 4px',
                    width: '40%',
                  }}
                >
                  <TrendingFlatIcon fontSize="small" sx={{ color: 'green', marginRight: '4px' }} />
                  <Typography variant="body2" sx={{ color: 'green' }}>
                    {dashboardData?.TOTAL_COUNT?.LAND_RATE || ''}
                  </Typography>
                </Box>
              )}
            </Box>
          </Paper>
        </Grid>

        <Grid item xs={12} md={3}>
          <Paper elevation={3} sx={{ p: 3, display: 'flex', flexDirection: 'column', borderRadius: '12px' }}>
            <Box sx={{ display: 'flex', mb: 2, alignItems: 'center' }}>
              <ProjectIcon style={{ marginInlineEnd: '10px' }} />
              <Typography
                variant="span"
                style={{
                  fontSize: '16px',
                  fontWeight: '600',
                  lineHeight: '24px',
                }}
              >
                {t('General.totalResedentials')}
              </Typography>
            </Box>
            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
              <Typography variant="p" sx={{ fontSize: '27px', fontWeight: '700' }}>
                {dashboardData?.TOTAL_COUNT?.PROJECT_COUNT || ''}
              </Typography>

              {dashboardData?.TOTAL_COUNT?.UNIT_RATE_MEASURE === 'UP' ? (
                <Box
                  sx={{
                    display: 'flex',
                    mt: 1,
                    backgroundColor: '#00ff001d',
                    borderRadius: '17px',
                    p: '4px 4px',
                    width: '40%',
                  }}
                >
                  <TrendingUpIcon fontSize="small" sx={{ color: 'green', marginRight: '4px' }} />
                  <Typography variant="body2" sx={{ color: 'green' }}>
                    {dashboardData?.TOTAL_COUNT?.PROJECT_RATE || ''}
                  </Typography>
                </Box>
              ) : dashboardData?.TOTAL_COUNT?.UNIT_RATE_MEASURE === 'DOWN' ? (
                <Box
                  sx={{
                    display: 'flex',
                    mt: 1,
                    backgroundColor: '#ff00001d',
                    borderRadius: '17px',
                    p: '4px 4px',
                    width: '40%',
                  }}
                >
                  <TrendingDownIcon fontSize="small" sx={{ color: 'red', marginRight: '4px' }} />
                  <Typography variant="body2" sx={{ color: 'red' }}>
                    {dashboardData?.TOTAL_COUNT?.PROJECT_RATE || ''}
                  </Typography>
                </Box>
              ) : (
                <Box
                  sx={{
                    display: 'flex',
                    mt: 1,
                    backgroundColor: '#00ff001d',
                    borderRadius: '17px',
                    p: '4px 4px',
                    width: '40%',
                  }}
                >
                  <TrendingFlatIcon fontSize="small" sx={{ color: 'green', marginRight: '4px' }} />

                  <Typography variant="body2" sx={{ color: 'green' }}>
                    {dashboardData?.TOTAL_COUNT?.PROJECT_RATE || ''}
                  </Typography>
                </Box>
              )}
            </Box>
          </Paper>
        </Grid>
        <Grid item xs={12} md={3}>
          <Paper elevation={3} sx={{ p: 3, display: 'flex', flexDirection: 'column', borderRadius: '12px' }}>
            <Box sx={{ display: 'flex', mb: 2, alignItems: 'center' }}>
              <UnitsIcon style={{ marginInlineEnd: '10px' }} />
              <Typography
                variant="span"
                style={{
                  fontSize: '16px',
                  fontWeight: '600',
                  lineHeight: '24px',
                }}
              >
                {t('General.totalUnits')}
              </Typography>
            </Box>
            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
              <Typography variant="p" sx={{ fontSize: '27px', fontWeight: '700' }}>
                {dashboardData?.TOTAL_COUNT?.UNIT_COUNT || ''}
              </Typography>

              {dashboardData?.TOTAL_COUNT?.UNIT_RATE_MEASURE === 'UP' ? (
                <Box
                  sx={{
                    display: 'flex',
                    mt: 1,
                    backgroundColor: '#00ff001d',
                    borderRadius: '17px',
                    p: '4px 4px',
                    width: '40%',
                  }}
                >
                  <TrendingUpIcon fontSize="small" sx={{ color: 'green', marginRight: '4px' }} />
                  <Typography variant="body2" sx={{ color: 'green' }}>
                    {dashboardData?.TOTAL_COUNT?.UNIT_RATE || ''}
                  </Typography>
                </Box>
              ) : dashboardData?.TOTAL_COUNT?.UNIT_RATE_MEASURE === 'DOWN' ? (
                <Box
                  sx={{
                    display: 'flex',
                    mt: 1,
                    flexDirection: isRtl ? 'row-reverse' : 'row',
                    backgroundColor: '#ff00001d',
                    borderRadius: '17px',
                    p: '4px 4px',
                    width: '40%',
                  }}
                >
                  <TrendingDownIcon
                    fontSize="small"
                    sx={{ color: 'red', marginRight: '4px', transform: isRtl ? 'rotate(-180deg)' : '' }}
                  />
                  <Typography variant="body2" sx={{ color: 'red' }}>
                    {dashboardData?.TOTAL_COUNT?.UNIT_RATE || ''}
                  </Typography>
                </Box>
              ) : (
                <Box
                  sx={{
                    display: 'flex',
                    mt: 1,
                    backgroundColor: '#00ff001d',
                    borderRadius: '17px',
                    p: '4px 4px',
                    width: '40%',
                  }}
                >
                  <TrendingFlatIcon fontSize="small" sx={{ color: 'green', marginRight: '4px' }} />

                  <Typography variant="body2" sx={{ color: 'green' }}>
                    {dashboardData?.TOTAL_COUNT?.UNIT_RATE || ''}
                  </Typography>
                </Box>
              )}
            </Box>
          </Paper>
        </Grid>
        <Grid item xs={12} md={4}>
          <Paper elevation={3} sx={{ p: 3 }}>
            <Typography variant="h6">{t('DashboardPage.products')}</Typography>
            <Stack direction="row">
              <PieChart
                series={[
                  {
                    paddingAngle: 5,
                    innerRadius: 60,
                    outerRadius: 80,
                    data,
                  },
                ]}
                margin={{ right: 5 }}
                width={200}
                height={270}
                legend={{ hidden: true }}
              >
                <PieCenterLabel>
                  {dashboardData?.PROJECT_PLAN_COUNT}
                  {t('DashboardPage.products')}
                </PieCenterLabel>{' '}
              </PieChart>
            </Stack>

            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                width: '62%',
                marginLeft: '3rem',
              }}
            >
              <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%', mb: 1 }}>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <Box sx={{ width: 12, height: 12, borderRadius: '50%', backgroundColor: '#7D3C98', mr: 1 }} />
                  <Typography variant="body2">{t('PLANS.plans')}</Typography>
                </Box>
                <Typography variant="body2" sx={{ ml: 1, fontWeight: 'bold' }}>
                  {dashboardData?.PLAN_TOTAL_COUNT}
                </Typography>
              </Box>
              <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <Box sx={{ width: 12, height: 12, borderRadius: '50%', backgroundColor: '#F39C12', mr: 1 }} />
                  <Typography variant="body2">{t('ManageProject.title')}</Typography>
                </Box>
                <Typography variant="body2" sx={{ ml: 1, fontWeight: 'bold' }}>
                  {dashboardData?.PROJECT_TOTAL_COUNT}
                </Typography>
              </Box>
            </Box>
          </Paper>
        </Grid>
        <Grid item xs={12} md={8}>
          <Paper elevation={3} sx={{ p: 3, height: '100%', width: 'fit-content' }}>
            <Typography variant="h6">{t('DashboardPage.salesRatio')}</Typography>
            <LineChart
              width={701}
              height={320}
              data={dataCharts}
              direction={isRtl ? 'rtl' : 'ltr'}
              margin={{ top: 10, right: isRtl ? 0 : 30, left: isRtl ? 30 : 0, bottom: 0 }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <YAxis direction={'ltr'} />
              <RechartsTooltip content={<CustomTooltip />} />
              <RechartsLegend />
              <Line type="monotone" dataKey="value" stroke="#82ca9d" />
            </LineChart>{' '}
          </Paper>
        </Grid>
        <Grid sx={{ mt: 3 }} md={12}>
          <Container sx={{ pr: 0 }}>
            <Grid container spacing={3}>
              <Grid item xs={12} md={12}>
                <Paper elevation={3} sx={{ p: 3, borderRadius: '12px' }}>
                  <Typography variant="h6" sx={{ fontWeight: 'bold', mb: 2 }}>
                    {t('PLANS.plans')}
                  </Typography>
                  <TableContainer component={Paper}>
                    <Table>
                      <ClientHeadTable headLabel={tableHeadPlans} />

                      <TableBody>
                        {dataTopPlans.map((row, index) => (
                          <TableRow key={index}>
                            <TableCell>
                              <Box display={'flex'} alignItems={'center'} gap={2}>
                                {' '}
                                <img
                                  style={{ width: '45px', height: '45px', objectFit: 'cover', borderRadius: '50%' }}
                                  src={row.primaryPhoto}
                                  alt={''}
                                />
                                <Typography
                                  margin={'0'}
                                  display={'flex'}
                                  alignItems={'center'}
                                  justifyContent={'center'}
                                >
                                  {' '}
                                  {isRtl ? row.nameAr : row.nameEn}
                                </Typography>
                              </Box>
                            </TableCell>

                            <TableCell>
                              <Box display={'flex'} alignItems={'start'} gap={2}>
                                <img
                                  style={{ width: '45px', height: '45px', objectFit: 'cover', borderRadius: '50%' }}
                                  src={row.ownerLogo}
                                  alt={''}
                                />
                                <Typography
                                  margin={'0'}
                                  display={'flex'}
                                  alignItems={'center'}
                                  justifyContent={'center'}
                                >
                                  {isRtl ? row.ownerNameAr : row.ownerNameEn}
                                </Typography>
                              </Box>
                            </TableCell>

                            <TableCell sx={{ textAlign: isRtl ? 'right' : 'left' }}>{row.totalLands}</TableCell>
                            <TableCell sx={{ textAlign: isRtl ? 'right' : 'left' }}>{row.actuallyLands}</TableCell>
                            <TableCell sx={{ textAlign: isRtl ? 'right' : 'left' }}>{row.availableLands}</TableCell>
                            <TableCell sx={{ textAlign: isRtl ? 'right' : 'left' }}>{row.soldLand}</TableCell>
                            <TableCell sx={{ textAlign: isRtl ? 'right' : 'left' }}>{row.reservedLands}</TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Paper>
              </Grid>
              <Grid item xs={12} md={12}>
                <Paper elevation={3} sx={{ p: 3, borderRadius: '12px' }}>
                  <Typography variant="h6" sx={{ fontWeight: 'bold', mb: 2 }}>
                    {t('ManageProject.title')}
                  </Typography>
                  <TableContainer component={Paper}>
                    <Table>
                      <ClientHeadTable headLabel={tableHeadProjects} />
                      <TableBody>
                        {dataTopResidentialProjects?.map((row, index) => (
                          <TableRow key={index}>
                            <TableCell sx={{ textAlign: isRtl ? 'right' : 'left' }}>
                              <Box display={'flex'} alignItems={'center'} gap={2}>
                                {' '}
                                <img
                                  style={{
                                    width: '45px',
                                    height: '45px',
                                    objectFit: 'cover',
                                    borderRadius: '50%',
                                    margin: '0 15px 0px 0px',
                                  }}
                                  src={row.primaryPhoto}
                                  alt={''}
                                />
                                <Typography
                                  margin={'0'}
                                  display={'flex'}
                                  alignItems={'center'}
                                  justifyContent={'center'}
                                >
                                  {' '}
                                  {isRtl ? row.nameAr : row.nameEn}
                                </Typography>
                              </Box>
                            </TableCell>

                            <TableCell sx={{ textAlign: isRtl ? 'right' : 'left' }}>
                              <Box display={'flex'} alignItems={'center'} gap={2}>
                                {' '}
                                <img
                                  style={{
                                    width: '45px',
                                    height: '45px',
                                    objectFit: 'cover',
                                    borderRadius: '50%',
                                    display: 'inline !important',
                                  }}
                                  src={row.ownerLogo}
                                  alt={''}
                                />
                                <Typography
                                  margin={'0'}
                                  display={'flex'}
                                  alignItems={'center'}
                                  justifyContent={'center'}
                                >
                                  {' '}
                                  {isRtl ? row.ownerNameAr : row.ownerNameEn}
                                </Typography>
                              </Box>
                            </TableCell>

                            <TableCell sx={{ textAlign: isRtl ? 'right' : 'left' }}>{row?.totalUnits}</TableCell>
                            <TableCell sx={{ textAlign: isRtl ? 'right' : 'left' }}>{row?.actuallyUnits}</TableCell>
                            <TableCell sx={{ textAlign: isRtl ? 'right' : 'left' }}>{row?.availableUnits}</TableCell>
                            <TableCell sx={{ textAlign: isRtl ? 'right' : 'left' }}>{row?.soldUnit}</TableCell>
                            <TableCell sx={{ textAlign: isRtl ? 'right' : 'left' }}>
                              {row?.reservedUnits || 0}
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Paper>
              </Grid>
            </Grid>
          </Container>
        </Grid>
      </Grid>
    </Container>
  );
};

export default Dashboard;
