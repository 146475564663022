// ReusableModal.js
import React from 'react';
import { Dialog, DialogContent, DialogTitle, Box } from '@mui/material';

const ModalData = ({ isOpen, handleClose, title, content, children, width = 600 }) => (
  <Dialog open={isOpen} onClose={handleClose} PaperProps={{ sx: { width, maxWidth: 'none' } }}>
    <DialogTitle>{title}</DialogTitle>
    <DialogContent>
      {content}
      {children} {/* This is where the additional content will be rendered */}
    </DialogContent>
  </Dialog>
);

export default ModalData;
