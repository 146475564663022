import { Helmet } from 'react-helmet-async';
import { useEffect, useState } from 'react';
// @mui
import { Card, Stack, Container, Typography } from '@mui/material';
import { Link, useParams } from 'react-router-dom';

// mock
import { getClientRequestSummary } from '../network/apis';
import { ReqTechnologiesTable } from '../sections/@dashboard/requestTechnologies';

export default function RequestSummary() {
  const params = useParams();

  const [clientTechnologies, setClientTechnologies] = useState([]);

  useEffect(() => {
    getClientRequestSummary(params.request_id).then((res) => {
      setClientTechnologies(res.data.data);
    });
  }, []);

  return (
    <>
      <Helmet>
        <title> Client Technologies</title>
      </Helmet>

      <Container maxWidth="xl">
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Client Technologies
          </Typography>
        </Stack>

        <Card>
          {clientTechnologies.length > 0 && (
            <>
              <ReqTechnologiesTable list={clientTechnologies} />
            </>
          )}
        </Card>
          <h4>Here's Request Approval Link</h4>
        <Link to={`/dashboard/request-approval/${params.request_id}`}>Link</Link>
      </Container>
    </>
  );
}
