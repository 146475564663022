/* eslint-disable react/prop-types */
import React, { useState } from 'react';
// @mui
import { useNavigate } from 'react-router-dom';
import {
  Table,
  TableRow,
  TableBody,
  TableCell,
  Typography,
  TableContainer,
  TablePagination,
  IconButton,
} from '@mui/material';
import { Box } from '@mui/system';
import { DownloadOutlined } from '@mui/icons-material';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { useTranslation } from 'react-i18next';

// components
import Scrollbar from '../../../components/scrollbar';

import ConfirmationModal from '../../../components/confirmationModal/ConfirmationModal';

// sections
import ClientReqHead from '../../../components/clientHeadTable/ClientHeadTable';

// ----------------------------------------------------------------------

// ----------------------------------------------------------------------

export default function ClientReqTable({ requests, handleChangePage, page, onDownload, updateRequestStatus }) {
  const { t, i18n } = useTranslation();
  const isRtl = i18n.language === 'ar';
  const [rowsPerPage] = useState(8);
  const navigate = useNavigate();

  const [open, setOpen] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  const [requestId, setRequestId] = useState('0');
  const [requestStatus, setRequestStatus] = useState('0');
  const [updatedData, setUpdatedData] = useState([]);

  const [tableData, setTableData] = useState(requests.content);

  const TABLE_HEAD = [
    { id: 'requestId', label: t('reversationRequest.invoice_id'), alignRight: isRtl && true },
    { id: 'requestType', label: t('reversationRequest.type'), alignRight: isRtl && true },
    { id: 'flagNameEn', label: t('reversationRequest.unit_piece_name'), alignRight: isRtl && true },
    { id: 'buyerName', label: t('reversationRequest.customer'), alignRight: isRtl && true },
    { id: 'phoneNumber', label: t('reversationRequest.phone'), alignRight: isRtl && true },
    { id: 'downPayment', label: t('reversationRequest.downpay'), alignRight: isRtl && true },
    { id: 'paymentStatus', label: t('reversationRequest.payment_status'), alignRight: isRtl && true },
    { id: 'paymentBrand', label: t('reversationRequest.payment_brand'), alignRight: isRtl && true },

    { id: 'requestStatus', label: t('reversationRequest.request_status'), alignRight: isRtl && true },
    { id: 'creationDate', label: t('reversationRequest.creation_date'), alignRight: isRtl && true },
    { id: 'expirationDate', label: t('reversationRequest.expiration_date'), alignRight: isRtl && true },
    { id: 'actions', label: t('General.Action'), alignRight: isRtl && true }, // Added actions column
  ];

  const handleInputChange = (event, index, key) => {
    const updatedData = [...tableData];
    updatedData[index] = {
      ...updatedData[index],
      [key]: event.target.value,
    };
    setOpen(true);
    setRequestId(updatedData[index].id);
    setRequestStatus(event.target.value);
    setUpdatedData(updatedData);
  };

  const updateRequest = () => {
    setTableData(updatedData);
    updateRequestStatus(requestId, requestStatus);
  };

  const handleRowClick = (index) => {
    setSelectedRow(index);
  };

  return (
    <>
      <Scrollbar>
        <TableContainer sx={{ minWidth: 1000 }}>
          <Table sx={{ direction: isRtl ? 'rtl' : 'ltr' }}>
            <ClientReqHead style={{ whiteSpace: 'nowrap' }} headLabel={TABLE_HEAD} />
            <TableBody>
              {tableData?.map((row, index) => {
                const {
                  userPhone,
                  requestType,
                  fullNameAr,
                  fullNameEn,
                  id,
                  paymentBrand,
                  invoiceId,
                  creationDate,
                  expireDate,
                  requestStatus,
                  downPayment,
                  paymentStatus,
                  flagNameEn,
                  flagNameAr,
                } = row;

                return (
                  <TableRow
                    hover
                    tabIndex={-1}
                    key={row.id}
                    onClick={() => handleRowClick(index)}
                    style={{ cursor: 'pointer' }}
                  >
                    <TableCell sx={{ textAlign: isRtl ? 'right' : 'left' }} component="th" scope="row">
                      <Typography
                        sx={{ color: 'text.secondary', fontWeight: '400', fontSize: '12px', lineHeight: '20px' }}
                        variant="subtitle2"
                        noWrap
                      >
                        {' '}
                        {invoiceId || t('General.noData')}
                      </Typography>
                    </TableCell>
                    <TableCell sx={{ textAlign: isRtl ? 'right' : 'left' }} component="th" scope="row">
                      <Typography
                        sx={{ color: 'text.secondary', fontWeight: '400', fontSize: '12px', lineHeight: '20px' }}
                        variant="subtitle2"
                        noWrap
                      >
                        {' '}
                        {t(`General.${requestType}`)}
                      </Typography>
                    </TableCell>
                    <TableCell sx={{ textAlign: isRtl ? 'right' : 'left' }} component="th" scope="row">
                      <Typography
                        sx={{ color: 'text.secondary', fontWeight: '400', fontSize: '12px', lineHeight: '20px' }}
                        variant="subtitle2"
                        noWrap
                      >
                        {' '}
                        {isRtl ? flagNameAr || t('General.noData') : flagNameEn || t('General.noData')}
                      </Typography>
                    </TableCell>
                    <TableCell sx={{ textAlign: isRtl ? 'right' : 'left' }} component="th" scope="row">
                      <Typography
                        sx={{ color: 'text.secondary', fontWeight: '400', fontSize: '12px', lineHeight: '20px' }}
                        variant="subtitle2"
                        noWrap
                      >
                        {isRtl ? fullNameAr || t('General.noData') : fullNameEn || t('General.noData')}
                      </Typography>
                    </TableCell>
                    <TableCell sx={{ textAlign: isRtl ? 'right' : 'left' }} component="th" scope="row">
                      <Typography
                        sx={{ color: 'text.secondary', fontWeight: '400', fontSize: '12px', lineHeight: '20px' }}
                        variant="subtitle2"
                        noWrap
                      >
                        {' '}
                        {userPhone || t('General.noData')}
                      </Typography>
                    </TableCell>
                    <TableCell sx={{ textAlign: isRtl ? 'right' : 'left' }} component="th" scope="row">
                      <Typography
                        sx={{ color: 'text.secondary', fontWeight: '400', fontSize: '12px', lineHeight: '20px' }}
                        variant="subtitle2"
                        noWrap
                      >
                        {' '}
                        {downPayment || ''} {t('General.SAR')}
                      </Typography>
                    </TableCell>
                    <TableCell sx={{ textAlign: isRtl ? 'right' : 'left' }} component="th" scope="row">
                      <Typography
                        sx={{ color: 'text.secondary', fontWeight: '400', fontSize: '12px', lineHeight: '20px' }}
                        variant="subtitle2"
                        noWrap
                      >
                        {' '}
                        <Box
                          sx={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            padding: '4px 16px',
                            backgroundColor: '#E7F7EF',
                            borderRadius: '8px',
                            width: '88px',
                          }}
                        >
                          <Typography
                            sx={{ color: '#0CAF60', fontWeight: '700', fontSize: '10px', lineHeight: '16px' }}
                            variant="subtitle2"
                            textAlign={'center'}
                            noWrap
                          >
                            {t(`General.${paymentStatus}`)}
                          </Typography>
                        </Box>
                      </Typography>
                    </TableCell>
                    <TableCell sx={{ textAlign: isRtl ? 'right' : 'left' }} component="th" scope="row">
                      <Typography
                        sx={{ color: 'text.secondary', fontWeight: '400', fontSize: '12px', lineHeight: '20px' }}
                        variant="subtitle2"
                        noWrap
                      >
                        {' '}
                        {paymentBrand || ''}
                      </Typography>
                    </TableCell>
                    <TableCell sx={{ textAlign: isRtl ? 'right' : 'left' }} component="th" scope="row">
                      {requestStatus === 'UNDER_REVIEW' ? (
                        <Select
                          value={requestStatus}
                          disabled={selectedRow !== index}
                          onChange={(event) => handleInputChange(event, index, 'requestStatus')}
                          sx={{ ml: 1, minWidth: '70px' }}
                        >
                          <MenuItem value="UNDER_REVIEW"> {t('reversationRequest.UNDER_REVIEW')}</MenuItem>
                          <MenuItem value="CONFIRMED">{t('reversationRequest.CONFIRMED')}</MenuItem>
                          <MenuItem value="REFUNDED">{t('reversationRequest.REFUNDED')}</MenuItem>
                          <MenuItem value="EXPIRED">{t('reversationRequest.EXPIRED')}</MenuItem>
                          <MenuItem value="CANCELED">{t('reversationRequest.CANCELED')}</MenuItem>
                        </Select>
                      ) : (
                        <>
                          {requestStatus === 'CONFIRMED' ? (
                            <Box
                              sx={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                padding: '4px 16px',
                                backgroundColor: '#E7F7EF',
                                borderRadius: '8px',
                                width: '88px',
                              }}
                            >
                              <Typography
                                sx={{ color: '#0CAF60', fontWeight: '700', fontSize: '10px', lineHeight: '16px' }}
                                variant="subtitle2"
                                textAlign={'center'}
                                noWrap
                              >
                                {t(`reversationRequest.${requestStatus}`)}
                              </Typography>
                            </Box>
                          ) : (
                            <Box
                              sx={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                padding: '4px 16px',
                                backgroundColor: '#FFEDEC',
                                borderRadius: '8px',
                                width: '88px',
                              }}
                            >
                              <Typography
                                sx={{ color: '#E03137', fontWeight: '700', fontSize: '10px', lineHeight: '16px' }}
                                variant="subtitle2"
                                textAlign={'center'}
                                noWrap
                              >
                                {t(`reversationRequest.${requestStatus}`)}
                              </Typography>
                            </Box>
                          )}
                        </>
                      )}
                    </TableCell>{' '}
                    <TableCell sx={{ textAlign: isRtl ? 'right' : 'left' }} component="th" scope="row">
                      <Typography
                        sx={{ color: 'text.secondary', fontWeight: '400', fontSize: '12px', lineHeight: '20px' }}
                        variant="subtitle2"
                        noWrap
                      >
                        {' '}
                        {creationDate || ''}
                      </Typography>
                    </TableCell>
                    <TableCell sx={{ textAlign: isRtl ? 'right' : 'left' }} component="th" scope="row">
                      <Typography
                        sx={{ color: 'text.secondary', fontWeight: '400', fontSize: '12px', lineHeight: '20px' }}
                        variant="subtitle2"
                        noWrap
                      >
                        {' '}
                        {expireDate || ''}
                      </Typography>
                    </TableCell>
                    <TableCell sx={{ textAlign: isRtl ? 'right' : 'left' }}>
                      <IconButton onClick={() => onDownload(id)}>
                        <Box
                          sx={{
                            width: '30px',
                            height: '30px',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            borderRadius: '8px',
                            padding: '10px',
                            backgroundColor: '#FE964A',
                          }}
                        >
                          <DownloadOutlined sx={{ color: '#E9EAEC !important', fontWeight: '300' }} />
                        </Box>
                      </IconButton>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Scrollbar>
      {requests?.totalElements === 0 && (
        <Box textAlign="center">
          <h5>{t('General.noDataFound')}</h5>
        </Box>
      )}

      {requests?.totalPages > 0 && (
        <TablePagination
          rowsPerPageOptions={[8]}
          component="div"
          count={requests.totalElements}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
        />
      )}

      {open && (
        <div>
          <ConfirmationModal
            open={open}
            handelClose={() => {
              setOpen(false);
            }}
            onUpdate={() => {
              updateRequest();
            }}
          />
        </div>
      )}
    </>
  );
}
