/* eslint-disable react/prop-types */
import React, { useState } from "react";

// localiztion

import { useTranslation } from "react-i18next";

// @mui
import { useNavigate } from "react-router-dom";
import {
  Table,
  TableRow,
  TableBody,
  Button,
  TableCell,
  Typography,
  TableContainer,
  TablePagination,
  Box,
} from "@mui/material";
// icons imports

import { ReactComponent as DeleteIcon } from "../../theme/images/deleteTableIcon.svg";
import { ReactComponent as EditIcon } from "../../theme/images/editTableIcon.svg";
import { ReactComponent as ShowIcon } from "../../theme/images/showTableIcon.svg";

// components
import Scrollbar from "../scrollbar/Scrollbar";

// sections
import ClientHeadTable from "../clientHeadTable/ClientHeadTable";

// ----------------------------------------------------------------------

// ----------------------------------------------------------------------

export default function OurServicesTable({
  requests,
  handleChangePage,
  page,
  onDelete,
  hasEditPermission,
  hasDeletePermission,
  setEdit,
}) {
  const { t, i18n } = useTranslation();
  const isRtl = i18n.language === "ar";
  const TABLE_HEAD = [
    {
      id: "ourOffersImage",
      label: t("ourOffers.image"),
      alignRight: isRtl && true,
    },
    {
      id: "ourOffersName",
      label: t("ourOffers.name"),
      alignRight: isRtl && true,
    },
    {
      id: "ourOffersDescription",
      label: t("ourOffers.description"),
      alignRight: isRtl && true,
    },
    {
      id: "ourServiceCreationDate",
      label: t("General.creationDate"),
      alignRight: isRtl && true,
    },

    { id: "action", label: t("General.Action"), alignRight: isRtl && true },
  ];
  const [rowsPerPage] = useState(1);
  const navigate = useNavigate();

  return (
    <>
      <Scrollbar>
        <TableContainer sx={{ minWidth: 800 }}>
          <Table sx={{ direction: isRtl ? "rtl" : "ltr" }}>
            <ClientHeadTable headLabel={TABLE_HEAD} />
            <TableBody>
              {requests.length === 0 && (
                <TableCell component="th" scope="row">
                  <Typography variant="subtitle2" noWrap>
                    No Data
                  </Typography>
                </TableCell>
              )}
              {requests?.map((row) => {
                const {
                  id,
                  nameEn,
                  nameAr,
                  image,
                  descriptionEn,
                  descriptionAr,
                  creationDate,
                } = row;
                const displayCreationDate = creationDate
                  ? new Date(creationDate).toLocaleDateString()
                  : new Date().toLocaleDateString();

                return (
                  <TableRow hover key={id} tabIndex={-1}>
                    <TableCell
                      sx={{ textAlign: isRtl ? "right" : "left" }}
                      component="th"
                      scope="row"
                    >
                      <Typography
                        sx={{
                          color: "text.secondary",
                          fontWeight: "400",
                          fontSize: "12px",
                          lineHeight: "20px",
                        }}
                        variant="subtitle2"
                        noWrap
                      >
                        <img
                          style={{
                            width: "45px",
                            height: "45px",
                            objectFit: "cover",
                            borderRadius: "50%",
                          }}
                          src={image}
                          alt={""}
                        />
                      </Typography>
                    </TableCell>
                    <TableCell
                      sx={{ textAlign: isRtl ? "right" : "left" }}
                      component="th"
                      scope="row"
                    >
                      <Typography
                        sx={{
                          color: "text.secondary",
                          fontWeight: "400",
                          fontSize: "12px",
                          lineHeight: "20px",
                        }}
                        variant="subtitle2"
                        noWrap
                      >
                        {" "}
                        {isRtl ? nameAr : nameEn}
                      </Typography>
                    </TableCell>
                    <TableCell
                      sx={{ textAlign: isRtl ? "right" : "left" }}
                      component="th"
                      scope="row"
                    >
                      <Typography
                        sx={{
                          maxWidth: "300px",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          color: "text.secondary",
                          fontWeight: "400",
                          fontSize: "12px",
                          lineHeight: "20px",
                        }}
                        variant="subtitle2"
                        noWrap
                      >
                        {" "}
                        {isRtl ? descriptionAr : descriptionEn}{" "}
                      </Typography>
                    </TableCell>

                    <TableCell
                      sx={{ textAlign: isRtl ? "right" : "left" }}
                      component="th"
                      scope="row"
                    >
                      <Typography
                        sx={{
                          color: "text.secondary",
                          fontWeight: "400",
                          fontSize: "12px",
                          lineHeight: "20px",
                        }}
                        variant="subtitle2"
                        noWrap
                      >
                        {" "}
                        {isRtl ? displayCreationDate : displayCreationDate}{" "}
                      </Typography>
                    </TableCell>
                    <TableCell sx={{ textAlign: isRtl ? "right" : "left" }}>
                      <Button
                        onClick={() => {
                          setEdit((prev) => ({
                            ...prev,
                            id,
                            open: true,
                            viewMode: true,
                          }));
                        }}
                      >
                        <ShowIcon />
                      </Button>
                      {hasEditPermission && (
                        <Button
                          onClick={() => {
                            setEdit((prev) => ({
                              ...prev,
                              id,
                              open: true,
                              viewMode: false,
                            }));
                          }}
                        >
                          <EditIcon />
                        </Button>
                      )}
                      {hasDeletePermission && (
                        <Button onClick={() => onDelete(id)}>
                          <DeleteIcon />
                        </Button>
                      )}
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Scrollbar>
      {requests?.totalElements === 0 && (
        <Box textAlign="center">
          <h5>No data found</h5>
        </Box>
      )}

      {requests?.totalPages > 0 && (
        <TablePagination
          rowsPerPageOptions={[8]}
          component="div"
          count={requests.totalPages}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
        />
      )}
    </>
  );
}
