// src/components/dashboardButton/DashboardButton.js

import React from 'react';
import { Button } from '@mui/material';
import Box from '@mui/material/Box';
import { NavLink } from 'react-router-dom';

import { ReactComponent as DashboardIcon } from '../../theme/images/bashboardIcon.svg'; // Import SVG as React component

const DashboardButton = () => {
  return (
    <Box display="flex" justifyContent="center" alignItems="center" m={2}>
      <Button
        variant="contained"
        component={NavLink}
        to="/dashboard"
        sx={{
          backgroundColor: '#0CAF60',
          color: 'white',
          width: '200px',
          height: '50px',
          textTransform: 'none',
          display: 'flex',
          borderRadius: '10px',
          justifyContent: 'space-between',
          flexDirection: 'row-reverse',
          padding: '0 16px',
          fontSize: '16px !important',
          fontWeight: '600 !important',
          '&:hover': {
            backgroundColor: '#00a743',
          },
        }}
        endIcon={<DashboardIcon />}
      >
        Dashboard
      </Button>
    </Box>
  );
};

export default DashboardButton;
