import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
  Modal,
  Box,
  Typography,
  Button,
  Select,
  MenuItem,
  FormControl,
  DialogActions,
} from '@mui/material';
import { ReactComponent as WarningIcon } from '../../theme/images/warning.svg';

const ModalSection = ({
  open,
  onClose,
  title,
  description,
  selectLabel,
  selectOptions,
  onSelectChange,
  hasAssignPermission,
  hasFinishPermission,
  buttons,
  showWarningIcon,
  selectedValue,
  setSelectedValue,
  requestId,
  isFinishModalOpen,
}) => {
  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 500,
    bgcolor: 'background.paper',
    borderRadius: '16px',
    boxShadow: 24,
    p: 4,
  };
  const { t, i18n } = useTranslation();
  const isRtl = i18n.language === 'ar';
  return (
    <Modal open={open} onClose={onClose} aria-labelledby="modal-title" aria-describedby="modal-description">
      <Box sx={style}>
        {showWarningIcon && (
          <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
            <WarningIcon fontSize="large" sx={{ color: 'orange' }} />
          </Box>
        )}
        <Typography
          sx={{ textAlign: 'center', fontSize: showWarningIcon ? '14px' : '24px!important', mt: 2 }}
          id="modal-title"
          variant="h4"
          component="h4"
        >
          {title}
        </Typography>
        <Typography id="modal-description" sx={{ mt: 4, textAlign: 'center', color: '#687588' }}>
          {description}
        </Typography>
        {!showWarningIcon && (
          <FormControl fullWidth sx={{ mt: 4 }}>
            <p style={{ marginBottom: '5px', fontSize: '14px', ontWeight: '600' }}>{selectLabel}</p>
            <Select
              value={selectedValue}
              onChange={onSelectChange}
              displayEmpty
              renderValue={(selected) => {
                if (selected === '') {
                  return <span style={{ color: '#9e9e9e' }}>{t('General.select')}</span>;
                }
                const selectedOption = selectOptions.find((option) => option.userId === selected);
                return selectedOption
                  ? isRtl
                    ? `${selectedOption.firstNameAr} ${selectedOption.lastNameAr}`
                    : `${selectedOption.firstNameEn} ${selectedOption.lastNameEn}`
                  : selected;
              }}
            >
              {selectOptions.map((option, index) => (
                <MenuItem key={index} value={option.userId}>
                  {isRtl ? option.firstNameAr : option.firstNameEn}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        )}

        <DialogActions sx={{ mt: '20px', justifyContent: 'center', display: 'flex', gap: '20px' }}>
          {buttons.map((button, index) => (
            <Button
              key={index}
              sx={button.sx || {}}
              variant={button.variant}
              color={button.color}
              onClick={button.onClick}
            >
              {button.label}
            </Button>
          ))}
        </DialogActions>
      </Box>
    </Modal>
  );
};

export default ModalSection;
