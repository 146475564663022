/* eslint-disable react/prop-types */
import { useState } from 'react';
// @mui
import { useNavigate } from 'react-router-dom';
import {
  Paper,
  Table,
  TableRow,
  TableBody,
  Button,
  TableCell,
  Typography,
  TableContainer,
  Pagination,
} from '@mui/material';
import { Box } from '@mui/system';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

// icons
import CycloneIcon from '@mui/icons-material/Cyclone';

import { ReactComponent as EditIcon } from '../../../theme/images/editTableIcon.svg';
import { ReactComponent as ShowIcon } from '../../../theme/images/showTableIcon.svg';

// components
import Scrollbar from '../../../components/scrollbar';

// sections
import ClientReqHead from '../../../components/clientHeadTable/ClientHeadTable';
import { cloneLand } from '../../../network/apis';

// ----------------------------------------------------------------------

// ----------------------------------------------------------------------

export default function ClientReqTable({
  requests,
  handleChangePage,
  page,
  onDelete,
  hasEditPermission,
  hasAddPermission,
}) {
  const [rowsPerPage] = useState(1);
  const { t, i18n } = useTranslation();
  const isRtl = i18n.language === 'ar';
  const navigate = useNavigate();
  const handleClone = async (id) => {
    const response = await cloneLand(id);
    if (response?.data?.STATUS === 'SUCCESS') {
      navigate(`/dashboard/land/edit/${response?.data?.ID}`, { state: { isClone: true } });
    } else {
      toast.error(response?.data?.MESSAGE);
    }
  };
  const TABLE_HEAD = [
    { id: 'NAME_EN', label: t('General.name'), alignRight: isRtl && true },
    { id: 'planName', label: t('LANDS.planName'), alignRight: isRtl && true },
    { id: 'land_type', label: t('General.type'), alignRight: isRtl && true },
    { id: 'purpose', label: t('General.purpose'), alignRight: isRtl && true },
    { id: 'reservation_status', label: t('General.Status'), alignRight: isRtl && true },
    { id: 'creation_date', label: t('General.creationDate'), alignRight: isRtl && true },
    { id: 'action', label: t('General.Action'), alignRight: isRtl && true },
  ];

  return (
    <>
      <Scrollbar>
        <TableContainer component={Paper} sx={{ minWidth: 800 }}>
          <Table sx={{ direction: isRtl ? 'rtl' : 'ltr' }}>
            <ClientReqHead headLabel={TABLE_HEAD} />
            <TableBody>
              {requests?.content?.map((row) => {
                const { id, nameEn, nameAr, CREATION_DATE, plan, pieceStatus, reservationStatus, type, purpose } = row;

                const displayDate = CREATION_DATE
                  ? new Date(CREATION_DATE).toLocaleDateString()
                  : new Date().toLocaleDateString();

                return (
                  <TableRow hover key={id} tabIndex={-1}>
                    <TableCell sx={{ textAlign: isRtl ? 'right' : 'left' }} component="th" scope="row">
                      <Typography
                        sx={{ color: 'text.secondary', fontWeight: '400', fontSize: '12px', lineHeight: '20px' }}
                        variant="subtitle2"
                        noWrap
                      >
                        {' '}
                        {isRtl ? nameAr : nameEn}
                      </Typography>
                    </TableCell>

                    <TableCell sx={{ textAlign: isRtl ? 'right' : 'left' }} component="th" scope="row">
                      <Typography
                        sx={{ color: 'text.secondary', fontWeight: '400', fontSize: '12px', lineHeight: '20px' }}
                        variant="subtitle2"
                        noWrap
                      >
                        {' '}
                        {isRtl ? plan.nameAr : plan.nameEn || ''}
                      </Typography>
                    </TableCell>

                    <TableCell sx={{ textAlign: isRtl ? 'right' : 'left' }} component="th" scope="row">
                      <Typography
                        sx={{ color: 'text.secondary', fontWeight: '400', fontSize: '12px', lineHeight: '20px' }}
                        variant="subtitle2"
                        noWrap
                      >
                        {type === 'RESIDENTIAL' ? t('Units.residential') : t('Units.commercial') || ''}
                      </Typography>
                    </TableCell>
                    <TableCell sx={{ textAlign: isRtl ? 'right' : 'left' }} component="th" scope="row">
                      <Typography
                        sx={{ color: 'text.secondary', fontWeight: '400', fontSize: '12px', lineHeight: '20px' }}
                        variant="subtitle2"
                        noWrap
                      >
                        {' '}
                        {purpose === 'RESIDENTIAL' ? t('Units.residential') : t('Units.commercial') || ''}
                      </Typography>
                    </TableCell>
                    <TableCell sx={{ textAlign: isRtl ? 'right' : 'left' }} component="th" scope="row">
                      {reservationStatus === 'FREE' ? (
                        <Box
                          sx={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            padding: '4px 16px',
                            backgroundColor: '#E7F7EF',
                            borderRadius: '8px',
                            width: '88px',
                          }}
                        >
                          <Typography
                            sx={{ color: '#0CAF60', fontWeight: '700', fontSize: '10px', lineHeight: '16px' }}
                            variant="subtitle2"
                            textAlign={'center'}
                            noWrap
                          >
                            {t('Units.available')}
                          </Typography>
                        </Box>
                      ) : reservationStatus === 'RESERVED' ? (
                        <Box
                          sx={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            padding: '4px 16px',
                            backgroundColor: '#f3c5105c',
                            borderRadius: '8px',
                            width: '88px',
                          }}
                        >
                          <Typography
                            sx={{ color: '#907200', fontWeight: '700', fontSize: '10px', lineHeight: '16px' }}
                            variant="subtitle2"
                            textAlign={'center'}
                            noWrap
                          >
                            {t('Units.reserved')}
                          </Typography>
                        </Box>
                      ) : (
                        <Box
                          sx={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            padding: '4px 16px',
                            backgroundColor: '#FFEDEC',
                            borderRadius: '8px',
                            width: '88px',
                          }}
                        >
                          <Typography
                            sx={{ color: '#E03137', fontWeight: '700', fontSize: '10px', lineHeight: '16px' }}
                            variant="subtitle2"
                            textAlign={'center'}
                            noWrap
                          >
                            {t('Units.sold')}
                          </Typography>
                        </Box>
                      )}
                    </TableCell>
                    <TableCell sx={{ textAlign: isRtl ? 'right' : 'left' }} component="th" scope="row">
                      <Typography
                        sx={{ color: 'text.secondary', fontWeight: '400', fontSize: '12px', lineHeight: '20px' }}
                        variant="subtitle2"
                        noWrap
                      >
                        {' '}
                        {displayDate || ''}
                      </Typography>
                    </TableCell>

                    {hasEditPermission && (
                      <TableCell align="left">
                        <Box sx={{ display: 'flex', alignItems: 'center', gap: '2px' }}>
                          <Button
                            onClick={() =>
                              navigate(`/dashboard/land/view/${id}`, {
                                state: { editData: row },
                              })
                            }
                          >
                            <ShowIcon />
                          </Button>
                          <Button
                            onClick={() =>
                              navigate(id ? `/dashboard/land/edit/${id}` : '/dashboard/land/add', {
                                state: { editData: row },
                              })
                            }
                          >
                            <EditIcon />
                          </Button>
                          {(hasAddPermission || hasEditPermission) && (
                            <Button onClick={() => handleClone(id)}>
                              <CycloneIcon />
                            </Button>
                          )}
                        </Box>
                      </TableCell>
                    )}
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Scrollbar>
      {requests?.totalElements === 0 && (
        <Box textAlign="center">
          <h5>{t('General.noDataFound')}</h5>
        </Box>
      )}

      {requests?.totalPages > 0 && (
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            padding: '10px',
            marginTop: '5rem',
          }}
        >
          <Pagination count={requests.totalPages} page={page + 1} onChange={handleChangePage} color="primary" />
          <Box sx={{ display: 'block' }}>
            {t('General.showing')} {page * rowsPerPage + 1} {t('General.to')}{' '}
            {Math.min((page + 1) * rowsPerPage, requests.totalElements)} {t('General.of')} {requests.totalElements}{' '}
            {t('General.entries')}
          </Box>
        </Box>
      )}
    </>
  );
}
