import React from 'react';
import { useTranslation } from 'react-i18next';
import BasicTabs from '../components/tab/tab';
import AddFinancialData from './AddFinancialData';
import City from './Cities';
import Regions from './Regions';
import Facility from './Facilities';
import Services from './Services';
import PostSaleServices from './PostSaleServices';
import Interfaces from './Interfaces';
import DocumentType from './DocumentsType';
import Layout from '../components/Layout/Layout';

const ManageApplicationt = () => {
  const { t } = useTranslation();

  const tabs = [
    { label: t('manageApplication.financialData'), content: <AddFinancialData /> },
    { label: t('manageApplication.regions'), content: <Regions /> },
    { label: t('manageApplication.cities'), content: <City /> },
    { label: t('manageApplication.facilities'), content: <Facility /> },
    { label: t('manageApplication.services'), content: <Services /> },
    { label: t('manageApplication.post-SaleService'), content: <PostSaleServices /> },
    { label: t('manageApplication.interface'), content: <Interfaces /> },
    { label: t('manageApplication.documentType'), content: <DocumentType /> },
  ];

  return (
    <Layout>
      <h3> {t('manageApplication.manageApplicationData')} </h3>
      <BasicTabs tabs={tabs} />
    </Layout>
  );
};

export default ManageApplicationt;
