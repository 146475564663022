/* eslint-disable react/prop-types */
import React, { useState } from 'react';

// localiztion

import { useTranslation } from 'react-i18next';

// @mui
import { useNavigate } from 'react-router-dom';
import {
  Table,
  TableRow,
  TableBody,
  Button,
  TableCell,
  Typography,
  TableContainer,
  TablePagination,
  Box,
} from '@mui/material';
// icons imports

import { ReactComponent as DeleteIcon } from '../../theme/images/deleteTableIcon.svg';
import { ReactComponent as ShowIcon } from '../../theme/images/showTableIcon.svg';

// components
import Scrollbar from '../scrollbar/Scrollbar';

// sections
import ClientHeadTable from '../clientHeadTable/ClientHeadTable';

// ----------------------------------------------------------------------

// ----------------------------------------------------------------------

export default function OurServicesTable({ requests, handleChangePage, page, hasEditPermission, setEdit }) {
  const { t, i18n } = useTranslation();
  const isRtl = i18n.language === 'ar';
  const TABLE_HEAD = [
    {
      id: 'technicalSupportName',
      label: t('technicalSupport.full_name'),
      alignRight: isRtl && true,
    },
    {
      id: 'technicalSupportEmail',
      label: t('technicalSupport.email'),
      alignRight: isRtl && true,
    },
    {
      id: 'technicalSupportPhone',
      label: t('technicalSupport.phone'),
      alignRight: isRtl && true,
    },
    {
      id: 'technicalSupportMessage',
      label: t('technicalSupport.message'),
      alignRight: isRtl && true,
    },
    {
      id: 'technicalSupportType',
      label: t('technicalSupport.type'),
      alignRight: isRtl && true,
    },
    {
      id: 'technicalSupportStatus',
      label: t('General.status'),
      alignRight: isRtl && true,
    },
    {
      id: 'ourServiceCreationDate',
      label: t('General.creationDate'),
      alignRight: isRtl && true,
    },

    { id: 'action', label: t('General.Action'), alignRight: isRtl && true },
  ];
  const [rowsPerPage] = useState(1);
  function type(id) {
    switch (id) {
      case 1:
        return t('technicalSupport.technical_support');
      case 2:
        return t('technicalSupport.complain');
      default:
        return t('technicalSupport.others');
    }
  }
  return (
    <>
      <Scrollbar>
        <TableContainer sx={{ minWidth: 800 }}>
          <Table sx={{ direction: isRtl ? 'rtl' : 'ltr' }}>
            <ClientHeadTable headLabel={TABLE_HEAD} />
            <TableBody>
              {requests.length === 0 && (
                <TableCell component="th" scope="row">
                  <Typography variant="subtitle2" noWrap>
                    No Data
                  </Typography>
                </TableCell>
              )}
              {requests?.map((row) => {
                const { id, name, email, phone, message, categoryId, creationDate, status } = row;
                const displayCreationDate = creationDate
                  ? new Date(creationDate).toLocaleDateString()
                  : new Date().toLocaleDateString();

                return (
                  <TableRow hover key={id} tabIndex={-1}>
                    <TableCell sx={{ textAlign: isRtl ? 'right' : 'left' }} component="th" scope="row">
                      <Typography
                        sx={{
                          color: 'text.secondary',
                          fontWeight: '400',
                          fontSize: '12px',
                          lineHeight: '20px',
                        }}
                        variant="subtitle2"
                        noWrap
                      >
                        {name}
                      </Typography>
                    </TableCell>{' '}
                    <TableCell sx={{ textAlign: isRtl ? 'right' : 'left' }} component="th" scope="row">
                      <Typography
                        sx={{
                          color: 'text.secondary',
                          fontWeight: '400',
                          fontSize: '12px',
                          lineHeight: '20px',
                        }}
                        variant="subtitle2"
                        noWrap
                      >
                        {email}
                      </Typography>
                    </TableCell>
                    <TableCell sx={{ textAlign: isRtl ? 'right' : 'left' }} component="th" scope="row">
                      <Typography
                        sx={{
                          color: 'text.secondary',
                          fontWeight: '400',
                          fontSize: '12px',
                          lineHeight: '20px',
                        }}
                        variant="subtitle2"
                        noWrap
                      >
                        {phone}
                      </Typography>
                    </TableCell>
                    <TableCell sx={{ textAlign: isRtl ? 'right' : 'left' }} component="th" scope="row">
                      <Typography
                        sx={{
                          color: 'text.secondary',
                          fontWeight: '400',
                          fontSize: '12px',
                          lineHeight: '20px',
                        }}
                        variant="subtitle2"
                        noWrap
                      >
                        {message}{' '}
                      </Typography>
                    </TableCell>{' '}
                    <TableCell sx={{ textAlign: isRtl ? 'right' : 'left' }} component="th" scope="row">
                      <Typography
                        sx={{
                          color: 'text.secondary',
                          fontWeight: '400',
                          fontSize: '12px',
                          lineHeight: '20px',
                        }}
                        variant="subtitle2"
                        noWrap
                      >
                        {type(categoryId)}
                      </Typography>
                    </TableCell>
                    <TableCell component="th" scope="row" sx={{ textAlign: isRtl ? 'right' : 'left' }}>
                      {parseInt(status, 10) === 1 ? (
                        <Box
                          sx={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            padding: '4px 16px',
                            backgroundColor: '#E7F7EF',
                            borderRadius: '8px',
                            width: '88px',
                          }}
                        >
                          <Typography
                            sx={{ color: '#0CAF60', fontWeight: '700', fontSize: '10px', lineHeight: '16px' }}
                            variant="subtitle2"
                            textAlign={'center'}
                            noWrap
                          >
                            {t('technicalSupport.replied')}
                          </Typography>
                        </Box>
                      ) : (
                        <Box
                          sx={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            padding: '4px 16px',
                            backgroundColor: '#FFEDEC',
                            borderRadius: '8px',
                            width: '88px',
                          }}
                        >
                          <Typography
                            sx={{ color: '#E03137', fontWeight: '700', fontSize: '10px', lineHeight: '16px' }}
                            variant="subtitle2"
                            textAlign={'center'}
                            noWrap
                          >
                            {t('technicalSupport.pending')}
                          </Typography>
                        </Box>
                      )}
                    </TableCell>
                    <TableCell sx={{ textAlign: isRtl ? 'right' : 'left' }} component="th" scope="row">
                      <Typography
                        sx={{
                          color: 'text.secondary',
                          fontWeight: '400',
                          fontSize: '12px',
                          lineHeight: '20px',
                        }}
                        variant="subtitle2"
                        noWrap
                      >
                        {' '}
                        {isRtl ? displayCreationDate : displayCreationDate}{' '}
                      </Typography>
                    </TableCell>
                    <TableCell sx={{ textAlign: isRtl ? 'right' : 'left' }}>
                      <Button
                        onClick={() => {
                          setEdit((prev) => ({
                            ...prev,
                            id,
                            open: true,
                          }));
                        }}
                      >
                        <ShowIcon />
                      </Button>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Scrollbar>
      {requests?.totalElements === 0 && (
        <Box textAlign="center">
          <h5>No data found</h5>
        </Box>
      )}

      {requests?.totalPages > 0 && (
        <TablePagination
          rowsPerPageOptions={[8]}
          component="div"
          count={requests.totalPages}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
        />
      )}
    </>
  );
}
