import { Navigate } from "react-router-dom";

export const ProtectedRoute = ({ children }) => {
  const userToken =
    localStorage.getItem("userToken") || sessionStorage.getItem("userToken");
  if (!userToken || userToken === "undefined" || !JSON.parse(userToken)) {
    return <Navigate to="/login" replace />;
  }

  return children;
};
