import React from 'react';
import styles from './styles.module.css';
import { useTheme } from '../../theme/index';

const defaultOptions = {
  invertedIconLogic: false,
};

const ReactThemeToggleButton = ({ invertedIconLogic = defaultOptions.invertedIconLogic }) => {
  const { mode, toggleTheme } = useTheme();
  const isDark = mode !== 'dark';

  return (
    // eslint-disable-next-line jsx-a11y/label-has-associated-control
    <label
      className={`${styles.container} ${isDark ? styles.IsDark : styles.IsLight}`}
      title={isDark ? 'Activate light mode' : 'Activate dark mode'}
      aria-label={isDark ? 'Activate light mode' : 'Activate dark mode'}
    >
      <input
        type="checkbox"
        defaultChecked={invertedIconLogic ? !isDark : isDark}
        onChange={() => {
          toggleTheme();
        }}
      />
      <div />
    </label>
  );
};

export default ReactThemeToggleButton;
