import React, { createContext, useContext, useEffect, useState } from 'react';
import { useLocation, useParams, useNavigate } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { nextStep, prevStep } from '../Store/features/Units/unitsSlice';
import { addUnit, deleteAttachments, deletePictures, deleteSketch, editUnit, getUnitsDetials } from '../network/apis';
import LoadingOverlay from '../components/loading-overlay/LoadingOverlay';

const FormContext = createContext();

export const useUnitFormContext = () => useContext(FormContext);

export const FormProvider = ({ children }) => {
  const dispatch = useDispatch();
  const { id } = useParams();

  const navigate = useNavigate();
  const location = useLocation();
  const isClone = location.state?.isClone;
  const fromPage = location.state?.from;
  const [detailsData, setDetails] = useState([]);
  const [uploadedFilesUploaded, setUploadedFilesUploaded] = useState({
    unitSketch: [],
    primaryPhoto: [],
    virtualTour: [],
    attachments: [],
    unitImages: [],
  });
  const [refetch, setRefetch] = useState(false);
  const fetchUnitDetails = async () => {
    try {
      const { data } = await getUnitsDetials(id);
      setDetails(data);
    } catch (error) {
      console.error('Failed to fetch unit details:', error);
      toast.error('Failed to fetch unit details');
    } finally {
      setSubmitLoading(false);
    }
  };

  useEffect(() => {
    if (id && fromPage !== 'edit-project-to-add-unit') {
      setSubmitLoading(true);
      fetchUnitDetails();
    } else {
      setSubmitLoading(false);
    }
  }, [id, refetch]);

  const handleDelete = async (fileId, fieldName) => {
    let response;
    if (fieldName === 'attachment') {
      response = await deleteAttachments(fileId);
    } else if (fieldName === 'sketch') {
      response = await deleteSketch(fileId);
    } else {
      response = await deletePictures(fileId);
    }
    // Call the deletePictures API

    if (response?.status === '1') {
      toast.success('File deleted successfully!');
    } else {
      toast.error('Failed to delete the file!');
    }
  };

  const currentPath = location.pathname;

  const [viewMode, setIsViewMode] = useState(false);

  useEffect(() => {
    if (currentPath.includes('view')) {
      setIsViewMode(true);
    } else {
      setIsViewMode(false); // Ensure to reset the view mode if path does not include 'view'
    }
  }, [currentPath]);

  const [loading_submit, setSubmitLoading] = useState(true);
  const currentStep = useSelector((state) => state.form.currentStep);

  const [setCurrentStep] = useState(currentStep);
  const formDetails = useSelector((state) => state.unitform.formData);
  const isEditMode = detailsData && Object.keys(detailsData).length > 0;
  const { requestId, userId, managerId, isManager } = location.state || '';

  // Add Item FunctionCopy code
  function addItem(fieldName, items, acceptMultiple = true) {
    // Initialize the field if it doesn't exist
    if (!uploadedFilesUploaded[fieldName]) {
      uploadedFilesUploaded[fieldName] = [];
    }

    if (acceptMultiple) {
      // If multiple items are allowed, add items if they don't exist
      items.forEach((item) => {
        if (!uploadedFilesUploaded[fieldName].includes(item)) {
          uploadedFilesUploaded[fieldName].push(item);
        }
      });
    } else {
      // If multiple items are not allowed, replace the existing item
      uploadedFilesUploaded[fieldName] = [items[0]];
    }

    return uploadedFilesUploaded;
  }

  // Delete Item Function
  function deleteItem(fieldName, item) {
    if (uploadedFilesUploaded[fieldName]) {
      setUploadedFilesUploaded((prevUploadedFilesUploaded) => {
        const newUploadedFilesUploaded = { ...prevUploadedFilesUploaded };
        newUploadedFilesUploaded[fieldName] = newUploadedFilesUploaded[fieldName].filter((i) => i?.id !== item);
        return newUploadedFilesUploaded;
      });
    }
    return uploadedFilesUploaded;
  }

  const handleSubmit = (event) => {
    const addedUnit = {
      nameEn: formDetails?.nameEn,
      nameAr: formDetails?.nameAr,
      type: formDetails?.type,
      area: formDetails?.area,
      facade: formDetails?.facade || '',
      northernBoardEn: formDetails?.northernBoardEn || '',
      northernBoardAr: formDetails?.northernBoardAr || '',
      southernBoardEn: formDetails?.southernBoardEn || '',
      southernBoardAr: formDetails?.southernBoardAr || '',
      westernBoardEn: formDetails?.westernBoardEn || '',
      westernBoardAr: formDetails?.westernBoardAr || '',
      easternBoardEn: formDetails?.easternBoardEn || '',
      easternBoardAr: formDetails?.easternBoardAr || '',
      purpose: formDetails?.purpose,
      states: formDetails?.states,
      piecePrice: formDetails?.piecePrice,
      realEstateTax: formDetails?.realEstateTax,
      commissionFees: formDetails?.commissionFees,
      commissionFeesVat: formDetails?.commissionFeesVat,
      ownershipFees: formDetails?.ownershipFees,
      meterPrice: formDetails?.meterPrice,
      totalPrice: formDetails?.totalPrice,
      whatsappNumber: formDetails?.whatsappNumber,
      email: formDetails?.email,
      mobile: formDetails?.mobile,
      pieceDataStatus: formDetails?.pieceDataStatus,
      pieceStatus: formDetails?.pieceStatus,
      reservationStatus: formDetails?.reservationStatus,
      blockNumber: formDetails?.blockNumber,
      projectId: formDetails?.projectDetails?.id,
      totalPriceNoVat: formDetails?.totalPriceNoVat,
      levelNo: formDetails?.levelNo,
      buildingId: formDetails?.buildingId,
      roomDetails: formDetails?.roomDetails,
      virtualType: formDetails.virtualType ? 'IMAGE' : 'LINK',
      virtualLink: formDetails?.virtualLink || '',
      unitSketch: uploadedFilesUploaded?.unitSketch?.map((item) => item.id),
      primaryPhoto: uploadedFilesUploaded?.primaryPhoto?.map((item) => item.id),
      virtualTour: uploadedFilesUploaded?.virtualTour?.map((item) => item.id),
      attachments: uploadedFilesUploaded?.attachments?.map((item) => item.id),
      pictures: uploadedFilesUploaded?.unitImages?.map((item) => item.id),
      isClone: isClone ? 1 : 0,
    };
    setSubmitLoading(true);

    if (isEditMode) {
      editUnit({ ...addedUnit, id, requestId: requestId || '' })
        .then((response) => {
          setSubmitLoading(false);
          if (response.data.STATUS === 'SUCCESS') {
            toast.success(
              fromPage === 'review-products' && userId === managerId && isManager === '1'
                ? 'The Request successfully approved'
                : 'The unit is successfully updated'
            );

            if (fromPage === 'review-products') {
              navigate('/dashboard/review-products');
            } else {
              navigate(`/dashboard/units`);
            }
          } else if (response.data.STATUS === 'FAILED') {
            toast.error(response.data.MESSAGE);
          }
        })
        .catch((error) => {
          setSubmitLoading(false);
          toast.error(error.response.data.message);
        });
    } else {
      addUnit(addedUnit)
        .then((response) => {
          setSubmitLoading(false);
          if (response.data.STATUS === 'SUCCESS') {
            toast.success('The Unit is successfully added');
            if (fromPage === 'review-products') {
              navigate('/dashboard/review-products');
            } else {
              navigate(id ? `/dashboard/project/edit/${id}` : `/dashboard/units`, {
                state: id ? { from: 'add-unit-with-project', projectId: id } : { from: 'add-unit-without-project' },
              });
            }
          } else if (response.data.STATUS === 'FAILED') {
            toast.error(response.data.MESSAGE);
          }
        })
        .catch((error) => {
          setSubmitLoading(false);
          if (error?.response) toast.error(error?.response?.data?.message);
          else {
            toast.error('Please check your network');
          }
        });
    }
  };

  const handleNext = () => {
    dispatch(nextStep());
  };

  const handlePrev = () => {
    dispatch(prevStep());
  };
  return (
    <FormContext.Provider
      value={{
        formDetails,
        handleDelete,
        handleSubmit,

        handleNext,
        handlePrev,
        addItem,
        deleteItem,
        currentStep,
        setCurrentStep,
        isEditMode,
        id,
        loading_submit,
        viewMode,
        userId,
        managerId,
        isManager,
        requestId,
        setDetails,
        detailsData,
        setSubmitLoading,
        refetch,
        setRefetch,
        uploadedFilesUploaded,
      }}
    >
      {loading_submit ? <p>{<LoadingOverlay />}</p> : children}
    </FormContext.Provider>
  );
};
