// ForgetPasswordDialog.js
import React from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  Container,
  Box,
  Typography,
} from '@mui/material';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import { forgetPassword } from '../../../network/apis';
import MasakinLogo from '../../../theme/images/logo.jpeg';

const ForgetPasswordSchema = Yup.object().shape({
  email: Yup.string().email('Invalid email').required('Required'),
});

const ForgetPasswordDialog = ({ open, setOpen }) => {
  const handleClose = () => {
    setOpen(false);
  };

  const handleSubmit = async (values, { setSubmitting }) => {
    try {
      const response = await forgetPassword(values.email);
      const { MESSAGE, STATUS } = response.data;
      if (STATUS === 'FAILED') {
        toast.error(MESSAGE);
      } else {
        toast.success('The invitation has been sent to your email');
        window.location.href = '/login';
      }
      setSubmitting(false);
    } catch (error) {
      toast.error('Error sending email. Please try again.');
      setSubmitting(false);
    }
  };
  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogContent>
        <Container maxWidth="sm">
          <Box sx={{ mt: 5, textAlign: 'center' }}>
            {/* <img style={{ width: '50px', margin: 'auto' }} src={MasakinLogo} alt="Logo" /> */}
            <Typography sx={{ mt: 3 }} variant="h6" gutterBottom>
              Reset your password
            </Typography>
            <Typography sx={{ mt: 3 }} variant="body1" gutterBottom>
              Enter your email address and we will send you password reset instructions.
            </Typography>
            <Formik initialValues={{ email: '' }} validationSchema={ForgetPasswordSchema} onSubmit={handleSubmit}>
              {({ isSubmitting }) => (
                <Form>
                  <Field
                    name="email"
                    as={TextField}
                    label="Registered Email"
                    fullWidth
                    margin="normal"
                    variant="outlined"
                    helperText="Input your registered email"
                  />
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    fullWidth
                    sx={{ mt: 3 }}
                    disabled={isSubmitting}
                  >
                    Send
                  </Button>
                  <Button href="/login" variant="outlined" fullWidth sx={{ mt: 2 }}>
                    Back to Login
                  </Button>
                </Form>
              )}
            </Formik>
          </Box>
        </Container>
      </DialogContent>
    </Dialog>
  );
};

export default ForgetPasswordDialog;
