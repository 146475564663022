import React from 'react';
import {
    FormProvider,
} from '../context/unitFormContext';
import ManageUnits from './ManageUnits';



const ManageUnitsWithProvider = () => (
    <FormProvider>
        <ManageUnits />
    </FormProvider>
);

export default ManageUnitsWithProvider;