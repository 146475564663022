import PropTypes from "prop-types";
// @mui
import {
  Box,
  Checkbox,
  TableRow,
  TableCell,
  TableHead,
  TableSortLabel,
  Typography,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";

// ----------------------------------------------------------------------

const visuallyHidden = {
  border: 0,
  margin: -1,
  padding: 0,
  width: "1px",
  height: "1px",
  overflow: "hidden",
  position: "absolute",
  whiteSpace: "nowrap",
  clip: "rect(0 0 0 0)",
};

ClientHeadTable.propTypes = {
  order: PropTypes.oneOf(["asc", "desc"]),
  orderBy: PropTypes.string,

  headLabel: PropTypes.array,

  onRequestSort: PropTypes.func,
};

export default function ClientHeadTable({
  order,
  orderBy,
  headLabel,
  onRequestSort = () => {},
  sortable = true,
}) {
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };
  const theme = useTheme();
  const isDarkMode = theme.palette.mode === "dark";

  return (
    <TableHead>
      <TableRow>
        {headLabel.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.alignRight ? "right" : "left"}
            sortDirection={orderBy === headCell.id ? order : false}
            sx={{ backgroundColor: isDarkMode ? "#000000" : "#32de84" }}
          >
            {sortable ? (
              <TableSortLabel
                hideSortIcon
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : "asc"}
                onClick={createSortHandler(headCell.id)}
              >
                <Typography
                  sx={{
                    color: "white",
                    fontWeight: "700",
                    fontSize: "12px",
                    lineHeight: "20px",
                    whiteSpace: "nowrap",
                  }}
                >
                  {headCell.label}
                </Typography>
              </TableSortLabel>
            ) : (
              <Typography
                sx={{
                  color: isDarkMode ? theme.palette.secondary.main : "white",
                  fontWeight: "700",
                  fontSize: "12px",
                  lineHeight: "20px",
                  whiteSpace: "nowrap",
                }}
              >
                {headCell.label}
              </Typography>
            )}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}
