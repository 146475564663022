import * as React from 'react';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';

import { styled, css } from '@mui/system';
import { Modal as BaseModal } from '@mui/base/Modal';
import Fade from '@mui/material/Fade';
import TextField from '@mui/material/TextField';
import { Grid, Typography, Button, Input, Stack, CircularProgress } from '@mui/material';
import { Formik } from 'formik';
import { useTranslation } from 'react-i18next';
import { replyRequest } from '../../network/apis';

export default function EditOurService({ open, handleClose, data }) {
  const { t } = useTranslation();
  const [loading, setLoading] = React.useState(false);

  // Handle Add Our Service
  const handleAddOurService = async (values) => {
    setLoading(true);
    try {
      await replyRequest({
        id: data?.id,
        reply: values.reply,
      });
      toast.success('Reply Sent successfully');
      handleClose();
    } catch (error) {
      console.log(error);
      toast.error('Failed to updated service. Please try again');
    } finally {
      setLoading(false);
    }
  };
  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={open}
      onClose={handleClose}
      closeAfterTransition
      slots={{ backdrop: StyledBackdrop }}
    >
      <Fade in={open}>
        <ModalContent sx={style}>
          <Typography id="transition-modal-title" variant="h6" component="h2">
            {t('technicalSupport.technical_support')}
          </Typography>
          <Formik
            initialValues={{
              name: data?.name,
              email: data?.email,
              phone: data?.phone,
              message: data?.message,
              reply: data?.reply,
            }}
            onSubmit={(values, { setSubmitting }) => {
              handleAddOurService({
                id: data?.id,
                reply: values.reply,
              });
              handleClose();
            }}
          >
            {({ values, errors, handleChange, handleSubmit, handleBlur, touched, setFieldValue }) => {
              return (
                <>
                  <form
                    onSubmit={(e) => {
                      e.preventDefault();
                      handleSubmit(e);
                    }}
                  >
                    <Grid
                      container
                      spacing={{ xs: 2, md: 1 }}
                      columns={{ xs: 4, sm: 8, md: 12 }}
                      sx={{
                        height: '100%',
                        padding: '20px',
                        borderBottom: '1px solid #E5E5E5',
                      }}
                    >
                      <Grid item xs={2} sm={4} md={12}>
                        <Typography variant="body1" gutterBottom>
                          {t('technicalSupport.full_name')}
                        </Typography>
                        <TextField
                          disabled
                          name="name"
                          required
                          fullWidth
                          value={values.name}
                          variant="outlined"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          error={touched.name && Boolean(errors.name)}
                          helperText={touched.name && errors.name}
                        />
                      </Grid>{' '}
                      <Grid item xs={2} sm={4} md={6}>
                        <Typography variant="body1" gutterBottom>
                          {t('technicalSupport.email')}
                        </Typography>
                        <TextField
                          disabled
                          name="email"
                          required
                          fullWidth
                          value={values.email}
                          variant="outlined"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          error={touched.email && Boolean(errors.email)}
                          helperText={touched.email && errors.email}
                        />
                      </Grid>{' '}
                      <Grid item xs={2} sm={4} md={6}>
                        <Typography variant="body1" gutterBottom>
                          {t('technicalSupport.phone')}
                        </Typography>
                        <TextField
                          name="phone"
                          disabled
                          required
                          fullWidth
                          value={values.phone}
                          variant="outlined"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          error={touched.phone && Boolean(errors.phone)}
                          helperText={touched.phone && errors.phone}
                        />
                      </Grid>
                      <Grid item xs={2} sm={4} md={12}>
                        <Typography variant="body1" gutterBottom>
                          {t('technicalSupport.message')}
                        </Typography>
                        <TextField
                          name="message"
                          disabled
                          required
                          multiline
                          rows={3}
                          fullWidth
                          value={values.message}
                          variant="outlined"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          error={touched.message && Boolean(errors.message)}
                          helperText={touched.message && errors.message}
                        />
                      </Grid>
                      <Grid item xs={2} sm={4} md={12}>
                        <Typography variant="body1" gutterBottom>
                          {t('technicalSupport.reply')}
                        </Typography>
                        <TextField
                          name="reply"
                          placeholder={t('technicalSupport.enter_reply')}
                          required
                          multiline
                          rows={3}
                          fullWidth
                          value={values.reply}
                          variant="outlined"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          error={touched.reply && Boolean(errors.reply)}
                          helperText={touched.reply && errors.reply}
                        />
                      </Grid>
                    </Grid>

                    <Stack
                      sx={{ width: '100%' }}
                      padding={2}
                      direction="row"
                      justifyContent="end"
                      alignItems="end"
                      gap={2}
                    >
                      <Button
                        sx={{ mt: 4, border: '1px solid #111827' }}
                        size="large"
                        type="button"
                        onClick={handleClose}
                      >
                        {' '}
                        {t('General.cancel')}
                      </Button>
                      <Button
                        sx={{ mt: 4, border: '1px solid #111827' }}
                        size="large"
                        type="submit"
                        variant="contained"
                      >
                        {' '}
                        {loading ? <CircularProgress /> : t('technicalSupport.send')}
                        {}
                      </Button>
                    </Stack>
                  </form>
                </>
              );
            }}
          </Formik>
        </ModalContent>
      </Fade>
    </Modal>
  );
}

const Backdrop = React.forwardRef((props, ref) => {
  const { open, ...other } = props;
  return (
    <Fade in={open}>
      <div ref={ref} {...other} />
    </Fade>
  );
});

Backdrop.propTypes = {
  open: PropTypes.bool,
};

const grey = {
  50: '#F3F6F9',
  100: '#E5EAF2',
  200: '#DAE2ED',
  300: '#C7D0DD',
  400: '#B0B8C4',
  500: '#9DA8B7',
  600: '#6B7A90',
  700: '#434D5B',
  800: '#303740',
  900: '#1C2025',
};

const Modal = styled(BaseModal)`
  position: fixed;
  z-index: 1300;
  inset: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const StyledBackdrop = styled(Backdrop)`
  z-index: -1;
  position: fixed;
  inset: 0;
  background-color: rgb(0 0 0 / 0.5);
  -webkit-tap-highlight-color: transparent;
`;

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 800,
  height: '90%',
  maxHeight: '90%',
  overflow: 'auto',
};

const ModalContent = styled('div')(
  ({ theme }) => css`
    font-family: 'IBM Plex Sans', sans-serif;
    font-weight: 500;
    text-align: start;
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 8px;
    overflow: hidden;
    background-color: ${'#fff'};
    border-radius: 8px;
    border: 1px solid ${grey[200]};
    box-shadow: 0 4px 12px ${'rgb(0 0 0 / 0.2)'};
    padding: 24px;
    color: ${grey[900]};

    & .modal-title {
      margin: 0;
      line-height: 1.5rem;
      margin-bottom: 8px;
    }

    & .modal-description {
      margin: 0;
      line-height: 1.5rem;
      font-weight: 400;
      color: ${grey[800]};
      margin-bottom: 4px;
    }
  `
);
