import * as React from 'react';
import PropTypes from 'prop-types';

import { styled, css } from '@mui/system';
import { Modal as BaseModal } from '@mui/base/Modal';
import Fade from '@mui/material/Fade';
import TextField from '@mui/material/TextField';
import { Grid, Typography, Button, Stack, CircularProgress } from '@mui/material';
import { Formik } from 'formik';
import { useTranslation } from 'react-i18next';

export default function ShowUser({ open, handleClose, data }) {
  console.log(data);
  const { t } = useTranslation();
  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={open}
      onClose={handleClose}
      closeAfterTransition
      slots={{ backdrop: StyledBackdrop }}
    >
      <Fade in={open}>
        <ModalContent sx={style}>
          <Typography id="transition-modal-title" variant="h6" component="h2">
            {t('users.profile')}
          </Typography>
          <Formik
            initialValues={{
              fullName: data?.fullName || '',
              phone: data?.phone || '',
              email: data?.email || '',
              birthDate: data?.birthDate || '',
            }}
          >
            {({ values }) => {
              return (
                <>
                  <Grid
                    padding={'20px'}
                    container
                    spacing={{ xs: 2, md: 2 }}
                    columns={{ xs: 4, sm: 8, md: 12 }}
                    sx={{
                      height: '100%',
                      padding: '20px',
                      borderBottom: '1px solid #E5E5E5',
                    }}
                  >
                    <Grid item xs={2} sm={4} md={6}>
                      <Typography variant="body1" gutterBottom>
                        {t('users.userName')}
                      </Typography>
                      <TextField disabled fullWidth value={values.fullName} variant="outlined" />
                    </Grid>{' '}
                    <Grid item xs={2} sm={4} md={6}>
                      <Typography variant="body1" gutterBottom>
                        {t('users.userEmail')}
                      </Typography>
                      <TextField disabled fullWidth value={values.email} variant="outlined" />
                    </Grid>{' '}
                    <Grid item xs={2} sm={4} md={6}>
                      <Typography variant="body1" gutterBottom>
                        {t('users.phoneNumber')}
                      </Typography>
                      <TextField disabled fullWidth value={values.phone} variant="outlined" />
                    </Grid>{' '}
                    <Grid item xs={2} sm={4} md={6}>
                      <Typography variant="body1" gutterBottom>
                        {t('users.dateOfBirth')}
                      </Typography>
                      <TextField disabled fullWidth value={values.birthDate} variant="outlined" />
                    </Grid>{' '}
                  </Grid>{' '}
                </>
              );
            }}
          </Formik>
        </ModalContent>
      </Fade>
    </Modal>
  );
}

const Backdrop = React.forwardRef((props, ref) => {
  const { open, ...other } = props;
  return (
    <Fade in={open}>
      <div ref={ref} {...other} />
    </Fade>
  );
});

Backdrop.propTypes = {
  open: PropTypes.bool,
};

const grey = {
  50: '#F3F6F9',
  100: '#E5EAF2',
  200: '#DAE2ED',
  300: '#C7D0DD',
  400: '#B0B8C4',
  500: '#9DA8B7',
  600: '#6B7A90',
  700: '#434D5B',
  800: '#303740',
  900: '#1C2025',
};

const Modal = styled(BaseModal)`
  position: fixed;
  z-index: 1300;
  inset: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const StyledBackdrop = styled(Backdrop)`
  z-index: -1;
  position: fixed;
  inset: 0;
  background-color: rgb(0 0 0 / 0.5);
  -webkit-tap-highlight-color: transparent;
`;

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 800,
  height: 'fit-content',
  maxHeight: '90%',
  overflow: 'auto',
};
const ModalContent = styled('div')(
  ({ theme }) => css`
    font-family: 'IBM Plex Sans', sans-serif;
    font-weight: 500;
    text-align: start;
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 8px;
    overflow: hidden;
    background-color: ${'#fff'};
    border-radius: 8px;
    border: 1px solid ${grey[200]};
    box-shadow: 0 4px 12px ${'rgb(0 0 0 / 0.2)'};
    padding: 24px;
    color: ${grey[900]};

    & .modal-title {
      margin: 0;
      line-height: 1.5rem;
      margin-bottom: 8px;
    }

    & .modal-description {
      margin: 0;
      line-height: 1.5rem;
      font-weight: 400;
      color: ${grey[800]};
      margin-bottom: 4px;
    }
  `
);
