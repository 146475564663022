import { Helmet } from 'react-helmet-async';
import { useNavigate } from 'react-router-dom'; // Import useNavigate

// localization

import { useTranslation } from 'react-i18next';

import { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
// @mui
import { Stack, Button, Typography, Skeleton, Grid } from '@mui/material';
import { CityReqTable } from '../sections/@dashboard/owners';
import Layout from '../components/Layout/Layout';
import SearchComponet from '../components/search/Search';
import DeleteFileModal from '../components/deleteFileModal/DeleteFileModal';

import { getOwnersList, deleteOwner } from '../network/apis';

export default function ManageOwners() {
  const [deleteData, setDeleteData] = useState({
    open: false,
    id: '',
  });
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const [page, setPage] = useState(0);
  const [filters, setFilters] = useState({});
  const [loading, setLoading] = useState(false);
  const [clientReqList, setClientReqList] = useState([]);
  const [search, setSearch] = useState('');

  const clientRequests = (search, page) => {
    setLoading(true);
    getOwnersList(page, search)
      .then((res) => {
        setClientReqList(res.data);
        setLoading(false);
      })
      .catch((error) => {
        console.error('Error fetching owners list:', error);
        // Display a toast message with a generic error message
        toast.error(error.message);
        setLoading(false);
      });
  };

  useEffect(() => {
    clientRequests(page, search);
  }, [page, search]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleDelete = (id) => {
    deleteOwner(id)
      .then((res) => {
        if (res.data.STATUS === 'FAILED') {
          toast.error(res.data.MESSAGE);
        } else toast.error('Region deleted successfully!');
        // Reload the cities list
        clientRequests(page, search);
      })
      .catch((error) => {
        toast.error('Failed to delete region. Please try again.');
      });
  };

  const handleNavigate = () => {
    navigate('/dashboard/manage-owner/add');
  };

  return (
    <>
      <Helmet>
        <title> Manage Owners </title>
      </Helmet>

      <Layout>
        <Grid marginBottom={1} display="flex" alignItems={'center'} justifyContent="space-between" md={6} xs={12}>
          <Typography sx={{ fontSize: '24px', fontWeight: '700', lineHeight: '32px', color: '#111827' }}>
            {t('Owners.owners')}{' '}
          </Typography>
          <Button
            onClick={handleNavigate}
            style={{
              backgroundColor: 'black',
              color: 'white',
              borderRadius: '10px',
              padding: '21px 24px',
              width: '164px',
              height: '56px',
            }}
          >
            + {t('Owners.addOwner')}
          </Button>
        </Grid>
        <Grid mb={2} rowSpacing={2} container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }}>
          <Grid item xs={2} sm={4} md={4}>
            <SearchComponet onSearch={setSearch} style={{ width: '100%' }} />
          </Grid>
        </Grid>
        {loading ? (
          <Stack>
            <Skeleton height={100} />
            <Skeleton height={100} />
            <Skeleton height={100} />
            <Skeleton height={100} />
            <Skeleton height={100} />
            <Skeleton height={100} />
          </Stack>
        ) : (
          <>
            <CityReqTable
              handleChangePage={handleChangePage}
              page={page}
              requests={clientReqList}
              onDelete={(id) => setDeleteData({ open: true, id })}
            />
          </>
        )}
        <DeleteFileModal
          open={deleteData.open}
          handelClose={() => setDeleteData({ ...deleteData, open: false })}
          onDelete={() => {
            handleDelete(deleteData?.id);
          }}
        />
      </Layout>
    </>
  );
}
