/* eslint-disable react/prop-types */
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
// @mui
import {
  Table,
  Button,
  TableRow,
  TableBody,
  TableCell,
  Typography,
  TableContainer,
} from '@mui/material';
import EllipsisText from "react-ellipsis-text";

// components
import Label from '../../../components/label';
import Scrollbar from '../../../components/scrollbar';
// sections
import { ReqTechnologiesHead } from './index';

// mock

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'function', label: 'Function', alignRight: false },
  { id: 'technology', label: 'Technology', alignRight: false },
  { id: 'level', label: 'Level', alignRight: false },
  { id: 'estimate', label: 'Estimate', alignRight: false },
  { id: 'availability', label: 'Availability', alignRight: false },
  { id: 'status', label: 'Status', alignRight: false },
  { id: 'cpmment', label: 'Comment', alignRight: false },
  { id: 'view', label: 'View Details', alignRight: false },
];

// ----------------------------------------------------------------------

export default function FeaturesTable({ list }) {
  const [page, setPage] = useState(0);

  const [rowsPerPage, setRowsPerPage] = useState(10);

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - list.length) : 0;

  const navigate = useNavigate()

  return (
    <>
      <Scrollbar>
        <TableContainer sx={{ minWidth: 800 }}>
          <Table>
            <ReqTechnologiesHead headLabel={TABLE_HEAD} rowCount={list.length} />
            <TableBody>
              {list.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
                const { id, category, availabilty, level, status , comment, technology , time} = row;
                return (
                  <TableRow hover key={id} tabIndex={-1}>
                    <TableCell component="th" scope="row">
                      <Typography variant="subtitle2" noWrap>
                        {category?.name}
                      </Typography>
                    </TableCell>
                    <TableCell align="left">{technology?.name}</TableCell>
                    <TableCell align="left">{level}</TableCell>
                    <TableCell align="left">{time}</TableCell>
                    <TableCell align="left">{availabilty}</TableCell>

                    <TableCell align="left">
                      <Label color={(status === 3 && 'error') || (status === 1 && 'success') || 'warning'}>
                        {status === 1 ? 'Approved' : status === 3 ? 'Rejected' : 'Pending'}
                      </Label>
                    </TableCell>
                    <TableCell align="left">
                      {comment ? <EllipsisText text={comment} length={30} />  : "-"}
                    </TableCell>
                    <TableCell align="left">
                      <Button onClick={() => navigate(`/dashboard/request-technology-details/${id}`)}>View</Button>
                    </TableCell>
                  </TableRow>
                );
              })}
              {emptyRows > 0 && (
                <TableRow style={{ height: 53 * emptyRows }}>
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Scrollbar>
    </>
  );
}
