import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { addProject } from '../../../network/apis'; // Import the API function

export const addProjectRequest = createAsyncThunk('form/addProject', async (formData, thunkAPI) => {
  try {
    const response = await addProject(formData);
    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

const initialState = {
  formData: {},
  files: [],
  currentStep: 0,
  loading: false, // Track loading state
  error: null, // Track error state
};

const formSlice = createSlice({
  name: 'projectForm',
  initialState,
  reducers: {
    updateFormData: (state, action) => {
      state.formData = { ...state.formData, ...action.payload };
    },
    setFileInfo: (state, action) => {
      state.files.push(action.payload);
    },
    resetFormData: (state) => {
      state.formData = initialState.formData;
    },
    nextStep: (state) => {
      state.currentStep += 1;
    },
    prevStep: (state) => {
      state.currentStep -= 1;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(addProjectRequest.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(addProjectRequest.fulfilled, (state, action) => {
        state.loading = false;
        // Handle successful form submission (e.g., reset form state or navigate away)
      })
      .addCase(addProjectRequest.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export const { updateFormData, resetFormData, nextStep, prevStep, setFileInfo } = formSlice.actions;

export default formSlice.reducer;
