import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';

import { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
// @mui
import { Card, Stack, Button, Container, Typography, Skeleton, Grid } from '@mui/material';
import { CityReqTable } from '../sections/@dashboard/documentType';
import SearchComponet from '../components/search/Search';
import ModalComponent from '../components/modal/modal';
import DocumentTypeForm from '../sections/@dashboard/documentType/DocumentTypeForm';
import { getDocumentTypeList, deleteDocumetType } from '../network/apis';

export default function Cities() {
  const { t, i18n } = useTranslation();
  const isRtl = i18n.language === 'ar';


  const [page, setPage] = useState(0);
  const [filters, setFilters] = useState({});
  const [loading, setLoading] = useState(false);
  const [clientReqList, setClientReqList] = useState([]);
  const [search, setSearch] = useState('');

  const clientRequests = (page, search) => {
    setLoading(true);
    getDocumentTypeList(page, search)
      .then((res) => {
        setClientReqList(res.data);
        setLoading(false);
      })
      .catch(() => setLoading(false));
  };

  useEffect(() => {
    clientRequests(page, search);
  }, [page, search]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleDelete = (id) => {
    deleteDocumetType(id)
      .then((res) => {
        if (res.data.status === '0') {
          toast.error(res.data.message);
        } else toast.error(t('documentType.documentTypeDeleted'));
        // Reload the cities list
        clientRequests(page, search);
      })
      .catch((error) => {
        toast.error(t('General.deleteFailed'));
      });
  };
  const [isModalOpen, setModalOpen] = useState(false);

  const handleOpenModal = () => {
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
    clientRequests(page, search);
  };
  return (
    <>
      <Helmet>
        <title> {t('documentType.documentType')} </title>
      </Helmet>
      <ModalComponent
        style={{ position: 'relative' }}
        isOpen={isModalOpen}
        handleClose={handleCloseModal}
        title={t('documentType.addDocumentType') }
        content=""
      >
        <DocumentTypeForm handleClose={handleCloseModal} />
      </ModalComponent>
      <>




         <Grid container spacing={2} alignItems="center">
          
         <Grid justifyContent="start" display="flex" item xs={2} sm={4} md={6}>
         <SearchComponet onSearch={setSearch} />
          </Grid>

          <Grid item xs={6} sm={6} display="flex" justifyContent="end">
       
            <Button
              onClick={handleOpenModal}
              style={{
                backgroundColor: 'black',
                color: 'white',
                borderRadius: '10px',
                padding: '21px 24px',
                width: 'fit-content',
                height: '56px',
                    marginBottom: '15px'
              }}
            >
              {' '}
              + {t('documentType.addDocumentType') }
            </Button>
          </Grid>
   
        </Grid>
        {loading ? (
          <Stack>
            <Skeleton height={100} />
            <Skeleton height={100} />
            <Skeleton height={100} />
            <Skeleton height={100} />
            <Skeleton height={100} />
            <Skeleton height={100} />
          </Stack>
        ) : (
          <>
            <>
              <CityReqTable
                handleChangePage={handleChangePage}
                page={page}
                requests={clientReqList}
                onDelete={handleDelete}
              />
            </>
          </>
        )}
      </>
    </>
  );
}
