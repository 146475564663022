/* eslint-disable no-case-declarations */
import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

// @mui
import { Box } from '@mui/system';
import { Card, Container, Typography, FormControlLabel, Checkbox, RadioGroup, Radio, Paper } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
// components
import { createClientRequestTechnologies, getTechnologies } from '../network/apis';

// ----------------------------------------------------------------------

export default function RequestTechnologies() {
  const navigate = useNavigate();
  const params = useParams();
  const [technologies, setTechnologies] = useState([]);
  const [loading, setLoading] = useState(false);
  const [expanded, setExpanded] = useState(false);

  useEffect(() => {
    getTechnologies().then((res) => {
      setTechnologies(
        res.data.data.map((item) => ({
          ...item,
          is_checked: false,
          selectedTechnology:
            item.technologies.length > 1
              ? item.technologies?.find((tech) => tech.default)?.id
              : item.technologies[0].id,
          selectedLevel:
            item.level.length > 0 ? item.level?.find((level) => level.default)?.value : item.level[0].value,
          selectedAvailability:
            item.availability.length > 0
              ? item.availability?.find((availability) => availability.default)?.value
              : item.availability[0].value,
        }))
      );
    });
  }, []);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const handleSubmit = () => {
    const selectedTechnologies = technologies.filter((tech) => tech.is_checked);
    setLoading(true);
    createClientRequestTechnologies({
      client_request_id: Number(params.request_id),
      request_categories: selectedTechnologies.map((tech) => ({
        id: Number(tech.id),
        technology: Number(tech.selectedTechnology),
        availability: Number(tech.selectedAvailability),
        level: Number(tech.selectedLevel),
      })),
    })
      .then((res) => {
        if (res) {
          setLoading(false);
          navigate(`/dashboard/request-summary/${params.request_id}`);
        }
      })
      .catch((error) => {
        setLoading(false);
        toast.error(
          error.response.status === 400 ? 'Technologies already added for this request' : error.response.data.message,
          {
            position: 'top-right',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: 'light',
          }
        );
        if(error.response.status === 400){
          navigate(`/dashboard/request-summary/${params.request_id}`);
        }
      });
  };

  return (
    <>
      <Helmet>
        <title> Request Technologies </title>
      </Helmet>

      <Container maxWidth="xl">
        <Typography variant="h4" sx={{ mb: 5 }}>
          Step 2 | Request Technologies
        </Typography>
      </Container>
      <Card>
        <Box sx={{ p: 3 }}>
          <h4>Select Technologies</h4>
          {technologies.map((technology, index) => (
            <Box key={technology.id} sx={{ border: '1px solid #919eab33', borderRadius: '8px', mb: 1 }}>
              <Accordion expanded={expanded === index} onChange={handleChange(index)}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1bh-content" id="panel1bh-header">
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={technology.is_checked}
                        onChange={() =>
                          setTechnologies(
                            technologies.map((techItem, Ti) => {
                              if (index === Ti) {
                                return {
                                  ...techItem,
                                  is_checked: !techItem.is_checked,
                                };
                              }
                              return techItem;
                            })
                          )
                        }
                      />
                    }
                    label={technology.name}
                  />
                </AccordionSummary>
                <AccordionDetails>
                  <h5>Technologies</h5>
                  <RadioGroup
                    aria-labelledby="technology"
                    name="technology"
                    value={technology.selectedTechnology}
                    onChange={(e) =>
                      setTechnologies(
                        technologies.map((tech, i) => {
                          if (index === i) {
                            return {
                              ...tech,
                              selectedTechnology: e.target.value,
                            };
                          }
                          return tech;
                        })
                      )
                    }
                  >
                    {technology.technologies.map((item) => (
                      <FormControlLabel key={item.id} value={item.id} control={<Radio />} label={item.name} />
                    ))}
                  </RadioGroup>
                  <h5>Availabilty</h5>
                  <RadioGroup
                    aria-labelledby="availability"
                    name="availability"
                    value={technology.selectedAvailability}
                    onChange={(e) =>
                      setTechnologies(
                        technologies.map((tech, i) => {
                          if (index === i) {
                            return {
                              ...tech,
                              selectedAvailability: e.target.value,
                            };
                          }
                          return tech;
                        })
                      )
                    }
                  >
                    {technology.availability.map((item) => (
                      <FormControlLabel key={item.value} value={item.value} control={<Radio />} label={item.name} />
                    ))}
                  </RadioGroup>
                  <h5>Level</h5>
                  <RadioGroup
                    aria-labelledby="level"
                    name="level"
                    value={technology.selectedLevel}
                    onChange={(e) =>
                      setTechnologies(
                        technologies.map((tech, i) => {
                          if (index === i) {
                            return {
                              ...tech,
                              selectedLevel: e.target.value,
                            };
                          }
                          return tech;
                        })
                      )
                    }
                  >
                    {technology.level.map((item) => (
                      <FormControlLabel key={item.value} value={item.value} control={<Radio />} label={item.name} />
                    ))}
                  </RadioGroup>
                </AccordionDetails>
              </Accordion>
            </Box>
          ))}

          <LoadingButton loading={loading} sx={{ mt: 3 }} variant="contained" onClick={handleSubmit}>
            Save
          </LoadingButton>
        </Box>
      </Card>
    </>
  );
}
