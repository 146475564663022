import { alpha } from '@mui/material/styles';
import palette from './palette';

const GREY = {
  0: '#FFFFFF',
  100: '#F9FAFB',
  200: '#F4F6F8',
  300: '#DFE3E8',
  400: '#C4CDD5',
  500: '#919EAB',
  600: '#637381',
  700: '#454F5B',
  800: '#212B36',
  900: '#161C24',
};

const darkModePalette = {
  ...palette,
  mode: 'dark',
  background: {
    // paper: '#1d1d1d',
    // default: '#121212',
    paper: '#fff',
    default: '#fff',
    neutral: '#2c2c2c',
  },
  components: {
    MuiTableCell: {
      styleOverrides: {
        body: {
          backgroundColor: GREY[100], // Customize the background color of th
          color: '#fff', // Customize the text color of th
          borderBottom: `1px solid ${GREY[200]}`, // Add border
          fontWeight: 'bold', // Set font weight for header cells
        },
      },
    },
  },
  text: {
    primary: '#121212',
    secondary: '#121212',
    disabled: '#555555',
  },

  action: {
    active: GREY[600],
    hover: alpha('#ffffff', 0.08),
    disabled: alpha(GREY[500], 0.8),
    disabledBackground: alpha(GREY[500], 0.24),
    focus: alpha(GREY[500], 0.24),
    hoverOpacity: 0.08,
    disabledOpacity: 0.48,
  },
};

export default darkModePalette;
