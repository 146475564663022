import React, { useState } from 'react';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  TextField,
  Popover,
  Typography,
  Divider,
  Stack,
} from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import moment from 'moment';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';

// Validation schema

const FilterComponent = ({ onApply, isLogers = false }) => {
  const { t, i18n } = useTranslation();
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const currentDate = moment().startOf('day'); // Get current date without time

  const startDate = moment().subtract(1, 'month');
  const [label, setLabel] = useState(`${t('General.from')} ${startDate.format('D-MM-YYYY')} - 
  ${t('General.tillToday')} ${moment(currentDate).format('D-MM-YYYY')}`);

  const getTypeAndValue = (duration, startDate, endDate) => {
    let type;
    let value;
    let newLabel;
    switch (duration) {
      case '7days':
        type = 'day';
        value = 7;
        newLabel = `${t('General.from')} ${moment().startOf('day').subtract(7, 'days').format('D-MM-YYYY')} - 
        ${t('General.tillToday')} ${moment(currentDate).format('D-MM-YYYY')}`;
        break;
      case '1month':
        type = 'month';
        value = 1;
        newLabel = `${t('General.from')} ${moment().startOf('day').subtract(1, 'month').format('D-MM-YYYY')} - ${t(
          'General.tillToday'
        )} ${moment(currentDate).format('D-MM-YYYY')}`;
        break;
      case '3months':
        type = 'month';
        value = 3;
        newLabel = `${t('General.from')} ${moment().startOf('day').subtract(3, 'months').format('D-MM-YYYY')} - ${t(
          'General.tillToday'
        )} ${moment(currentDate).format('D-MM-YYYY')}`;
        break;
      case '6months':
        type = 'month';
        value = 6;
        newLabel = `${t('General.from')} ${moment().startOf('day').subtract(6, 'months').format('D-MM-YYYY')} - ${t(
          'General.tillToday'
        )} ${moment(currentDate).format('D-MM-YYYY')}`;
        break;
      case '1year':
        type = 'year';
        value = 1;

        newLabel = `${t('General.from')} ${moment().startOf('day').subtract(1, 'years').format('D-MM-YYYY')} - ${t(
          'General.tillToday'
        )} ${moment(currentDate).format('D-MM-YYYY')}`;
        break;
      case 'dateRange':
        if (startDate && endDate) {
          newLabel = `${t('General.from')} ${moment(startDate).format('D-MM-YYYY')} - To ${moment(endDate).format(
            'D-MM-YYYY'
          )}`;
        } else {
          newLabel = 'Select Date Range';
        }
        type = 'range';
        value = '';

        break;
      default:
        newLabel = `${t('General.from')} ${startDate.format('D-MM-YYYY')} - ${t('General.tillToday')} ${moment(
          currentDate
        ).format('D-MM-YYYY')}`;
    }
    setLabel(newLabel);
    return { type, value };
  };

  const handleFormSubmit = (values, { setSubmitting }) => {
    const { type, value } = getTypeAndValue(values.duration, values.startDate, values.endDate);
    const start = values.startDate ? moment(values.startDate).format('YYYY-MM-DD') : '';
    const end = values.endDate ? moment(values.endDate).format('YYYY-MM-DD') : '';
    if (!values.duration) {
      toast.error('Please select a duration');
      setSubmitting(false);
      return;
    }

    // Additional check for startDate and endDate when duration is 'dateRange'
    if (type === 'dateRange' && (!start || !end)) {
      toast.error('Please select a start and end date');
      setSubmitting(false);
      return;
    }
    onApply({ type, value, start, end });
    handleClose();

    setSubmitting(false);
  };

  const handleReset = (resetForm) => {
    resetForm();
    setLabel(
      `${t('General.from')} ${startDate.format('MMMM Do, YYYY')} - ${t('General.tillToday')} ${moment(
        currentDate
      ).format('MMMM Do, YYYY')}`
    );
    onApply({ type: '', value: '', start: '', end: '' });
  };
  const FilterSchema = Yup.object().shape({
    startDate: Yup.date().max(Yup.ref('endDate'), t('General.start_date_validation')).nullable(),
    endDate: Yup.date().min(Yup.ref('startDate'), t('General.end_date_validation')).nullable(),
  });

  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <Formik
        initialValues={{
          duration: '',
          startDate: null,
          endDate: null,
        }}
        validationSchema={FilterSchema}
        onSubmit={handleFormSubmit}
      >
        {({ values, setFieldValue, errors, touched, handleSubmit, resetForm }) => {
          return (
            <Box>
              <Typography variant="span" sx={{ mr: 2 }}>
                {t('General.select_duration')} :
              </Typography>
              <Button aria-describedby={id} sx variant="outlined" onClick={handleClick}>
                <CalendarTodayIcon sx={{ mr: 1 }} />
                {label}
              </Button>
              <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left',
                }}
              >
                <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, p: 2, minWidth: 250 }}>
                  <Form onSubmit={handleSubmit}>
                    <FormControl sx={{ width: '100%' }}>
                      <RadioGroup
                        name="duration"
                        value={values.duration}
                        onChange={(event) => setFieldValue('duration', event.currentTarget.value)}
                        sx={{ flexDirection: 'column' }}
                      >
                        {!isLogers && (
                          <>
                            <FormControlLabel
                              sx={{
                                display: 'flex',
                                width: '100%',
                                justifyContent: 'space-between',
                                margin: '0.3rem',
                                flexDirection: 'row-reverse',
                                '&.Mui-checked': {
                                  backgroundColor: '#e3f2fd',
                                },
                              }}
                              value="7days"
                              control={<Radio />}
                              label={`${t('DashboardPage.7Days')}`}
                            />
                            <Divider />
                            <FormControlLabel
                              sx={{
                                display: 'flex',
                                width: '100%',
                                justifyContent: 'space-between',
                                margin: '0.3rem',
                                flexDirection: 'row-reverse',
                                '&.Mui-checked': {
                                  backgroundColor: '#e3f2fd',
                                },
                              }}
                              value="1month"
                              control={<Radio />}
                              label={`${t('DashboardPage.1Month')}`}
                            />
                            <Divider />
                            <FormControlLabel
                              sx={{
                                display: 'flex',
                                width: '100%',
                                justifyContent: 'space-between',
                                margin: '0.3rem',
                                flexDirection: 'row-reverse',
                                '&.Mui-checked': {
                                  backgroundColor: '#e3f2fd',
                                },
                              }}
                              value="3months"
                              control={<Radio />}
                              label={`${t('DashboardPage.3Months')}`}
                            />
                            <Divider />
                            <FormControlLabel
                              sx={{
                                display: 'flex',
                                width: '100%',
                                justifyContent: 'space-between',
                                margin: '0.3rem',
                                flexDirection: 'row-reverse',
                                '&.Mui-checked': {
                                  backgroundColor: '#e3f2fd',
                                },
                              }}
                              value="6months"
                              control={<Radio />}
                              label={`${t('DashboardPage.6Months')}`}
                            />
                            <Divider />
                            <FormControlLabel
                              sx={{
                                display: 'flex',
                                width: '100%',
                                justifyContent: 'space-between',
                                margin: '0.3rem',
                                flexDirection: 'row-reverse',
                                '&.Mui-checked': {
                                  backgroundColor: '#e3f2fd',
                                },
                              }}
                              value="1year"
                              control={<Radio />}
                              label={`${t('DashboardPage.1Year')}`}
                            />
                            <Divider />
                          </>
                        )}

                        <FormControlLabel
                          sx={{
                            display: 'flex',
                            width: '100%',
                            justifyContent: 'space-between',
                            margin: '0.3rem',
                            flexDirection: 'row-reverse',
                            '&.Mui-checked': {
                              backgroundColor: '#e3f2fd',
                            },
                          }}
                          value="dateRange"
                          control={<Radio />}
                          label={t('General.dateRange')}
                        />
                      </RadioGroup>
                    </FormControl>
                    <Divider />
                    {values.duration === 'dateRange' && (
                      <Box sx={{ display: 'flex', gap: 2, mt: 2 }}>
                        <Stack gap={1}>
                          <Typography>{t('General.start_date')}</Typography>{' '}
                          <DatePicker
                            value={values.startDate}
                            onChange={(value) => setFieldValue('startDate', value)}
                            renderInput={(props) => (
                              <TextField
                                {...props}
                                fullWidth
                                error={Boolean(errors.startDate)}
                                helperText={errors.startDate}
                              />
                            )}
                          />
                          <span style={{ color: 'red', fontSize: '8px', fontWeight: 'bold' }}>
                            {' '}
                            {Boolean(touched.startDate && errors.startDate) && errors.startDate}
                          </span>
                        </Stack>

                        <span style={{ marginTop: '1rem', color: 'grey' }}>{t('General.to')}</span>
                        <Stack gap={1}>
                          <Typography>{t('General.end_date')}</Typography>{' '}
                          <DatePicker
                            value={values.endDate}
                            onChange={(value) => setFieldValue('endDate', value)}
                            renderInput={(props) => (
                              <TextField
                                {...props}
                                fullWidth
                                error={Boolean(touched.endDate && errors.endDate)}
                                helperText={touched.endDate && errors.endDate}
                              />
                            )}
                          />
                          <span style={{ color: 'red', fontSize: '8px', fontWeight: 'bold' }}>
                            {Boolean(touched.endDate && errors.endDate) && errors.endDate}
                          </span>
                        </Stack>
                      </Box>
                    )}
                    <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: 2, mt: 2 }}>
                      <Button onClick={() => handleReset(resetForm)} variant="outlined" color="secondary">
                        {t('General.reset')}
                      </Button>
                      <Button type="submit" variant="contained" color="primary">
                        {t('General.apply')}
                      </Button>
                    </Box>
                  </Form>
                </Box>
              </Popover>
            </Box>
          );
        }}
      </Formik>
    </LocalizationProvider>
  );
};

export default FilterComponent;
