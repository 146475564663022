/* eslint-disable react/prop-types */
import React, { useState } from 'react';

// localiztion

import { useTranslation } from 'react-i18next';

// @mui
import { useNavigate } from 'react-router-dom';
import {
  Table,
  TableRow,
  TableBody,
  Button,
  TableCell,
  Typography,
  TableContainer,
  Pagination,
  Box,
} from '@mui/material';
// icons imports

import { ReactComponent as DeleteIcon } from '../../../theme/images/deleteTableIcon.svg';
import { ReactComponent as EditIcon } from '../../../theme/images/editTableIcon.svg';

// components
import Scrollbar from '../../../components/scrollbar';

// sections
import ClientReqHead from '../../../components/clientHeadTable/ClientHeadTable';

// ----------------------------------------------------------------------

// ----------------------------------------------------------------------

export default function ClientReqTable({
  requests,
  handleChangePage,
  page,
  onDelete,
  hasEditPermission,
  hasDeletePermission,
}) {
  const [rowsPerPage] = useState(10); // Set a default value for rowsPerPage

  const { t, i18n } = useTranslation();
  const isRtl = i18n.language === 'ar';
  const TABLE_HEAD = [
    { id: 'nameEn', label: t('Building.buildingName'), alignRight: isRtl && true },

    { id: 'buildingCode', label: t('Building.buildingCode'), alignRight: isRtl && true },
    { id: 'unitCount', label: t('Building.unitCount'), alignRight: isRtl && true },
    { id: 'levelCount', label: t('Building.levelCount'), alignRight: isRtl && true },
    { id: 'action', label: t('General.Action'), alignRight: isRtl && true },
  ];
  const navigate = useNavigate();

  return (
    <>
      <Scrollbar>
        <TableContainer sx={{ minWidth: 800 }}>
          <Table sx={{ direction: isRtl ? 'rtl' : 'ltr' }}>
            <ClientReqHead headLabel={TABLE_HEAD} />
            <TableBody>
              {requests.length === 0 && (
                <TableCell component="th" scope="row">
                  <Typography variant="subtitle2" noWrap>
                    No Buildings
                  </Typography>
                </TableCell>
              )}
              {requests?.map((row) => {
                const { projectId, buildingCode, nameEn, unitCount, id, levelCount } = row;

                return (
                  <TableRow hover key={id} tabIndex={-1}>
                    <TableCell sx={{ textAlign: isRtl ? 'right' : 'left' }} component="th" scope="row">
                      <Typography
                        sx={{ color: 'text.secondary', fontWeight: '400', fontSize: '12px', lineHeight: '20px' }}
                        variant="subtitle2"
                        noWrap
                      >
                        {' '}
                        {nameEn}
                      </Typography>
                    </TableCell>

                    <TableCell sx={{ textAlign: isRtl ? 'right' : 'left' }} component="th" scope="row">
                      <Typography
                        sx={{ color: 'text.secondary', fontWeight: '400', fontSize: '12px', lineHeight: '20px' }}
                        variant="subtitle2"
                        noWrap
                      >
                        {' '}
                        {buildingCode || ''}
                      </Typography>
                    </TableCell>
                    <TableCell sx={{ textAlign: isRtl ? 'right' : 'left' }} component="th" scope="row">
                      <Typography
                        sx={{ color: 'text.secondary', fontWeight: '400', fontSize: '12px', lineHeight: '20px' }}
                        variant="subtitle2"
                        noWrap
                      >
                        {' '}
                        {unitCount || ''}
                      </Typography>
                    </TableCell>
                    <TableCell sx={{ textAlign: isRtl ? 'right' : 'left' }} component="th" scope="row">
                      <Typography
                        sx={{ color: 'text.secondary', fontWeight: '400', fontSize: '12px', lineHeight: '20px' }}
                        variant="subtitle2"
                        noWrap
                      >
                        {' '}
                        {levelCount || ''}
                      </Typography>
                    </TableCell>

                    <TableCell sx={{ textAlign: isRtl ? 'right' : 'left' }}>
                      {hasEditPermission && (
                        <Button
                          onClick={() =>
                            navigate(id ? `/dashboard/building/edit/${id}` : '/dashboard/building/add/', {
                              state: { editData: row },
                            })
                          }
                        >
                          <EditIcon />
                        </Button>
                      )}
                      {hasDeletePermission && (
                        <Button onClick={() => onDelete(id)}>
                          <DeleteIcon />
                        </Button>
                      )}
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Scrollbar>
      {requests?.totalElements === 0 && (
        <Box textAlign="center">
          <h5>No data found</h5>
        </Box>
      )}

      {requests?.totalPages > 0 && (
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            padding: '10px',
            marginTop: '5rem',
          }}
        >
          <Pagination count={requests.totalPages} page={page + 1} onChange={handleChangePage} color="primary" />
          <Box sx={{ display: 'block' }}>
            {t('General.showing')} {page * rowsPerPage + 1} {t('General.to')}{' '}
            {Math.min((page + 1) * rowsPerPage, requests.totalElements)} {t('General.of')} {requests.totalElements}{' '}
            {t('General.entries')}
          </Box>
        </Box>
      )}
    </>
  );
}
