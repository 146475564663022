import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Box, Tabs, Tab, Button } from '@mui/material';
import { TabContext, TabPanel } from '@mui/lab';
import { useTranslation } from 'react-i18next';
import { usePlanFormContext } from '../context/planFormContext';
import GeneralData from '../sections/@dashboard/planFormTabs/GeneralData';
import Layout from '../components/Layout/Layout';
import StyledTabs from '../components/StyledTabs/StyledTabs';
import OwnerDataForm from '../sections/@dashboard/planFormTabs/OwnerData';
import ConsltantDataForm from '../sections/@dashboard/planFormTabs/ConsultantData';

const ManagePlans = () => {
  const {
    id,
    currentTab,
    isViewMode,
    isEditMode,
    detailsData,
    refetch,
    setRefetch,
    uploadedFilesUploaded,

    addItem,
    deleteItem,
    handleChange,
    handleNext,
    handlePrev,
    handleSubmit,
    handleDelete,
    selectedFacilities,
    setSelectedFacilities,
    selectedServices,
    setSelectedServices,
    selectedAfterSaleServices,
    setSelectedPostSaleServices,
  } = usePlanFormContext();
  const { i18n, t } = useTranslation();
  const isRtl = i18n.language === 'ar';
  const direction = isRtl === 'ar' ? 'rtl' : 'ltr';
  return (
    <Layout sx={{ width: '100%' }}>
      <h3>{t('PLANS.newPlan')}</h3>
      <TabContext value={currentTab}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider', direction: isRtl ? 'rtl' : 'ltr', display: 'flex' }}>
          <StyledTabs
            value={currentTab}
            onChange={handleChange}
            tabs={[
              {
                label: t('PLANS.generalData'),
                value: '1',
              },
              { label: t('ManageProject.ownerData'), value: '2' },
              { label: t('ManageProject.consultantData'), value: '3' },
            ]}
          />
        </Box>
        <TabPanel value="1">
          <GeneralData
            id={id}
            uploadedFilesUploaded={uploadedFilesUploaded}
            isViewMode={isViewMode}
            isEditMode={isEditMode}
            editData={detailsData}
            handleDelete={handleDelete}
            handleNext={handleNext}
            addItem={addItem}
            deleteItem={deleteItem}
            refetch={refetch}
            setRefetch={setRefetch}
            selectedAfterSaleServices={selectedAfterSaleServices}
            setSelectedPostSaleServices={setSelectedPostSaleServices}
            selectedServices={selectedServices}
            setSelectedServices={setSelectedServices}
            selectedFacilities={selectedFacilities}
            setSelectedFacilities={setSelectedFacilities}
          />
        </TabPanel>
        <TabPanel value="2">
          <OwnerDataForm
            handleNext={handleNext}
            handlePrev={handlePrev}
            isViewMode={isViewMode}
            editData={detailsData && detailsData.owner}
            isEditMode={isEditMode}
          />
        </TabPanel>
        <TabPanel handleNext={handleNext} handlePrev={handlePrev} value="3">
          <ConsltantDataForm
            editData={detailsData && detailsData.consultant}
            isEditMode={isEditMode}
            handlePrev={handlePrev}
            isViewMode={isViewMode}
            submitForm={handleSubmit}
          />
        </TabPanel>
      </TabContext>
    </Layout>
  );
};

export default ManagePlans;
