import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  Autocomplete,
  Button,
  CircularProgress,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  FormHelperText,
  Select,
  Stack,
  SvgIcon,
  TextField,
  Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Formik } from 'formik';
import * as yup from 'yup';
import { toast } from 'react-toastify';
import { LoadingButton } from '@mui/lab';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import moment from 'moment';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import Layout from '../../../components/Layout/Layout';
import ControlledCheckbox from '../../../components/check-box/useCheckBox';
import {
  addAdmin,
  getJobs,
  editAdmin,
  getListAdminsNames,
  getRegionList,
  getCitiesListWithRegion,
  getDistrictListWithCity,
} from '../../../network/apis';

export default function AdminForm() {
  const [filters, setFilters] = useState({
    regionFilter: '',
    cityFilter: '',
    districtFilter: '',
  });
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const location = useLocation();
  const path = location.pathname;
  const editData = location.state;
  const editMode = path.includes('edit');

  const [jobsList, setJobsList] = useState([]);
  const [listAdminNames, setListAdminNames] = useState(null);
  const { t, i18n } = useTranslation();
  const isRtl = i18n.language === 'ar';
  useEffect(() => {
    getJobs().then((res) => {
      setJobsList(res.data);
    });
  }, []);

  useEffect(() => {
    getListAdminsNames().then((res) => {
      setListAdminNames(res.data);
    });
  }, []);

  // region states
  const [regionOptions, setRegionOptions] = useState([]);
  const [dropdownOpened, setDropdownOpened] = useState(false);

  const getAllRegions = (page, search) => {
    setLoading(true);
    getRegionList(page, 100, search)
      .then((res) => {
        setRegionOptions(res.data.content);

        setLoading(false);
      })
      .catch(() => setLoading(false));
  };

  useEffect(() => {
    if (!dropdownOpened) {
      getAllRegions(0, '');
      setDropdownOpened(true);
    }
  }, [editData, dropdownOpened]);

  // city states

  const [citydropdownOpened, setCityDropdownOpened] = useState(false);
  const [cityOptions, setCityOptions] = useState([]);

  const getCitiesWithRegion = (page, size, region) => {
    setLoading(true);
    getCitiesListWithRegion(page, size, region, filters.cityFilter)
      .then((res) => {
        setCityOptions(res.data.content);
        setLoading(false);
      })
      .catch(() => setLoading(false));
  };
  useEffect(() => {
    if (editData?.regionId) getCitiesWithRegion(0, 100, editData.regionId);
    else getCitiesWithRegion(0, 100, filters.regionFilter);
  }, [editData, citydropdownOpened, filters]);

  // District states

  const [districtdropdownOpened, setDistrictDropdownOpened] = useState(false);
  const [districtOptions, setDistrictoptions] = useState([]);

  const getDistrictWithCity = (page, size, city) => {
    setLoading(true);
    getDistrictListWithCity(page, 1000, city, filters.districtFilter)
      .then((res) => {
        setDistrictoptions(res.data.content);
        setLoading(false);
      })
      .catch(() => setLoading(false));
  };
  useEffect(() => {
    if (editData?.cityId) getDistrictWithCity(0, 100, editData.cityId);
    else getDistrictWithCity(0, 100, filters.cityFilter);
  }, [editData, districtdropdownOpened, filters]);
  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    setFilters((prevFilters) => ({
      ...prevFilters,
      [name]: value,
    }));
  };
  console.log(filters);
  const validationSchema = yup.object({
    firstNameEn: yup
      .string()
      .required(t('Validation.englishReq'))
      .max(30, t('Validation.max30Char'))
      .test('is-english', t('Validation.englishReq'), (value) => {
        const englishRegex = /^[A-Za-z0-9_\s]*$/;
        return englishRegex.test(value);
      }),

    firstNameAr: yup
      .string()
      .max(30, t('Validation.max30Char'))
      .required(t('Validation.arabicReq'))
      .test('is-arabic', t('Validation.arabicReq'), (value) => {
        const arabicRegex = /[\u0600-\u06FF]/;
        const englishRegex = /[A-Za-z0-9]/;
        return arabicRegex.test(value) && !englishRegex.test(value);
      }),

    lastNameEn: yup
      .string()
      .required(t('Validation.englishReq'))
      .max(30, t('Validation.max30Char'))
      .test('is-english', t('Validation.englishReq'), (value) => {
        const englishRegex = /^[A-Za-z0-9_\s]*$/;
        return englishRegex.test(value);
      }),

    lastNameAr: yup
      .string()
      .max(30, t('Validation.max30Char'))
      .required(t('Validation.arabicReq'))
      .test('is-arabic', t('Validation.arabicReq'), (value) => {
        const arabicRegex = /[\u0600-\u06FF]/;
        const englishRegex = /[A-Za-z0-9]/;
        return arabicRegex.test(value) && !englishRegex.test(value);
      }),

    nationalId: yup
      .string()
      .required(t('Validation.enterNationalId'))
      .test('is-numeric', t('Validation.mustBeNumber'), (value) => value !== undefined && !Number.isNaN(Number(value)))
      .test('is-10-digits', t('Validation.nationalDigits'), (value) => value !== undefined && value.length === 10),

    jobId: yup.string().required(t('Validation.requiredField')),

    birthDate: yup
      .string()
      .required(t('Validation.enterBirthDate'))
      .test('is-18-years-or-above', t('Validation.mustAbove18'), (value) => {
        const birthDate = moment(value, 'YYYY-MM-DD');
        const age = moment().diff(birthDate, 'years');
        return age >= 18;
      }),

    phone: yup
      .string()
      .required(t('General.required_field'))
      .matches(/^\d{9}$/, t('Validation.phoneValidation')),

    email: yup.string().email(t('Validation.EnterValidEmail')).required(t('Validation.requiredField')),

    managerId: yup.string().when('isManager', {
      is: (isManager) => !isManager,
      then: yup.string().required(t('Validation.selectManager')),
      otherwise: yup.string().nullable(),
    }),
  });
  const handleSubmit = async (values) => {
    const adminData = {
      jobId: values.jobId,
      fullNameEn: values.fullNameEn,
      fullNameAr: values.fullNameAr,
      firstNameEn: values.firstNameEn,
      firstNameAr: values.firstNameAr,
      lastNameEn: values.lastNameEn,
      lastNameAr: values.lastNameAr,
      cityId: values.city?.id,
      regionId: values.region?.id,
      districtId: values.district?.id,
      nationalId: values.nationalId,
      birthDate: values.birthDate,
      phone: values.phone,
      email: values.email,
      status: values.status ? '1' : '0',
      managerId: values.managerId || '',
      isManager: values.isManager ? '1' : '0',
    };
    setLoading(true);
    if (editMode) {
      const res = await editAdmin({ userId: editData.editData.userId, ...adminData });
      if (res.data.error || res.data.STATUS === 'FAILED') {
        toast.error(res.data.error || res.data.MESSAGE);
        setLoading(false);
      } else {
        toast.success(res.data.MESSAGE);
        setLoading(false);
        navigate('/dashboard/manage-employees');
      }
    } else {
      const res = await addAdmin({ ...adminData });
      if (res.data.error || res.data.STATUS === 'FAILED') {
        toast.error(res.data.error || res.data.MESSAGE);
        setLoading(false);
      } else {
        toast.success(res.data.MESSAGE);
        setLoading(false);
        navigate('/dashboard/manage-employees');
      }
    }
  };

  const CustomArrowIcon = ({ isRtl }) => (
    <SvgIcon style={{ transform: 'rotate(-360deg)', color: '#637381' }}>
      <path d="M7 10l5 5 5-5z" />
    </SvgIcon>
  );

  return (
    <Layout>
      <h3>{editData ? t('Employees.editAdmin') : t('Employees.newAdmin')}</h3>
      <Formik
        initialValues={{
          jobId: editData ? editData.editData.job.id : '',
          fullNameEn: editData ? editData.editData.fullNameEn : '',
          fullNameAr: editData ? editData.editData.fullNameAr : '',
          firstNameEn: editData ? editData.editData.firstNameEn : '',
          firstNameAr: editData ? editData.editData.firstNameAr : '',
          lastNameEn: editData ? editData.editData.lastNameEn : '',
          lastNameAr: editData ? editData.editData.lastNameAr : '',
          city: editData ? editData.editData.cityObj : '',
          region: editData ? editData.editData.regionObj : '',
          district: editData ? editData.editData.districtObj : '',
          nationalId: editData ? editData.editData?.nationalId : '',
          birthDate: editData ? editData.editData.birthDate : '',
          phone: editData ? editData.editData.phone : '',
          email: editData ? editData.editData.email : '',
          status: editData ? editData.editData.status : '1',
          managerId: editData ? editData.editData.managerId : '',
          isManager: editData ? editData.editData.isManager === '1' : false,
        }}
        validationSchema={validationSchema}
        onSubmit={(values) => {
          handleSubmit(values);
        }}
      >
        {({ values, errors, touched, handleChange, handleBlur, handleSubmit, setFieldValue }) => (
          <form onSubmit={handleSubmit}>
            <Grid rowSpacing={2} container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }}>
              <Grid item xs={12} sm={4} md={12}>
                <Typography variant="body1" gutterBottom>
                  {t('Employees.jobTitle')}
                </Typography>
                <FormControl fullWidth>
                  <Select
                    IconComponent={() => <CustomArrowIcon isRtl={isRtl} />}
                    labelId="jobs"
                    id="jobId"
                    name="jobId"
                    value={values.jobId}
                    onChange={handleChange}
                    error={touched.jobId && Boolean(errors.jobId)}
                    helperText={touched.jobId && errors.jobId}
                    displayEmpty // This prop ensures the placeholder is shown when no value is selected
                    renderValue={(selected) => {
                      if (!selected) {
                        return <em>{t('General.select')}</em>; // Placeholder text
                      }
                      return isRtl
                        ? jobsList?.find((option) => option.id === selected)?.nameAr
                        : jobsList?.find((option) => option.id === selected)?.nameEn;
                    }}
                  >
                    <MenuItem disabled value="">
                      {t('General.select')}
                    </MenuItem>
                    {jobsList &&
                      jobsList.map((job) => (
                        <MenuItem key={job.id} value={job.id}>
                          {isRtl ? job.nameAr : job.nameEn}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={2} sm={4} md={6}>
                <Typography variant="body1" gutterBottom>
                  {t('Employees.firstNameEn')}
                </Typography>
                <TextField
                  name="firstNameEn"
                  placeholder={t('General.input_placeholder')}
                  required
                  fullWidth
                  value={values.firstNameEn}
                  variant="outlined"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.firstNameEn && Boolean(errors.firstNameEn)}
                  helperText={touched.firstNameEn && errors.firstNameEn}
                />
              </Grid>
              <Grid item xs={2} sm={4} md={6}>
                <Typography variant="body1" gutterBottom>
                  {t('Employees.firstNameAr')}
                </Typography>
                <TextField
                  name="firstNameAr"
                  placeholder={t('General.input_placeholder')}
                  required
                  fullWidth
                  value={values.firstNameAr}
                  variant="outlined"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.firstNameAr && Boolean(errors.firstNameAr)}
                  helperText={touched.firstNameAr && errors.firstNameAr}
                />
              </Grid>
              <Grid item xs={2} sm={4} md={6}>
                <Typography variant="body1" gutterBottom>
                  {t('Employees.lastNameEn')}
                </Typography>
                <TextField
                  name="lastNameEn"
                  placeholder={t('General.input_placeholder')}
                  required
                  fullWidth
                  value={values.lastNameEn}
                  variant="outlined"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.lastNameEn && Boolean(errors.lastNameEn)}
                  helperText={touched.lastNameEn && errors.lastNameEn}
                />
              </Grid>
              <Grid item xs={2} sm={4} md={6}>
                <Typography variant="body1">{t('Employees.lastNameAr')}</Typography>
                <TextField
                  name="lastNameAr"
                  placeholder={t('General.input_placeholder')}
                  required
                  fullWidth
                  value={values.lastNameAr}
                  variant="outlined"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.lastNameAr && Boolean(errors.lastNameAr)}
                  helperText={touched.lastNameAr && errors.lastNameAr}
                />
              </Grid>
              <Grid item xs={2} sm={6} md={4}>
                <Stack gap={1}>
                  <Typography variant="body1">
                    {t('General.region')} <span style={{ color: 'red' }}>*</span>{' '}
                  </Typography>
                  <Autocomplete
                    disablePortal
                    id="combo-box-demo"
                    value={values?.region ? values?.region : null}
                    onChange={(__, newValue) => {
                      // Set the new region value
                      setFieldValue('region', newValue);

                      // Clear city and district if region is changed or unset
                      if (!newValue) {
                        setFieldValue('city', null);
                        setFieldValue('district', null);
                      }
                    }}
                    options={regionOptions?.map((option) => ({
                      id: option.id,
                      nameEn: option.nameEn,
                      nameAr: option.nameAr,
                    }))}
                    error={touched.region || Boolean(errors.region)}
                    helperText={touched.region || errors.region}
                    getOptionLabel={(option) => (isRtl ? option.nameAr : option.nameEn || option.nameEn)}
                    sx={{ width: '100%' }}
                    renderInput={(params) => (
                      <TextField
                        placeholder={t('Employees.enterRegion')}
                        name="regionFilter"
                        onChange={handleFilterChange}
                        value={filters.regionFilter}
                        {...params}
                      />
                    )}
                    IconComponent={() => <CustomArrowIcon isRtl={isRtl} />}
                  />
                </Stack>
              </Grid>
              <Grid item xs={2} sm={6} md={4}>
                <Stack gap={1}>
                  <InputLabel id="cityId">
                    {t('General.City')} <span style={{ color: 'red' }}>*</span>{' '}
                  </InputLabel>
                  <Autocomplete
                    disablePortal
                    id="combo-box-demo"
                    disabled={!values?.region} // City field is disabled if no region is selected
                    value={values?.city ? values?.city : null}
                    onOpen={() => {
                      if (values?.region) {
                        getCitiesWithRegion(0, 1000, values.region?.id);
                      }
                    }}
                    onChange={(__, newValue) => {
                      setFieldValue('city', newValue);

                      // Clear district if city is changed or unset
                      if (!newValue) {
                        setFieldValue('district', null);
                      }
                    }}
                    options={cityOptions?.map((option) => ({
                      id: option.id,
                      nameEn: option.nameEn,
                      nameAr: option.nameAr,
                    }))}
                    error={touched.city || Boolean(errors.city)}
                    helperText={touched.city || errors.city}
                    getOptionLabel={(option) => (isRtl ? option.nameAr : option.nameEn || option.nameEn)}
                    sx={{ width: '100%' }}
                    renderInput={(params) => (
                      <TextField
                        name="cityFilter"
                        placeholder={t('Employees.enterCity')}
                        onChange={handleFilterChange}
                        value={filters.cityFilter}
                        {...params}
                      />
                    )}
                    IconComponent={() => <CustomArrowIcon isRtl={isRtl} />}
                  />

                  {touched.city && errors.city && <FormHelperText error>{errors.city}</FormHelperText>}
                </Stack>
              </Grid>
              <Grid item xs={2} sm={6} md={4}>
                <Stack gap={1}>
                  <InputLabel id="districtId">
                    {t('General.district')} <span style={{ color: 'red' }}>*</span>{' '}
                  </InputLabel>
                  <Autocomplete
                    disablePortal
                    id="combo-box-demo"
                    disabled={!values?.region || !values?.city} // District is disabled if no region or city is selected
                    value={values?.district ? values?.district : null}
                    onOpen={() => {
                      if (values?.city) {
                        getDistrictWithCity(0, 1000, values.city?.id, '');
                      }
                    }}
                    onChange={(__, newValue) => {
                      setFieldValue('district', newValue);
                    }}
                    options={districtOptions?.map((option) => ({
                      id: option.id,
                      nameEn: option.nameEn,
                      nameAr: option.nameAr,
                    }))}
                    error={touched.district || Boolean(errors.district)}
                    helperText={touched.district || errors.district}
                    getOptionLabel={(option) => (isRtl ? option.nameAr : option.nameEn || option.nameEn)}
                    sx={{ width: '100%' }}
                    renderInput={(params) => (
                      <TextField
                        name="districtFilter"
                        placeholder={t('Employees.enterDistrict')}
                        onChange={handleFilterChange}
                        value={filters.districtFilter}
                        {...params}
                      />
                    )}
                    IconComponent={() => <CustomArrowIcon isRtl={isRtl} />}
                  />

                  {touched.district && errors.district && <FormHelperText error>{errors.district}</FormHelperText>}
                </Stack>
              </Grid>
              <Grid item xs={2} sm={4} md={6}>
                <Typography variant="body1" gutterBottom>
                  {t('Employees.nationalId')}
                </Typography>
                <TextField
                  name="nationalId"
                  placeholder={t('General.input_placeholder')}
                  required
                  fullWidth
                  value={values?.nationalId}
                  variant="outlined"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched?.nationalId && Boolean(errors?.nationalId)}
                  helperText={touched?.nationalId && errors?.nationalId}
                />
              </Grid>
              <Grid item xs={2} sm={4} md={6}>
                <Typography variant="body1" gutterBottom>
                  {t('Employees.dateBirth')}
                </Typography>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    sx={{ width: '100%' }}
                    value={values.birthDate ? dayjs(values.birthDate) : null}
                    onChange={(newValue) => {
                      setFieldValue('birthDate', newValue ? newValue.format('YYYY-MM-DD') : '');
                    }}
                    renderInput={(params) => (
                      <TextField
                        onBlur={handleBlur}
                        InputLabelProps={{
                          style: { textAlign: isRtl ? 'right' : 'left' },
                        }}
                        error={Boolean(errors.birthDate && touched.birthDate)} // Add error prop to TextField
                        helperText={touched.birthDate && errors.birthDate ? errors.birthDate : ''} // Add helper text for the error
                        {...params}
                        fullWidth
                      />
                    )}
                  />
                </LocalizationProvider>
                {/* Remove the separate error message and use helperText in TextField */}
              </Grid>
              <Grid item xs={2} sm={4} md={6}>
                <Typography variant="body1" gutterBottom>
                  {t('Employees.phone')}
                </Typography>
                <TextField
                  name="phone"
                  placeholder={t('General.input_placeholder')}
                  required
                  fullWidth
                  value={values.phone}
                  variant="outlined"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.phone && Boolean(errors.phone)}
                  helperText={touched.phone && errors.phone}
                />
              </Grid>
              <Grid item xs={2} sm={4} md={6}>
                <Typography variant="body1" gutterBottom>
                  {t('ownerForm.email')}
                </Typography>
                <TextField
                  name="email"
                  placeholder={t('General.input_placeholder')}
                  required
                  fullWidth
                  value={values.email}
                  variant="outlined"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.email && Boolean(errors.email)}
                  helperText={touched.email && errors.email}
                />
              </Grid>
              <Grid item xs={2} sm={4} md={6}>
                <Stack>
                  <InputLabel id="status">{t('General.Status')}</InputLabel>
                  <Select
                    sx={{ mt: 1 }}
                    labelId="status"
                    IconComponent={() => <CustomArrowIcon isRtl={isRtl} />}
                    id="status"
                    value={values.status}
                    onChange={(event) => {
                      handleChange({
                        target: {
                          name: 'status',
                          value: event.target.value,
                        },
                      });
                    }}
                  >
                    <MenuItem value="1">{t('General.Active')}</MenuItem>
                    <MenuItem value="0">{t('General.InActive')}</MenuItem>
                  </Select>
                </Stack>
              </Grid>
              <Grid item xs={12} sm={4} md={6}>
                <Typography variant="body1" gutterBottom>
                  {t('Employees.directManager')}
                </Typography>
                <FormControl fullWidth>
                  <Select
                    labelId="manager"
                    id="managerId"
                    name="managerId"
                    IconComponent={() => <CustomArrowIcon isRtl={isRtl} />}
                    label={t('Employees.directManager')}
                    value={values.managerId}
                    onChange={handleChange}
                    error={touched.managerId && Boolean(errors.managerId)}
                    disabled={values.isManager}
                    // input={
                    //   <OutlinedInput
                    //     startAdornment={
                    //       <InputAdornment position="start" sx={{ direction: 'rtl', textAlign: 'right' }}>
                    //         {t('General.select')}
                    //       </InputAdornment>
                    //     }
                    //   />
                    // }
                  >
                    {listAdminNames &&
                      listAdminNames.map((manager) => (
                        <MenuItem key={manager.userId} value={manager.userId}>
                          {isRtl
                            ? `${manager?.firstNameAr} ${manager.lastNameAr}`
                            : `${manager?.firstNameEn} ${manager?.lastNameEn}`}
                        </MenuItem>
                      ))}
                  </Select>
                  {touched.managerId && errors.managerId && (
                    <span style={{ color: 'red' }}>{t('Employees.thisFieldIsRequired')}</span>
                  )}
                </FormControl>
              </Grid>{' '}
              <Grid item xs={2} sm={4} md={6}>
                <ControlledCheckbox
                  label={t('Employees.isManager')}
                  checked={values.isManager}
                  onChange={(event) => {
                    handleChange(event);
                    setFieldValue('isManager', event.target.checked);
                  }}
                />
              </Grid>
              <Stack
                sx={{ width: '100%', mt: 4 }}
                direction="row"
                justifyContent="end"
                alignItems="end"
                spacing={2}
                gap={1}
              >
                <Button
                  sx={{ mt: 4, border: '1px solid #111827', mr: 4 }}
                  size="large"
                  type="button"
                  variant=""
                  onClick={() => navigate(`/dashboard/manage-employees`)}
                >
                  {t('Units.cancel')}
                </Button>
                <LoadingButton sx={{ mt: 4 }} loading={loading} size="large" type="submit" variant="contained">
                  {t('Units.save')}
                </LoadingButton>
              </Stack>
            </Grid>
          </form>
        )}
      </Formik>
    </Layout>
  );
}
