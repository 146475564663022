import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { alpha } from '@mui/material/styles';
import { Box, Divider, Typography, Stack, MenuItem, Avatar, IconButton, Popover, Button } from '@mui/material';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import LogoutIcon from '@mui/icons-material/Logout';
import HomeIcon from '@mui/icons-material/Home';
import account from '../../../_mock/account';
import ModalData from '../../../components/modal/modal';

export default function AccountPopover() {
  const { t, i18n } = useTranslation();
  const isRtl = i18n.language === 'ar';
  const Icons = (icon) => {
    switch (icon) {
      case 'home':
        return <HomeIcon />;
      case 'logout':
        return <LogoutIcon />;
      case 'profile':
        return <AccountCircleIcon />;
      default:
        return null;
    }
  };
  const MENU_OPTIONS = [
    {
      label: t('General.home'),
      icon: 'home',
      path: '/dashboard',
    },
    {
      label: t('General.profile'),
      icon: 'profile',
      path: '/dashboard/profile',
    },
  ];
  const [open, setOpen] = useState(null);
  const [dialogOpen, setDialogOpen] = useState(false);
  const navigate = useNavigate();
  const user = JSON.parse(localStorage.getItem('user') || sessionStorage.getItem('user'));
  const avatar = user?.profilePicture;
  const handleOpen = (event) => {
    setOpen(event.currentTarget);
  };

  const handleClose = () => {
    setOpen(null);
  };

  const handleLogoutClick = () => {
    setDialogOpen(true);
    handleClose();
  };

  const handleDialogClose = () => {
    setDialogOpen(false);
  };

  const handleConfirmLogout = () => {
    localStorage.clear();
    sessionStorage.clear();
    navigate('/login');
    handleDialogClose();
  };

  return (
    <>
      <IconButton
        onClick={handleOpen}
        sx={{
          p: 0,
          ...(open && {
            '&:before': {
              zIndex: 1,
              content: "''",
              width: '100%',
              height: '100%',
              borderRadius: '50%',
              position: 'absolute',
              bgcolor: (theme) => alpha(theme.palette.grey[900], 0.8),
            },
          }),
        }}
      >
        <Avatar src={avatar} alt="photoURL" />
      </IconButton>

      <Popover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        PaperProps={{
          sx: {
            p: 0,
            mt: 1.5,
            ml: 0.75,
            width: 180,
            '& .MuiMenuItem-root': {
              typography: 'body2',
              borderRadius: 0.75,
            },
          },
        }}
      >
        <Box sx={{ my: 1.5, px: 2.5 }}>
          <Typography variant="subtitle2" noWrap>
            {isRtl ? `${user?.firstNameAr} ${user?.lastNameAr}` : `${user?.firstNameEn} ${user?.lastNameEn}`}
          </Typography>
        </Box>

        <Divider sx={{ borderStyle: 'dashed' }} />

        <Stack sx={{ p: 1 }}>
          {MENU_OPTIONS.map((option) => (
            <MenuItem
              sx={{ display: 'flex', justifyContent: 'space-between' }}
              key={option.label}
              onClick={() => {
                navigate(option.path);
                handleClose();
              }}
            >
              {option.label} {Icons(option.icon)}
            </MenuItem>
          ))}
        </Stack>

        <MenuItem
          onClick={handleLogoutClick}
          sx={{ margin: '0 10px 10px 10px', padding: '2px 12px', display: 'flex', justifyContent: 'space-between' }}
        >
          {t('General.logout')} <LogoutIcon sx={{ marginRight: '10px' }} />
        </MenuItem>
      </Popover>
      <ModalData isOpen={dialogOpen} handleClose={handleDialogClose} width={350}>
        <Box
          sx={{
            textAlign: 'center',
            height: '14vh',
          }}
        >
          <Typography sx={{ fontWeight: 'bold' }}>{t('General.logout_text')}</Typography>
          <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2, gap: 2 }}>
            <Button onClick={handleDialogClose} sx={{ border: '1px solid', borderRadius: '8px' }} color="primary">
              {t('General.cancel')}
            </Button>
            <Button
              onClick={handleConfirmLogout}
              sx={{ border: '1px solid', borderRadius: '8px' }}
              color="primary"
              autoFocus
            >
              {t('General.logout')}
            </Button>
          </Box>
        </Box>
      </ModalData>
    </>
  );
}
