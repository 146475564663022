import axios from 'axios';

import { Container, Typography } from '@mui/material';
import DOMPurify from 'dompurify';
import { useEffect, useState } from 'react';
import LoadingOverlay from '../components/loading-overlay/LoadingOverlay';
import { BACKEND_URL } from '../constants';

const ViewPrivacyPolicy = () => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const sanitizedHtml = DOMPurify.sanitize(data?.EN);
  const fetchData = async () => {
    setLoading(true);
    try {
      const response = await axios.get(`${BACKEND_URL}/termsPrivacy/getPrivacyDetails`);
      setData(response.data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };
  useEffect(() => {
    fetchData();
  }, []);
  if (loading) {
    return <LoadingOverlay />;
  }

  return (
    <Container sx={{ mt: 4, mb: 4 }}>
      <Typography variant="h4" sx={{ mb: 2 }}>
        Privacy & Policy
      </Typography>

      <div
        style={{
          whiteSpace: 'pre-wrap',
          border: '1px solid #ccc',
          borderRadius: '5px',
          padding: '10px',
          background: '#f8f8f8',
        }}
        dangerouslySetInnerHTML={{ __html: sanitizedHtml }}
      />
    </Container>
  );
};

export default ViewPrivacyPolicy;
