import React from 'react';
import { useTranslation } from 'react-i18next';
import { Dialog, DialogActions, DialogContent, DialogTitle, Button, Box, TextField } from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';

const DateFilterDialog = ({ open, onClose, onSave, startDate, setStartDate, endDate, setEndDate }) => {
  const { t, i18n } = useTranslation()
  const isRtl = i18n.language === "ar"
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>{t('General.selectDate')}</DialogTitle>
      <DialogContent>
        <LocalizationProvider dateAdapter={AdapterMoment}>
          <Box display="flex" flexDirection="column" gap={2} sx={{ width: 400, mt: 2 }}>
          <span>{t('General.startDate')}</span>
            <DatePicker
              value={startDate}
              onChange={(newValue) => setStartDate(newValue)}
              renderInput={(params) => <TextField {...params} />}
            />
              <span>{t('General.endDate')}</span>
            <DatePicker
              value={endDate}
              onChange={(newValue) => setEndDate(newValue)}
              renderInput={(params) => (
                <TextField
                  {...params}
                  InputProps={{
                    ...params.InputProps,
                    sx: {
                        '& .MuiInputAdornment-root': {
                        marginLeft: 'auto',  // Adjust icon position
                        marginRight: '0',    // Reset any potential right margin
                      },
                    },
                  }}
                />
              )}
            />

          </Box>
        </LocalizationProvider>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>{t('General.cancel')}</Button>
        <Button onClick={onSave} variant="contained" color="primary">
          {t('General.generate')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default DateFilterDialog;
