// src/RolesTable.js
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

// localization

import { useTranslation } from 'react-i18next';

import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  Box,
  Pagination,
} from '@mui/material';
import { styled } from '@mui/system';

import TableReqHead from '../../../components/clientHeadTable/ClientHeadTable';

import { ReactComponent as EditIcon } from '../../../theme/images/editIcon.svg';

const StatusLabel = styled('span')(({ status }) => ({
  textAlign: 'center',
  padding: '16px',
  fontSize: '13px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  color: status === '1' ? '#0CAF60' : '#E03137',
  backgroundColor: status === '1' ? '#E7F7EF' : '#FFEDEC',
  fontWeight: 700,
  width: '88px!important',
  height: '24px!important',
  borderRadius: '8px',
}));

const RolesTable = ({ handleChangePage, page, requests, handleChangeRowsPerPage, rowsPerPage }) => {
  const { t, i18n } = useTranslation();
  const isRtl = i18n.language === 'ar';
  const TABLE_HEAD = [
    { id: 'roleName', label: t('Roles.roleName'), alignRight: isRtl && true },
    { id: 'lastUpdate', label: t('Roles.lastUpdate'), alignRight: isRtl && true },
    { id: 'creation_date', label: t('General.creationDate'), alignRight: isRtl && true },
    { id: 'roleStatus', label: t('General.Status'), alignRight: isRtl && true },
    { id: 'action', label: t('General.Action'), alignRight: isRtl && true },
  ];
  const [selectedRole, setSelectedRole] = useState('');

  const handleRoleChange = (event) => {
    setSelectedRole(event.target.value);
  };
  const navigate = useNavigate();
  return (
    <>
      <TableContainer component={Paper}>
        <Table sx={{ direction: isRtl ? 'rtl' : 'ltr' }}>
          <TableReqHead t={t} isRtl={isRtl} headLabel={TABLE_HEAD} />

          <TableBody>
            {requests?.totalElements === 0 && (
              <Box textAlign="center">
                <h5>{t('General.noDataFound')}</h5>
              </Box>
            )}
            {requests?.content?.map((role, index) => (
              <TableRow key={index}>
                <TableCell component="th" scope="row" sx={{ textAlign: isRtl ? 'right' : 'left' }}>
                  {isRtl ? role.nameAr : role.nameEn}
                </TableCell>
                <TableCell component="th" scope="row" sx={{ textAlign: isRtl ? 'right' : 'left' }}>
                  {role.creationDate}
                </TableCell>
                <TableCell component="th" scope="row" sx={{ textAlign: isRtl ? 'right' : 'left' }}>
                  {role.lastUpdateDate || '-'}
                </TableCell>
                <TableCell component="th" scope="row" sx={{ textAlign: isRtl ? 'right' : 'left' }}>
                  <StatusLabel status={role.active}>{role.active === '1' ? t('General.Active') : t('General.InActive')}</StatusLabel>
                </TableCell>
                <TableCell component="th" scope="row" sx={{ textAlign: isRtl ? 'right' : 'left' }}>
                  <IconButton
                    style={{
                      backgroundColor: '#2F78EE',
                      color: 'white',
                      borderRadius: '8px',
                      padding: '10px',
                    }}
                    onClick={() => {
                      navigate(role?.roleId ? `/dashboard/role/edit/${role?.roleId}` : '/dashboard/project/add', {
                        state: { editData: role },
                      });
                    }}
                  >
                    <EditIcon style={{ fontSize: '16px' }} />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        {requests?.totalPages > 1 && (
          <div style={{ display: 'flex', justifyContent: 'space-between', padding: '10px', marginTop: '5rem' }}>
            <Pagination count={requests.totalPages} page={page + 1} onChange={handleChangePage} color="primary" />

            <Box sx={{ display: 'block' }}>
              {t('General.showing')} {page * rowsPerPage + 1} {t('General.to')}{' '}
              {Math.min((page + 1) * rowsPerPage, requests.totalElements)} {t('General.of')} {requests.totalElements}{' '}
              {t('General.entries')}
            </Box>
            {/* <FormControl variant="outlined" size="small">
              <Select value={rowsPerPage} onChange={handleChangeRowsPerPage}>
                <MenuItem value={8}>8</MenuItem>
                <MenuItem value={16}>16</MenuItem>
                <MenuItem value={24}>24</MenuItem>
              </Select>
            </FormControl> */}
          </div>
        )}
      </TableContainer>
    </>
  );
};

export default RolesTable;
