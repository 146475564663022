import React, { useState, useRef } from 'react';
import { IconButton, Typography, Snackbar, Chip, Box, Button, Alert } from '@mui/material';
import { AttachFileOutlined, VideoFile, PictureAsPdf, ImageOutlined } from '@mui/icons-material';
import { ReactComponent as UploadIcon } from '../../theme/images/uploadIcon.svg';
import { ReactComponent as PdfIcon } from '../../theme/images/pdfIcon.svg';

const FileUpload = ({
  onUpload,
  label,
  multiple,
  maxSize,
  fieldName,
  viewMode,
  deleteItem = () => {},
  uploadedFiles = [],
  setUploadedFiles,
}) => {
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const fileInputRef = useRef(null);

  const handleFileChange = (event) => {
    const files = event.target.files;
    const fileErrors = [];
    let newFiles = Array.from(files);

    // Check for file size errors
    newFiles = newFiles.filter((file) => {
      // if (file.size > maxSize) {
      //   fileErrors.push(`${file.name} exceeds the maximum size of ${maxSize / 1000000}MB.`);
      //   return false;
      // }
      return true;
    });

    if (fileErrors.length) {
      setSnackbarMessage(fileErrors.join('\n'));
      setOpenSnackbar(true);
      return;
    }

    // Set the fieldName on each file
    newFiles = newFiles.map((file) => ({ file, fieldName }));

    if (!multiple) {
      newFiles = [newFiles[0]];
      setUploadedFiles(newFiles);
    } else {
      setUploadedFiles((prevFiles) => [...prevFiles, ...newFiles]);
    }

    onUpload(newFiles, fieldName);
  };

  const handleDeleteFile = (id) => {
    deleteItem(fieldName, id);
    setUploadedFiles((prevFiles) => prevFiles.filter((file) => file.id !== id));
  };

  const handleCloseSnackbar = () => setOpenSnackbar(false);
  const handleButtonClick = () => fileInputRef.current.click();

  return (
    <>
      <Button
        sx={{
          display: 'flex',
          border: '1px solid #E9EAEC',
          marginBottom: '1rem',
          padding: '16px 20px',
          height: '54px',
          borderRadius: '10px',
          width: '100%',
          justifyContent: 'space-between',
          flexDirection: 'row-reverse',
        }}
        onClick={!viewMode ? handleButtonClick : undefined}
        startIcon={<UploadIcon />}
        disabled={viewMode}
      >
        <input
          ref={fileInputRef}
          type="file"
          multiple={multiple}
          accept="*/*"
          disabled={viewMode}
          onChange={handleFileChange}
          style={{ display: 'none' }}
          id={`upload-${label}`}
        />
        <label htmlFor={`upload-${label}`}>
          <IconButton component="span">
            <Typography variant="body2">{label || 'Upload File'}</Typography>
          </IconButton>
        </label>
      </Button>

      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert onClose={handleCloseSnackbar} severity="error" sx={{ width: '100%' }}>
          {snackbarMessage}
        </Alert>
      </Snackbar>

      {multiple && uploadedFiles.length > 0 && (
        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1 }}>
          {uploadedFiles.map((fileObj, index) => {
            const { file } = fileObj;
            let icon;
            if (file?.type?.startsWith('image/')) {
              icon = <ImageOutlined sx={{ color: '#FF2511 !important' }} />;
            } else if (file?.type?.startsWith('video/')) {
              icon = <VideoFile sx={{ color: '#BA0F30' }} />;
            } else if (file?.type === 'application/pdf') {
              icon = <PdfIcon />;
            } else {
              icon = <AttachFileOutlined />;
            }

            return (
              <Chip
                key={index}
                icon={icon}
                label={file.name}
                onDelete={viewMode ? {} : () => handleDeleteFile(index)}
                onClick={() => {
                  const fileURL = URL.createObjectURL(file);
                  window.open(fileURL, '_blank');
                }}
              />
            );
          })}
        </Box>
      )}

      {!multiple && uploadedFiles?.length > 0 && (
        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1 }}>
          {uploadedFiles.map((fileObj, index) => {
            const { file } = fileObj;
            let icon;

            if (file && file.type) {
              if (file.type.startsWith('image/')) {
                icon = <ImageOutlined sx={{ color: '#FF2511 !important' }} />;
              } else if (file.type.startsWith('video/')) {
                icon = <VideoFile sx={{ color: '#BA0F30' }} />;
              } else if (file.type === 'application/pdf') {
                icon = <PdfIcon />;
              } else {
                icon = <AttachFileOutlined />;
              }
            } else {
              icon = <AttachFileOutlined />;
            }

            return (
              <Chip
                key={index}
                icon={icon}
                label={file?.name || 'Unknown File'}
                onDelete={viewMode ? undefined : () => handleDeleteFile(index)}
                onClick={() => {
                  if (file) {
                    const fileURL = URL.createObjectURL(file);
                    window.open(fileURL, '_blank');
                  }
                }}
              />
            );
          })}
        </Box>
      )}
    </>
  );
};

export default FileUpload;
