import React from 'react';

import { Chip } from '@mui/material';

import { AttachFileOutlined, ImageOutlined } from '@mui/icons-material';

import { ReactComponent as PdfIcon } from '../../theme/images/pdfIcon.svg';

const PreviewFile = ({ ViewMode = true, file, onDelete = () => {} }) => {
  const getFileIcon = (fileName) => {
    const extension = fileName?.split('.')?.pop()?.toLowerCase();
    switch (extension) {
      case 'jpg':
      case 'jpeg':
      case 'png':
      case 'gif':
        return <ImageOutlined sx={{ color: '#FF2511 !important' }} />;
      case 'pdf':
        return <PdfIcon />;
      default:
        return <AttachFileOutlined sx={{ color: '#FF2511 !important' }} />;
    }
  };
  return ViewMode ? (
    <Chip
      icon={getFileIcon(file.displayName)}
      label={file?.displayName} // Display the file name
      onClick={() => window.open(file.path, '_blank')}
      sx={{
        cursor: 'pointer',
        textOverflow: 'ellipsis',
        direction: 'ltr',
        overflow: 'hidden',
        maxWidth: '200px',
      }}
    />
  ) : (
    <Chip
      icon={getFileIcon(file.displayName)}
      label={file?.displayName} // Display the file name
      onClick={() => window.open(file.path, '_blank')}
      onDelete={onDelete}
      sx={{
        cursor: 'pointer',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        direction: 'ltr',
        maxWidth: '200px',
      }}
    />
  );
};

export default PreviewFile;
