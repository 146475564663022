/* eslint-disable react/prop-types */
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

// @mui
import { useNavigate } from 'react-router-dom';
import {
  Table,
  TableRow,
  TableBody,
  Button,
  TableCell,
  Typography,
  TableContainer,
  TablePagination,
  Tooltip,
} from '@mui/material';
import { Box } from '@mui/system';

// components
import Scrollbar from '../../../components/scrollbar';
// sections
import ClientReqHead from '../../../components/clientHeadTable/ClientHeadTable';

// icon imports

import { ReactComponent as DeleteIcon } from '../../../theme/images/deleteTableIcon.svg';

// ----------------------------------------------------------------------

// ----------------------------------------------------------------------

export default function ClientReqTable({ requests, handleChangePage, page, onDelete }) {
  const [rowsPerPage] = useState(1);
  const navigate = useNavigate();

  const { t, i18n } = useTranslation();
  const isRtl = i18n.language === 'ar';

  const TABLE_HEAD = [
    { id: 'nameEn', label: t('General.nameEn'), alignRight: false },
    { id: 'nameAr', label: t('General.nameAr'), alignRight: false },
    { id: 'description', label: t('General.description'), alignRight: false },
    { id: 'creation_date', label: t('General.creationDate'), alignRight: false },
    { id: 'duration', label: t('General.duration'), alignRight: false },
    { id: 'action', label: t('General.Action'), alignRight: false },
  ];
  return (
    <>
      <Scrollbar>
        <TableContainer sx={{ minWidth: 800 }}>
          <Table>
            <ClientReqHead headLabel={TABLE_HEAD} />
            <TableBody>
              {requests?.content?.map((row) => {
                const { id, nameEn, nameAr, creationDate, duration, description } = row;
                const descriptionWords = description ? description.split(' ') : [];
                const showTooltip = descriptionWords.length > 20;
                return (
                  <TableRow hover key={id} tabIndex={-1}>
                    <TableCell component="th" scope="row">
                      <Typography
                        sx={{ color: 'text.secondary', fontWeight: '400', fontSize: '12px', lineHeight: '20px' }}
                        variant="subtitle2"
                        noWrap
                      >
                        {' '}
                        {nameEn}
                      </Typography>
                    </TableCell>
                    <TableCell component="th" scope="row">
                      <Typography
                        sx={{ color: 'text.secondary', fontWeight: '400', fontSize: '12px', lineHeight: '20px' }}
                        variant="subtitle2"
                        noWrap
                      >
                        {' '}
                        {nameAr}
                      </Typography>
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {showTooltip ? (
                        <Tooltip title={description}>
                          <Typography
                            sx={{ color: 'text.secondary', fontWeight: '400', fontSize: '12px', lineHeight: '20px' }}
                            variant="subtitle2"
                            noWrap
                          >
                            {' '}
                            {description.split(' ').slice(0, 3).join(' ')}...
                          </Typography>
                        </Tooltip>
                      ) : (
                        <Typography
                          sx={{ color: 'text.secondary', fontWeight: '400', fontSize: '12px', lineHeight: '20px' }}
                          variant="subtitle2"
                          noWrap
                        >
                          {' '}
                          {description === '0' ? '' : description}
                        </Typography>
                      )}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      <Typography
                        sx={{ color: 'text.secondary', fontWeight: '400', fontSize: '12px', lineHeight: '20px' }}
                        variant="subtitle2"
                        noWrap
                      >
                        {' '}
                        {creationDate}
                      </Typography>
                    </TableCell>
                    <TableCell component="th" scope="row">
                      <Typography
                        sx={{ color: 'text.secondary', fontWeight: '400', fontSize: '12px', lineHeight: '20px' }}
                        variant="subtitle2"
                        noWrap
                      >
                        {duration} <span> {t('General.month')}</span>
                      </Typography>
                    </TableCell>
                    <TableCell align="left">
                      <Button onClick={() => onDelete(id)}>
                        <DeleteIcon />
                      </Button>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Scrollbar>
      {requests?.totalElements === 0 && (
        <Box textAlign="center">
          <h5>{t('General.noDataFound')}</h5>
        </Box>
      )}

      {requests?.totalPages > 0 && (
        <TablePagination
          rowsPerPageOptions={[9]}
          component="div"
          count={requests.totalPages}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
        />
      )}
    </>
  );
}
