// src/store/features/Login/loginSlice.js
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isLoggedIn: false,
  RememberMe: false,
  sideMenu: JSON.parse(localStorage.getItem('sideMenu') || sessionStorage.getItem('sideMenu')) || [],
};

const loginSlice = createSlice({
  name: 'login',
  initialState,
  reducers: {
    setLoggedIn: (state, action) => {
      state.isLoggedIn = action.payload;
    },
    setSideMenu: (state, action) => {
      state.sideMenu = action.payload;
      localStorage.setItem('sideMenu', JSON.stringify(state.sideMenu)); // Save to localStorage immediately
      sessionStorage.setItem('sideMenu', JSON.stringify(state.sideMenu));
    },
    logout: (state) => {
      state.isLoggedIn = false;
      state.sideMenu = [];
      localStorage.removeItem('userToken');
      localStorage.removeItem('sideMenu');
      sessionStorage.removeItem('userToken');
      sessionStorage.removeItem('sideMenu');
    },
  },
});

export const { setLoggedIn, setSideMenu, logout } = loginSlice.actions;

export default loginSlice.reducer;
