import imageCompression from 'browser-image-compression';

function useCompressImage() {
  async function compressImage(file) {
    let unpdatefile;
    const originalSizeMB = file.size / 1024 / 1024;
    // Define the maximum size in MB after compression
    const maxSize = originalSizeMB * 0.6; // Example: reduce to 60% of original if less than 1MB
    const options = {
      maxSizeMB: maxSize,
      maxWidthOrHeight: 800,
      useWebWorker: true,
      initialQuality: 0.9,
    };
    // Compress the image
    try {
      const compressedFile = await imageCompression(file, options);
      unpdatefile = new File([compressedFile], file.name, {
        type: file.type,
        lastModified: Date.now(),
      });
    } catch (error) {
      console.error('Error during compression:', error);
    }
    return unpdatefile;
  }
  return [compressImage];
}
export default useCompressImage;
