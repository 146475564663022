/* eslint-disable react/prop-types */
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
// @mui
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import ThumbDownIcon from '@mui/icons-material/ThumbDown';
import {
  Table,
  TableRow,
  TableBody,
  TableCell,
  Typography,
  TableContainer,
  Box,
  IconButton,
  Button,
} from '@mui/material';
import EllipsisText from "react-ellipsis-text";
// components
import Label from '../../../components/label';
import Scrollbar from '../../../components/scrollbar';
import ConfirmationDialog from '../../../components/dialog/Dialog';
// sections
import { RequestApprovalHead } from './index';

// mock

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'function', label: 'Function', alignRight: false },
  { id: 'technology', label: 'Technology', alignRight: false },
  { id: 'level', label: 'Level', alignRight: false },
  { id: 'Estimate', label: 'Estimate', alignRight: false },
  { id: 'availability', label: 'Availability', alignRight: false },
  { id: 'status', label: 'Status', alignRight: false },
  { id: 'comment', label: 'Comment', alignRight: false },
  { id: 'view', label: 'View Details', alignRight: false },
  { id: 'actions', label: '', alignRight: false },

  // { id: '' },
];

// ----------------------------------------------------------------------

export default function RequestApprovalTable({ list, requestList }) {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [page] = useState(0);

  const [rowsPerPage] = useState(25);
  const [request_technology_status, setRequestTechnologyStatus] = useState({});

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - list.length) : 0;

  return (
    <>
      <Scrollbar>
        <TableContainer sx={{ minWidth: 800 }}>
          <Table>
            <RequestApprovalHead headLabel={TABLE_HEAD} rowCount={list.length} />
            <TableBody>
              {list.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index) => {
                const { id, category, availabilty, level, comment, status, technology, time } = row;
                return (
                  <TableRow hover key={index} tabIndex={-1}>
                    <TableCell component="th" scope="row">
                      <Typography variant="subtitle2" noWrap>
                        {category?.name}
                      </Typography>
                    </TableCell>
                    <TableCell align="left">{technology?.name}</TableCell>
                    <TableCell align="left">{level}</TableCell>
                    <TableCell align="left">{time}</TableCell>
                    <TableCell align="left">{availabilty}</TableCell>

                    <TableCell align="left">
                      <Label color={(status === 3 && 'error') || (status === 1 && 'success') || 'warning'}>
                        {status === 1 ? 'Approved' : status === 3 ? 'Rejected' : 'Pending'}
                      </Label>
                    </TableCell>
                    <TableCell align="left">
                      {comment ? <EllipsisText text={comment} length={30} />  : "-"}
                    </TableCell>
                    <TableCell align="left">
                      <Button onClick={() => navigate(`/dashboard/request-technology-details/${id}`)}>View</Button>
                    </TableCell>
                    <TableCell>
                      {status === 2 && (
                        <Box display="flex">
                          <IconButton
                            onClick={() => {
                              setOpen(true);
                              setRequestTechnologyStatus({
                                status: 1,
                                id,
                              });
                            }}
                            sx={{ color: '#198754' }}
                          >
                            <ThumbUpIcon />
                          </IconButton>
                          <IconButton
                            onClick={() => {
                              setOpen(true);
                              setRequestTechnologyStatus({
                                status: 0,
                                id,
                              });
                            }}
                            sx={{ color: '#dc3545' }}
                          >
                            <ThumbDownIcon />
                          </IconButton>
                        </Box>
                      )}
                    </TableCell>
                  </TableRow>
                );
              })}
              {emptyRows > 0 && (
                <TableRow style={{ height: 53 * emptyRows }}>
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <ConfirmationDialog
          open={open}
          setOpen={setOpen}
          requestList={requestList}
          request_technology_status={request_technology_status}
        />
      </Scrollbar>
    </>
  );
}
