import * as React from 'react';
import Checkbox from '@mui/material/Checkbox';
import { FormControlLabel, FormGroup } from '@mui/material';

export default function ControlledCheckbox({ checked, notChecked, onChange, label }) {
  return (
    <>
      <FormGroup>
        <FormControlLabel
          control={
            <Checkbox
              checked={checked}
              disabled={notChecked}
              onChange={onChange}
              inputProps={{ 'aria-label': 'controlled' }}
            />
          }
          label={label || 'Mandatory'}
        />
      </FormGroup>
    </>
  );
}
