import { useEffect } from 'react';
import { ToastContainer } from 'react-toastify';
import { I18nextProvider, useTranslation } from 'react-i18next';
// routes
import Router from './routes';
// theme
import ThemeProvider from './theme';
// components
import ScrollToTop from './components/scroll-to-top';
import 'react-toastify/dist/ReactToastify.css';
import { PusherProvider } from './PusherContext';
import { NotificationProvider } from './context/NotificationsContext';

import i18n from './i18n';
import { changeLanguage } from './network/apis';

// ----------------------------------------------------------------------
if (window.location.href.includes('request-approval')) {
  localStorage.setItem('referrer_link', window.location.href);
}
const DirectionHandler = ({ children }) => {
  const { i18n } = useTranslation();
  // const direction = 'ltr';

  // useEffect(() => {
  //   document.body.setAttribute('dir', direction);
  // }, [direction]);

  return <>{children}</>;
};

export default function App() {
  const { i18n } = useTranslation();
  const isRtl = i18n.language === 'ar';

  useEffect(() => {
    document.body.setAttribute('dir', isRtl ? 'rtl' : 'ltr');
    const apiChangeLanguage = async () => {
      const res = await changeLanguage(isRtl ? 'ar' : 'en');
    };
    apiChangeLanguage();
  }, [isRtl]);
  return (
    <I18nextProvider i18n={i18n}>
      <ThemeProvider>
        <PusherProvider>
          <NotificationProvider>
            <DirectionHandler>
              <ScrollToTop />
              <Router />
              <ToastContainer
                position="top-right"
                autoClose={5000}
                hideProgressBar={false}
                closeOnClick
                pauseOnHover
                theme="light"
              />
            </DirectionHandler>
          </NotificationProvider>
        </PusherProvider>
      </ThemeProvider>
    </I18nextProvider>
  );
}
