import { Helmet } from 'react-helmet-async';
// @mui
import { styled } from '@mui/material/styles';
import { Box, Button, Container, Typography } from '@mui/material';
// hooks
import useResponsive from '../hooks/useResponsive';
// components
import Logo from '../components/logo';
// sections
import { LoginForm } from '../sections/auth/login';

// ----------------------------------------------------------------------

const StyledRoot = styled('div')(({ theme }) => ({
  backgroundImage: "url('/assets/video/cover.mp4')",
  backgroundSize: 'cover',
  backgroundRepeat: 'no-repeat',
  width: '100%',
  height: '100vh',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  [theme.breakpoints.up('md')]: {
    display: 'flex',
  },
}));

const StyledSection = styled('div')(({ theme }) => ({
  width: '100%',
  maxWidth: 500,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  boxShadow: theme.customShadows.card,
  backgroundColor: theme.palette.background.default,
}));

const StyledContent = styled('div')(({ theme }) => ({
  maxWidth: 400,
  maxHeight: 400,
  position: 'relative',
  backgroundColor: 'white',
  margin: 'auto',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  padding: theme.spacing(3, 3),
  borderRadius: '15px',
}));
const StyledVIdeo = styled('video')(({ theme }) => ({
  width: '100%',
  height: '100%',
  objectFit: 'cover',
}));

// ----------------------------------------------------------------------

export default function LoginPage() {
  const mdUp = useResponsive('up', 'md');

  return (
    <>
      <Helmet>
        <title> Login </title>
      </Helmet>

      <StyledRoot>
        {/* {mdUp && (
          <StyledSection>
            <img src="/assets/images/login/Photo.png" alt="login" />
          </StyledSection>
        )} */}

        <Container maxWidth="sm">
          <Box position={'absolute'} top={0} left={0} width={'100%'} height={'100%'} overflow={'hidden'}>
            <StyledVIdeo src="/assets/video/cover.mp4" autoPlay loop muted />
          </Box>
          <StyledContent>
            {/* <Logo /> */}
            {/* <div>
              <img
                src="/assets/images/login/Ornament.png"
                alt="Ornament"
                style={{
                  width: '96px',
                  height: '41px',
                  imageRendering: 'auto',
                  objectFit: 'contain',
                  position: 'relative',
                  bottom: '66px',
                  right: '22%',
                }}
              />
            </div> */}
            {/* <Typography
              sx={{ mb: 5, color: '#111827', fontFamily: 'Manrope', textAlign: 'center' }}
              variant="h4"
              gutterBottom
            >
              Login to your first account
            </Typography> */}
            <LoginForm />
          </StyledContent>
        </Container>
      </StyledRoot>
    </>
  );
}
