import React, { useState, useRef } from "react";
import { useTranslation } from "react-i18next";

import {
  IconButton,
  Typography,
  Snackbar,
  Chip,
  Box,
  Button,
  Alert,
  LinearProgress,
} from "@mui/material";

import CircularProgress from "@mui/material/CircularProgress";

import { VideoFile, ImageOutlined } from "@mui/icons-material";
import { ReactComponent as UploadIcon } from "../../theme/images/uploadIcon.svg";
import { ReactComponent as PdfIcon } from "../../theme/images/pdfIcon.svg";
import { axiosInstance } from "../../network/config";

import useCompressImage from "../../hooks/useCompressImage";

function addIdToUploadedFile(uploadedFiles = [], ids = []) {
  const result = uploadedFiles.map((file, index) => ({
    file,
    id: ids[index],
  }));
  return result;
}

const UploadFileOnChange = ({
  viewMode,
  uploadedFilesUploaded,
  type,
  category,
  label,
  multiple,
  acceptedTypes,
  fieldName,
  setFieldValue = () => {},
  addItem = () => {},
  deleteItem = () => {},
}) => {
  const { t } = useTranslation();
  const [handleCompress] = useCompressImage();
  const [uploadProgress, setUploadProgress] = useState(0);
  const [loading, setLoading] = useState(false);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const fileInputRef = useRef(null);
  const handleFileChange = async (event) => {
    const files = Array.from(event.target.files);
    let newUploadedFiles = [];

    if (multiple) {
      newUploadedFiles = [...uploadedFilesUploaded[fieldName], ...files];
    } else {
      newUploadedFiles = [files[0]];
    }
    try {
      setLoading(true);
      const formData = new FormData();

      if (type === "ATTACHMENT" || type === "SKETCH") {
        newUploadedFiles.forEach((file) => {
          formData.append("file", file);
        });
      } else {
        const compressedFiles = await Promise.all(
          newUploadedFiles.map((file) => handleCompress(file))
        );
        compressedFiles.forEach((file) => {
          formData.append("file", file);
        });
      }
      const response = await axiosInstance.post(
        "/utils/uploadToServer",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
          onUploadProgress: (progressEvent) => {
            const progress = Math.round(
              (progressEvent.loaded * 100) / progressEvent.total
            );
            setUploadProgress(progress);
          },
          params: {
            type,
            category,
          },
        }
      );
      const uploadedFileWithId = addIdToUploadedFile(
        newUploadedFiles,
        response.data
      );
      addItem(fieldName, uploadedFileWithId, multiple);
      setFieldValue("updateUi", uploadedFileWithId);
    } catch (e) {
      setSnackbarMessage(e.response.data.message);
      setOpenSnackbar(true);
    } finally {
      setLoading(false);
    }
  };

  const handleDeleteFile = (id) => {
    deleteItem(fieldName, id);
  };

  const handleCloseSnackbar = () => setOpenSnackbar(false);
  const handleButtonClick = () => fileInputRef.current.click();
  return (
    <>
      <Button
        sx={{
          display: "flex",
          border: "1px solid #E9EAEC",
          marginBottom: "1rem",
          padding: "16px 20px",
          height: "54px",
          borderRadius: "10px",
          width: "100%",
          justifyContent: "space-between",
          flexDirection: "row-reverse",
        }}
        disabled={viewMode}
        onClick={handleButtonClick}
        startIcon={<UploadIcon />}
      >
        <input
          ref={fileInputRef}
          type="file"
          multiple={multiple}
          accept={acceptedTypes}
          onChange={handleFileChange}
          style={{ display: "none" }}
          id={`upload-${label}`}
        />
        <label htmlFor={`upload-${label}`}>
          <IconButton
            sx={{
              width: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Typography sx={{ mr: 1 }} variant="body2">
              {label || "Upload File"}
            </Typography>
            {loading && (
              <CircularProgress
                sx={{ color: "#FF2511 !important" }}
                size={20}
              />
            )}
          </IconButton>
        </label>
      </Button>
      {loading && (
        <Box sx={{ marginTop: "16px" }}>
          <Typography variant="body1">
            {t("General.uploading")}: {uploadProgress}%
          </Typography>
          <LinearProgress variant="determinate" value={uploadProgress} />
        </Box>
      )}
      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        <Alert
          onClose={handleCloseSnackbar}
          severity="error"
          sx={{ width: "100%" }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>

      {multiple && uploadedFilesUploaded[fieldName].length > 0 && (
        <Box sx={{ display: "flex", flexWrap: "wrap", gap: 1 }}>
          {uploadedFilesUploaded[fieldName].map((file, index) => {
            let icon;
            if (
              file?.file?.type?.startsWith("image/") ||
              file?.type?.startsWith("image/")
            ) {
              icon = <ImageOutlined sx={{ color: "#FF2511 !important" }} />;
            } else if (
              file?.file?.type?.startsWith("video/") ||
              file?.type?.startsWith("video/")
            ) {
              icon = <VideoFile sx={{ color: "#BA0F30 !important" }} />;
            } else if (
              file?.file?.type?.startsWith("application/pdf") ||
              file?.type?.startsWith("application/pdf")
            ) {
              icon = <PdfIcon />;
            } else {
              icon = (
                <CircularProgress
                  sx={{ color: "#FF2511 !important" }}
                  size={24}
                />
              ); // Replace PdfIcon with CircularProgress
            }
            return (
              <Chip
                key={index}
                icon={icon}
                label={file?.file?.name}
                onDelete={() => handleDeleteFile(file?.id)}
                onClick={() => {
                  const fileURL = URL?.createObjectURL(file?.file);
                  window.open(fileURL, "_blank");
                }}
                sx={{
                  cursor: "pointer",
                  textOverflow: "ellipsis",
                  overflow: "hidden",
                  direction: "ltr",
                  maxWidth: "200px",
                }}
              />
            );
          })}
        </Box>
      )}

      {!multiple && uploadedFilesUploaded[fieldName]?.length > 0 && (
        <Box sx={{ display: "flex", flexWrap: "wrap", gap: 1 }}>
          {uploadedFilesUploaded[fieldName]?.map((file, index) => {
            let icon;
            if (
              file?.file?.type?.startsWith("image/") ||
              file?.type?.startsWith("image/")
            ) {
              icon = <ImageOutlined sx={{ color: "#FF2511 !important" }} />;
            } else if (
              file?.file?.type?.startsWith("video/") ||
              file?.type?.startsWith("video/")
            ) {
              icon = <VideoFile sx={{ color: "#BA0F30 !important" }} />;
            } else if (
              file?.file?.type?.startsWith("application/pdf") ||
              file?.type?.startsWith("application/pdf")
            ) {
              icon = <PdfIcon />;
            } else {
              icon = (
                <CircularProgress
                  sx={{ color: "#FF2511 !important" }}
                  size={24}
                />
              ); // Replace PdfIcon with CircularProgress
            }
            return (
              <Chip
                sx={{
                  cursor: "pointer",
                  textOverflow: "ellipsis",
                  overflow: "hidden",
                  maxWidth: "200px",
                  padding: "5px 10px 5px 10px",
                }}
                key={index}
                icon={icon}
                label={file?.file?.name}
                onClick={() => {
                  const fileURL = URL.createObjectURL(file?.file);
                  window.open(fileURL, "_blank");
                }}
              />
            );
          })}
        </Box>
      )}
    </>
  );
};

export default UploadFileOnChange;
