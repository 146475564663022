/* eslint-disable react/prop-types */
// localization

import { useTranslation } from 'react-i18next';

import { useState } from 'react';
// @mui
import { useNavigate } from 'react-router-dom';
import {
  Table,
  TableRow,
  TableBody,
  Button,
  TableCell,
  Typography,
  TableContainer,
  Pagination,
  Box,
} from '@mui/material';

// icons imports

import { ReactComponent as EditIcon } from '../../../theme/images/editTableIcon.svg';

// components
import Scrollbar from '../../../components/scrollbar/Scrollbar';

// sections
import ClientReqHead from '../../../components/clientHeadTable/ClientHeadTable';

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'NAME_EN', label: 'Unit Name', alignRight: false },
  { id: 'creation_date', label: 'Creation Date', alignRight: false },
  { id: 'status', label: 'Status', alignRight: false },
  { id: 'action', label: 'Action', alignRight: false },
];

// ----------------------------------------------------------------------

export default function ClientReqTable({ requests, handleChangePage, page, onDelete }) {
  const [rowsPerPage] = useState(1);
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const isRtl = i18n.language === 'ar';
  const TABLE_HEAD = [
    { id: 'NAME_EN', label: t('General.name'), alignRight: isRtl && true },
    { id: 'creation_date', label: t('General.creationDate'), alignRight: isRtl && true },
    { id: 'status', label: t('General.Status'), alignRight: isRtl && true },
    { id: 'action', label: t('General.Action'), alignRight: isRtl && true },
  ];

  return (
    <>
      <Scrollbar>
        <TableContainer sx={{ minWidth: 800 }}>
          <Table sx={{ direction: isRtl ? 'rtl' : 'ltr' }}>
            <ClientReqHead t={t} isRtl={isRtl} headLabel={TABLE_HEAD} />
            <TableBody>
              {requests?.map((row) => {
                const { id, nameEn, CREATION_DATE, states } = row;
                const displayDate = CREATION_DATE
                  ? new Date(CREATION_DATE).toLocaleDateString()
                  : new Date().toLocaleDateString();

                return (
                  <TableRow hover key={id} tabIndex={-1}>
                    <TableCell sx={{ textAlign: isRtl ? 'right' : 'left' }} component="th" scope="row">
                      <Typography
                        sx={{ color: 'text.secondary', fontWeight: '400', fontSize: '12px', lineHeight: '20px' }}
                        variant="subtitle2"
                        noWrap
                      >
                        {' '}
                        {nameEn}
                      </Typography>
                    </TableCell>

                    <TableCell sx={{ textAlign: isRtl ? 'right' : 'left' }} component="th" scope="row">
                      <Typography
                        sx={{ color: 'text.secondary', fontWeight: '400', fontSize: '12px', lineHeight: '20px' }}
                        variant="subtitle2"
                        noWrap
                      >
                        {' '}
                        {displayDate || ''}
                      </Typography>
                    </TableCell>
                    <TableCell sx={{ textAlign: isRtl ? 'right' : 'left' }} component="th" scope="row">
                      <Typography
                        sx={{ color: 'text.secondary', fontWeight: '400', fontSize: '12px', lineHeight: '20px' }}
                        variant="subtitle2"
                        noWrap
                      >
                        {' '}
                        {states}
                      </Typography>
                    </TableCell>
                    <TableCell sx={{ textAlign: isRtl ? 'right' : 'left' }}>
                      <Button
                        onClick={() =>
                          navigate(id ? `/dashboard/units/edit/${id}` : '/dashboard/units/add', {
                            state: { from: 'edit-project' },
                          })
                        }
                      >
                        <EditIcon />
                      </Button>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Scrollbar>
      {requests?.totalElements === 0 && (
        <Box textAlign="center">
          <h5>No data found</h5>
        </Box>
      )}

      {requests?.totalPages > 0 && (
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            padding: '10px',
            marginTop: '5rem',
          }}
        >
          <Pagination count={requests.totalPages} page={page + 1} onChange={handleChangePage} color="primary" />
          <Box sx={{ display: 'block' }}>
            {t('General.showing')} {page * rowsPerPage + 1} {t('General.to')}{' '}
            {Math.min((page + 1) * rowsPerPage, requests.totalElements)} {t('General.of')} {requests.totalElements}{' '}
            {t('General.entries')}
          </Box>
        </Box>
      )}
    </>
  );
}
