import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@mui/material/styles';
import { Container, Typography, Card } from '@mui/material';
import { FinancialDataForm } from '../sections/@dashboard/financialData';
// components
// ----------------------------------------------------------------------

export default function AddFinancialData() {
  const theme = useTheme();

  const { t, i18n } = useTranslation();
  const isRtl = i18n.language === 'ar';
  return (
    <>
      <Helmet>
        <title> {t('manageApplication.financialData')}  </title>
      </Helmet>

      <Container >
      
        <div style={{ height: "80vh" }} >
          <FinancialDataForm  />
        </div>
      </Container>
    </>
  );
}
