// @mui
import { GlobalStyles as MUIGlobalStyles } from '@mui/material';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
// ----------------------------------------------------------------------

export default function GlobalStyles() {
  const { i18n } = useTranslation();
  const [direction, setDirection] = useState('ltr');

  useEffect(() => {
    setDirection(i18n.language === 'ar' ? 'rtl' : 'ltr');
  }, [i18n.language]);

  const inputGlobalStyles = (
    <MUIGlobalStyles
      styles={{
        '*': {
          boxSizing: 'border-box',
          fontFamily: 'Cairo, sans-serif',
        },
        html: {
          margin: 0,
          direction,
          padding: 0,
          width: '100%',
          height: '100%',
          WebkitOverflowScrolling: 'touch',
          fontFamily: 'Cairo, sans-serif',
        },
        body: {
          margin: 0,
          padding: 0,
          width: '100%',
          height: '100%',
          fontFamily: 'Cairo, sans-serif',
          textAlign: direction === 'rtl' ? 'right' : 'left',
        },
        '#root': {
          width: '100%',
          height: '100%',
        },
        input: {
          '&[type=number]': {
            MozAppearance: 'textfield',
            '&::-webkit-outer-spin-button': {
              margin: 0,
              WebkitAppearance: 'none',
            },
            '&::-webkit-inner-spin-button': {
              margin: 0,
              WebkitAppearance: 'none',
            },
          },
          '&:-webkit-autofill': {
            '-webkit-box-shadow': `0 0 0 100px #f1f6eb  inset !important`,
            '-webkit-text-fill-color': '#121212 !important',
            caretColor: '#121212  !important',
            borderRadius: 'inherit',
          },
        },
        img: {
          display: 'block',
          maxWidth: '100%',
        },
        ul: {
          margin: 0,
          padding: 0,
        },
        '.MuiAutocomplete-endAdornment': {
          right: direction === 'rtl' ? '90%!important' : '0',
        },
        '#buildingId': {
          left: direction === 'rtl' ? '90%' : '0%',
        },
      }}
    />
  );

  return inputGlobalStyles;
}
