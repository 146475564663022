import { styled } from '@mui/material/styles';
import { ListItemIcon, ListItemButton, ListItemText } from '@mui/material';
import { TreeItem } from '@mui/lab';

// ----------------------------------------------------------------------

export const StyledNavItem = styled((props) => <ListItemButton disableGutters {...props} />)(({ theme, active }) => ({
  ...theme.typography.body2,
  height: 48,
  position: 'relative',
  textTransform: 'capitalize',
  backgroundColor: active ? (theme.palette.mode === 'dark' ? theme.palette.grey[800] : '#32de84') : '',
  borderRadius: theme.shape.borderRadius,
  color: active ? 'white' : theme.palette.primary.secondary, // Change color when active
}));

export const SubStyledNavItem = styled((props) => <ListItemButton disableGutters {...props} />)(
  ({ theme, active, isRtl }) => ({
    ...theme.typography.body2,
    height: 48,
    ...(isRtl
      ? { marginRight: theme.spacing(2), paddingRight: '22px' }
      : { marginLeft: theme.spacing(2), paddingLeft: '22px' }),
    width: '80%',
    textTransform: 'capitalize',
    backgroundColor: active ? (theme.palette.mode === 'dark' ? theme.palette.grey[800] : 'rgba(0, 167, 67, .6)') : '',
    borderRadius: theme.shape.borderRadius,
    color: active ? 'white' : theme.palette.primary.secondary, // Change color when active,

    '&:hover': {
      backgroundColor: theme.palette.mode === 'dark' ? theme.palette.grey[400] : 'rgba(0, 167, 67, .3)',
    },
    ...(active && {
      '&:before': {
        content: '""',
        height: '40%',
        position: 'absolute',
        ...(isRtl
          ? {
              right: -20,
              left: 'auto',
              borderRadius: '50px 0 0 50px',
            }
          : {
              left: -20,
              right: 'auto',
              borderRadius: '0 50px 50px 0',
            }),
        top: '50%',
        transform: 'translateY(-50%)',
        width: 4,
        backgroundColor: theme.palette.mode === 'dark' ? theme.palette.grey[400] : 'rgba(0, 167, 67, .6)',
      },
    }),
  })
);

export const StyledNavItemText = styled((props) => <ListItemText disableTypography {...props} />)(
  ({ theme, active, open }) => ({
    fontSize: 15,
    fontWeight: '400',
    ':hover': {
      backgroundColor: 'transparent',
    },
    display: open ? 'block' : 'none',
  })
);

export const StyledNavItemIcon = styled(ListItemIcon)({
  width: 22,
  height: 22,
  minWidth: 22,
  borderRadius: '50%',
  color: 'inherit',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
});

export const StyledTreeItem = styled(TreeItem)(({ theme }) => ({
  borderLeft: `1px solid ${theme.palette.divider}`,
  border: `5px solid ${theme.palette.mode === 'dark' ? theme.palette.grey[800] : 'green'}`,
  marginBottom: theme.spacing(1),
  padding: theme.spacing(1),
  '&:last-child': {
    marginBottom: 0,
  },
}));
